import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '../../../../components';
import {
  fromUserRoleToUrl,
  getCustomsDestinationName,
  URLS,
} from '../../../../utils';
import { Sim } from '../../../../utils/types/sims';
import useStore from '../../../../zustand';
import StatusTag from '../StatusTag';
import {
  StyledCardBottomlData,
  StyledCardBottomlDataList,
  StyledCardContainer,
  StyledCardGeneralData,
} from './CardData.styled';
import ModalOpener from './ModalOpener';
import { mapSimBalance, mapSimDetail } from './utils';

type Props = {
  data: Sim;
};

const CardData: FC<Props> = ({ data }) => {
  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const generalData = [
    {
      title: 'Exportador',
      content: <ModalOpener data={data?.attributes?.exporter_id} />,
    },
    {
      title: 'Importador',
      content: <ModalOpener data={data?.attributes?.importer_id} />,
    },
    {
      title: 'Despachante de Aduana',
      content: (
        <Typography theme="p">{data?.attributes?.customs_agent}</Typography>
      ),
    },
    {
      title: 'Agente de Transporte Aduanero',
      content: (
        <Typography theme="p">{data?.attributes?.customs_agent}</Typography>
      ),
    },
    {
      title: 'Vencimiento Embarque',
      content: (
        <Typography theme="p">
          {data?.attributes?.shipment_due_date?.toString()}
        </Typography>
      ),
    },
    {
      title: 'Aduana Destino',
      content: (
        <Typography theme="p">
          {getCustomsDestinationName(data?.attributes?.customs_destination)}
        </Typography>
      ),
    },
    {
      title: 'Factura',
      content: (
        <Link
          to={`/${transformedUserRole}${URLS.invoiceDetail.replace(
            '{invoiceID}',
            data?.attributes?.invoice_id?.data?.id.toString(),
          )}`}
        >
          <Typography theme="p">
            {data?.attributes?.invoice_id?.data?.attributes?.invoice_number}
          </Typography>
        </Link>
      ),
    },
    {
      title: 'CRT',
      content: (
        <Link
          to={`/${transformedUserRole}/facturas/${data?.attributes?.invoice_id?.data?.id}/sims/${data?.id}/crts/${data?.attributes?.crt_id?.id}`}
        >
          <Typography theme="p">{data?.attributes?.crt_id?.number}</Typography>
        </Link>
      ),
    },
    {
      title: 'Transporte',
      content: <ModalOpener data={data?.attributes?.crt_id?.carrier_company} />,
    },
  ];

  return (
    <StyledCardContainer>
      <Link
        to={`/${transformedUserRole}/facturas/${data.attributes.invoice_id.data.id}/sims/${data.id}`}
      >
        <Typography theme="h4" as="h2" $marginBottom="2rem">
          SIM {data?.attributes?.number}
        </Typography>
      </Link>
      {/* General Data */}
      <StyledCardGeneralData>
        {generalData.map((item) => (
          <li key={item.title}>
            <Typography theme="p" as="h3">
              {item.title}:
            </Typography>{' '}
            {item.content}
          </li>
        ))}
      </StyledCardGeneralData>
      {/* Status */}
      <div>
        <Typography
          theme="p"
          as="h3"
          $marginBottom="3rem"
          $marginTop="3rem"
          $marginRight="0.5rem"
        >
          Estado:
        </Typography>
        <StatusTag status={data?.attributes?.state} />
      </div>
      <StyledCardBottomlData>
        {/* Details */}
        <div>
          <Typography theme="p" as="h3" $marginBottom="1rem">
            Detalle:
          </Typography>
          <StyledCardBottomlDataList>
            {mapSimDetail(data?.attributes?.sim_items).map((item, index) => {
              return (
                <li key={item.replaceAll(' ', `-${index}-`)}>
                  <Typography theme="p">{item}</Typography>
                </li>
              );
            })}
          </StyledCardBottomlDataList>
        </div>
        {/* Balance */}
        <div>
          <Typography theme="p" as="h3" $marginBottom="1rem">
            Saldo:
          </Typography>
          <StyledCardBottomlDataList>
            {mapSimBalance(data?.attributes?.sim_items).map((item, index) => {
              return (
                <li key={item.replaceAll(' ', `-${index}-`)}>
                  <Typography theme="p">{item}</Typography>
                </li>
              );
            })}
          </StyledCardBottomlDataList>
        </div>
      </StyledCardBottomlData>
    </StyledCardContainer>
  );
};

export default CardData;
