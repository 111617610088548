import { Invoice, TableData } from '../../../types';

export const transformData = (data?: Invoice[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      invoice_number: item.attributes.invoice_number,
      exporter: item?.attributes?.exporter_id?.data?.attributes?.business_name,
      importer: item?.attributes?.importer_id?.data?.attributes?.business_name,
      exporterCompanyId: item?.attributes?.exporter_id?.data?.id,
      importerCompanyId: item?.attributes?.importer_id?.data?.id,
      detail: item?.attributes?.detail?.trim().replaceAll(',\n ', ',\n'),
      balance: item?.attributes?.balance?.trim().replaceAll(',\n ', ',\n'),
      operable: item.attributes.operable,
    };
  });

  return transformedData;
};
