import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Loader,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, Role, URLS } from '../../utils';
import AvailableComponent from '../../utils/AvailableComponent';
import { PaginationData } from '../../utils/types';
import useStore from '../../zustand';
import TrucksTable from './components/TrucksTable';
import useTrucks from './hooks/useTrucks';
import { StyledActionsContainer } from './Trucks.styled';

const PageContent = () => {
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.trucks,
      name: 'Camiones',
      withUserRole: true,
    },
  ];

  const handleOnClickNewTruck = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.addTruck}`);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 20,
    current: currentPage,
    onChange: setCurrentPage,
  };

  const {
    data,
    handleDeleteTruck,
    onCheckboxFilterChange,
    onInputFilterChange,
    totalResults,
    isLoading,
    handleTableChange,
  } = useTrucks({ paginationData });

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />

      {isLoading && <Loader />}
      <Typography theme="h1" $marginBottom="2rem">
        Camiones
      </Typography>
      <StyledActionsContainer>
        <CustomButton
          $isMini
          $marginBottom="6rem"
          onPress={handleOnClickNewTruck}
          text="Nuevo Camión"
        />
        <AvailableComponent availableFor={[Role.Transporter]}>
          <Link to={`/${transformedUserRole}/polizas`}>
            <CustomButton
              $isMini
              $marginBottom="6rem"
              text="Actualizar Pólizas"
              $theme="secondary"
            />
          </Link>
        </AvailableComponent>
      </StyledActionsContainer>
      <TrucksTable
        data={data}
        onDeleteTruck={handleDeleteTruck}
        onInputCheckboxChange={onCheckboxFilterChange}
        onInputFilterChange={onInputFilterChange}
        paginationData={{
          ...paginationData,
          total: totalResults,
        }}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
