export const EMAIL_VALIDATION =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ONLY_NUMBERS_VALIDATION = /^[0-9]+$/;

export const ONLY_FLOAT_NUMBERS_VALIDATION =
  /^(?=.*[1-9])(?=.{1,11}$)([1-9][0-9]*|0)(\.[0-9]+)?$/;

export const ONLY_LETTERS_VALIDATION = /^[a-zA-Z]*$/;
