import styled from 'styled-components';

import { theme } from '../../styles';

export const SubTextContainer = styled.div`
  color: ${theme.colors.darkGray};
  margin-top: 0.5rem;
  text-align: center;
`;

export const StyledCheckbox = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  input {
    appearance: auto;
    height: 2rem;
    position: relative;
    width: 2rem;
    &::before,
    &::after {
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &::before {
      background-color: ${theme.colors.defaultWhite};
      border-radius: 0.3rem;
      border: 2px solid ${theme.colors.defaultGray};
      color: ${theme.colors.darkGray};
    }
    &::after {
      border-radius: 0.3rem;
      height: 75%;
      opacity: 0.9;
      width: 70%;
    }
    &:checked {
      &::after {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;

export const StyledCheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
