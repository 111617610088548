import { RelatedDocsList } from '../../../types';
import { TableData } from '../types';

export const transformData = (data?: RelatedDocsList[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: `${item.crt?.id || Math.floor(Math.random() * 10)}-${
        item.crt?.id || Math.floor(Math.random() * 10)
      }-${item.mics_count}`,
      sim: item.sim.number,
      simID: item.sim.id,
      crt: item.crt.number,
      crtID: item.crt.id,
      mics: item.mics_count,
    };
  });

  return transformedData;
};
