import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { BoxContainer, Breadcrumb } from '../../components';
import { URLS } from '../../utils';
import AddCompanyForm from './components/AddCompanyForm';

const PageContent = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryCompanyType = searchParams.get('tipo');

  const currentTitle = params.company_id
    ? 'Editar mi empresa'
    : `Nueva Empresa ${queryCompanyType}`;

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: `${URLS.addCompany}${queryCompanyType}`,
      name: currentTitle,
      withUserRole: true,
    },
  ];

  return (
    <BoxContainer $useMarginTop $useMarginBottom>
      <Breadcrumb items={breadcrumbItems} />
      <AddCompanyForm
        title={currentTitle}
        companyId={params.company_id}
        companyType={queryCompanyType}
        ctaText={params.company_id ? 'Actualizar' : 'Crear'}
      />
    </BoxContainer>
  );
};

export default PageContent;
