import { formatDate } from '../../../../../utils/dates';
import { CrtLocationAttributes } from '../../../../../utils/types/crt';
import { TableData } from '../types';

export const transformData = (data?: CrtLocationAttributes[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item, index) => {
    const description = !index ? 'Recepción' : 'Entrega';
    return {
      key: index,
      description,
      place: item.place,
      country: item.country,
      date: item.date ? formatDate(item.date, 'simple') : 'No especificado',
    };
  });

  return transformedData;
};
