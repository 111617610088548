import axios, { AxiosError } from 'axios';

import { useRedirectOnTokenExpired } from '.';

const useAxios = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
  });
  const { handleOnTokenExpired } = useRedirectOnTokenExpired();

  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      handleOnTokenExpired(error);
      return Promise.reject(error);
    },
  );

  return instance;
};

export default useAxios;
