import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder } from '../../../utils';
import { useAxios } from '../../../utils/hooks';
import { CompanyResponse } from '../../../utils/types/company';
import { Crt } from '../../../utils/types/crt';
import {
  InputAutocompleteOption,
  Pagination,
} from '../../../utils/types/index';
import { CrtResponse, TableData } from '../types';

type Props = {
  paginationCurrentPage: number;
};

const useCrts = ({ paginationCurrentPage }: Props) => {
  const API_URL_CRTS = 'custom-crt/all?populate=*',
    API_URL_GET_CLIENTS =
      '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';

  const paginationParams = `&pagination[page]=${paginationCurrentPage}&pagination[pageSize]=10`;

  const filterSimNumberQuery = '&filters[sim_number][$containsi]=',
    filterCrtNumberQuery = '&filters[number][$containsi]=',
    filterTransporterQuery = '&filters[carrier_company][id][$containsi]=',
    filterCheckboxTextQuery = '&filters[active][$eq]=';

  const axios = useAxios();

  const [data, setData] = useState<Crt[] | null>(null);
  const [transporterOptions, setTransporterOptions] = useState<
    InputAutocompleteOption[]
  >([]);
  const [paginationData, setPaginationData] = useState<Pagination>(
    {} as Pagination,
  );
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');

  // Filter Controls
  const [filterSimNumberValue, setFilterSimNumberValue] = useState('');
  const [filterCrtNumberValue, setFilterCrtNumberValue] = useState('');
  const [filterTransporterValue, setFilterTransporterValue] = useState('');
  const [filterCheckboxValue, setFilterCheckboxTextValue] = useState(false);
  const [filterTransporterTextQueryInput, setFilterTransporterTextQueryInput] =
    useState<string | null>(null);

  // Build final API URL with filters
  const finalURL = () => {
    const queryParams = [];

    if (filterSimNumberValue) {
      queryParams.push(`${filterSimNumberQuery}${filterSimNumberValue}`);
    }

    if (filterCrtNumberValue) {
      queryParams.push(`${filterCrtNumberQuery}${filterCrtNumberValue}`);
    }
    if (filterTransporterValue) {
      queryParams.push(`${filterTransporterQuery}${filterTransporterValue}`);
    }

    if (filterCheckboxValue) {
      queryParams.push(`${filterCheckboxTextQuery}${filterCheckboxValue}`);
    }

    const queryString = queryParams.join('');

    return `${API_URL_CRTS}${paginationParams}${queryString}${sortQuery}`;
  };

  const getCrts = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);
    axios
      .get<CrtResponse>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data.data);
        setPaginationData(response.data.meta.pagination);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Get Carrier Companies
  const getCarrierCompanyOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponse>(
        `${API_URL_GET_CLIENTS}${filterTransporterTextQueryInput}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputAutocompleteOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  key: `${item.id}`,
                  label: item.attributes.business_name,
                  value: item.attributes.business_name,
                };
              })
            : [];

        setTransporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  useEffect(() => {
    getCrts();
  }, [
    paginationCurrentPage,
    filterCrtNumberValue,
    filterSimNumberValue,
    filterCheckboxValue,
    filterTransporterValue,
    sortQuery,
  ]);

  useEffect(() => {
    const hasNotFilterTransporterTextQueryInput =
      filterTransporterTextQueryInput?.length === 0;

    const hasFilterTransporterTextQueryInput =
      filterTransporterTextQueryInput &&
      !!filterTransporterTextQueryInput.length;

    if (hasNotFilterTransporterTextQueryInput) {
      setFilterTransporterValue(filterTransporterTextQueryInput);
      dispatchResetTransporterOptions();
    }

    if (hasFilterTransporterTextQueryInput) {
      getCarrierCompanyOption();
    }
  }, [filterTransporterTextQueryInput]);

  const dispatchResetTransporterOptions = () => {
    setTransporterOptions([]);
  };

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    data,
    hasError,
    isLoading,
    paginationData,
    transporterOptions,
    filterCheckboxValue,
    setFilterSimNumberValue,
    setFilterCrtNumberValue,
    setFilterTransporterValue,
    setFilterCheckboxTextValue,
    setFilterTransporterTextQueryInput,
    handleTableChange,
  };
};

export default useCrts;
