import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { Pagination } from '../../../utils/types/index';
import { Sim } from '../../../utils/types/sims';
import { SimResponse } from '../types';

type Props = {
  paginationCurrentPage: number;
};

const useSims = ({ paginationCurrentPage }: Props) => {
  const API_URL_SIMS = '/custom-sim/all?';

  const paginationParams = `pagination[page]=${paginationCurrentPage}&pagination[pageSize]=10`;

  const filterInvoiceNumberQuery =
      '&filters[invoice_id][invoice_number][$containsi]=',
    filterSimNumberQuery = '&filters[number][$containsi]=',
    filterStatusQuery = '&filters[state][$eq]=';

  const axios = useAxios();

  const [data, setData] = useState<Sim[] | null>(null);
  const [paginationData, setPaginationData] = useState<Pagination>(
    {} as Pagination,
  );
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Filter Controls
  const [filterInvoiceNumberValue, setFilterInvoiceNumberValue] = useState('');
  const [filterSimNumberValue, setFilterSimNumberValue] = useState('');
  const [filterStateValue, setFilterStateValue] = useState('');

  // Build final API URL with filters
  const finalURL = () => {
    return `${API_URL_SIMS}${paginationParams}${
      filterInvoiceNumberValue
        ? filterInvoiceNumberQuery + filterInvoiceNumberValue
        : ''
    }${
      filterSimNumberValue ? filterSimNumberQuery + filterSimNumberValue : ''
    }${filterStateValue ? filterStatusQuery + filterStateValue : ''}`;
  };

  const getSims = async (token: string | null) => {
    axios
      .get<SimResponse>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data.data);
        setPaginationData(response.data.meta.pagination);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    getSims(token);
  }, [
    paginationCurrentPage,
    filterInvoiceNumberValue,
    filterSimNumberValue,
    filterStateValue,
  ]);

  return {
    data,
    hasError,
    isLoading,
    paginationData,
    setFilterInvoiceNumberValue,
    setFilterSimNumberValue,
    setFilterStateValue,
  };
};

export default useSims;
