import { SortOrder } from 'antd/es/table/interface';
import { Params } from 'react-router-dom';

import { InputSelectListOption } from './types';
export * from './roles';

type UserRolesType = {
  [index: string]: {
    id: number;
    name: string;
    description: string;
  };
};

export const USER_ROLES_MAP = (value: string) => {
  const USER_ROLES: UserRolesType = {
    Despachante: {
      id: 3,
      name: 'Despachante Administrador',
      description: 'despachante administrador',
    },
    'Importadora-Exportadora': {
      id: 8,
      name: 'Administrador Imp/Exp',
      description: 'Role for Administrador Imp/Exp',
    },
    Transportista: {
      id: 7,
      name: 'Transportista',
      description: 'Role for Transportista',
    },
    1: {
      id: 1,
      name: 'Authenticated',
      description: 'Default role given to authenticated user.',
    },
    2: {
      id: 2,
      name: 'Public',
      description: 'Default role given to unauthenticated user.',
    },
    4: { id: 4, name: 'Super Admin', description: 'Super Admin role' },
    5: { id: 5, name: 'Despachante', description: 'Despachante Role' },
    6: { id: 6, name: 'Recepcionista', description: 'Role for Recepcionista' },
  };

  const keys = Object.keys(USER_ROLES);

  return keys.includes(value) ? USER_ROLES[value] : null;
};

export const USER_ROLES_LIST = {
  superAdmin: 'Super Admin',
  admin: 'Despachante Administrador',
  impExpAdmin: 'Administrador Imp/Exp',
  transporter: 'Transportista',
  dispatcher: 'Despachante',
};

export const URLS = {
  addCompany: '/empresa/nueva?tipo=',
  addCrt: '/crt/nuevo',
  addInvoice: '/facturas/nuevo',
  addMerchandise: '/mercaderias/nuevo',
  addMic: '/mic/nuevo',
  addSemitrailer: '/semiremolques/nuevo',
  addTruck: '/camiones/nuevo',
  addTruckDriver: '/choferes/nuevo',
  addUser: '/usuarios/nuevo',
  crtDetail: '/crts/{crtID}',
  crtRelatedMics: '/facturas/{invoiceID}/sims/{simID}/crts/{crtID}/mics',
  dashboard: '/dashboard',
  dispatcherLanding: '/despachante/mic/nuevo',
  files: '/mis-archivos/reportes',
  filesCrt: '/mis-archivos/reportes/crt',
  filesMic: '/mis-archivos/reportes/mic',
  filesOp: '/mis-archivos/reportes/op',
  filesSemitrailer: '/mis-archivos/reportes/semiremolques',
  filesTruck: '/mis-archivos/reportes/camiones',
  filesTruckDriver: '/mis-archivos/reportes/choferes',
  invoiceDetail: '/facturas/{invoiceID}',
  landing: '/login',
  listCrts: '/crts',
  listInvoices: '/facturas',
  listMerchandise: '/mercaderias',
  listMics: '/mics',
  listSims: '/sims',
  merchandise: '/mercaderias',
  micDetail: '/facturas/{invoiceID}/sims/{simID}/crts/{crtID}/mics/{micID}',
  mics: '/mics',
  myCompany: '/empresas/{companyID}/editar',
  profile: '/usuario/perfil',
  receiverLanding: '/recepcionista/mic/registrar-recepcion',
  reportCRT: '/reportes/crt-operativas',
  reportOP: '/reportes/op-operativas',
  reportSemitrailers: '/reportes/semiremolques',
  reportTruckDrivers: '/reportes/choferes',
  reportTrucks: '/reportes/camiones',
  semitrailers: '/semiremolques',
  simDetail: '/sims/{simID}',
  simRelatedCrtDetail: '/facturas/{invoiceID}/sims/{simID}/crts/{crtID}',
  truckDriver: '/choferes',
  trucks: '/camiones',
  users: '/usuarios',
};

export const CUSTOMS_DESTINATIONS = [
  { value: 'ADMINISTRACION_CTRAL', label: 'ADMINISTRACION CTRAL.' },
  { value: 'ADUANA_DESBORDE_DJAI', label: 'ADUANA DESBORDE DJAI' },
  { value: 'BAHIA_BLANCA', label: 'BAHIA BLANCA' },
  { value: 'BARILOCHE', label: 'BARILOCHE' },
  { value: 'BARRANQUERAS', label: 'BARRANQUERAS' },
  { value: 'BERNARDO_DE_YRIGOYEN', label: 'BERNARDO DE YRIGOYEN' },
  { value: 'BS_AS_NORTE', label: 'BS. AS. NORTE' },
  { value: 'BS_AS_SUR', label: 'BS. AS. SUR' },
  { value: 'BS_AS_CAPITAL', label: 'BS. AS. CAPITAL' },
  { value: 'CALETA_OLIVIA', label: 'CALETA OLIVIA' },
  { value: 'CAMPANA', label: 'CAMPANA' },
  { value: 'CLORINDA', label: 'CLORINDA' },
  { value: 'COLON', label: 'COLON' },
  { value: 'COMODORO_RIVADAVIA', label: 'COMODORO RIVADAVIA' },
  { value: 'CONCEPCION_DEL_URUGUAY', label: 'CONCEPCION DEL URUGUAY' },
  { value: 'CONCORDIA', label: 'CONCORDIA' },
  { value: 'CORDOBA', label: 'CORDOBA' },
  { value: 'CORRIENTES', label: 'CORRIENTES' },
  { value: 'DIAMANTE', label: 'DIAMANTE' },
  { value: 'ESQUEL', label: 'ESQUEL' },
  { value: 'EXTERIOR_EXPORTAC', label: 'EXTERIOR EXPORTAC' },
  { value: 'EZEIZA', label: 'EZEIZA' },
  { value: 'FORMOSA', label: 'FORMOSA' },
  { value: 'GENERAL_DEHEZA', label: 'GENERAL DEHEZA' },
  { value: 'GENERAL_PICO', label: 'GENERAL PICO' },
  { value: 'GOYA', label: 'GOYA' },
  { value: 'GUALEGUAYCHU', label: 'GUALEGUAYCHU' },
  { value: 'IGUAZU', label: 'IGUAZU' },
  { value: 'JUJUY', label: 'JUJUY' },
  { value: 'LA_PLATA', label: 'LA PLATA' },
  { value: 'LA_QUIACA', label: 'LA QUIACA' },
  { value: 'LA_RIOJA', label: 'LA RIOJA' },
  { value: 'MAR_DEL_PLATA', label: 'MAR DEL PLATA' },
  { value: 'MENDOZA', label: 'MENDOZA' },
  { value: 'MULTIADUANA', label: 'MULTIADUANA' },
  { value: 'NECOCHEA', label: 'NECOCHEA' },
  { value: 'NEUQUEN', label: 'NEUQUEN' },
  { value: 'OBERA', label: 'OBERA' },
  { value: 'ORAN', label: 'ORAN' },
  { value: 'PARANA', label: 'PARANA' },
  { value: 'PASO_DE_LOS_LIBRES', label: 'PASO DE LOS LIBRES' },
  { value: 'POCITOS', label: 'POCITOS' },
  { value: 'POSADAS', label: 'POSADAS' },
  { value: 'PUERTO_DESEADO', label: 'PUERTO DESEADO' },
  { value: 'PUERTO_MADRYN', label: 'PUERTO MADRYN' },
  { value: 'RAFAELA', label: 'RAFAELA' },
  { value: 'RIO_GALLEGOS', label: 'RIO GALLEGOS' },
  { value: 'RIO_GRANDE', label: 'RIO GRANDE' },
  { value: 'ROSARIO', label: 'ROSARIO' },
  { value: 'SAN_MARTIN_DE_LOS_ANDES', label: 'SAN MARTIN DE LOS ANDES' },
  { value: 'SALTA', label: 'SALTA' },
  { value: 'SAN_ANTONIO_OESTE', label: 'SAN ANTONIO OESTE' },
  { value: 'SAN_JAVIER', label: 'SAN JAVIER' },
  { value: 'SAN_JUAN', label: 'SAN JUAN' },
  { value: 'SAN_LORENZO', label: 'SAN LORENZO' },
  { value: 'SAN_LUIS', label: 'SAN LUIS' },
  { value: 'SAN_NICOLAS', label: 'SAN NICOLAS' },
  { value: 'SAN_PEDRO', label: 'SAN PEDRO' },
  { value: 'SAN_RAFAEL', label: 'SAN RAFAEL' },
  { value: 'SANTA_CRUZ', label: 'SANTA CRUZ' },
  { value: 'SANTA_FE', label: 'SANTA FE' },
  { value: 'SANTIAGO_DEL_ESTERO', label: 'SANTIAGO DEL ESTERO' },
  { value: 'SANTO_TOME', label: 'SANTO TOME' },
  { value: 'TINOGASTA', label: 'TINOGASTA' },
  { value: 'TUCUMAN', label: 'TUCUMAN' },
  { value: 'USHUAIA', label: 'USHUAIA' },
  { value: 'VILLA_CONSTITUCION', label: 'VILLA CONSTITUCION' },
  { value: 'VILLA_REGINA', label: 'VILLA REGINA' },
  { value: 'Z_F_CORONEL_ROSALES', label: 'Z.F.CORONEL ROSALES' },
  { value: 'Z_F_CONCEP_DEL_URUG', label: 'Z.F. CONCEP.DEL URUG.' },
];

export const getCustomsDestinationName = (queryValue?: string) => {
  if (!queryValue) return '';

  const result =
    CUSTOMS_DESTINATIONS.find(
      (destination) => destination.value === queryValue,
    ) || ({} as InputSelectListOption);

  return result.label;
};

export const sortString = (stringA: string, stringB: string) => {
  if (!stringA || !stringB) return 0;

  const lowerStringA = stringA.toLowerCase(),
    lowerStringB = stringB.toLowerCase();
  if (lowerStringA < lowerStringB) return -1;
  if (lowerStringA > lowerStringB) return 1;
  return 0;
};

export const checkForValue = (value?: string | number | boolean) => {
  return value ? value : '';
};

export const simsUrl = (params: Readonly<Params<string>>) =>
  `${URLS.invoiceDetail.replace(
    '{invoiceID}',
    params.invoice_id || String(),
  )}${URLS.simDetail.replace('{simID}', params.sim_id || String())}`;

export const orderByOptions = [
  { value: 'ASC', text: 'Ascendente' },
  { value: 'DESC', text: 'Descendente' },
];

export enum ReportMessages {
  SUCCESS = 'Su reporte fue generado con éxito.',
}

export const WAIT_TIMES = {
  xsm: 500,
  sm: 1000,
  md: 1500,
  lg: 2500,
};

export const LANDING_URL_MAP: { [key: string]: string } = {
  Despachante: '/despachante/mic/nuevo',
  Recepcionista: '/recepcionista/mic/registrar-recepcion',
};

export enum NotificationMessages {
  GENERIC_ERROR = 'Ha ocurrido un error.',
  MERCHANDISE_ADD_SUCCESS = 'La mercaderia se ha creado exitosamente.',
  MERCHANDISE_EDIT_SUCCESS = 'La mercaderia se ha actualizado exitosamente.',
  MY_COMPANY_EDIT_SUCCESS = 'Los datos de la empresa se han actualizado con exito.',
}

const orderKeyMaps = {
  descend: 'DESC',
  ascend: 'ASC',
};

export const getOrder = (order?: SortOrder) => {
  if (!order) return undefined;
  return orderKeyMaps[order] || undefined;
};
