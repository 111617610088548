import { Popconfirm } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { CustomButton, InputText, Loader } from '../../../../components';
import { theme } from '../../../../styles';
import { fromUserRoleToUrl } from '../../../../utils';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { Pagination } from '../../../../utils/types';
import { Merchandise } from '../../../../utils/types/merchandise';
import useStore from '../../../../zustand';
import { TableData } from '../../types';
import { StyledTableIconCell } from './MerchandiseTable.styled';
import { transformData } from './utils';

type Props = {
  data: Merchandise[];
  currentPage: number;
  pagination: Pagination;
  isLoading: boolean;
  onPaginationChange: (page: number) => void;
  onChangeMerchandiseName: (value: string) => void;
  onDeleteMerchandise: () => void;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const OpTable: FC<Props> = ({
  data,
  pagination,
  currentPage,
  isLoading,
  onPaginationChange,
  onChangeMerchandiseName,
  onDeleteMerchandise,
  onTableChange,
}) => {
  const newData = transformData(data);

  const paginationData = {
    current: currentPage,
    defaultCurrent: Number(true),
    total: pagination?.total || Number(),
    pageSize: pagination?.pageSize || Number(),
  };

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Código',
      key: 'code',
      dataIndex: 'code',
      align: 'left',
      sorter: true,
      render: (value?: string) => {
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Descripción',
      key: 'description',
      dataIndex: 'description',
      sorter: true,
      align: 'left',
      render: (value?: string) => {
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Registro de exportación requerido',
      key: 'export_record_required',
      align: 'left',
      sorter: true,
      render: (props: TableData) => {
        const Icon = props.export_record_required ? (
          <FaCheck color={theme.colors.success} />
        ) : (
          <FaTimes color={theme.colors.error} />
        );
        return (
          <StyledTableIconCell className="center">{Icon}</StyledTableIconCell>
        );
      },
    },
    {
      title: 'Comentarios',
      dataIndex: 'comment',
      key: 'comment',
      align: 'left',
      sorter: true,
      render: (value: string) => {
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Editar',
      key: 'operation_edit',
      fixed: 'right',
      width: 100,
      render: (props: TableData) => {
        return (
          <Link to={`/${transformedUserRole}/mercaderias/${props.key}/editar`}>
            <CustomButton $theme="secondary" $isMini>
              <FaPen />
            </CustomButton>
          </Link>
        );
      },
    },
    {
      title: 'Borrar',
      key: 'operation_delete',
      fixed: 'right',
      width: 100,
      render: (props: TableData) => (
        <Popconfirm
          title="Precaución"
          description="¿Está seguro que quiere borrar el elemento? Este cambio es irreversible."
          onConfirm={onDeleteMerchandise.bind({
            merchandiseID: props.key,
          })}
          okText="Eliminar"
          cancelText="Cancelar"
          okButtonProps={{ danger: true }}
        >
          <div>
            <CustomButton $theme="secondary" $isMini>
              <FaTrash />
            </CustomButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const handleOnChangeMerchandiseName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMerchandiseName(event.target.value);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputText
          placeholder="Nombre"
          onChange={handleOnChangeMerchandiseName}
        />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          ...paginationData,
          showSizeChanger: false,
          position: ['bottomCenter'],
          onChange: onPaginationChange,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default OpTable;
