import { URLS } from '../../../utils';

export const superAdminOptions = [
  { name: 'Camiones', url: `/super-admin${URLS.trucks}` },
  { name: 'Semiremolques', url: `/super-admin${URLS.semitrailers}` },
  { name: 'Choferes', url: `/super-admin${URLS.truckDriver}` },
];

export const adminOptions = (companyToReplace: string) => {
  return [
    { name: 'Mis Archivos', url: `/admin${URLS.files}` },
    {
      name: 'Mi Empresa',
      url: URLS.myCompany.replace('{companyID}', companyToReplace),
    },
    { name: 'Usuarios', url: `/admin${URLS.users}` },
    { name: 'Dashboard', url: URLS.dashboard },
    { name: 'Mercaderías', url: `/admin${URLS.merchandise}` },
  ];
};

export const transporterOptions = (companyToReplace: string) => {
  return [
    { name: 'Mis Archivos', url: `/transportista${URLS.files}/crt` },
    {
      name: 'Mi Empresa',
      url: URLS.myCompany.replace('{companyID}', companyToReplace),
    },
    { name: 'Usuarios', url: `/transportista${URLS.users}` },
    { name: 'Dashboard', url: URLS.dashboard },
    { name: 'Camiones', url: `/transportista${URLS.trucks}` },
    { name: 'Semiremolques', url: `/transportista${URLS.semitrailers}` },
    { name: 'Choferes', url: `/transportista${URLS.truckDriver}` },
  ];
};

export const impExpAdminOptions = (companyToReplace: string) => {
  return [
    { name: 'Mis Archivos', url: `/cliente${URLS.files}` },
    {
      name: 'Mi Empresa',
      url: URLS.myCompany.replace('{companyID}', companyToReplace),
    },
    { name: 'Usuarios', url: `/cliente${URLS.users}` },
    { name: 'Dashboard', url: URLS.dashboard },
  ];
};
