import { Popconfirm } from 'antd';
import React, { FC } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { Alert, CustomButton } from '../../../../components';
import { Role } from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { PlainSimAlt } from '../../../../utils/types/sims';
import { StyledActionsContaner } from './HeaderActions.styled';

type Props = {
  data: PlainSimAlt | null;
  onClose?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onFileUpload?: () => void;
};
const HeaderActions: FC<Props> = ({
  data,
  onClose,
  onEdit,
  onFileUpload,
  onDelete,
}) => {
  const navigate = useNavigate();

  const hasCRT = data?.crt_id?.id;

  const handleOnCrt = React.useCallback((data: PlainSimAlt | null) => {
    return () => navigate(`crts/${data?.crt_id?.id ?? 'nuevo'}`);
  }, []);

  const isClosed = data?.closed;

  return (
    <div>
      <Alert
        canShow={isClosed}
        icon="warning"
        message="El SIM fue cerrado"
        type="warning"
      />
      <StyledActionsContaner>
        <div>
          <AvailableComponent availableFor={[Role.ImpExpAdmin]}>
            {data?.crt_id?.id && (
              <Link to={`crts/${data?.crt_id?.id}`}>
                <CustomButton text="Ver CRT" $isMini $theme="secondary" />
              </Link>
            )}
          </AvailableComponent>
          <AvailableComponent availableFor={[Role.Admin]}>
            <CustomButton
              text={`${hasCRT ? 'Ver' : 'Asociar'} CRT`}
              $isMini
              $theme="secondary"
              onPress={handleOnCrt(data)}
            />

            {!isClosed && (
              <Popconfirm
                title="Precaución"
                description="¿Está seguro que quiere cerrar el SIM?"
                onConfirm={onClose}
                okText="Cerrar"
                cancelText="Cancelar"
                okButtonProps={{ danger: true }}
              >
                <div>
                  <CustomButton text="Cerrar Sim" $isMini $theme="danger" />
                </div>
              </Popconfirm>
            )}

            {!data?.attachment && (
              <CustomButton
                text="Adjuntar Archivo"
                $isMini
                $theme="primary"
                onPress={onFileUpload}
              >
                <FaPaperclip size="1.5rem" />
              </CustomButton>
            )}
          </AvailableComponent>
        </div>
        <AvailableComponent availableFor={[Role.Admin]}>
          <div>
            <CustomButton
              text="Editar"
              $isMini
              $theme="success"
              onPress={onEdit}
            />
            {!hasCRT && (
              <Popconfirm
                title="Precaución"
                description="¿Está seguro que quiere borrar el elemento? Este cambio es irreversible."
                onConfirm={onDelete}
                okText="Eliminar"
                cancelText="Cancelar"
                okButtonProps={{ danger: true }}
              >
                <div>
                  <CustomButton text="Eliminar" $isMini $theme="danger" />
                </div>
              </Popconfirm>
            )}
          </div>
        </AvailableComponent>
      </StyledActionsContaner>
    </div>
  );
};

export default HeaderActions;
