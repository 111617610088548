import {
  fromUserRoleToUrl,
  isAdmin,
  isImpExpAdmin,
  isTransporter,
  URLS,
} from '../../../../../utils';

type MicsNavigationToDetails = {
  useRole: string;
  invoiceId: number;
  simId: number;
  crtId: number;
  micId: number;
};

export const handleGoToMicDetails = ({
  useRole,
  invoiceId,
  simId,
  crtId,
  micId,
}: MicsNavigationToDetails) => {
  let navigateTo = String();

  const transformedUserRole = fromUserRoleToUrl(useRole);
  if (isAdmin(useRole))
    navigateTo = `/${transformedUserRole}${URLS.listInvoices}/${invoiceId}${URLS.listSims}/${simId}/crts/${crtId}${URLS.listMics}/${micId}`;
  else if (isImpExpAdmin(useRole))
    navigateTo = `/${transformedUserRole}${URLS.listInvoices}/${invoiceId}${URLS.listSims}/${simId}/crts/${crtId}${URLS.listMics}/${micId}`;
  else if (isTransporter(useRole))
    navigateTo = `/${transformedUserRole}/crts/${crtId}/mics/${micId}`;

  return navigateTo;
};
