export const theme = {
  colors: {
    darkGray: '#798C94',
    defaultBlack: '#264653',
    defaultGray: '#B7C0E1',
    defaultWhite: '#F5F7FD',
    error: '#E76F51',
    lightError: '#EA8A72',
    lightGray: '#D3D8DA',
    primary: '#009FB7',
    success: '#2A9D8F',
    successCta: '#44CF6C',
    warning: '#E9C46A',
  },
  breakpoints: {
    xsm: '320px',
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xlg: '1440px',
  },
  zIndex: {
    modal: 998,
    loader: 999,
    headerFooter: 997,
    tableFilters: 50,
  },
};
