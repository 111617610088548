import { create } from 'zustand';

type State = {
  userName: string;
  userRole: string;
  userCompanyID: number | null;
  userCompanyName: string | null;
  userCompanyType: string | null;
  userIsAuthenticated: boolean | null;
};

type Action = {
  setUserName: (userName: State['userName']) => void;
  setUserRole: (userRole: State['userRole']) => void;
  setUserCompanyID: (userCompanyID: State['userCompanyID']) => void;
  setUserCompanyName: (userCompanyName: State['userCompanyName']) => void;
  setUserCompanyType: (userCompanyType: State['userCompanyType']) => void;
  setUserIsAuthenticated: (
    userAuthenticated: State['userIsAuthenticated'],
  ) => void;
};

const STORAGE_KEYS = {
  userData: 'userData',
};

const getUserFromStorage = () => {
  const userData = sessionStorage.getItem(STORAGE_KEYS.userData);

  const result: State = userData ? JSON.parse(userData) : ({} as State);

  return {
    userName: result.userName,
    userRole: result.userRole,
    userCompanyID: result.userCompanyID,
    userCompanyName: result.userCompanyName,
    userCompanyType: result.userCompanyType,
    userAuthenticated: result.userIsAuthenticated,
  };
};

const initialData = getUserFromStorage();

const useStore = create<State & Action>()((set) => ({
  userName: initialData.userName,
  userRole: initialData.userRole,
  userCompanyID: initialData.userCompanyID,
  userCompanyName: initialData.userCompanyName,
  userCompanyType: initialData.userCompanyType,
  userIsAuthenticated: initialData.userAuthenticated,
  setUserName: (userName) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userName }),
      );
      return { userName };
    }),
  setUserRole: (userRole) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userRole }),
      );
      return { userRole };
    }),
  setUserCompanyID: (userCompanyID) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userCompanyID }),
      );
      return { userCompanyID };
    }),
  setUserCompanyName: (userCompanyName) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userCompanyName }),
      );
      return { userCompanyName };
    }),
  setUserCompanyType: (userCompanyType) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userCompanyType }),
      );
      return { userCompanyType };
    }),
  setUserIsAuthenticated: (userIsAuthenticated) =>
    set((state) => {
      sessionStorage.setItem(
        STORAGE_KEYS.userData,
        JSON.stringify({ ...state, userIsAuthenticated }),
      );
      return { userIsAuthenticated };
    }),
}));

export default useStore;
