import { styled } from 'styled-components';

import { theme } from '../../../../../../styles';

const colors = {
  color1: theme.colors.success,
  color2: theme.colors.error,
  color3: theme.colors.primary,
  color4: theme.colors.warning,
};

export const StyledSelectedDataMainContainer = styled.div`
  margin-top: 4rem;
`;

export const StyledSelectedData = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
`;

export const StyledSelectedDataCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.2rem solid black;
  border-radius: 1rem;

  &:nth-child(1) {
    border-color: ${colors.color1};
    > div {
      &::before {
        background-color: ${colors.color1};
      }
    }
  }
  &:nth-child(2) {
    border-color: ${colors.color2};
    > div {
      &::before {
        background-color: ${colors.color2};
      }
    }
  }
  &:nth-child(3) {
    border-color: ${colors.color3};
    > div {
      &::before {
        background-color: ${colors.color3};
      }
    }
  }
  &:nth-child(4) {
    border-color: ${colors.color4};
    > div {
      &::before {
        background-color: ${colors.color4};
      }
    }
  }
`;

export const StyledCardHeader = styled.div`
  position: relative;
  padding: 2.5rem 2.5rem 0 2.5rem;
  &::before {
    content: '';

    height: 100%;
    left: 0;
    opacity: 0.35;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  > div {
    position: relative;
    z-index: 2;
  }
`;

export const StyledDataList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.5rem;
`;
