import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { BoxContainer, Breadcrumb, Loader, Typography } from '../../components';
import { theme } from '../../styles';
import { fromUserRoleToUrl, simsUrl, URLS } from '../../utils';
import { StyledErrorMessage } from '../../utils/styles';
import useStore from '../../zustand';
import { StyledBalanceInfo, StyledHeaderBalanceInfo } from './AddCrt.styled';
import AddCrtForm from './components/AddCrtForm';
import useAddCrt from './hooks/useAddCrt';

const renderIcon = (value: number) => {
  return value > 0 ? (
    <FaCheck size="1.5rem" color={theme.colors.success} />
  ) : (
    <FaTimes size="1.5rem" color={theme.colors.error} />
  );
};

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id || String(),
      ),
      name: 'Detalle Factura',
      withUserRole: true,
    },
    {
      location: simsUrl(params),
      name: 'Detalle SIM',
      withUserRole: true,
    },
    {
      location: URLS.addCrt,
      name: `${params.crt_id ? 'Editar' : 'Nuevo'} CRT`,
      withUserRole: true,
    },
  ];

  if (params.crt_id) {
    breadcrumbItems.splice(breadcrumbItems.length - 1, 0, {
      location: `${simsUrl(params)}/crts/${params.crt_id}`,
      name: 'Detalle CRT',
      withUserRole: true,
    });
  }

  const {
    simData,
    customError,
    singleCrtData,
    carrierCompanyOptions,
    handleAddCrt,
    isLoading,
    setCarrierCompanyQuery,
  } = useAddCrt({
    crtID: params.crt_id,
    simID: params.sim_id,
    invoiceID: params.invoice_id,
  });

  const handleOnCancel = React.useCallback(() => {
    if (params.crt_id)
      navigate(
        `/${transformedUserRole}${simsUrl(params)}/crts/${params.crt_id}`,
      );
    else navigate(`/${transformedUserRole}${simsUrl(params)}`);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {params.crt_id ? 'Editar' : 'Nuevo'} CRT
      </Typography>
      <StyledHeaderBalanceInfo>
        <Typography theme="p" as="h2" $marginBottom="2rem">
          Cantidades declaradas en SIM
        </Typography>
        <ul>
          {simData?.sim_items?.map((item) => (
            <StyledBalanceInfo key={item?.id}>
              {renderIcon(item.balance)}
              <Typography theme="p">
                {item?.merchandise_id.description}:
              </Typography>
              <Typography theme="p">{item?.quantity}</Typography>
              <Typography theme="p">{item?.unit}</Typography>
            </StyledBalanceInfo>
          ))}
        </ul>
      </StyledHeaderBalanceInfo>
      {params.crt_id && (
        <StyledHeaderBalanceInfo>
          <Typography theme="p" as="h2" $marginBottom="2rem">
            Despachado en MICs
          </Typography>
          <ul>
            {singleCrtData.crtItems?.map((item) => {
              const micsBalanceCalculated = item.quantity - item.balance;

              return (
                <StyledBalanceInfo key={item.id}>
                  {renderIcon(item.balance)}
                  <Typography theme="p">{item.description}:</Typography>
                  <Typography theme="p">{micsBalanceCalculated}</Typography>
                  <Typography theme="p">{item.unit}</Typography>
                </StyledBalanceInfo>
              );
            })}
          </ul>
        </StyledHeaderBalanceInfo>
      )}

      {customError.length > 0 && (
        <StyledErrorMessage>
          {customError.map((item) => (
            <div key={item.replaceAll(' ', '')}>
              <Typography theme="p" $marginBottom="1.5rem">
                {item}
              </Typography>
            </div>
          ))}
        </StyledErrorMessage>
      )}

      <AddCrtForm
        singleCrtData={singleCrtData}
        handleOnSubmit={handleAddCrt}
        handleOnFormCancel={handleOnCancel}
        simItems={simData?.sim_items}
        carrierCompanyOptions={carrierCompanyOptions}
        handleOnCarrierCompanyQuery={setCarrierCompanyQuery}
        ctaText={params.crt_id ? 'Actualizar' : 'Crear'}
      />
    </BoxContainer>
  );
};

export default PageContent;
