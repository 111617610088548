import { Params } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  isAdmin,
  isImpExpAdmin,
  isTransporter,
  URLS,
} from '../../../utils';

export type MicBreadcrumbParams = {
  userRole: string;
  params: Readonly<Params<string>>;
  invoiceID?: string;
  simID?: string;
  crtID?: string;
  micID?: string;
};

type MicsNavigationToDetails = {
  userRole: string;
  invoiceID?: string;
  simID?: string;
  crtID?: string;
};

export const micDetailRedirection = (
  invoiceID?: string,
  simID?: string,
  crtID?: string,
  micID?: string,
) => {
  return URLS.micDetail
    .replace('invoiceID', invoiceID || String())
    .replace('simID', simID || String())
    .replace('crtID', crtID || String())
    .replace('micID', micID || String());
};

export const breadcrumbs = (args: MicBreadcrumbParams) => {
  let breadcrumbItems: {
    location: string;
    name: string;
    withUserRole?: boolean;
  }[] = [];

  if (isAdmin(args.userRole) || isImpExpAdmin(args.userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: URLS.listInvoices,
        name: 'Facturas',
        withUserRole: true,
      },
      {
        location: URLS.invoiceDetail.replace(
          '{invoiceID}',
          args.invoiceID || String(),
        ),
        name: 'Detalle Factura',
        withUserRole: true,
      },
      {
        location: URLS.listMics,
        name: 'MICs',
        withUserRole: true,
      },
      {
        location: micDetailRedirection(
          args.invoiceID,
          args.simID,
          args.crtID,
          args.micID,
        ),
        name: 'Detalle Mic',
        withUserRole: true,
      },
    ];
  }

  if (isTransporter(args.userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: URLS.listCrts,
        name: 'CRTs',
        withUserRole: true,
      },
      {
        location: `/crts/${args.params.crt_id}`,
        name: 'Detalle CRT',
        withUserRole: true,
      },
      {
        location: `/crts/${args.params.crt_id}/mics`,
        name: 'MICs',
        withUserRole: true,
      },
      {
        location: micDetailRedirection(
          args.invoiceID,
          args.simID,
          args.crtID,
          args.micID,
        ),
        name: 'Detalle Mic',
        withUserRole: true,
      },
    ];
  }

  return breadcrumbItems;
};

export const handleGoBack = ({
  userRole,
  invoiceID,
  simID,
  crtID,
}: MicsNavigationToDetails) => {
  let navigateTo = String();
  const transformedUserRole = fromUserRoleToUrl(userRole);

  if (isAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}/mics`;
  else if (isImpExpAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}/mics`;
  else if (isTransporter(userRole))
    navigateTo = `/${transformedUserRole}/crts/${crtID}/mics`;

  return navigateTo;
};
