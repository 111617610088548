import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
