import styled, { css } from 'styled-components';

import { theme } from '../../styles';

type StyledProps = {
  $hasValue?: boolean;
  $invalid?: boolean;
};

export const StyledSelectContainer = styled.div<StyledProps>`
  display: flex;
  > div {
    flex-grow: 1;
    border-radius: 0.5rem;
    border: 2px solid ${theme.colors.defaultGray};
    color: ${theme.colors.darkGray};
    font-size: 1.4rem;
    min-height: 4.15rem;
    > div {
      color: ${theme.colors.defaultBlack};
    }

    @media only screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 1.5rem;
    }
  }

  button {
    border-radius: 0.5rem;
    border: 0.2rem solid ${theme.colors.defaultGray};
    padding-top: 0.4rem;
    width: 4.2rem;
  }

  ${({ $hasValue }) => {
    return (
      $hasValue &&
      css`
        > div {
          color: ${theme.colors.defaultBlack};
        }
      `
    );
  }}

  ${({ $invalid }) => {
    return (
      $invalid &&
      css`
        > div {
          color: ${theme.colors.error};
          border-color: ${theme.colors.error};
        }
        label {
          color: ${theme.colors.error};
        }
      `
    );
  }}
`;

export const SubTextContainer = styled.div`
  color: ${theme.colors.error};
  margin-top: 0.5rem;
  text-align: left;
`;

export const StyledInputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;
