import React from 'react';

import { USER_ROLES_LIST } from '../../utils';
import useStore from '../../zustand';
import AdminDashboard from './components/Dashboards/AdminDashboard';
import SuperAdminDashboard from './components/Dashboards/SuperAdminDashboard';
import TransporterDashboard from './components/Dashboards/TransporterDashboard';

const PageContent = () => {
  const [userRole] = useStore((state) => [state.userRole]);

  return (
    <div>
      {userRole === USER_ROLES_LIST.superAdmin && <SuperAdminDashboard />}
      {userRole === USER_ROLES_LIST.admin && <AdminDashboard />}
      {userRole === USER_ROLES_LIST.impExpAdmin && <AdminDashboard />}
      {userRole === USER_ROLES_LIST.transporter && <TransporterDashboard />}
    </div>
  );
};

export default PageContent;
