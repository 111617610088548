import React, { FC } from 'react';
import { IconType } from 'react-icons';
import { FaExclamationTriangle } from 'react-icons/fa';

import Typography from '../Typography';
import { StyledAlertContainer } from './Alert.styled';

enum IconList {
  'warning',
}

export enum TypesList {
  'warning',
  'error',
}

type Props = {
  canShow?: boolean;
  icon?: keyof typeof IconList;
  message: string;
  type?: keyof typeof TypesList;
};

const iconsMap: { [key: string]: IconType } = {
  warning: FaExclamationTriangle,
};

/**
 * Shows a full width alert message with an icon
 * @param canShow Condition to make component visible
 * @param icon Icon to render
 * @param message Message to render
 * @param type Component's type to change styles
 * @returns Full width alert component
 */
const Alert: FC<Props> = ({ canShow, icon, message, type }) => {
  const Icon = icon ? iconsMap[icon] : null;
  if (!canShow) return null;
  return (
    <StyledAlertContainer type={type}>
      <div>
        {Icon && <Icon size="2rem" />}
        <Typography theme="p">{message}</Typography>
      </div>
    </StyledAlertContainer>
  );
};

export default Alert;
