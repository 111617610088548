import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { URLS } from '../../../../utils';
import useStore from '../../../../zustand';
import CustomButton from '../../../CustomButton';
import Typography from '../../../Typography';
import { StyledMenu } from './Menu.styled';

type Props = {
  options: { name: string; url: string }[];
};

const Menu: FC<Props> = ({ options }) => {
  const navigate = useNavigate();

  const [setUserAuthenticated] = useStore((store) => [
    store.setUserIsAuthenticated,
  ]);

  const handleLogOut = React.useCallback(() => {
    navigate(URLS.landing);
    setUserAuthenticated(false);
    sessionStorage.clear();
  }, []);

  return (
    <menu>
      <StyledMenu>
        <li>
          <Link to={URLS.profile}>
            <Typography theme="p">Mi Perfil</Typography>
          </Link>
        </li>
        {options.map((item) => (
          <li key={`${item.url}`}>
            <Link to={item.url}>
              <Typography theme="p">{item.name}</Typography>
            </Link>
          </li>
        ))}
        <li>
          <CustomButton
            $theme="link"
            text="Cerrar Sesión"
            $textAlign="left"
            onPress={handleLogOut}
          />
        </li>
      </StyledMenu>
    </menu>
  );
};

export default Menu;
