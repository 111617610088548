import React from 'react';

import { BoxContainer, Typography } from '../../components';
import ProfileForm from './components/ProfileForm';

const PageContent = () => {
  return (
    <BoxContainer $useMarginTop $useMarginBottom>
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        Mi Perfil
      </Typography>
      <ProfileForm />
    </BoxContainer>
  );
};

export default PageContent;
