import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationMessages, WAIT_TIMES } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { PoliciesType, UpdatePoliciesInputs } from '../types';

const useUpdatePolicies = () => {
  const API_URL_GET_LICENSES =
      '/custom-wheeled-truck?populate=*&filters[company_id][id][$eq]=userCompanyId&filters[license_plate][$containsi]=',
    API_URL_UPDATE_POLICIES = '/custom-insurance-policy';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [licensesOptions, setLicensesOptions] = useState<PoliciesType[]>([]);

  // Filter controls
  const [inputLicensesQuery, setInputLicensesQuery] = useState('');

  const getLicenses = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No token provided');

    setIsLoading(true);
    setHasError(false);

    const response = await axios.get<{ data: PoliciesType[] }>(
      API_URL_GET_LICENSES + inputLicensesQuery,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setLicensesOptions(response.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getLicenses().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [inputLicensesQuery]);

  // Update Policies
  const handleUpdatePolicies = async (data: UpdatePoliciesInputs) => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) throw new Error('No token provided');

      const vehicle_ids = data.selectAllLicenses
        ? []
        : data.licenses.map((item) => item.id);

      const updatePoliciesData = {
        insurance_policy: {
          number: data.number,
          expiration_date: data.expirationDate,
        },
        vehicle_ids,
      };

      await axios.put(API_URL_UPDATE_POLICIES, updatePoliciesData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({
        message: 'Se ha actualizado satisfactoriamente.',
      });
      setTimeout(() => {
        navigate(-1);
      }, WAIT_TIMES.md);
    } catch (error) {
      log.error(error);
      showErrorNotification({ message: NotificationMessages.GENERIC_ERROR });
    }
  };

  return {
    isLoading,
    hasError,
    setInputLicensesQuery,
    licensesOptions,
    handleUpdatePolicies,
  };
};

export default useUpdatePolicies;
