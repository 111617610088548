import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledActionCard = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  width: 90%;
  &::before {
    content: '';
    border-radius: 1rem 1rem 0 0;
    height: 100%;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
  }

  div:last-child {
    text-align: center;
  }

  &.blue {
    color: ${theme.colors.primary};
    &::before {
      background-color: ${theme.colors.primary};
    }
    div:last-child {
      background-color: ${theme.colors.primary};
    }
  }
  &.yellow {
    color: ${theme.colors.warning};
    &::before {
      background-color: ${theme.colors.warning};
    }
    div:last-child {
      background-color: ${theme.colors.warning};
    }
  }
  &.green {
    color: ${theme.colors.success};
    &::before {
      background-color: ${theme.colors.success};
    }
    div:last-child {
      background-color: ${theme.colors.success};
    }
  }
  &.red {
    color: ${theme.colors.error};
    &::before {
      background-color: ${theme.colors.error};
    }
    div:last-child {
      background-color: ${theme.colors.error};
    }
  }
`;

export const StyledDataContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: 'number title title icon';
  padding: 1rem;

  > :nth-child(1) {
    align-self: flex-start;
    grid-area: number;
    justify-self: start;
  }
  > :nth-child(2) {
    grid-area: title;
    text-align: center;
    color: ${theme.colors.defaultBlack};
  }
  > :nth-child(3) {
    align-self: flex-start;
    grid-area: icon;
    justify-self: end;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    height: 100%;
    grid-template-areas:
      'number . . icon'
      'title title title title';
    padding: 1.5rem 2rem;

    > :nth-child(2) {
      text-align: left;
      margin-top: 1rem;
    }
  }
`;

export const StyledLinkContainer = styled.div`
  border-radius: 0 0 1rem 1rem;
  color: ${theme.colors.defaultWhite};
  padding: 0.5rem;
  position: relative;
  width: 100%;
  z-index: 3;
`;
