import { formatDate } from '../../../../../utils/dates';
import { Mic } from '../../../../../utils/types/mics';
import { TableData } from '../../../types';

export const transformData = (data?: Mic[] | null) => {
  if (!data || !data.length) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    const issueDate = formatDate(item?.attributes?.date_of_issue, 'withTime');
    const receptionDate = formatDate(
      item?.attributes?.reception_date,
      'withTime',
    );

    return {
      key: item.id,
      micNumber: item?.attributes?.number,
      issueDate,
      receptionDate,
      description: item?.attributes?.description,
    };
  });

  return transformedData;
};
