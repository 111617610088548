import styled from 'styled-components';

import { theme } from '../../../../styles';

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    > div {
      max-width: 21rem;
      width: 100%;
      input,
      select {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

export const StyledTableIconCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
