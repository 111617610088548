export enum Role {
  Admin = 'admin',
  SuperAdmin = 'super-admin',
  Dispatcher = 'despachante',
  ImpExpAdmin = 'cliente',
  Transporter = 'transportista',
  Receptionist = 'recepcionista',
}

export const validateRole = (role?: string, roleToValidate?: Role) => {
  if (!role) return false;
  const transformedRoleName = fromUserRoleToUrl(role);
  return transformedRoleName.toLocaleLowerCase() === roleToValidate;
};

export const isSuperAdmin = (role: string) => {
  return validateRole(role, Role.SuperAdmin);
};

export const isAdmin = (role: string) => {
  return validateRole(role, Role.Admin);
};

export const isDispatcher = (role: string) => {
  return validateRole(role, Role.Dispatcher);
};

export const isImpExpAdmin = (role: string) => {
  return validateRole(role, Role.ImpExpAdmin);
};

export const isTransporter = (role: string) => {
  return validateRole(role, Role.Transporter);
};

export const isReceptionist = (role: string) => {
  return validateRole(role, Role.Transporter);
};

export const fromUserRoleToUrl = (value?: string) => {
  if (!value) return String();
  const transformedRoleName = value.toLowerCase().replaceAll('\u0020', '-');
  if (transformedRoleName === 'despachante-administrador') return Role.Admin;
  if (transformedRoleName === 'administrador-imp/exp') return Role.ImpExpAdmin;
  return transformedRoleName;
};
