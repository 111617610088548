import { useWindowSize } from '../../../utils/hooks';

export enum Layouts {
  'responsive',
  'mobile',
  'desktop',
}

export const getLogoSize = (layout?: keyof typeof Layouts) => {
  const { width } = useWindowSize();
  const isDesktop = width > 768;

  const desktopLogoSize = '3.8rem';
  const mobileLogoSize = '2.1rem';

  const LOGO_SIZES = {
    desktop: desktopLogoSize,
    mobile: mobileLogoSize,
    responsive: isDesktop ? desktopLogoSize : mobileLogoSize,
  };

  if (!layout) return mobileLogoSize;

  return LOGO_SIZES[layout];
};

type TypographyTypes = 'h1' | 'h6';

export const getTypographySize = (layout?: keyof typeof Layouts) => {
  const { width } = useWindowSize();
  const isDesktop = width > 768;

  const desktopSize = 'h1';
  const mobileSize = 'h6';

  const TYPOGRAPHY_SIZES = {
    desktop: desktopSize as TypographyTypes,
    mobile: mobileSize as TypographyTypes,
    responsive: (isDesktop ? desktopSize : mobileSize) as TypographyTypes,
  };

  if (!layout) return desktopSize;

  return TYPOGRAPHY_SIZES[layout];
};
