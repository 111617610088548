import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fromUserRoleToUrl, WAIT_TIMES } from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { CompanyResponse } from '../../../utils/types/company';
import { PlainSimAlt } from '../../../utils/types/sims';
import useStore from '../../../zustand';
import { AddCrtInputs, Crt } from '../types';
import {
  transformFromAddCrtInputsToCrt,
  transformFromCrtToAddCrtInputs,
} from '../utils';

type Props = {
  simID?: string;
  crtID?: string;
  invoiceID?: string;
};

const useAddCrt = ({ crtID, simID, invoiceID }: Props) => {
  const API_URL_CREATE_CRT = '/custom-crt/sim/{sim_id}/create',
    API_URL_FIND_CRT = '/custom-crt/{crt_id}/find',
    API_URL_GET_SIM = '/custom-sim/{sim_id}/find',
    API_URL_UPDATE_CRT = '/custom-crt/{crt_id}/sim/{sim_id}/update',
    API_URL_GET_CLIENTS =
      '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification } = useShowNotification();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const errorHandler = new ErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [simData, setSimData] = useState<PlainSimAlt>({} as PlainSimAlt);
  const [singleCrtData, setSingleCrtData] = useState<AddCrtInputs>(
    {} as AddCrtInputs,
  );
  const [customError, setCustomError] = useState<string[]>([]);

  // CarrierCompany Options State
  const [carrierCompanyOptions, setCarrierCompanyOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [carrierCompanyQuery, setCarrierCompanyQuery] = useState<string | null>(
    null,
  );

  // Get Sim Details
  const getSimDetail = () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No Token provided');
    }

    setHasError(false);
    if (!simID) throw new Error('No Sim ID provided');

    axios
      .get<PlainSimAlt>(API_URL_GET_SIM.replace('{sim_id}', simID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSimData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      getSimDetail();
    } catch (error) {
      log.error(error);
    }
  }, []);

  // Get CRT Data
  const getCrtData = (token: string | null) => {
    setIsLoading(true);
    setHasError(false);

    if (!crtID) throw new Error('No Crt ID provided');

    axios
      .get<Crt>(API_URL_FIND_CRT.replace('{crt_id}', crtID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        const transformedData = transformFromCrtToAddCrtInputs(result);
        setSingleCrtData(transformedData);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }
      if (simID && crtID) getCrtData(token);
    } catch (error) {
      log.error(error);
      setHasError(true);
    }
  }, []);

  // Create / Update CRT
  const handleAddCrt = async (data: AddCrtInputs) => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      if (!simID) throw new Error('No Sim ID provided');

      // Create CRT
      if (!crtID) {
        const transformedData = transformFromAddCrtInputsToCrt(data, simData);
        const crtData = { data: { ...transformedData } };

        await axios.post(
          API_URL_CREATE_CRT.replace('{sim_id}', simID),
          { ...crtData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        setIsLoading(false);

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}`,
          );
        }, WAIT_TIMES.md);
      } else {
        // Update CRT
        const transformedData = transformFromAddCrtInputsToCrt(data);
        const crtData = { data: { ...transformedData } };

        await axios.put(
          API_URL_UPDATE_CRT.replace('{crt_id}', crtID).replace(
            '{sim_id}',
            simID,
          ),
          { ...crtData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}`,
          );
        }, WAIT_TIMES.md);
      }
    } catch (error) {
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  // Get Carrier Companies
  const getCarrierCompanyOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponse>(
        `${API_URL_GET_CLIENTS}${carrierCompanyQuery ?? String()}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setCarrierCompanyOptions(results);
      })
      .catch((error) => {
        log.error(error);
        const mappedError = errorHandler.mapErrorMessage(error);
        setCustomError(mappedError);
      });
  };

  useEffect(() => {
    getCarrierCompanyOption();
  }, [carrierCompanyQuery]);

  return {
    hasError,
    isLoading,
    simData,
    customError,
    singleCrtData,
    carrierCompanyOptions,
    handleAddCrt,
    setCarrierCompanyQuery,
  };
};

export default useAddCrt;
