import { AutoComplete } from 'antd';
import React, { FC } from 'react';

import { InputAutocompleteOption } from '../../utils/types';
import InputText from '../InputText';

type Props = {
  options: InputAutocompleteOption[] | undefined;
  onSearch: (value: string) => void;
  onSelect: (value: string, option: InputAutocompleteOption) => void;
  placeholder: string;
};

const InputAutocomplete: FC<Props> = ({
  options,
  onSelect,
  onSearch,
  placeholder,
}) => {
  return (
    <AutoComplete options={options} onSelect={onSelect} onSearch={onSearch}>
      <InputText placeholder={placeholder} />
    </AutoComplete>
  );
};

export default InputAutocomplete;
