import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import { StyledContainedTableContainer } from '../../../../utils/styles';
import { SimItem } from '../../../../utils/types/sims';
import { TableData } from '../../types';
import { transformData } from './utils';

type Props = {
  data?: SimItem[];
};

const MerchandiseTable: FC<Props> = ({ data }) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Mercadería',
      dataIndex: 'merchandise',
      key: 'merchandise',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Constancia de Exportación',
      dataIndex: 'exportCertificate',
      key: 'exportCertificate',
    },
  ];

  return (
    <StyledContainedTableContainer>
      <Table columns={columns} dataSource={newData} pagination={false} />
    </StyledContainedTableContainer>
  );
};

export default MerchandiseTable;
