import {
  fromUserRoleToUrl,
  isAdmin,
  isImpExpAdmin,
  isTransporter,
} from '../../../utils';

type CrtNavigationToDetails = {
  userRole: string;
  invoiceId: string;
  simId: string;
  crtId: string;
};

export const handleGoToCrtDetails = ({
  userRole,
  invoiceId,
  simId,
  crtId,
}: CrtNavigationToDetails) => {
  let navigateTo = String();
  const transformedUserRole = fromUserRoleToUrl(userRole);

  if (isAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceId}/sims/${simId}/crts/${crtId}`;
  else if (isImpExpAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceId}/sims/${simId}/crts/${crtId}`;
  else if (isTransporter(userRole))
    navigateTo = `/${transformedUserRole}/crts/${crtId}`;

  return navigateTo;
};
