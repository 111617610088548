import React, { FC } from 'react';
import { FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';

import CustomButton from '../CustomButton';
import { StyledMainContainer } from './GenerateReportActions.styled';

type Props = {
  onGenerateReportPDF: () => void;
  onGenerateReportXLXS: () => void;
};
const GenerateReportActions: FC<Props> = ({
  onGenerateReportPDF,
  onGenerateReportXLXS,
}) => {
  return (
    <StyledMainContainer>
      <CustomButton
        $theme="icon"
        title="Generar Reporte en PDF"
        onPress={onGenerateReportPDF}
      >
        <FaRegFilePdf size="4rem" />
      </CustomButton>
      <CustomButton
        $theme="icon"
        title="Generar Reporte en Hojas de Cálculo"
        onPress={onGenerateReportXLXS}
      >
        <FaRegFileExcel size="4rem" />
      </CustomButton>
    </StyledMainContainer>
  );
};

export default GenerateReportActions;
