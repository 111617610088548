import { Merchandise } from '../../../utils/types/merchandise';
import { AddMerchandiseInputs, MerchandisePayload } from '../types';

export const transformFromMerchandiseToAddMerchandiseInputs = (
  data: Merchandise,
): AddMerchandiseInputs => {
  return {
    code: data.attributes.code,
    comment: data.attributes.comment,
    description: data.attributes.description,
    exportRecordRequired: data.attributes.export_record_required,
  };
};

export const transformFromAddMerchandiseInputsToMerchandise = (
  data: AddMerchandiseInputs,
): MerchandisePayload => {
  return {
    code: data.code,
    comment: data.comment,
    description: data.description,
    export_record_required: data.exportRecordRequired,
  };
};
