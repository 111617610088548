import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder, NotificationMessages } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { PaginationData } from '../../../utils/types';
import { SemitrailerData, TableData } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useSemitrailersTable = ({ paginationData }: Props) => {
  const API_URL_SEMITRAILERS = '/custom-wheeled-semitrailer?populate=*',
    API_URL_GET_SEMITRAILERS = 'wheeleds/';

  const filterQueryText = '&filters[license_plate][$containsi]=',
    filterCheckboxText = '&insurancePolicyExpired=';

  const pagination = `&pagination[page]=${paginationData.current}&pagination[pageSize]=${paginationData.pageSize}`;

  const axios = useAxios();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<SemitrailerData | null>(null);
  const [totalResults, setTotalResults] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');

  // Filter controls
  const [checkboxFilterValue, setCheckboxFilterValue] = useState(false);
  const [inputQueryFilterValue, setInputQueryFilterValue] = useState('');

  const onInputFilterChange = (value: string) => {
    setInputQueryFilterValue(value);
  };

  const onCheckboxFilterChange = (value: boolean) => {
    setCheckboxFilterValue(value);
  };

  const finalURL = () => {
    return `${API_URL_SEMITRAILERS}${pagination}${filterCheckboxText}${checkboxFilterValue}${
      inputQueryFilterValue && filterQueryText + inputQueryFilterValue
    }${sortQuery}`;
  };

  const updateData = (token: string | null) => {
    axios
      .get<SemitrailerData>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
        setTotalResults(response.data.meta.pagination.total);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    updateData(token);
  }, [
    paginationData.current,
    checkboxFilterValue,
    inputQueryFilterValue,
    sortQuery,
  ]);

  // Reset to first page when filtering
  useEffect(() => {
    if (paginationData.onChange) paginationData.onChange(1);
  }, [checkboxFilterValue, inputQueryFilterValue]);

  // Delete Semitrailer
  const handleDeleteSemitrailer = async (truckID: string) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      await axios.delete(`${API_URL_GET_SEMITRAILERS}${truckID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({
        message: 'El registro fue borrado correctamente',
      });
      updateData(token);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    data: data?.data,
    handleDeleteSemitrailer,
    hasError,
    isLoading,
    onCheckboxFilterChange,
    onInputFilterChange,
    totalResults,
    handleTableChange,
  };
};

export default useSemitrailersTable;
