import React, { BaseSyntheticEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CustomButton, InputText } from '../../../../components';
import Typography from '../../../../components/Typography';
import RecoveryPassword from '../../../RecoveryPassword/components/RecoveryPassword';
import useLogin from '../../hooks/useLogin';
import {
  FormWrapper,
  StyledErrorMessage,
  StyledPasswordField,
} from './LoginForm.styled';

type Inputs = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [typedEmail, setTypedEmail] = useState('');
  const { handleLogin, hasError } = useLogin();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleLogin(data);
  };

  const handleOnBLur = (event: BaseSyntheticEvent) => {
    setTypedEmail(event.target.value);
  };

  const formErrors = Object.keys(formState.errors);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <InputText
        placeholder="Email"
        $hasValue={formState.dirtyFields.email}
        $invalid={!!formState.errors.email}
        {...register('email', {
          required: 'Este Campo es requerido',
        })}
        subTextMessage={formState.errors.email?.message}
        onBlur={handleOnBLur}
      />
      <StyledPasswordField>
        <InputText
          type="password"
          $hasValue={formState.dirtyFields.password}
          $invalid={!!formState.errors.password}
          placeholder="Contraseña"
          {...register('password', {
            required: 'Este Campo es requerido',
            minLength: {
              value: 8,
              message: 'La contraseña necesita al menos 8 caracteres',
            },
          })}
          subTextMessage={formState.errors.password?.message}
        />
        <RecoveryPassword emailValue={typedEmail} />
      </StyledPasswordField>
      {formErrors.length > 0 ||
        (hasError && (
          <StyledErrorMessage>
            <Typography $marginBottom="3rem" theme="p">
              Email o contraseña inválidos
            </Typography>
          </StyledErrorMessage>
        ))}
      <CustomButton text="Iniciar Sesión" type="submit" $marginBottom="5rem" />

      <div>
        <Typography theme="p">¿Dudas o sugerencias?</Typography>{' '}
        <Typography theme="a" as="a" $linkUrl="mailto:info@misdespachos.com">
          Contáctenos
        </Typography>
      </div>
    </FormWrapper>
  );
};

export default LoginForm;
