export const currencies = [
  { value: 'USD', text: 'DÓLAR (USD)' },
  { value: 'EUR', text: 'EURO (EUR)' },
  { value: 'CNY', text: 'YUAN (CNY)' },
  { value: 'ARP', text: 'PESO (ARP)' },
  { value: 'BRL', text: 'REAL (BRL)' },
  { value: 'GBP', text: 'LIBRA ESTERLINA (GBP)' },
];

export const units = [
  { value: 'TON', text: 'TONELADA (tn)' },
  { value: 'KILOGRAM', text: 'KILOGRAMO (kg)' },
  { value: 'BASE_KILOGRAM', text: 'KILOGRAMO BASE (kg)' },
  { value: 'BRUTE_KILOGRAM', text: 'KILOGRAMO BRUTO (kg)' },
  { value: 'ACTIVE_KILOGRAM', text: 'KILOGRAMO ACTIVO (kg)' },
  { value: 'KARAT', text: 'QUILATE (kt)' },
  { value: 'GRAM', text: 'GRAMO (g)' },
  { value: 'BASE_GRAM', text: 'GRAMO BASE (g)' },
  { value: 'ACTIVE_GRAM', text: 'GRAMO ACTIVO (g)' },
  { value: 'MILLIGRAM', text: 'MILIGRAMO (mg)' },
  { value: 'MICROGRAM', text: 'MICROGRAMO (µg)' },
  { value: 'NANOGRAM', text: 'NANOGRAMO (ng)' },
  { value: 'PICOGRAM', text: 'PICOGRAMO (pg)' },
  { value: 'MILE', text: 'MILLA (mi)' },
  { value: 'KILOMETER', text: 'KILÓMETRO (km)' },
  { value: 'METER', text: 'METRO (m)' },
  { value: 'CENTIMETER', text: 'CENTÍMETRO (cm)' },
  { value: 'MILLIMETER', text: 'MILÍMETRO (mm)' },
  { value: 'CUBIC_KILOMETER', text: 'KILÓMETRO CÚBICO (km³)' },
  { value: 'CUBIC_HECTOMETER', text: 'HECTÓMETRO CÚBICO (hm³)' },
  { value: 'CUBIC_DEKAMETER', text: 'DECÁMETRO CÚBICO (dam³)' },
  { value: 'CUBIC_METER', text: 'METRO CÚBICO (m³)' },
  { value: 'HECTOLITER', text: 'HECTOLITRO (hL)' },
  { value: 'LITER', text: 'LITRO (L)' },
  { value: 'CUBIC_CENTIMETER', text: 'CENTIMETRO CÚBICO (cm³)' },
  { value: 'MILLILITER', text: 'MILILITRO (mL)' },
  { value: 'CUBIC_MILLIMETER', text: 'MILIMETRO CÚBICO (mm³)' },
  { value: 'UNIT', text: 'UNIDAD (u)' },
  { value: 'PAIR', text: 'PAR' },
  { value: 'DOZEN', text: 'DOCENA' },
  { value: 'GROSS', text: 'GRUESA' },
  { value: 'CURIE', text: 'CURIE (Ci)' },
  { value: 'MILLICURIE', text: 'MILICURIE (mCi)' },
  { value: 'MICROCURIE', text: 'MICROCURIE (µCi)' },
  { value: 'SQUARE_METER', text: 'METRO CUADRADO (m²)' },
  { value: 'MEGAWATT', text: 'MEGAWATT (MWh)' },
  { value: 'JOULE', text: 'JOULE (J)' },
  { value: 'MEGAJOULE', text: 'MILLÓN DE JOULES (MJ)' },
  { value: 'UIACTHOR', text: 'UIACTHOR' },
  { value: 'MUIACTHOR', text: 'MUIACTHOR' },
  { value: 'UIACTANT', text: 'UIACTANT' },
  { value: 'UIACTIG', text: 'UIACTIG' },
  { value: 'MUIACTIG', text: 'MUIACTIG' },
];

export const saleConditions = [
  { value: 'CFR', text: 'CFR' },
  { value: 'CIF', text: 'CIF' },
  { value: 'CIP', text: 'CIP' },
  { value: 'CPT', text: 'CPT' },
  { value: 'DAP', text: 'DAP' },
  { value: 'DAT', text: 'DAT' },
  { value: 'DDP', text: 'DDP' },
  { value: 'EXW', text: 'EXW' },
  { value: 'FAS', text: 'FAS' },
  { value: 'FCA', text: 'FCA' },
  { value: 'FOB', text: 'FOB' },
  { value: 'MUL', text: 'MUL' },
];

export const roundNumber = (value: number) => Math.floor(value * 100) / 100;
