import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledErrorContainer = styled.div`
  color: ${theme.colors.error};
`;

export const StyledItemHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const StyledItemsContainer = styled.div`
  margin-bottom: 4rem;
`;

export const StyledSubFormRow = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
  margin-bottom: 9rem;
  > div > div {
    max-width: 100%;
  }
`;
