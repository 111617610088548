import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { RelatedMicsResponse } from '../types';

type Props = {
  crtID?: string;
};

const useCrtRelatedMics = ({ crtID }: Props) => {
  const API_URL_RELATED_SIMS = `custom-mic/crt/${crtID}/find-related?populate=*`;

  const API_URL_FILTER_BY_CHECKBOX = '&filters[reception_date][$notNull]=true',
    API_URL_FILTER_BY_NUMBER = '&filters[number][$containsi]=';

  const axios = useAxios();

  const [data, setData] = useState<RelatedMicsResponse | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Filters
  const [checkboxFilterValue, setCheckboxFilterValue] = useState(false);
  const [numberFilterValue, setNumberFilterValue] = useState('');

  const finalURL = () => {
    const filterNumber = numberFilterValue
      ? API_URL_FILTER_BY_NUMBER + numberFilterValue
      : '';
    const filterCheckbox = checkboxFilterValue
      ? API_URL_FILTER_BY_CHECKBOX
      : '';

    return `${API_URL_RELATED_SIMS}${filterNumber}${filterCheckbox}`;
  };

  const getMics = (token: string | null) => {
    axios
      .get<RelatedMicsResponse>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        setData(result);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    getMics(token);
  }, [checkboxFilterValue, numberFilterValue]);

  return {
    data,
    isLoading,
    hasError,
    setCheckboxFilterValue,
    setNumberFilterValue,
  };
};

export default useCrtRelatedMics;
