import styled from 'styled-components';

import { theme } from '../../../../styles';

export const StyledPromptContainer = styled.div`
  align-items: center;
  background-color: ${theme.colors.warning};
  border-radius: 0.5rem;
  color: ${theme.colors.defaultWhite};
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    > :nth-child(1) {
      justify-content: flex-start;
    }
    > :nth-child(2) {
      justify-content: flex-end;
    }
  }
`;

export const StyledActionsBtnsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  + div {
    margin-top: 2rem;
  }

  > div {
    max-width: 17rem;
    width: 100%;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-wrap: nowrap;
    + div {
      margin-top: 0rem;
    }
  }
`;
