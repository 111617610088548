import { styled } from 'styled-components';

import { theme } from '../../styles';
import { transitionAnimation } from '../../utils/styles';

export const StyledBreadcrumbContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.5rem;
`;
export const StyledBreadcrumbItem = styled.li`
  ${transitionAnimation}
  position: relative;
  color: ${theme.colors.defaultGray};
  &:last-child {
    color: ${theme.colors.defaultBlack};
  }
  &:hover {
    color: ${theme.colors.primary};
  }
  + li {
    margin-left: 1rem;
    &::before {
      content: '/';
      font-size: 1.5rem;
      color: ${theme.colors.defaultGray};
      margin-right: 1rem;
    }
  }
`;
