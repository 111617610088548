import { Popconfirm } from 'antd';
import React, { FC } from 'react';
import { FaPaperclip, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { Alert, CustomButton } from '../../../../components';
import { Role } from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { Invoice } from '../../../../utils/types/invoice';
import {
  StyledActionsBtnsContainer,
  StyledActionsContainer,
} from './HeaderActions.styled';

type Props = {
  data: Invoice | null;
  handleOnDelete: () => void;
  handleOnEdit: () => void;
  handleOnFileUpload: () => void;
};

const HeaderActions: FC<Props> = ({
  data,
  handleOnDelete,
  handleOnEdit,
  handleOnFileUpload,
}) => {
  const navigate = useNavigate();

  const handleOnNewSim = React.useCallback(() => {
    navigate('sims/nuevo');
  }, []);

  return (
    <div>
      <Alert
        canShow={!data?.has_balance}
        icon="warning"
        message="La factura no posee saldo disponible, por lo que no pueden crearse nuevos SIM."
        type="warning"
      />
      <AvailableComponent availableFor={[Role.Admin]}>
        <StyledActionsContainer>
          <StyledActionsBtnsContainer>
            {data?.has_balance && (
              <CustomButton
                $isMini
                text="Asociar SIM"
                $theme="success"
                onPress={handleOnNewSim}
              >
                <FaPlus size="1.5rem" />
              </CustomButton>
            )}
            {!data?.attachment && (
              <CustomButton
                $isMini
                text="Adjuntar Archivo"
                $theme="success"
                onPress={handleOnFileUpload}
              >
                <FaPaperclip size="1.5rem" />
              </CustomButton>
            )}
          </StyledActionsBtnsContainer>

          <StyledActionsBtnsContainer>
            <CustomButton
              $isMini
              text="Editar"
              $theme="secondary"
              onPress={handleOnEdit}
            />
            {data?.related_docs?.docs_count === 0 ? (
              <Popconfirm
                title="Precaución"
                description="¿Está seguro que quiere borrar el elemento? Este cambio es irreversible."
                onConfirm={handleOnDelete}
                okText="Eliminar"
                cancelText="Cancelar"
                okButtonProps={{ danger: true }}
              >
                <div>
                  <CustomButton $isMini text="Borrar" $theme="danger" />
                </div>
              </Popconfirm>
            ) : null}
          </StyledActionsBtnsContainer>
        </StyledActionsContainer>
      </AvailableComponent>
    </div>
  );
};

export default HeaderActions;
