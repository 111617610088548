import React, { useCallback, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { BoxContainer, Typography } from '../../components';
import { fromUserRoleToUrl, Role } from '../../utils';
import useStore from '../../zustand';
import useGetFilesCount from './hooks/useGetFilesCount';
import {
  StyledContentList,
  StyledContentListItem,
  StyledFolderIcon,
  StyledMyFilesMainContainer,
} from './MyFiles.styled';

const PageContent = () => {
  const [isReportItemsOpen, setIsReportItemsOpen] = useState(true);

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const { data } = useGetFilesCount();

  const handleCloseReportItems = useCallback(() => {
    setIsReportItemsOpen(false);
  }, [isReportItemsOpen]);

  const handleOpenReportItems = useCallback(() => {
    setIsReportItemsOpen(true);
  }, [isReportItemsOpen]);

  const sideBarLinks = [
    {
      id: 1,
      title: 'Reportes',
      url: `/${transformedUserRole}/mis-archivos/reportes`,
      action: handleOpenReportItems,
      count: data.total_reports,
      content: [
        {
          id: 1,
          title: 'OP Operativas',
          url: 'reportes/op',
          count: data.po_reports_count,
          hideFor: [Role.Transporter],
        },
        {
          id: 2,
          title: 'CRT Operativas',
          url: 'reportes/crt',
          count: data.crt_reports_count,
        },
        {
          id: 3,
          title: 'MICs',
          url: 'reportes/mic',
          count: data.mic_reports_count,
        },
        {
          id: 4,
          title: 'Camiones',
          url: 'reportes/camiones',
          count: data.truck_reports_count,
        },
        {
          id: 5,
          title: 'Semitrailers',
          url: 'reportes/semiremolques',
          count: data.semitrailer_reports_count,
        },
        {
          id: 6,
          title: 'Choferes',
          url: 'reportes/choferes',
          count: data.driver_reports_count,
        },
      ],
    },
    {
      id: 2,
      title: 'Facturas',
      url: `/${transformedUserRole}/mis-archivos/facturas`,
      action: handleCloseReportItems,
      count: data.invoice_attachments_count,
      hideFor: [Role.Transporter],
    },
    {
      id: 3,
      title: 'SIMs',
      url: `/${transformedUserRole}/mis-archivos/sims`,
      action: handleCloseReportItems,
      count: data.sim_attachments_count,
      hideFor: [Role.Transporter],
    },
    {
      id: 4,
      title: 'CRTs',
      url: `/${transformedUserRole}/mis-archivos/crts`,
      action: handleCloseReportItems,
      count: data.crt_attachments_count,
    },
    {
      id: 5,
      title: 'MICs',
      url: `/${transformedUserRole}/mis-archivos/mics`,
      action: handleCloseReportItems,
      count: data.mic_attachments_count,
    },
  ];

  return (
    <StyledMyFilesMainContainer>
      <div>
        <BoxContainer $useMarginBottom $useMarginTop $isFullWidth>
          <div>
            <span>
              <StyledFolderIcon size="2.5rem" />
            </span>
            <Typography
              as="p"
              theme="h6"
              $marginLeft="0.5rem"
              $marginBottom="3rem"
            >
              Mis Archivos
            </Typography>
          </div>

          <ul>
            {sideBarLinks.map((item) => {
              if (
                !item.hideFor ||
                !item.hideFor.includes(transformedUserRole as Role)
              )
                return (
                  <li key={item.id}>
                    <StyledContentListItem onClick={item.action}>
                      <NavLink to={item.url}>
                        <div>
                          <StyledFolderIcon size="2rem" />
                          <Typography
                            theme="p"
                            $marginLeft="0.5rem"
                            $marginBottom="2rem"
                          >
                            {item.title}
                          </Typography>
                        </div>
                        <Typography theme="subtext" as="p" $marginBottom="2rem">
                          <strong> {item.count}</strong>
                        </Typography>
                      </NavLink>
                    </StyledContentListItem>
                    {item.content && (
                      <StyledContentList
                        className={isReportItemsOpen ? 'open' : ''}
                      >
                        {item.content.map((subItem) => {
                          if (
                            !subItem.hideFor ||
                            !subItem.hideFor.includes(
                              transformedUserRole as Role,
                            )
                          )
                            return (
                              <li key={subItem.id}>
                                <StyledContentListItem>
                                  <NavLink to={subItem.url}>
                                    <div>
                                      <StyledFolderIcon size="2rem" />
                                      <Typography
                                        theme="p"
                                        $marginLeft="0.5rem"
                                        $marginBottom="2rem"
                                      >
                                        {subItem.title}
                                      </Typography>
                                    </div>
                                    <Typography
                                      theme="subtext"
                                      as="p"
                                      $marginBottom="2rem"
                                    >
                                      <strong> {subItem.count}</strong>
                                    </Typography>
                                  </NavLink>
                                </StyledContentListItem>
                              </li>
                            );
                        })}
                      </StyledContentList>
                    )}
                  </li>
                );
            })}
          </ul>
        </BoxContainer>
      </div>
      <div>
        <BoxContainer $useMarginBottom $useMarginTop $isFullWidth>
          <Outlet />
        </BoxContainer>
      </div>
    </StyledMyFilesMainContainer>
  );
};

export default PageContent;
