import { styled } from 'styled-components';

export const StyledTableIconCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  &.left {
    justify-content: left;
  }
`;

export const StyledTableIconCellTooltip = styled.div`
  align-items: center;
  display: flex;
  justify-content: left;
  width: 100%;
  &.left {
    justify-content: left;
  }
`;
