import React, { useCallback, useState } from 'react';

import { BoxContainer, Breadcrumb, Typography } from '../../components';
import { URLS } from '../../utils';
import CardsGroup from './components/CardsGroup';
import useSims from './hooks/useSims';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listSims,
      name: 'SIMs',
      withUserRole: true,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    isLoading,
    paginationData,
    setFilterInvoiceNumberValue,
    setFilterSimNumberValue,
    setFilterStateValue,
  } = useSims({ paginationCurrentPage: currentPage });

  const handleOnPaginationChange = useCallback((value: number) => {
    document.documentElement.scrollTo(0, 0);
    setCurrentPage(value);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        SIMs
      </Typography>
      <CardsGroup
        data={data}
        pagination={paginationData}
        onInputInvoiceNumberFilter={setFilterInvoiceNumberValue}
        onInputSimNumberFilter={setFilterSimNumberValue}
        onInputStatusFilter={setFilterStateValue}
        onPaginationChange={handleOnPaginationChange}
        currentPage={currentPage}
        isLoading={isLoading}
      />
    </BoxContainer>
  );
};

export default PageContent;
