import { InvoiceItems } from '../../../utils/types/invoice';
import { AddSimInputs } from '../types';

export type CurrentSimBalance = {
  index: number;
  quantity: number;
};

type CauclateBalanceParams = {
  index: number;
  currentSimData: AddSimInputs;
  currentInvoiceData: InvoiceItems[];
  currentSimQuantities: CurrentSimBalance[];
};

export const calculateSimBalance = ({
  index,
  currentSimData,
  currentInvoiceData,
  currentSimQuantities,
}: CauclateBalanceParams) => {
  const hasCurrentSimData = Object.entries(currentSimData).length;

  const currentSimQuantity = currentSimQuantities.find(
    (currentSimQuantity) => currentSimQuantity.index === index,
  );

  let absoluteBalance = Number();

  if (!hasCurrentSimData) {
    absoluteBalance = currentInvoiceData[index].balance;
  } else {
    if (currentSimData.merchandiseDeclaration.length) {
      absoluteBalance =
        currentInvoiceData[index].balance +
        currentSimData.merchandiseDeclaration[index].quantity;
    } else {
      absoluteBalance = currentInvoiceData[index].quantity;
    }
  }

  if (currentSimQuantity) {
    if (!currentSimQuantity.quantity) {
      if (currentSimData.merchandiseDeclaration?.length) {
        return (
          currentInvoiceData[index].balance +
          currentSimData.merchandiseDeclaration[index].quantity
        );
      } else {
        return currentInvoiceData[index].balance;
      }
    } else if (currentSimQuantity.quantity <= absoluteBalance) {
      return absoluteBalance - currentSimQuantity.quantity;
    }
    return Number();
  } else {
    return currentInvoiceData[index].balance;
  }
};
