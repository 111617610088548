import React, { FC } from 'react';

import { ActionCard } from '../../../../components';
import { ActionCardData } from '../../types';
import { StyledCardsContainer } from './DashboardCards.styled';

type Props = {
  cards: ActionCardData[];
};

const DashboardCards: FC<Props> = ({ cards }) => {
  return (
    <StyledCardsContainer>
      {cards.map((item) => (
        <ActionCard
          key={`${item.url}-${item.number}`}
          color={item.color}
          icon={item.icon}
          number={item.number}
          title={item.title}
          url={item.url}
          ctaText={item.ctaText}
        />
      ))}
    </StyledCardsContainer>
  );
};

export default DashboardCards;
