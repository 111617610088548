import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, Role, URLS } from '../../utils';
import AvailableComponent from '../../utils/AvailableComponent';
import { PaginationData } from '../../utils/types';
import useStore from '../../zustand';
import InvoicesTable from './components/InvoicesTable';
import useInvoices from './hooks/useInvoices';

const PageContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 8,
    current: currentPage,
    onChange: setCurrentPage,
  };

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
  ];

  const [inputSelectListQuery, setInputSelectListQuery] = useState('');

  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const {
    clientsArray,
    data,
    isLoading,
    totalResults,
    setCheckboxFilterValue,
    setInputInvoiceNumberFilterValue,
    setSelectedClient,
    handleTableChange,
  } = useInvoices({ selectInputQuery: inputSelectListQuery, paginationData });

  const handleOnClickNewInvoice = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.addInvoice}`);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Facturas
      </Typography>
      <AvailableComponent availableFor={[Role.Admin]}>
        <CustomButton
          $isMini
          $marginBottom="6rem"
          onPress={handleOnClickNewInvoice}
          text="Nueva Factura"
        />
      </AvailableComponent>
      <InvoicesTable
        data={data}
        isLoading={isLoading}
        onFilterInvoiceNumberChange={setInputInvoiceNumberFilterValue}
        onInputCheckboxChange={setCheckboxFilterValue}
        onSelectQueryChange={setInputSelectListQuery}
        onSelectOptionChange={setSelectedClient}
        selectListOptions={clientsArray}
        paginationData={{
          ...paginationData,
          total: totalResults,
        }}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
