import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { CompanyResponse } from '../../../utils/types/company';
import { Driver } from '../../../utils/types/driver';
import { Semitrailer } from '../../../utils/types/semitrailer';
import { Truck } from '../../../utils/types/truck';
import { VehicleOwner } from '../../../utils/types/vehicleOwner';

const useGetFilterOptions = () => {
  const axios = useAxios();
  const token = sessionStorage.getItem('token');

  // URLS
  const TRANSPORT_URL =
    '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';
  const VEHICLE_OWNER_URL =
    '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';
  const TRUCK_URL =
    '/wheeleds?filters[type][$eq]=Truck&filters[license_plate][$containsi]=';
  const SEMITRAILER_URL =
    '/wheeleds?filters[type][$eq]=Semitrailer&filters[license_plate][$containsi]=';
  const DRIVER_URL = '/truck-drivers?populate=*&filters[ic][$containsi]=';
  const IMPORTER_EXPORTER_URL =
    '/companies?filters[type][$eq]=Importadora/Exportadora&filters[business_name][$containsi]=';

  // Filter Queries
  const [transportQuery, setTransportQuery] = useState('');
  const [vehicleOwnerQuery, setVehicleOwnerQuery] = useState('');
  const [truckQuery, setTruckQuery] = useState('');
  const [semitrailerQuery, setSemitrailerQuery] = useState('');
  const [driverQuery, setDriverQuery] = useState('');
  const [importerQuery, setImporterQuery] = useState('');
  const [exporterQuery, setExporterQuery] = useState('');

  // Options States
  const [transportOptions, setTransportOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [vehicleOwnerOptions, setVehicleOwnerOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [truckrOptions, setTruckOptions] = useState<InputSelectListOption[]>(
    [],
  );
  const [semitrailerOptions, setSemitrailerOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [driverOptions, setDriverOptions] = useState<InputSelectListOption[]>(
    [],
  );
  const [importerOptions, setImporterOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [exporterOptions, setExporterOptions] = useState<
    InputSelectListOption[]
  >([]);

  // Fetch Options

  // Transport
  const getTransportOptions = () => {
    axios
      .get<CompanyResponse>(`${TRANSPORT_URL}${transportQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setTransportOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getTransportOptions();
  }, [transportQuery]);

  // Vehicle Owner
  const getVehicleOwnerOptions = () => {
    axios
      .get<{ data: VehicleOwner[] }>(
        `${VEHICLE_OWNER_URL}${vehicleOwnerQuery}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  label: item.attributes.business_name,
                  value: item.id,
                };
              })
            : [];

        setVehicleOwnerOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getVehicleOwnerOptions();
  }, [vehicleOwnerQuery]);

  // Trucks
  const getTruckOptions = () => {
    axios
      .get<{ data: Truck[] }>(`${TRUCK_URL}${truckQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  label: item.attributes.license_plate,
                  value: item.id,
                };
              })
            : [];

        setTruckOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getTruckOptions();
  }, [truckQuery]);

  // Semitrailer
  const getSemitrailerOptions = () => {
    axios
      .get<{ data: Semitrailer[] }>(`${SEMITRAILER_URL}${semitrailerQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  label: item.attributes.license_plate,
                  value: item.id,
                };
              })
            : [];

        setSemitrailerOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getSemitrailerOptions();
  }, [semitrailerQuery]);

  // Driver
  const getDriverOptions = () => {
    axios
      .get<{ data: Driver[] }>(`${DRIVER_URL}${driverQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  label: `${item.attributes.ic} - ${item.attributes.last_name}`,
                  value: item.id,
                };
              })
            : [];

        setDriverOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getDriverOptions();
  }, [driverQuery]);

  // Importer
  const getImporterOptions = () => {
    axios
      .get<CompanyResponse>(`${IMPORTER_EXPORTER_URL}${importerQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setImporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getImporterOptions();
  }, [importerQuery]);

  // Exporter
  const getExporterOptions = () => {
    axios
      .get<CompanyResponse>(`${IMPORTER_EXPORTER_URL}${exporterQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setExporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getExporterOptions();
  }, [exporterQuery]);

  // Results
  const filterQueries = {
    transport: setTransportQuery,
    vehicleOwner: setVehicleOwnerQuery,
    truck: setTruckQuery,
    semitrailer: setSemitrailerQuery,
    driver: setDriverQuery,
    importer: setImporterQuery,
    exporter: setExporterQuery,
  };

  const filterOptions = {
    transport: transportOptions,
    vehicleOwner: vehicleOwnerOptions,
    truck: truckrOptions,
    semitrailer: semitrailerOptions,
    driver: driverOptions,
    importer: importerOptions,
    exporter: exporterOptions,
  };

  return {
    filterOptions,
    filterQueries,
  };
};

export default useGetFilterOptions;
