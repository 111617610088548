import React from 'react';

import PageContent from '../../features/Files';

const FilesPage = () => {
  return (
    <div>
      <PageContent />
    </div>
  );
};

export default FilesPage;
