import React, { useState } from 'react';

import { BoxContainer, Breadcrumb, Typography } from '../../components';
import { URLS } from '../../utils';
import CrtsTable from './components/CrtsTable';
import useCrts from './hooks/useCrts';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listCrts,
      name: 'CRTs',
      withUserRole: true,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    isLoading,
    paginationData,
    transporterOptions,
    filterCheckboxValue,
    setFilterSimNumberValue,
    setFilterCrtNumberValue,
    setFilterTransporterValue,
    setFilterCheckboxTextValue,
    setFilterTransporterTextQueryInput,
    handleTableChange,
  } = useCrts({ paginationCurrentPage: currentPage });

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        CRTs
      </Typography>
      <CrtsTable
        data={data}
        pagination={paginationData}
        transporterOptions={transporterOptions}
        filterCheckboxValue={filterCheckboxValue}
        onFilterSimNumberChange={setFilterSimNumberValue}
        onFilterCrtNumberChange={setFilterCrtNumberValue}
        onFilterTransporterChange={setFilterTransporterValue}
        onInputCheckboxChange={setFilterCheckboxTextValue}
        onChangeTransporterQuery={setFilterTransporterTextQueryInput}
        onPaginationChange={setCurrentPage}
        currentPage={currentPage}
        isLoading={isLoading}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
