import React, { useEffect, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Breadcrumb,
  CustomButton,
  InputDatePicker,
  InputSelect,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import { fromUserRoleToUrl, URLS } from '../../../../utils';
import { useGetCompanies, useGetMerchandise } from '../../../../utils/hooks';
import {
  StyledErrorMessage,
  StyledFormActions,
  StyledSubForm,
} from '../../../../utils/styles';
import { InputSelectListOption } from '../../../../utils/types';
import useStore from '../../../../zustand';
import { RelatedDocs } from "../../../InvoiceDetail/types";
import useAddInvoice from '../../hooks/useAddInvoice';
import {
  StyledErrorContainer,
  StyledItemHeader,
  StyledItemsContainer,
  StyledSubFormRow,
} from './AddInvoiceForm.styled';
import { currencies, roundNumber, saleConditions, units } from './utils';

type Inputs = {
  invoiceNumber: string;
  date: string;
  opNumber: string;
  exporterID: { label: string; value: string | number };
  importerID: { label: string; value: string | number };
  saleCondition: string;
  items: ItemsInput[];
  related_docs: RelatedDocs
};

type ItemsInput = {
  description: { label: string; value: string | number };
  quantity: number;
  UM: string;
  unitValue: string;
  amount: string;
  currency: string;
};

const AddInvoiceForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const [importerCompaniesQuery, setImporterCompaniesQuery] = useState('');
  const [exporterCompaniesQuery, setExporterCompaniesQuery] = useState('');
  const [merchandiseQuery, setMerchandiseQuery] = useState('');
  const [hasRelatedDocs, setHasRelatedDocs] =useState(false);

  const companiesFilterByType = 'filters[type][$eq]=Importadora/Exportadora&';
  const importerCompanies = useGetCompanies({
    query: importerCompaniesQuery,
    filter: companiesFilterByType,
  });
  const exporterCompanies = useGetCompanies({
    query: exporterCompaniesQuery,
    filter: companiesFilterByType,
  });
  const merchandiseOptions = useGetMerchandise({ query: merchandiseQuery });

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.addInvoice,
      name: `${params.invoice_id ? 'Editar' : 'Nueva'} Factura`,
      withUserRole: true,
    },
  ];

  const { register, handleSubmit, reset, formState, control, watch } =
    useForm<Inputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control,
    rules: {
      required: 'La factura debe tener por lo menos un item asociado',
    },
  });

  const { handleAddInvoice, singleInvoiceData, isLoading, customError } =
    useAddInvoice({
      invoiceID: params.invoice_id,
    });

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleInvoiceData) {
      reset(singleInvoiceData);
      if (singleInvoiceData.related_docs?.docs_count>0) {
        setHasRelatedDocs(true)
      }
    }
  }, [singleInvoiceData]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleAddInvoice(data);
  };

  const handleOnCancel = React.useCallback(() => {
    const urlToRedirect = params.invoice_id
      ? URLS.invoiceDetail.replace('{invoiceID}', params.invoice_id)
      : URLS.listInvoices;
    navigate(`/${transformedUserRole}${urlToRedirect}`);
  }, []);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA = !formState.isDirty || isLoading || errorsArray.length > 0;

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {params.invoice_id ? 'Editar' : 'Nueva'} Factura
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {customError.length > 0 && (
          <StyledErrorMessage>
            {customError.map((item) => (
              <div key={item.replaceAll(' ', '')}>
                <Typography theme="p" $marginBottom="1.5rem">
                  {item}
                </Typography>
              </div>
            ))}
          </StyledErrorMessage>
        )}
        <StyledSubForm>
          <InputText
            placeholder="Número de Factura"
            label="Número de Factura"
            $hasValue={formState.dirtyFields.invoiceNumber}
            $invalid={!!formState.errors.invoiceNumber}
            {...register('invoiceNumber', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.invoiceNumber?.message}
          />
          <Controller
            name="date"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
            }}
            render={({ field }) => (
              <InputDatePicker
                $hasValue={formState.dirtyFields.date}
                $invalid={!!formState.errors.date}
                handleOnChange={(date) => {
                  field.onChange(date);
                }}
                label="Fecha"
                placeholder="Fecha"
                subTextMessage={formState.errors.date?.message}
                value={field.value}
              />
            )}
          />
          <InputText
            placeholder="Número de OP"
            label="Número de OP"
            $hasValue={formState.dirtyFields.opNumber}
            $invalid={!!formState.errors.opNumber}
            {...register('opNumber')}
            subTextMessage={formState.errors.opNumber?.message}
          />
          <Controller
            name="importerID"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
            }}
            render={({ field }) => {
              return (
                <InputSelectList
                  options={importerCompanies.companiesInputOptions}
                  $hasValue={!!formState.dirtyFields.importerID}
                  $invalid={!!formState.errors.importerID}
                  label="Importador"
                  placeholder="Importador"
                  handleOnChange={(event) => {
                    field.onChange(event);
                  }}
                  handleOnInputChange={setImporterCompaniesQuery}
                  subTextMessage={formState.errors.importerID?.message}
                  value={field.value}
                />
              );
            }}
          />
          <Controller
            name="exporterID"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
              validate: (selectedOption: InputSelectListOption) => {
                if (watch('importerID.value') === selectedOption.value) {
                  return 'El Importador y el Exportador deben ser diferentes';
                }
              },
            }}
            render={({ field }) => {
              return (
                <InputSelectList
                  options={exporterCompanies.companiesInputOptions}
                  $hasValue={!!formState.dirtyFields.exporterID}
                  $invalid={!!formState.errors.exporterID}
                  label="Exportador"
                  placeholder="Exportador"
                  handleOnChange={(event) => {
                    field.onChange(event);
                  }}
                  handleOnInputChange={setExporterCompaniesQuery}
                  subTextMessage={formState.errors.exporterID?.message}
                  value={field.value}
                />
              );
            }}
          />

          <InputSelect
            options={saleConditions}
            placeholder="Condición de Venta"
            label="Condición de Venta"
            $hasValue={formState.dirtyFields.saleCondition}
            $invalid={!!formState.errors.saleCondition}
            {...register('saleCondition', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.saleCondition?.message}
          />
        </StyledSubForm>

        <Typography theme="h4" as="h2" $marginBottom="4rem">
          Items
        </Typography>
        <StyledErrorContainer>
          <Typography theme="p" $marginBottom="2rem">
            {formState.errors.items?.root?.message}
          </Typography>
        </StyledErrorContainer>
        <StyledItemsContainer>
          {fields.map((field, index) => {
            return (
              <div key={field.id} style={{ marginBottom: '2rem' }}>
                <StyledItemHeader>
                  <Typography theme="h5">Item {index + 1}</Typography>
                  <CustomButton
                    $isMini
                    $theme="danger"
                    onPress={() => {
                      remove(index);
                    }}
                  >
                    <FaTrashAlt size="2rem" />
                  </CustomButton>
                </StyledItemHeader>
                <StyledSubFormRow>
                  <Controller
                    name={`items.${index}.description`}
                    control={control}
                    rules={{
                      required: {
                        message: 'Este Campo es requerido',
                        value: true,
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <InputSelectList
                          options={merchandiseOptions.merchandisesInputOptions}
                          $hasValue={
                            !!formState.dirtyFields.items?.[index]?.description
                          }
                          $invalid={
                            !!formState.errors.items?.[index]?.description
                          }
                          label="Descripción"
                          placeholder="Descripción"
                          handleOnChange={(event) => {
                            field.onChange(event);
                          }}
                          handleOnInputChange={setMerchandiseQuery}
                          subTextMessage={
                            formState.errors.items?.[index]?.description
                              ?.message
                          }
                          value={field.value}
                          isDisabled={hasRelatedDocs}
                        />
                      );
                    }}
                  />
                  <InputText
                    placeholder="Cantidad"
                    label="Cantidad"
                    {...register(`items.${index}.quantity`, {
                      required: 'Este Campo es requerido',
                    })}
                    subTextMessage={
                      formState.errors.items?.[index]?.quantity?.message
                    }
                    $hasValue={!!formState.dirtyFields.items?.[index]?.quantity}
                    $invalid={!!formState.errors.items?.[index]?.quantity}
                  />
                  <InputSelect
                    options={units}
                    placeholder="Unidad de Medida"
                    label="Unidad de Medida"
                    {...register(`items.${index}.UM`, {
                      required: 'Este Campo es requerido',
                    })}
                    subTextMessage={
                      formState.errors.items?.[index]?.UM?.message
                    }
                    $hasValue={!!formState.dirtyFields.items?.[index]?.UM}
                    $invalid={!!formState.errors.items?.[index]?.UM}
                  />
                  <InputText
                    placeholder="Valor Unitario"
                    label="Valor Unitario"
                    disabled
                    value={roundNumber(
                      parseFloat(watch(`items.${index}.amount`)) /
                        watch(`items.${index}.quantity`) || 0,
                    )}
                    {...register(`items.${index}.unitValue`)}
                    subTextMessage={
                      formState.errors.items?.[index]?.unitValue?.message
                    }
                    $hasValue={
                      !!formState.dirtyFields.items?.[index]?.unitValue
                    }
                    $invalid={!!formState.errors.items?.[index]?.unitValue}
                  />
                  <InputText
                    placeholder="Importe"
                    label="Importe"
                    {...register(`items.${index}.amount`, {
                      required: 'Este Campo es requerido',
                    })}
                    subTextMessage={
                      formState.errors.items?.[index]?.amount?.message
                    }
                    $hasValue={!!formState.dirtyFields.items?.[index]?.amount}
                    $invalid={!!formState.errors.items?.[index]?.amount}
                  />
                  <InputSelect
                    options={currencies}
                    placeholder="Moneda"
                    label="Moneda"
                    {...register(`items.${index}.currency`, {
                      required: 'Este Campo es requerido',
                    })}
                    subTextMessage={
                      formState.errors.items?.[index]?.currency?.message
                    }
                    $hasValue={!!formState.dirtyFields.items?.[index]?.currency}
                    $invalid={!!formState.errors.items?.[index]?.currency}
                  />
                </StyledSubFormRow>
              </div>
            );
          })}

          <CustomButton
            $isMini
            $theme="secondary"
            onPress={() => {
              append({} as ItemsInput);
            }}
          >
            <FaPlusCircle size="2rem" />
          </CustomButton>
        </StyledItemsContainer>

        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text={params.invoice_id ? 'Actualizar' : 'Crear'}
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default AddInvoiceForm;
