import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';

import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { CrtPlainGenericData } from '../../../utils/types/crt';
import {
  BalanceDetail,
  SelectedCrtResponse,
} from '../../../utils/types/crt_alt';
import { AddMicInputs } from '../types';
import { filterCrtsWithoutExpiredSim } from '../utils';

const useDispatcherLanding = () => {
  const API_URL_FILE_UPLOAD = '/upload';
  const API_URL_FIND_CRT =
    '/custom-crt/all?populate=*&filters[active][$eq]=true&filters[number][$containsi]=';
  const API_URL_CREATE_MIC = '/custom-mic/crt/{crt_id}/create';
  const API_URL_GET_CRT = '/custom-crt/{crt_id}/find';

  const axios = useAxios();
  const errorHandler = new ErrorHandler();
  const { showNotification } = useShowNotification();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState<string[]>([]);

  // CRT select list
  const [crtSelectQuery, setCrtSelectQuery] = useState('');
  const [crtSelectOptions, setCrtSelectOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [crtSelectedOption, setCrtSelectedOption] =
    useState<InputSelectListOption | null>(null);
  const [crtBalanceDetails, setCrtBalanceDetails] = useState<BalanceDetail[]>(
    [],
  );
  const [crtData, setCrtData] = useState<CrtPlainGenericData | undefined>(
    undefined,
  );

  // Attachment
  const [attachment, setAttachment] = useState<number | null>(null);

  const finalURL = () => {
    return `${API_URL_FIND_CRT}${crtSelectQuery}`;
  };

  const getCRTData = async (crtID: string | number) => {
    setIsLoading(true);
    setHasError(false);

    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No Token provided');

    const response = await axios.get<CrtPlainGenericData>(
      API_URL_GET_CRT.replace('{crt_id}', crtID.toString()),
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setCrtData(response.data);

    const balanceDetails = response.data?.balance_details ?? [];
    setCrtBalanceDetails(balanceDetails);

    setIsLoading(false);
  };
  useEffect(() => {
    if (crtSelectedOption?.value)
      getCRTData(crtSelectedOption.value).catch((error) => {
        setHasError(true);
        setIsLoading(false);
        log.error(error);
      });
  }, [crtSelectedOption]);

  const getCRTOptions = async () => {
    setIsLoading(true);
    setHasError(false);

    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No Token provided');

    const response = await axios.get<{ data: SelectedCrtResponse[] }>(
      finalURL(),
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const mappedResponse = filterCrtsWithoutExpiredSim(response.data.data);

    const result: InputSelectListOption[] = mappedResponse.map((item) => ({
      label: item.attributes.number,
      value: item.id,
    }));
    setCrtSelectOptions(result);

    setIsLoading(false);
  };
  useEffect(() => {
    getCRTOptions().catch((error) => {
      setIsLoading(false);
      setHasError(true);
      log.error(error);
    });
  }, [crtSelectQuery]);

  // Create / Update MIC
  const handleAddMic = async (data: AddMicInputs) => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      const issueDate = new Date(data.issueDate);

      const micData = {
        data: {
          number: data.micNumber,
          shipment_number: data.shipmentNumber,
          date_of_issue: issueDate.toISOString(),
          seal_number: data.sealNumber,
          vehicle_owner: data.vehicleOwner.value,
          truck: data.truckLicensePlate.value,
          semitrailer: data.semitrailerLicensePlate.value,
          truck_driver: data.truckDriverID.value,
          attachment,
          mic_items: data.merchandiseDeclaration.map((item, index) => ({
            quantity: item.quantity,
            unit: crtData?.crt_items?.[index]?.unit,
            merchandise_id: crtData?.crt_items?.[index]?.merchandise_id?.id,
          })),
          country: crtData?.delivery.country,
          place: crtData?.delivery.place,
          category: crtData?.delivery.category,
          company_id: crtData?.invoice_company_id,
        },
      };

      await axios.post(
        API_URL_CREATE_MIC.replace(
          '{crt_id}',
          crtSelectedOption?.value.toString() || String(),
        ),
        { ...micData },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setIsLoading(false);

      showNotification({
        message: 'Ha sido creada satisfactoriamente',
      });

      // Reset form
      setCrtSelectedOption(null);
      setCustomError([]);
    } catch (error) {
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError([...mappedError]);
      setIsLoading(false);
    }
  };

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const { file, onSuccess } = info;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      const filesData = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, filesData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      if (onSuccess) {
        onSuccess(filesData);
      }

      if (attachmentID) setAttachment(parseInt(attachmentID));
    } catch (error) {
      log.error(error);
    }
  };

  return {
    hasError,
    isLoading,
    setCrtSelectQuery,
    setCrtSelectedOption,
    crtSelectOptions,
    crtBalanceDetails,
    crtSelectedOption,
    handleUploadFile,
    handleAddMic,
    attachment,
    customError,
  };
};

export default useDispatcherLanding;
