import { Params } from 'react-router-dom';

import { isAdmin, isImpExpAdmin, isTransporter, URLS } from '../../../utils';

export type RedirectCrtDetails = {
  userRole: string;
  invoiceID?: string;
  simID?: string;
  crtID?: string;
  transformedUserRole?: string;
};

export type CrtRelatedBreadcrumbParams = {
  userRole: string;
  params: Readonly<Params<string>>;
  invoiceID?: string;
  simID?: string;
  crtID?: string;
};

export const buildCrtRelatedMicsURL = (
  invoiceID?: string,
  simID?: string,
  crtID?: string,
) => {
  return URLS.crtRelatedMics
    .replace('{invoiceID}', invoiceID || String())
    .replace('{simID}', simID || String())
    .replace('{crtID}', crtID || String());
};

export const breadcrumbs = (args: CrtRelatedBreadcrumbParams) => {
  let breadcrumbItems: {
    location: string;
    name: string;
    withUserRole?: boolean;
  }[] = [];

  if (isAdmin(args.userRole) || isImpExpAdmin(args.userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: URLS.listInvoices,
        name: 'Facturas',
        withUserRole: true,
      },
      {
        location: URLS.invoiceDetail.replace(
          '{invoiceID}',
          args.invoiceID || String(),
        ),
        name: 'Detalle Factura',
        withUserRole: true,
      },
      {
        location: buildCrtRelatedMicsURL(
          args.invoiceID,
          args.simID,
          args.crtID,
        ),
        name: 'MICs',
        withUserRole: true,
      },
    ];
  }

  if (isTransporter(args.userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: URLS.listCrts,
        name: 'CRTs',
        withUserRole: true,
      },
      {
        location: `/crts/${args.params.crt_id}`,
        name: 'Detalle CRT',
        withUserRole: true,
      },
      {
        location: buildCrtRelatedMicsURL(
          args.invoiceID,
          args.simID,
          args.crtID,
        ),
        name: 'MICs',
        withUserRole: true,
      },
    ];
  }

  return breadcrumbItems;
};

export const handleGoToCrtDetails = ({
  userRole,
  invoiceID,
  simID,
  crtID,
  transformedUserRole,
}: RedirectCrtDetails) => {
  let navigateTo = String();

  if (isAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}`;
  else if (isImpExpAdmin(userRole))
    navigateTo = `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}`;
  else if (isTransporter(userRole))
    navigateTo = `/${transformedUserRole}/crts/${crtID}`;

  return navigateTo;
};
