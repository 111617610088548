import React, { FC } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Typography from '../Typography';
import {
  StyledActionCard,
  StyledDataContainer,
  StyledLinkContainer,
} from './ActionCard.styled';
import { ColorListType, getIconFoo, IconListTypeFoo } from './utils';
type Props = {
  color: ColorListType;
  ctaText?: string;
  icon: IconListTypeFoo;
  number?: number;
  title?: string;
  url: string;
};

const ActionCard: FC<Props> = ({
  color,
  ctaText,
  icon,
  number,
  title,
  url,
}) => {
  const IconTag = getIconFoo(icon);

  return (
    <StyledActionCard className={color}>
      <StyledDataContainer>
        <Typography theme="h1">{number}</Typography>
        <Typography theme="h6">{title}</Typography>
        <IconTag size="4rem" />
      </StyledDataContainer>
      <Link to={url}>
        <StyledLinkContainer>
          <FaPlusCircle size="1.5rem" />
          <Typography theme="h6" $marginLeft="0.5rem">
            {ctaText ? ctaText : 'Crear'}
          </Typography>
        </StyledLinkContainer>
      </Link>
    </StyledActionCard>
  );
};

export default ActionCard;
