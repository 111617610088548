import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Breadcrumb,
  CustomButton,
  InputArea,
  InputDatePicker,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import {
  fromUserRoleToUrl,
  Role,
  URLS,
  USER_ROLES_LIST,
} from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import useStore from '../../../../zustand';
import useAddSemitrailer from '../../hooks/useAddSemitrailer';
import { SemitrailerFormInputs } from '../../types';

const AddSemitrailerForm = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const {
    handleAddSemitrailer,
    inputOptions,
    isLoading,
    setInputQuery,
    singleSemitrailerData,
  } = useAddSemitrailer({
    semitrailerID: params.semitrailer_id,
  });

  const { control, formState, handleSubmit, register, reset } =
    useForm<SemitrailerFormInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleSemitrailerData) {
      reset(singleSemitrailerData);
    }
  }, [singleSemitrailerData]);

  const onSubmit: SubmitHandler<SemitrailerFormInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleAddSemitrailer(data);
  };

  const handleOnCancel = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.semitrailers}`);
  }, []);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA = !formState.isDirty || isLoading || errorsArray.length > 0;

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.semitrailers,
      name: 'Semiremolques',
      withUserRole: true,
    },
    {
      location: URLS.addSemitrailer,
      name: `${params.semitrailer_id ? 'Editar' : 'Nuevo'} Semiremolque`,
      withUserRole: true,
    },
  ];

  const breadcrumbItemsForDispatcher = [
    { location: URLS.addMic, name: 'Nuevo MIC', withUserRole: true },
    { location: URLS.addTruck, name: 'Nuevo Camión', withUserRole: true },
  ];

  const selectedBreadcrumbItems =
    useRole === USER_ROLES_LIST.dispatcher
      ? breadcrumbItemsForDispatcher
      : breadcrumbItems;

  return (
    <>
      <Breadcrumb items={selectedBreadcrumbItems} />

      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {params.semitrailer_id ? 'Editar' : 'Nuevo'} Semiremolque
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AvailableComponent availableFor={[Role.SuperAdmin, Role.Dispatcher, Role.Admin]}>
          <div>
            <Typography theme="h3" as="h2">
              Propietario del vehículo
            </Typography>
            <StyledSubForm>
              <Controller
                name="vehicleOwner"
                control={control}
                rules={{
                  required: { message: 'Este Campo es requerido', value: true },
                }}
                render={({ field }) => {
                  return (
                    <InputSelectList
                      options={inputOptions}
                      $hasValue={!!formState.dirtyFields.vehicleOwner}
                      $invalid={!!formState.errors.vehicleOwner}
                      label="Propietario"
                      placeholder="Propietario"
                      handleOnChange={(event) => {
                        field.onChange(event);
                      }}
                      handleOnInputChange={(event) => {
                        setInputQuery(event);
                      }}
                      subTextMessage={formState.errors.vehicleOwner?.message}
                      value={field.value}
                    />
                  );
                }}
              />
            </StyledSubForm>
          </div>
        </AvailableComponent>
        <div>
          <Typography theme="h3" as="h2">
            Datos del Vehículo
          </Typography>
          <StyledSubForm>
            <InputText
              placeholder="Patente"
              $hasValue={formState.dirtyFields.licensePlate}
              $invalid={!!formState.errors.licensePlate}
              label="Patente"
              subTextMessage={formState.errors.licensePlate?.message}
              {...register('licensePlate', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputArea
              $hasValue={formState.dirtyFields.comment}
              $invalid={!!formState.errors.comment}
              label="Comentarios"
              maxLength={1000}
              placeholder="Comentarios"
              subTextMessage={formState.errors.comment?.message}
              {...register('comment')}
            />
          </StyledSubForm>
        </div>
        <div>
          <Typography theme="h3" as="h2">
            Póliza de Seguro
          </Typography>
          <StyledSubForm>
            <InputText
              $hasValue={formState.dirtyFields.insurancePolicyNumber}
              $invalid={!!formState.errors.insurancePolicyNumber}
              label="Número de Póliza"
              placeholder="Número de Póliza"
              subTextMessage={formState.errors.insurancePolicyNumber?.message}
              {...register('insurancePolicyNumber', {
                required: 'Este Campo es requerido',
              })}
            />
            <Controller
              name="insurancePolicyExpiration"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputDatePicker
                  $hasValue={formState.dirtyFields.insurancePolicyExpiration}
                  $invalid={!!formState.errors.insurancePolicyExpiration}
                  handleOnChange={(date) => {
                    field.onChange(date);
                  }}
                  label="Vencimiento de Poliza"
                  placeholder="Vencimiento de Poliza"
                  subTextMessage={formState.errors.vehicleOwner?.message}
                  value={field.value}
                />
              )}
            />
          </StyledSubForm>
        </div>
        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text={params.semitrailer_id ? 'Actualizar' : 'Crear'}
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default AddSemitrailerForm;
