import { Merchandise } from '../../../../../utils/types/merchandise';
import { TableData } from '../../../types';

export const transformData = (data?: Merchandise[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      code: item.attributes.code,
      description: item.attributes.description,
      export_record_required: item.attributes.export_record_required,
      comment: item.attributes.comment,
    };
  });

  return transformedData;
};
