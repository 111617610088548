import { Pagination } from 'antd';
import React, { FC, useState } from 'react';
import { FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';

import {
  CustomButton,
  EmptyMessage,
  InputCheckbox,
  InputDatePicker,
  InputSelectList,
  InputText,
  Loader,
} from '../../../../components';
import { END_OF_DAY_COMPLEMENT } from "../../../../utils/dates";
import { StyledTableContainer } from '../../../../utils/styles';
import {
  InputSelectListOption,
  Pagination as PaginationType,
} from '../../../../utils/types';
import { Mic } from '../../../../utils/types/mics';
import CardData from '../CardData';
import {
  StyledCardsContainer,
  StyledCardsGroupMainContainer,
  StyledMultipleFiltersContainer,
  StyledPaginationContainer,
  StyledReportButtonsContainer,
} from './CardsGroup.styled';
import { formatDateString } from './utils';

type Props = {
  currentPage: number;
  data: Mic[];
  isLoading: boolean;
  onPaginationChange: (page: number) => void;
  paginationData: PaginationType;
  onGenerateReportPDF: () => void;
  onGenerateReportXLXS: () => void;
  onFilter: {
    dateFrom: (value: string) => void;
    dateUntil: (value: string) => void;
    crtNumber: (value: string) => void;
    micNumber: (value: string) => void;
    transport: (value: InputSelectListOption | null) => void;
    vehicleOwner: (value: InputSelectListOption | null) => void;
    truck: (value: InputSelectListOption | null) => void;
    semitrailer: (value: InputSelectListOption | null) => void;
    driver: (value: InputSelectListOption | null) => void;
    importer: (value: InputSelectListOption | null) => void;
    exporter: (value: InputSelectListOption | null) => void;
    delivered: (value: boolean) => void;
  };
  onFilterQuery: {
    transport: (value: string) => void;
    vehicleOwner: (value: string) => void;
    truck: (value: string) => void;
    semitrailer: (value: string) => void;
    driver: (value: string) => void;
    importer: (value: string) => void;
    exporter: (value: string) => void;
  };
  onFilterOptions: {
    transport: InputSelectListOption[];
    vehicleOwner: InputSelectListOption[];
    truck: InputSelectListOption[];
    semitrailer: InputSelectListOption[];
    driver: InputSelectListOption[];
    importer: InputSelectListOption[];
    exporter: InputSelectListOption[];
  };
};

const CardsGroup: FC<Props> = ({
  data,
  isLoading,
  currentPage,
  paginationData,
  onPaginationChange,
  onFilter,
  onFilterOptions,
  onFilterQuery,
  onGenerateReportPDF,
  onGenerateReportXLXS,
}) => {
  const [dateFromValue, setdateFromValue] = useState<string | undefined>(
    undefined,
  );
  const [dateUntilValue, setdateUntilValue] = useState<string | undefined>(
    undefined,
  );

  // Date From
  const handleOnFilterDateFrom = React.useCallback(
    (date: unknown, dateString: string) => {
      const newDate = formatDateString(dateString);
      setdateFromValue(newDate);
      onFilter.dateFrom(newDate);
    },
    [],
  );

  // Date Until
  const handleOnFilterDateUntil = React.useCallback(
    (date: unknown, dateString: string) => {
      const newDate = (formatDateString(dateString)) + END_OF_DAY_COMPLEMENT;
      setdateUntilValue(newDate);
      onFilter.dateUntil(newDate);
    },
    [],
  );

  // Mic Number
  const handleOnFilterCrtNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilter.crtNumber(event.target.value);
    },
    [],
  );

  // Mic Number
  const handleOnFilterMicNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilter.micNumber(event.target.value);
    },
    [],
  );

  // Delivered
  const handleOnFilterDelivered = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilter.delivered(event.target.checked);
    },
    [],
  );

  return (
    <StyledCardsGroupMainContainer>
      {isLoading && <Loader />}
      <StyledTableContainer>
        <StyledMultipleFiltersContainer>
          <div>
            <InputDatePicker
              label="Fecha Desde"
              placeholder="Fecha Desde"
              handleOnChange={handleOnFilterDateFrom}
              value={dateFromValue}
            />
          </div>
          <div>
            <InputDatePicker
              label="Fecha Hasta"
              placeholder="Fecha Hasta"
              handleOnChange={handleOnFilterDateUntil}
              value={dateUntilValue}
            />
          </div>
          <div>
            <InputText
              label="Número CRT"
              placeholder="Número CRT"
              onChange={handleOnFilterCrtNumber}
            />
          </div>
          <div>
            <InputText
              label="Número MIC"
              placeholder="Número MIC"
              onChange={handleOnFilterMicNumber}
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.transport}
              handleOnInputChange={onFilterQuery.transport}
              options={onFilterOptions.transport}
              label="Transporte"
              placeholder="Transporte"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.vehicleOwner}
              handleOnInputChange={onFilterQuery.vehicleOwner}
              options={onFilterOptions.vehicleOwner}
              label="Propietario del Transporte"
              placeholder="Propietario del Transporte"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.truck}
              handleOnInputChange={onFilterQuery.truck}
              options={onFilterOptions.truck}
              label="Placa del Camión"
              placeholder="Placa del Camión"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.semitrailer}
              handleOnInputChange={onFilterQuery.semitrailer}
              options={onFilterOptions.semitrailer}
              label="Placa del Semiremolque"
              placeholder="Placa del Semiremolque"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.driver}
              handleOnInputChange={onFilterQuery.driver}
              options={onFilterOptions.driver}
              label="Conductor"
              placeholder="Conductor"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.importer}
              handleOnInputChange={onFilterQuery.importer}
              options={onFilterOptions.importer}
              label="Importador"
              placeholder="Importador"
            />
          </div>
          <div>
            <InputSelectList
              handleOnChange={onFilter.exporter}
              handleOnInputChange={onFilterQuery.exporter}
              options={onFilterOptions.exporter}
              label="Exportador"
              placeholder="Exportador"
            />
          </div>
          <div>
            <InputCheckbox
              label="Entregados"
              customID="mics-list-checkbox-filter"
              onChange={handleOnFilterDelivered}
            />
          </div>
        </StyledMultipleFiltersContainer>

        <StyledReportButtonsContainer>
          <CustomButton
            $theme="icon"
            title="Generar Reporte en PDF"
            onPress={onGenerateReportPDF}
          >
            <FaRegFilePdf size="4rem" />
          </CustomButton>
          <CustomButton
            $theme="icon"
            title="Generar Reporte en Hojas de Cálculo"
            onPress={onGenerateReportXLXS}
          >
            <FaRegFileExcel size="4rem" />
          </CustomButton>
        </StyledReportButtonsContainer>

        <StyledPaginationContainer>
          <Pagination
            defaultCurrent={1}
            total={paginationData?.total || 0}
            pageSize={paginationData?.pageSize || 0}
            current={currentPage}
            showSizeChanger={false}
            onChange={onPaginationChange}
          />
        </StyledPaginationContainer>
        <StyledCardsContainer>
          {data?.map((item) => {
            return <CardData key={item.id} data={item} />;
          })}
        </StyledCardsContainer>
      </StyledTableContainer>

      <EmptyMessage canShow={data?.length === undefined || data.length <= 0} />
    </StyledCardsGroupMainContainer>
  );
};

export default CardsGroup;
