import { UploadProps } from 'antd';
import React, { useState } from 'react';
import { FaFileArchive } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  FileUploader,
  Loader,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, isTransporter, Role } from '../../utils';
import AvailableComponent from '../../utils/AvailableComponent';
import { formatDate } from '../../utils/dates';
import { StyledAttachmentContainer } from '../../utils/styles';
import useStore from '../../zustand';
import HeaderActions from './components/HeaderActions';
import MerchandisesTable from './components/MerchandisesTable';
import useMicDetail from './hook/useMicDetail';
import { StyledMainListContainer } from './MicDetail.styled';
import { breadcrumbs, handleGoBack } from './utils';

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const crtID = params.crt_id,
    micID = params.mic_id,
    invoiceID = params.invoice_id,
    simID = params.sim_id;

  const breadcrumbItems = breadcrumbs({
    userRole,
    params,
    invoiceID,
    simID,
    crtID,
    micID,
  });

  const { data, isLoading, handleDeleteMic, handleUploadFile } = useMicDetail({
    micID,
    invoiceID,
    simID,
    crtID,
  });

  const issueDate = data?.date_of_issue
      ? formatDate(new Date(data?.date_of_issue?.toString()), 'withTime')
      : '',
    receptionDate = data?.reception_date
      ? formatDate(new Date(data?.reception_date?.toString()), 'withTime')
      : '',
    truckInsuranceExpirationDate = data?.truck?.insurance_policy
      ?.expiration_date
      ? formatDate(
          new Date(
            data?.truck?.insurance_policy?.expiration_date?.toString() ||
              String(),
          ),
          'simple',
        )
      : '',
    semitrailerInsuranceExpirationDate = data?.semitrailer?.insurance_policy
      ?.expiration_date
      ? formatDate(
          new Date(
            data?.semitrailer?.insurance_policy?.expiration_date?.toString() ||
              '',
          ),
          'simple',
        )
      : '';

  const renderedData = [
    {
      id: 1,
      data: [
        {
          title: 'SIM',
          content: (
            <Link
              to={`/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}`}
            >
              <Typography theme="p">{data?.sim?.number}</Typography>
            </Link>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin],
        },
        {
          title: 'CRT',
          content: isTransporter(userRole) ? (
            <Typography theme="p">{data?.crt_id.number}</Typography>
          ) : (
            <Link
              to={`/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}`}
            >
              <Typography theme="p">{data?.crt_id.number}</Typography>
            </Link>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Nro. de Remito',
          content: <Typography theme="p">{data?.shipment_number}</Typography>,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Fecha de Emisión',
          content: <Typography theme="p">{issueDate}</Typography>,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Fecha de Recepción',
          content: data?.reception_date ? (
            <Typography theme="p">{receptionDate}</Typography>
          ) : null,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Número de los Precintos',
          content: <Typography theme="p">{data?.seal_number}</Typography>,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 2,
      title: 'Empresa Transportista',
      data: [
        {
          title: 'Razón Social',
          content: (
            <Typography theme="p">
              {data?.carrier_company?.business_name}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'ID Tributario',
          content: (
            <Typography theme="p">{data?.carrier_company?.tax_id}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Dirección',
          content: (
            <Typography theme="p">
              {data?.carrier_company?.address} - {data?.carrier_company?.city},{' '}
              {data?.carrier_company?.country}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'CNRT',
          content: (
            <Typography theme="p">{data?.carrier_company?.cnrt}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Nro. de Permiso Internacional',
          content: (
            <Typography theme="p">
              {data?.carrier_company?.international_dr}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 3,
      title: 'Datos del camión',
      data: [
        {
          title: 'Patente',
          content: (
            <Typography theme="p">{data?.truck?.license_plate}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Marca',
          content: <Typography theme="p">{data?.truck?.brand}</Typography>,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Nro. de Chasis',
          content: (
            <Typography theme="p">{data?.truck?.chassis_number}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Año',
          content: <Typography theme="p">{data?.truck?.year}</Typography>,
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 4,
      title: 'Datos del semiremolque',
      data: [
        {
          title: 'Patente',
          content: (
            <Typography theme="p">
              {data?.semitrailer?.license_plate}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 5,
      title: 'Pólizas de seguro',
      data: [
        {
          title: 'Número',
          content: (
            <Typography theme="p">
              {data?.truck?.insurance_policy?.number}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Vencimiento',
          content: (
            <Typography theme="p">{truckInsuranceExpirationDate}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 6,
      title: '',
      data: [
        {
          title: 'Número',
          content: (
            <Typography theme="p">
              {data?.semitrailer?.insurance_policy?.number}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Vencimiento',
          content: (
            <Typography theme="p">
              {semitrailerInsuranceExpirationDate}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
    {
      id: 7,
      title: 'Datos de los conductores',
      data: [
        {
          title: 'Nombre',
          content: (
            <Typography theme="p">{data?.truck_driver?.first_name}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Apellido',
          content: (
            <Typography theme="p">{data?.truck_driver?.last_name}</Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
        {
          title: 'Tipo y Nro. de Documento',
          content: (
            <Typography theme="p">
              {data?.truck_driver?.ic_type} {data?.truck_driver?.ic}
            </Typography>
          ),
          availableFor: [Role.Admin, Role.ImpExpAdmin, Role.Transporter],
        },
      ],
    },
  ];

  const handleOnEdit = React.useCallback(() => {
    navigate(`editar`);
  }, []);

  const handleOnDelete = React.useCallback(() => {
    handleDeleteMic(params.mic_id);
  }, []);

  const [showFileUploadComponent, setShowFileUploadComponent] = useState(false);
  const [hasElementDropped, setHasElementDropped] = useState(false);

  const handleOnFileUpload = React.useCallback(() => {
    setShowFileUploadComponent(true);
  }, []);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    beforeUpload: () => false,
    onChange: (info) => setHasElementDropped(info.fileList.length > 0),
  };

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader isFullOpacity />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        MIC {data?.number}
      </Typography>

      <HeaderActions
        data={data}
        onDelete={handleOnDelete}
        onEdit={handleOnEdit}
        onFileUpload={handleOnFileUpload}
      />

      {showFileUploadComponent && !data?.attachment && (
        <FileUploader
          props={uploadProps}
          hasFile={hasElementDropped}
          onUploadFile={handleUploadFile}
        />
      )}

      {data?.attachment && (
        <Link to={data?.attachment.url}>
          <StyledAttachmentContainer>
            <FaFileArchive size="3rem" />
            <Typography theme="p">{data?.attachment.name}</Typography>
          </StyledAttachmentContainer>
        </Link>
      )}

      <StyledMainListContainer>
        {renderedData.map((item) => (
          <li key={item.id}>
            {item.title && (
              <Typography theme="h5" as="h2" $marginBottom="2rem">
                {item.title}
              </Typography>
            )}
            <ul>
              {item.data.map((dataItem) => {
                if (!dataItem.content) return null;

                return (
                  <AvailableComponent
                    key={dataItem.title}
                    availableFor={dataItem.availableFor}
                  >
                    <li>
                      <Typography theme="p" as="h3" $marginBottom="1rem">
                        {dataItem.title}:&nbsp;
                      </Typography>
                      {dataItem.content}
                    </li>
                  </AvailableComponent>
                );
              })}
            </ul>
          </li>
        ))}
        <li>
          <Typography theme="h5" as="h2" $marginBottom="2rem">
            Descripción de las mercancías:&nbsp;
          </Typography>
          <MerchandisesTable data={data?.mic_items} />
        </li>
      </StyledMainListContainer>

      <Link
        to={handleGoBack({
          userRole,
          invoiceID,
          simID,
          crtID,
        })}
      >
        <CustomButton text="Volver" $isMini $marginTop="4rem" />
      </Link>
    </BoxContainer>
  );
};

export default PageContent;
