import { SimItem } from '../../../../../utils/types/sims';
import { TableData } from '../../../types';

export const transformData = (data?: SimItem[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item?.quantity,
      merchandise: item?.merchandise_id?.description,
      quantity: item?.quantity,
      balance: item?.balance,
      exportCertificate: item?.export_record,
    };
  });

  return transformedData;
};
