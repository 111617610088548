import React from 'react';

import {
  BoxContainer,
  CustomButton,
  InputSelectList,
  Typography,
} from '../../components';
import { StyledFormActions } from '../../utils/styles';
import useDispatcherLanding from './hooks/useReceiverLanding';
import { StyledCrtSearcher } from './ReceiverLanding.styled';

const PageContent = () => {
  const {
    setSelectedMicId,
    setMicSelectOption,
    micSelectOptions,
    micSelectedOption,
    registerMicReception,
  } = useDispatcherLanding();

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        Registrar recepción
      </Typography>
      <StyledCrtSearcher>
        <Typography theme="h5" as="h2" $marginBottom="3rem">
          Número de MIC
        </Typography>
        <InputSelectList
          placeholder="Número de MIC"
          options={micSelectOptions}
          handleOnChange={setMicSelectOption}
          handleOnInputChange={setSelectedMicId}
        />
      </StyledCrtSearcher>

      <StyledFormActions>
        <CustomButton
          $theme="success"
          text="Registrar"
          type="button"
          onPress={registerMicReception}
          disabled={micSelectedOption == null}
        />
      </StyledFormActions>
    </BoxContainer>
  );
};

export default PageContent;
