import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledSelectContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.2rem solid ${theme.colors.defaultGray};
  color: ${theme.colors.darkGray};
  max-width: 27.5rem;
  position: relative;
  width: 100%;
  input {
    padding: 1rem;
    width: 100%;
  }
`;

export const StyledSelectInput = styled.select`
  padding: 1.3rem;
  width: 100%;
`;
export const StyledDropdownIcon = styled.div`
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledInputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

export const SubTextContainer = styled.div`
  color: ${theme.colors.error};
  margin-top: 0.5rem;
  text-align: left;
`;
