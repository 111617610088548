import styled, { css } from 'styled-components';

import { theme } from '../styles';
import { CSSProps } from './types';

export const baseStylesCSS = css<CSSProps>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  margin-right: ${({ $marginRight }) => $marginRight};
  margin-top: ${({ $marginTop }) => $marginTop};
  text-align: ${({ $textAlign }) => $textAlign};
`;

export const transitionAnimation = css`
  transition: all ease 300ms;
`;

export const limitWidth = css`
  @media only screen and (min-width: ${theme.breakpoints.xlg}) {
    max-width: 140rem;
  }
`;

// Form Styles
export const StyledSubForm = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin: 3rem auto 5rem;

  > div > div {
    max-width: unset;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 4rem;
`;

export const StyledFormActions = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
`;

export const StyledContainedTableContainer = styled.div`
  margin: 0 auto 4rem;
  overflow-x: scroll;
  width: 100%;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    margin: 0 auto;
    overflow-x: visible;
  }
`;

export const StyledTableContainer = styled.div`
  ${limitWidth}
  margin: 0 auto 4rem;
  overflow-x: scroll;
  width: 100%;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    margin: 0 auto;
    overflow-x: visible;
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: ${theme.zIndex.tableFilters};

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    > div {
      max-width: 21rem;
      width: 100%;
      input,
      select {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem auto;
  width: 100%;
`;

// Attatchment file styles
export const StyledAttachmentContainer = styled.div`
  align-items: center;
  color: ${theme.colors.primary};
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;
`;
