import styled, { css } from 'styled-components';

import { theme } from '../../styles';

type StyledProps = {
  $hasValue?: boolean;
  $invalid?: boolean;
};

export const StyledInputDatePickerContainer = styled.div<StyledProps>`
  > div {
    border-radius: 0.5rem;
    border: 2px solid ${theme.colors.defaultGray};
    color: ${theme.colors.darkGray};
    padding: 0;
    width: 100%;
    > div {
      color: ${theme.colors.defaultBlack};
      height: 4.15rem;
      padding: 0;
    }
    .ant-picker-input {
      padding: 1.3rem;
    }
  }

  ${({ $hasValue }) => {
    return (
      $hasValue &&
      css`
        > div {
          color: ${theme.colors.defaultBlack};
        }
      `
    );
  }}

  ${({ $invalid }) => {
    return (
      $invalid &&
      css`
        > div {
          color: ${theme.colors.error};
          border-color: ${theme.colors.error};
        }
        label {
          color: ${theme.colors.error};
        }
      `
    );
  }}
`;

export const SubTextContainer = styled.div`
  color: ${theme.colors.error};
  margin-top: 0.5rem;
  text-align: left;
`;

export const StyledInputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;
