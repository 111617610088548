import { css, styled } from 'styled-components';

import { theme } from '../../styles';
import { baseStylesCSS } from '../../utils/styles';
import { CSSProps } from '../../utils/types';

type ButtonProps = CSSProps & {
  $theme?: 'primary' | 'secondary' | 'link' | 'danger' | 'success' | 'icon';
  $isMini?: boolean;
};

export const StyledButton = styled.button<ButtonProps>`
  align-items: center;
  background-color: ${theme.colors.primary};
  border-radius: 0.5rem;
  color: ${theme.colors.defaultWhite};
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  max-width: 27.5rem;
  padding: 1.3rem;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  ${baseStylesCSS}
  ${({ $isMini }) => {
    return (
      $isMini &&
      css`
        max-width: 17rem;
        padding: 0.8rem;
      `
    );
  }}
  ${({ $theme }) => {
    switch ($theme) {
      case 'secondary':
        return css`
          background-color: ${theme.colors.defaultWhite};
          border: 0.2rem solid ${theme.colors.primary};
          color: ${theme.colors.primary};
        `;

      case 'success':
        return css`
          background-color: ${theme.colors.successCta};
        `;

      case 'danger':
        return css`
          background-color: ${theme.colors.error};
        `;

      case 'icon':
        return css`
          background-color: unset;
          color: ${theme.colors.primary};
          width: auto;
          padding: 0;
        `;
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${theme.colors.darkGray};
      color: ${theme.colors.defaultBlack};
      &:hover {
        cursor: default;
      }
    `}
`;

export const StyledButtonLink = styled.button<CSSProps>`
  ${baseStylesCSS}
  &:hover {
    cursor: pointer;
  }
`;
