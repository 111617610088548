export type TruckDriverData = {
  id: number;
  attributes: {
    first_name: string;
    last_name: string;
    ic_type: string;
    ic: string;
    comment: string;
    company_id: {
      data: CompanyResponse;
    };
  };
};

export type TruckDriverResponseData = {
  data: TruckDriverData;
};

export class TruckDriverInputs {
  constructor(response: TruckDriverResponseData) {
    this.id = response?.data.id;
    this.company_id = {
      value: response?.data.attributes.company_id?.data.id,
      label:
        response?.data.attributes.company_id?.data.attributes.business_name,
    };
    this.ic_type = {
      value: response?.data.attributes.ic_type,
      label: response?.data.attributes.ic_type,
    };
    this.ic = response?.data.attributes.ic;
    this.last_name = response?.data.attributes.last_name;
    this.first_name = response?.data.attributes.first_name;
    this.comment = response?.data.attributes.comment;
  }
  id?: number | string;
  ic?: string;
  ic_type: { label: string; value: string };
  first_name?: string;
  last_name?: string;
  company_id?: { label: string; value: string | number };
  comment?: string;
}

export type TruckDriverRequestData = {
  data: {
    id?: number | string;
    ic?: string;
    ic_type?: string;
    first_name?: string;
    last_name?: string;
    company_id?: string | number;
    comment?: string;
  };
};

export type CompanyResponse = {
  id: number;
  attributes: {
    business_name: string;
    tax_id: string;
    address: string;
    city: string;
    country: string;
    cnrt: string;
    international_dr: string;
    type: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
  };
};

export type CompanyData = {
  data: CompanyResponse[];
};
