import log from 'loglevel';
import { useEffect, useState } from 'react';

import { InputSelectListOption } from '../types';
import { Merchandise, MerchandiseResponse } from '../types/merchandise';
import useAxios from './useAxios';

type Props = {
  query: string;
};

const API_SEARCH_MERCHANDISES =
  'merchandises?filters[$or][0][code][$containsi]={value}&filters[$or][1][description][$containsi]={value}';

const useGetMerchandise = ({ query }: Props) => {
  const axios = useAxios();

  const [merchandisesInputOptions, setMerchandisesInputOptions] = useState<
    InputSelectListOption[]
  >([]);

  const [merchandisesArray, setMerchandisesArray] = useState<Merchandise[]>([]);

  const getMerchandisesOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<MerchandiseResponse>(
        API_SEARCH_MERCHANDISES.replaceAll('{value}', query),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const rawResponse = response.data.data;
        setMerchandisesArray(rawResponse);

        const results =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return {
                  label: `${item.attributes.code} - ${item.attributes.description}`,
                  value: item.id,
                };
              })
            : [];

        setMerchandisesInputOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  useEffect(() => {
    getMerchandisesOption();
  }, [query]);

  return {
    merchandisesInputOptions,
    merchandisesArray,
  };
};

export default useGetMerchandise;
