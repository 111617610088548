import React from 'react';

import { AddCompanyPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isReceptionist,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const companyRoutes = (useRole: string): Routes[] => {
  return [
    {
      path: '/super-admin/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/admin/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/cliente/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/transportista/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/recepcionista/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isReceptionist(useRole),
    },
    {
      path: '/despachante/empresa/nueva',
      component: <AddCompanyPage />,
      isAvailable: isDispatcher(useRole),
    },
  ];
};
