import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder } from '../../../utils';
import { useAxios } from '../../../utils/hooks';
import { PaginationData } from '../../../utils/types';
import { TableData, UsersData } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useUsersTable = ({ paginationData }: Props) => {
  const API_URL_USERS = '/custom-user/find',
    API_URL_USERS_BLOCK = '/custom-user/{userId}/block',
    API_URL_USERS_UNBLOCK = '/custom-user/{userId}/unblock';

  const axios = useAxios();

  const paginationParams = `?populate=*&page=${paginationData.current}&pageSize=${paginationData.pageSize}`;

  const filterSelectText = `&role=`;
  const filterQueryText = `&nameEmail=`;

  const [data, setData] = useState<UsersData | null>(null);
  const [totalResults, setTotalResults] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');
  // Filter controls
  const [selectFilterValue, setSelectFilterValue] = useState('');
  const [inputQueryFilterValue, setInputQueryFilterValue] = useState('');

  const onInputFilterChange = (value: string) => {
    setInputQueryFilterValue(value);
  };

  const onSelectFilterChange = (value: string) => {
    setSelectFilterValue(value);
  };

  // Handle retrieve users
  const handleGetUsers = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    axios
      .get<UsersData>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
        setTotalResults(response.data.meta.pagination.total);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle switch Block/Unblock user
  const handleSwitchUserActive = async (
    isBlockingUser: boolean,
    userID: string,
  ) => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    const URL_TO_USE = isBlockingUser
      ? API_URL_USERS_UNBLOCK
      : API_URL_USERS_BLOCK;

    try {
      setIsLoading(true);
      await axios.patch(URL_TO_USE.replace('{userId}', userID), null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      setHasError(true);
    }
    handleGetUsers();
  };

  const finalURL = () => {
    return `${API_URL_USERS}${paginationParams}${
      selectFilterValue && filterSelectText + selectFilterValue
    }${
      inputQueryFilterValue && filterQueryText + inputQueryFilterValue
    }${sortQuery}`;
  };

  useEffect(() => {
    handleGetUsers();
  }, [
    paginationData.current,
    selectFilterValue,
    inputQueryFilterValue,
    sortQuery,
  ]);

  // Reset to first page when filtering
  useEffect(() => {
    if (paginationData.onChange) paginationData.onChange(1);
  }, [selectFilterValue, inputQueryFilterValue]);

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    data: data?.data,
    handleSwitchUserActive,
    hasError,
    isLoading,
    onInputFilterChange,
    onSelectFilterChange,
    totalResults,
    handleTableChange,
  };
};

export default useUsersTable;
