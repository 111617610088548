import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { CustomButton, InputText } from '../../../../components';
import { StyledTableContainer } from '../../../../utils/styles';
import { PaginationData } from '../../../../utils/types';
import { TableData, TruckDriver } from '../../types';
import { StyledFiltersContainer } from './TrucksDriverTable.styled';
import { transformData } from './utils';

type Props = {
  data?: TruckDriver[] | null;
  onInputFilterChange: (value: string) => void;
  onDelete: (truckDriverID: string) => void;
  paginationData: PaginationData;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const TrucksDriverTable: FC<Props> = ({
  data,
  onInputFilterChange,
  onDelete,
  paginationData,
  onTableChange,
}) => {
  const newData = transformData(data);

  const handleSearchByName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputFilterChange(event.target.value);
    },
    [],
  );

  const columns: ColumnsType<TableData> = [
    {
      title: 'Nombre y Apellido',
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true,
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      // sorter: true, // TODO: Fix nested sorter
    },
    {
      title: 'Tipo de Documento',
      dataIndex: 'ic_type',
      key: 'ic_type',
      sorter: true,
    },
    {
      title: 'Nro. de Documento',
      dataIndex: 'ic',
      key: 'ic',
      sorter: true,
    },
    {
      title: 'Editar',
      key: 'operation_edit',
      fixed: 'right',
      width: 100,
      render: (props: TableData) => {
        return (
          <Link to={`${props.key}/editar`}>
            <CustomButton $theme="secondary" $isMini>
              <FaPen />
            </CustomButton>
          </Link>
        );
      },
    },
    {
      title: 'Borrar',
      key: 'operation_delete',
      fixed: 'right',
      width: 100,
      render: (record: TableData) => (
        <Popconfirm
          title="Precaución"
          description="¿Está seguro que desea borrar este chofer? Este cambio es irreversible."
          onConfirm={() => onDelete(record.key.toString())}
          okText="Eliminar"
          cancelText="Cancelar"
          okButtonProps={{ danger: true }}
        >
          <div>
            <CustomButton $theme="secondary" $isMini>
              <FaTrash />
            </CustomButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <StyledFiltersContainer>
        <InputText placeholder="Nombre" onChange={handleSearchByName} />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default TrucksDriverTable;
