import { Popconfirm } from 'antd';
import React, { FC, useCallback } from 'react';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';

// import { Link } from 'react-router-dom';
import { CustomButton, Typography } from '../../../../components';
import { theme } from '../../../../styles';
import { Archive } from '../../../../utils/types/archive';
import {
  StyledActionsContainer,
  StyledMainContainer,
  StyledTitleContainer,
  StyledTitleText,
} from './FileItem.styled';
import useFileDownload from './hooks/useFileDownload';
import { getExtensionIcon } from './utils';

type Props = {
  data: Archive;
  onDeleteFile: (id: number) => void;
};

const FileItem: FC<Props> = ({ data, onDeleteFile }) => {
  const actionIconSize = '2.5rem';
  const ExtIcon = getExtensionIcon(data.ext);

  const { downloadAction } = useFileDownload();

  const handleOnFileDownload = useCallback(() => {
    downloadAction(data.url, data.name);
  }, []);

  const handleOnDelete = useCallback(() => {
    onDeleteFile(data.id);
  }, []);

  return (
    <StyledMainContainer>
      <StyledTitleContainer>
        <ExtIcon size="3rem" color={theme.colors.primary} />
        <StyledTitleText>
          <Typography theme="p">{data.name}</Typography>
          <Typography theme="subtext">({data.size}kb)</Typography>
        </StyledTitleText>
      </StyledTitleContainer>
      <StyledActionsContainer>
        <CustomButton $isMini $theme="icon" onPress={handleOnFileDownload}>
          <FaDownload size={actionIconSize} />
        </CustomButton>
        <Popconfirm
          title="Precaución"
          description="¿Está seguro que quiere borrar el Archivo? Esta acción es irreversible."
          onConfirm={handleOnDelete}
          okText="Eliminar"
          cancelText="Cancelar"
          okButtonProps={{ danger: true }}
        >
          <div>
            <CustomButton $isMini $theme="icon">
              <FaTrashAlt size={actionIconSize} />
            </CustomButton>
          </div>
        </Popconfirm>
      </StyledActionsContainer>
    </StyledMainContainer>
  );
};

export default FileItem;
