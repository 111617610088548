import React, { useState } from 'react';

import { PaginationData } from '../../../../../utils/types';
import useDashboard from '../../../hooks/useDashboard';
import { ActionCardData } from '../../../types';
import DashboardCards from '../../DashboardCards';
import DashboardHeader from '../../DashboardHeader';
import DashboardTable from '../../DashboardTable';

const SuperAdminDashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 20,
    current: currentPage,
  };

  const {
    data,
    stats,
    isLoading,
    totalResults,
    onSelectFilterChange,
    onInputFilterChange,
    handleTableChange,
  } = useDashboard({
    paginationData,
  });

  const cards: ActionCardData[] = [
    {
      icon: 'industry',
      number: stats?.import_export_count,
      title: 'Empresas Imp/Exp',
      url: '/super-admin/empresa/nueva?tipo=Importadora-Exportadora',
      color: 'blue',
    },
    {
      icon: 'truck',
      number: stats?.carrier_count,
      title: 'Empresas Transportistas',
      url: '/super-admin/empresa/nueva?tipo=Transportista',
      color: 'yellow',
    },
    {
      icon: 'bag',
      number: stats?.custom_agent_count,
      title: 'Empresas Despachantes',
      url: '/super-admin/empresa/nueva?tipo=Despachante',
      color: 'green',
    },
    {
      icon: 'users',
      number: stats?.users_count,
      title: 'Usuarios',
      url: '/super-admin/usuarios/',
      ctaText: 'Ver más',
      color: 'red',
    },
  ];

  return (
    <div>
      <DashboardHeader />
      <DashboardCards cards={cards} />
      <DashboardTable
        data={data}
        isLoading={isLoading}
        onSelectFilterChange={onSelectFilterChange}
        onInputFilterChange={onInputFilterChange}
        paginationData={{
          ...paginationData,
          onChange: setCurrentPage,
          total: totalResults,
        }}
        onTableChange={handleTableChange}
      />
    </div>
  );
};

export default SuperAdminDashboard;
