import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { LANDING_URL_MAP, URLS, USER_ROLES_LIST } from '../../utils';
import useStore from '../../zustand';
import Logo from '../Logo';
import Typography from '../Typography';
import Menu from './components/Menu';
import {
  StyledHeaderContainer,
  StyledMenuLogo,
  StyledMenuLogoContainer,
  StyledSideMenuContainer,
  StyledUserNameContainer,
} from './Header.styled';
import {
  adminOptions,
  impExpAdminOptions,
  superAdminOptions,
  transporterOptions,
} from './utils';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const [userName, userRole, userCompanyID, userCompanyName] = useStore(
    (state) => [
      state.userName,
      state.userRole,
      state.userCompanyID,
      state.userCompanyName,
    ],
  );

  const companyToReplace = userCompanyID ? userCompanyID.toString() : '';
  const companyUserHeaderTitle = userCompanyName
    ? `${userName} - ${userCompanyName}`
    : userName;

  const menuOptions = React.useCallback((value: string) => {
    switch (value) {
      case USER_ROLES_LIST.superAdmin:
        return superAdminOptions;
      case USER_ROLES_LIST.admin:
        return adminOptions(companyToReplace);
      case USER_ROLES_LIST.transporter:
        return transporterOptions(companyToReplace);
      case USER_ROLES_LIST.impExpAdmin:
        return impExpAdminOptions(companyToReplace);
      default:
        return [];
    }
  }, []);

  const landingURL = (userRole: string) => {
    const result = LANDING_URL_MAP[userRole] ?? URLS.dashboard;
    return result;
  };

  return (
    <StyledHeaderContainer>
      <div>
        <Link to={landingURL(userRole)}>
          <Logo asTag="h1" layout="mobile" />
        </Link>
      </div>
      <StyledUserNameContainer>
        <Typography theme="p">{companyUserHeaderTitle} </Typography>
        <StyledMenuLogoContainer onClick={handleClick}>
          <StyledMenuLogo className={`${isOpen ? 'menu-open' : ''}`}>
            <span />
            <span />
            <span />
          </StyledMenuLogo>
        </StyledMenuLogoContainer>
      </StyledUserNameContainer>
      <StyledSideMenuContainer className={`${isOpen ? 'menu-open' : ''}`}>
        <Menu options={menuOptions(userRole)} />
      </StyledSideMenuContainer>
    </StyledHeaderContainer>
  );
};

export default Header;
