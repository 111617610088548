import { Semitrailer } from '../../../../../utils/types/semitrailer';
import { TableData } from '../../../types';

export const transformData = (data?: Semitrailer[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      id: item.id,
      license_plate: item.attributes.license_plate,
      owner: item?.attributes?.company_id?.data?.attributes?.business_name,
      insurancePolicyNumber:
        item?.attributes?.insurance_policy?.data?.attributes?.number,
      insuranceExpirationDate:
        item?.attributes?.insurance_policy?.data?.attributes?.expiration_date,
    };
  });

  return transformedData;
};
