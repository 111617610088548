import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Loader,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, URLS } from '../../utils';
import { PaginationData } from '../../utils/types';
import useStore from '../../zustand';
import TrucksDriverTable from './components/TrucksDriverTable';
import useTrucksDriverTable from './hooks/useTrucksDriverTable';

const PageContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 20,
    current: currentPage,
    onChange: setCurrentPage,
  };

  const {
    data,
    isLoading,
    onInputFilterChange,
    handleDeleteTruckDriver,
    totalResults,
    handleTableChange,
  } = useTrucksDriverTable({
    paginationData,
  });

  const navigate = useNavigate();
  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.truckDriver,
      name: 'Choferes',
      withUserRole: true,
    },
  ];

  const handleOnClickNewTruckDriver = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.addTruckDriver}`);
  }, []);

  return (
    <BoxContainer $useMarginTop $useMarginBottom>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Choferes
      </Typography>
      <CustomButton
        text="Nuevo Chofer"
        $marginBottom="6rem"
        $isMini
        onPress={handleOnClickNewTruckDriver}
      />
      <TrucksDriverTable
        data={data}
        onInputFilterChange={onInputFilterChange}
        onDelete={handleDeleteTruckDriver}
        paginationData={{
          ...paginationData,
          total: totalResults,
        }}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
