import log from 'loglevel';
import { useEffect, useState } from 'react';

import { InputSelectListOption } from '../types';
import { Company, CompanyResponse } from '../types/company';
import useAxios from './useAxios';

type Props = {
  query: string;
  filter?: string;
};

const useGetCompanies = ({ query, filter }: Props) => {
  const API_SEARCH_COMPANIES = `companies?${
    filter ? filter : ''
  }filters[business_name][$containsi]=`;

  const axios = useAxios();

  const [companiesInputOptions, setCompaniesInputOptions] = useState<
    InputSelectListOption[]
  >([]);

  const [companiesArray, setCompaniesArray] = useState<Company[]>([]);

  const getCompaniesOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponse>(`${API_SEARCH_COMPANIES}${query}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;
        setCompaniesArray(rawResponse);

        const results =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setCompaniesInputOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  useEffect(() => {
    getCompaniesOption();
  }, [query]);

  return {
    companiesInputOptions,
    companiesArray,
  };
};

export default useGetCompanies;
