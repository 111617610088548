import React from 'react';

import Typography from '../Typography';
import { StyledFooterContainer } from './Footer.styled';

const Footer = () => {
  return (
    <StyledFooterContainer>
      <Typography theme="p" $textAlign="center">
        Mis Despachos 2016 &copy;
      </Typography>
      <Typography theme="subtext" $textAlign="center">
        v2.0
      </Typography>
    </StyledFooterContainer>
  );
};

export default Footer;
