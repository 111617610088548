import React, { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Multiselect from 'react-widgets/Multiselect';

import {
  BoxContainer,
  CustomButton,
  InputCheckbox,
  InputDatePicker,
  InputText,
  Typography,
} from '../../components';
import { StyledFormActions, StyledSubForm } from '../../utils/styles';
import MultiselectOptionItem from './components/MultiselectOptionItem';
import useUpdatePolicies from './hook/useUpdatePolicies';
import { PoliciesType, UpdatePoliciesInputs } from './types';
import { StyledMultiselect } from './UpdatePolicies.styled';
import { filterResults } from './utils';

const PageContent = () => {
  const { setInputLicensesQuery, licensesOptions, handleUpdatePolicies } =
    useUpdatePolicies();

  const { handleSubmit, control, formState, register } =
    useForm<UpdatePoliciesInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: { licenses: [] },
    });

  const onSubmit: SubmitHandler<UpdatePoliciesInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleUpdatePolicies(data);
  };

  const handleGoBack = React.useCallback(() => {
    history.back();
  }, []);

  const [isOpen, setIsOpen] = useState(true);
  const handleOpenOptions = useCallback(() => setIsOpen(true), [open]);
  const handleCloseOptions = useCallback(() => setIsOpen(false), [open]);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        Actualizar póliza de la flota
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography theme="h4" as="h2">
          Vehículos a Actualizar
        </Typography>
        <StyledSubForm>
          <Controller
            name="licenses"
            control={control}
            render={({ field }) => {
              return (
                <StyledMultiselect className={isOpen ? 'is-open' : ''}>
                  <Multiselect
                    data={filterResults(licensesOptions, field.value)}
                    // This was intended because there is no way to update the types coming from the component itself
                    // @ts-ignore
                    textField={(item: PoliciesType) =>
                      item?.attributes?.license_plate
                    }
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={handleCloseOptions}
                    onClick={handleOpenOptions}
                    onSearch={setInputLicensesQuery}
                    messages={{
                      emptyList: 'No hay resultados.',
                    }}
                    renderListItem={({ item }) => (
                      <MultiselectOptionItem data={item} />
                    )}
                  />
                </StyledMultiselect>
              );
            }}
          />
          <InputCheckbox
            label="Toda la Flota"
            customID="all_licenses"
            {...register('selectAllLicenses')}
          />
        </StyledSubForm>
        <Typography theme="h4" as="h2">
          Datos de la Póliza
        </Typography>
        <StyledSubForm>
          <InputText
            placeholder="Número de la Póliza"
            label="Número de Póliza"
            $hasValue={formState.dirtyFields.number}
            $invalid={!!formState.errors.number}
            {...register('number', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.number?.message}
          />
          <Controller
            name="expirationDate"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
            }}
            render={({ field }) => (
              <InputDatePicker
                $hasValue={formState.dirtyFields.expirationDate}
                $invalid={!!formState.errors.expirationDate}
                handleOnChange={(date) => {
                  field.onChange(date);
                }}
                label="Vencimiento de Poliza"
                placeholder="Vencimiento de Poliza"
                subTextMessage={formState.errors.expirationDate?.message}
                value={field.value}
              />
            )}
          />
        </StyledSubForm>
        <StyledFormActions>
          <CustomButton $theme="success" text="Actualizar" type="submit" />
          <CustomButton
            $theme="secondary"
            text="Cancelar"
            type="button"
            onPress={handleGoBack}
          />
        </StyledFormActions>
      </form>
    </BoxContainer>
  );
};

export default PageContent;
