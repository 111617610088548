import React, { FC, ReactNode } from 'react';

import { CSSProps } from '../../utils/types';
import { StyledContainer } from './Typography.styled';

enum Tags {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'a',
}

enum TextThemes {
  'input',
  'button',
  'subtext',
}

type Props = CSSProps & {
  children: ReactNode;
  theme: keyof typeof Tags | keyof typeof TextThemes;
  as?: keyof typeof Tags;
  /** Only available for href typography */
  $linkUrl?: string;
};

const Typography: FC<Props> = ({
  children,
  theme,
  as = 'p',
  $linkUrl,
  ...rest
}) => {
  const TagName = as;

  if (TagName === 'a') {
    return (
      <StyledContainer {...rest}>
        <a className={theme} href={$linkUrl}>
          {children}
        </a>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer {...rest}>
      <TagName className={theme}>{children}</TagName>
    </StyledContainer>
  );
};

export default Typography;
