import React from 'react';

import { Footer, Header, PageLayout } from '../../components';
import PageContent from '../../features/ChangePassword';

const ChangePasswordPage = () => {
  return (
    <>
      <Header />
      <PageLayout>
        <PageContent />
      </PageLayout>
      <Footer />
    </>
  );
};

export default ChangePasswordPage;
