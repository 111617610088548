import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  checkForValue,
  fromUserRoleToUrl,
  NotificationMessages,
  ReportMessages,
  URLS,
} from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption, PaginationData } from '../../../utils/types';
import { CompanyResponse } from '../../../utils/types/company';
import { AltPlainDriverData } from '../../../utils/types/driver';
import useStore from '../../../zustand';
import { TruckDriversReportsResponse } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useTruckDriversReports = ({ paginationData }: Props) => {
  const API_URL_TRUCK_DRIVERS_REPORTS = '/reports/truck_driver';

  const TRANSPORT_URL =
    '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';

  const API_URL_TRUCK_DRIVER_REPORT_GENERATION_PDF =
      '/reports/format/pdf/truck_driver',
    API_URL_TRUCK_DRIVER_REPORT_GENERATION_XLSX =
      '/reports/format/xlsx/truck_driver';

  const paginationParams = `?page=${paginationData.current}&per_page=${paginationData.pageSize}`;

  const axios = useAxios();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<AltPlainDriverData[]>([]);
  const [totalResults, setTotalResults] = useState(0);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //----- Filters
  // APIs
  const API_URL_FILTERS = {
    firstName: '&name=',
    document: '&ic=',
    transport: '&carrier_company_id=',
  };
  const API_URL_SORTERS = {
    sortBy: '&sort=',
    orderBy: '&order=',
  };

  // Filter Queries
  const [transportQuery, setTransportQuery] = useState('');

  // Options States
  const [transportOptions, setTransportOptions] = useState<
    InputSelectListOption[]
  >([]);

  // Controls
  const [filterFirstName, setFilterFirstName] = useState('');
  const [filterDocument, setFilterDocument] = useState('');
  const [filterTransport, setFilterTransport] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);
  const [sorterSortBy, setSorterSortBy] = useState('');
  const [sorterOrderBy, setSorterOrderBy] = useState('');

  // Fetch Options
  // Transport
  const getTransportOptions = () => {
    const token = sessionStorage.getItem('token');
    if (!token) log.error('No Token provided');

    axios
      .get<CompanyResponse>(`${TRANSPORT_URL}${transportQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setTransportOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getTransportOptions();
  }, [transportQuery]);

  // Build final URL
  const finalURL = () => {
    const FIRST_NAME_URL =
      filterFirstName && API_URL_FILTERS.firstName + filterFirstName;
    const DOCUMENT_URL =
      filterDocument && API_URL_FILTERS.document + filterDocument;
    const TRANSPORT_URL =
      filterTransport?.value &&
      API_URL_FILTERS.transport + filterTransport?.value;
    const SORT_BY_URL = sorterSortBy && API_URL_SORTERS.sortBy + sorterSortBy;
    const ORDER_BY_URL =
      sorterOrderBy && API_URL_SORTERS.orderBy + sorterOrderBy;

    return (
      API_URL_TRUCK_DRIVERS_REPORTS +
      paginationParams +
      checkForValue(SORT_BY_URL) +
      checkForValue(ORDER_BY_URL) +
      checkForValue(FIRST_NAME_URL) +
      checkForValue(DOCUMENT_URL) +
      checkForValue(TRANSPORT_URL)
    );
  };

  const tableFilters = {
    firstName: setFilterFirstName,
    document: setFilterDocument,
    transport: setFilterTransport,
    sortBy: setSorterSortBy,
    orderBy: setSorterOrderBy,
  };

  // Fetch Truck Drivers Data
  const getTruckDriversReports = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const result = await axios.get<TruckDriversReportsResponse>(finalURL(), {
      headers: { Authorization: `Bearer ${token}` },
    });

    setData(result.data.drivers);
    setTotalResults(result.data.drivers_count);
    setIsLoading(false);
  };
  useEffect(() => {
    getTruckDriversReports().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [
    paginationData.current,
    filterFirstName,
    filterDocument,
    filterTransport,
    sorterSortBy,
    sorterOrderBy,
  ]);

  // Reports Generation
  const buildReportGenerationURL = (apiToUse: string) => {
    const FIRST_NAME_URL =
      filterFirstName && API_URL_FILTERS.firstName + filterFirstName;
    const DOCUMENT_URL =
      filterDocument && API_URL_FILTERS.document + filterDocument;
    const TRANSPORT_URL =
      filterTransport?.value &&
      API_URL_FILTERS.transport + filterTransport?.value;
    const SORT_BY_URL = sorterSortBy && API_URL_SORTERS.sortBy + sorterSortBy;
    const ORDER_BY_URL =
      sorterOrderBy && API_URL_SORTERS.orderBy + sorterOrderBy;

    const result =
      apiToUse +
      checkForValue(SORT_BY_URL) +
      checkForValue(ORDER_BY_URL) +
      checkForValue(FIRST_NAME_URL) +
      checkForValue(DOCUMENT_URL) +
      checkForValue(TRANSPORT_URL);
    return result.replace('&', '?');
  };

  const generateReport = async (type: 'pdf' | 'xlxs') => {
    const token = sessionStorage.getItem('token');

    const urlToUse =
      type === 'pdf'
        ? API_URL_TRUCK_DRIVER_REPORT_GENERATION_PDF
        : API_URL_TRUCK_DRIVER_REPORT_GENERATION_XLSX;

    setIsLoading(true);

    try {
      if (!token) {
        throw new Error('No Token provided');
      }

      await axios.get(buildReportGenerationURL(urlToUse), {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoading(false);
      showNotification({
        message: ReportMessages.SUCCESS,
      });
      navigate(`/${transformedUserRole}${URLS.filesTruckDriver}`);
    } catch (error) {
      log.error(error);
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      setHasError(true);
      setIsLoading(false);
    }
  };

  const generateReportPDF = () => {
    generateReport('pdf');
  };

  const generateReportXLXS = () => {
    generateReport('xlxs');
  };

  return {
    data,
    hasError,
    isLoading,
    setTransportQuery,
    tableFilters,
    totalResults,
    transportOptions,
    generateReportPDF,
    generateReportXLXS,
  };
};

export default useTruckDriversReports;
