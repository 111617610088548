import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  checkForValue,
  fromUserRoleToUrl,
  NotificationMessages,
  ReportMessages,
  URLS,
} from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption, PaginationData } from '../../../utils/types';
import { PO } from '../../../utils/types/po';
import useStore from '../../../zustand';
import { OpResponse } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useOpReports = ({ paginationData }: Props) => {
  const API_URL_REPORTS_PO = '/reports/po';

  // Report Generation
  const API_URL_PO_REPORT_GENERATION_PDF = '/reports/format/pdf/po';
  const API_URL_PO_REPORT_GENERATION_XLSX = '/reports/format/xlsx/po';

  const paginationParams = `?page=${paginationData.current}&per_page=${paginationData.pageSize}`;

  const axios = useAxios();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<PO[]>([]);
  const [totalResults, setTotalResults] = useState(0);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //----- Filters
  // APIs
  const API_URL_FILTERS = {
    dateFrom: '&date_from=',
    dateTo: '&date_to=',
    transport: '&carrier_company_id=',
    importer: '&importer_id=',
    exporter: '&exporter_id=',
    orderOptions: '&sort=',
    order: '&order=',
  };

  // Controls
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [filterTransport, setFilterTransport] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);
  const [filterImporter, setFilterImporter] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);
  const [filterExporter, setFilterExporter] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);
  const [sorterOrderOptions, setSorterOrderOptions] = useState('');
  const [sorterOrder, setSorterOrder] = useState('');

  // Build final URL
  const finalURL = () => {
    const DATE_FROM_URL =
      filterDateFrom && API_URL_FILTERS.dateFrom + filterDateFrom;
    const DATE_TO_URL = filterDateTo && API_URL_FILTERS.dateTo + filterDateTo;
    const TRANSPORT_URL =
      filterTransport?.value &&
      API_URL_FILTERS.transport + filterTransport?.value;
    const IMPORTER_URL =
      filterImporter?.value && API_URL_FILTERS.importer + filterImporter?.value;
    const EXPORTER_URL =
      filterExporter?.value && API_URL_FILTERS.exporter + filterExporter?.value;
    const ORDER_OPTIONS_URL =
      sorterOrderOptions && API_URL_FILTERS.orderOptions + sorterOrderOptions;
    const ORDER_URL = sorterOrder && API_URL_FILTERS.order + sorterOrder;

    return (
      API_URL_REPORTS_PO +
      paginationParams +
      checkForValue(ORDER_OPTIONS_URL) +
      checkForValue(ORDER_URL) +
      checkForValue(DATE_FROM_URL) +
      checkForValue(DATE_TO_URL) +
      checkForValue(TRANSPORT_URL) +
      checkForValue(IMPORTER_URL) +
      checkForValue(EXPORTER_URL)
    );
  };

  const tableFilters = {
    dateFrom: setFilterDateFrom,
    dateTo: setFilterDateTo,
    transport: setFilterTransport,
    importer: setFilterImporter,
    exporter: setFilterExporter,
    orderOptions: setSorterOrderOptions,
    order: setSorterOrder,
  };

  const tableFilterValues = [
    filterDateFrom,
    filterDateTo,
    filterTransport,
    filterImporter,
    filterExporter,
    sorterOrderOptions,
    sorterOrder,
  ];

  const getReportsPO = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const result = await axios.get<OpResponse>(finalURL(), {
      headers: { Authorization: `Bearer ${token}` },
    });

    setIsLoading(false);
    setData(result.data.operatives);
    setTotalResults(result.data.operatives_count);
  };

  useEffect(() => {
    getReportsPO().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [paginationData.current, ...tableFilterValues]);

  // Reset data table when filters are active
  useEffect(() => setData(Array<PO>()), [...tableFilterValues]);

  // Reports Generation
  const buildReportGenerationURL = (apiToUse: string) => {
    const DATE_FROM_URL =
      filterDateFrom && API_URL_FILTERS.dateFrom + filterDateFrom;
    const DATE_TO_URL = filterDateTo && API_URL_FILTERS.dateTo + filterDateTo;
    const TRANSPORT_URL =
      filterTransport?.value &&
      API_URL_FILTERS.transport + filterTransport?.value;
    const IMPORTER_URL =
      filterImporter?.value && API_URL_FILTERS.importer + filterImporter?.value;
    const EXPORTER_URL =
      filterExporter?.value && API_URL_FILTERS.exporter + filterExporter?.value;
    const ORDER_OPTIONS_URL =
      sorterOrderOptions && API_URL_FILTERS.orderOptions + sorterOrderOptions;
    const ORDER_URL = sorterOrder && API_URL_FILTERS.order + sorterOrder;

    const result =
      apiToUse +
      checkForValue(DATE_FROM_URL) +
      checkForValue(DATE_TO_URL) +
      checkForValue(TRANSPORT_URL) +
      checkForValue(IMPORTER_URL) +
      checkForValue(EXPORTER_URL) +
      checkForValue(ORDER_OPTIONS_URL) +
      checkForValue(ORDER_URL);
    return result.replace('&', '?');
  };

  const generateReport = async (type: 'pdf' | 'xlxs') => {
    const token = sessionStorage.getItem('token');

    const urlToUse =
      type === 'pdf'
        ? API_URL_PO_REPORT_GENERATION_PDF
        : API_URL_PO_REPORT_GENERATION_XLSX;

    setIsLoading(true);

    try {
      if (!token) {
        throw new Error('No Token provided');
      }

      await axios.get(buildReportGenerationURL(urlToUse), {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoading(false);
      showNotification({
        message: ReportMessages.SUCCESS,
      });
      navigate(`/${transformedUserRole}${URLS.filesOp}`);
    } catch (error) {
      log.error(error);
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      setHasError(true);
    }
  };

  const generateReportPDF = () => {
    generateReport('pdf');
  };

  const generateReportXLXS = () => {
    generateReport('xlxs');
  };

  return {
    hasError,
    isLoading,
    data,
    tableFilters,
    generateReportPDF,
    generateReportXLXS,
    totalResults,
  };
};

export default useOpReports;
