import React from 'react';

import { BoxContainer, Typography } from '../../components';
import ChangePasswordForm from './components/ChangePasswordForm';

const PageContent = () => {
  return (
    <BoxContainer $useMarginTop $useMarginBottom>
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        Cambio de contraseña
      </Typography>
      <ChangePasswordForm />
    </BoxContainer>
  );
};

export default PageContent;
