import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  CustomButton,
  InputArea,
  InputCheckbox,
  InputText,
} from '../../../../components';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import { AddMerchandiseInputs } from '../../types';
import { StyledSubSectionForm } from './AddMerchandiseForm.styled';

type Props = {
  ctaText: string;
  handleOnFormCancel: () => void;
  handleOnSubmit: (data: AddMerchandiseInputs) => void;
  singleMerchandiseData?: AddMerchandiseInputs;
};

const AddMerchandiseForm: FC<Props> = ({
  ctaText,
  singleMerchandiseData,
  handleOnSubmit,
  handleOnFormCancel,
}) => {
  const { register, handleSubmit, reset, formState } =
    useForm<AddMerchandiseInputs>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const onSubmit: SubmitHandler<AddMerchandiseInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleOnSubmit(data);
  };

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleMerchandiseData) {
      reset(singleMerchandiseData);
    }
  }, [singleMerchandiseData]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubForm>
          <StyledSubSectionForm>
            <InputText
              placeholder="Código"
              label="Código"
              $hasValue={formState.dirtyFields.code}
              $invalid={!!formState.errors.code}
              {...register('code', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.code?.message}
            />
            <InputText
              placeholder="Descripción"
              label="Descripción"
              $hasValue={formState.dirtyFields.description}
              $invalid={!!formState.errors.description}
              {...register('description', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.description?.message}
            />
            <InputCheckbox
              label="Requiere constancia de exportación"
              customID="add_merchandise"
              {...register('exportRecordRequired')}
            />
          </StyledSubSectionForm>
          <StyledSubSectionForm>
            <InputArea
              placeholder="Comentarios"
              label="Comentarios"
              maxLength={1000}
              $hasValue={formState.dirtyFields.comment}
              $invalid={!!formState.errors.comment}
              {...register('comment', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.comment?.message}
            />
          </StyledSubSectionForm>
        </StyledSubForm>

        <StyledFormActions>
          <CustomButton $theme="success" text={ctaText} type="submit" />
          <CustomButton
            $theme="secondary"
            onPress={handleOnFormCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </div>
  );
};

export default AddMerchandiseForm;
