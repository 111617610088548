import { UploadProps } from 'antd';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import React, { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaFileArchive } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  CustomButton,
  FileUploader,
  InputDatePicker,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import { fromUserRoleToUrl, URLS, USER_ROLES_LIST } from '../../../../utils';
import { ONLY_FLOAT_NUMBERS_VALIDATION } from '../../../../utils/regexp';
import {
  StyledAttachmentContainer,
  StyledFormActions,
  StyledSubForm,
} from '../../../../utils/styles';
import { Attachment } from '../../../../utils/types';
import { CrtBalanceDetails } from '../../../../utils/types/crt';
import useStore from '../../../../zustand';
import useGetSelectListOptions from '../../hooks/useGetSelectListOptions';
import { AddMicInputs, MerchandiseInputs } from '../../types';
import SelectedData from './components/SelectedData';
import { buildGoBackURL, validateMicBalance } from './utils';

type Props = {
  onFileUpload: (info: RcCustomRequestOptions) => Promise<void>;
  crtBalanceDetails: CrtBalanceDetails[];
  onSubmitForm: (data: AddMicInputs) => void;
  singleMicData: AddMicInputs | null;
  attachmentData?: Attachment;
  onCancel?: () => void;
  onChangeQuantity: (index: number, quantity: number) => void;
};

const AddMicForm: FC<Props> = ({
  onSubmitForm,
  onFileUpload,
  crtBalanceDetails,
  singleMicData,
  attachmentData,
  onCancel,
  onChangeQuantity
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const crtID = params.crt_id,
    invoiceID = params.invoice_id,
    simID = params.sim_id,
    micID = params.mic_id;

  const { register, handleSubmit, formState, control, watch, reset, setValue } =
    useForm<AddMicInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const { fields, append } = useFieldArray({
    name: 'merchandiseDeclaration',
    control,
    rules: {
      required: 'El MIC debe tener por lo menos un item asociado',
    },
  });

  const handleOnChangeQuantity: ChangeEventHandler<HTMLInputElement> =
    function (this: { index: number }, input) {
      const { index } = this;
      onChangeQuantity(index, Number(input.target.value));
    };

  const selectedVehicleOwner = watch('vehicleOwner');
  const selectedTruck = watch('truckLicensePlate');
  const selectedSemitrailer = watch('semitrailerLicensePlate');
  const selectedTruckDriver = watch('truckDriverID');

  const {
    setVehicleOwnerQuery,
    setTruckQuery,
    setSemitrailerQuery,
    setTruckDriverQuery,
    vehicleOwnerOptions,
    truckOptions,
    semitrailerOptions,
    truckDriverOptions,
    isLoading,
    filteredResults,
  } = useGetSelectListOptions({
    vehicleOwnerID: selectedVehicleOwner?.value,
    selectedSemitrailer: selectedSemitrailer?.value,
    selectedTruck: selectedTruck?.value,
    selectedTruckDriver: selectedTruckDriver?.value,
  });

  const setSemitrailerAndDriver = (item: any) => {
    if (item?.label) {
      setTruckQuery(item.label);
    }

    if (item?.semitrailer) {
      setSemitrailerQuery(item.semitrailer.attributes.license_plate);
      setValue('semitrailerLicensePlate', {
        label: item.semitrailer.attributes.license_plate,
        value: item.semitrailer.id,
      },
        {shouldValidate: true, shouldTouch:true},
      );
    } else {
      setValue('semitrailerLicensePlate', {
        label: '',
        value: '',
      });
      setSemitrailerQuery('');
    }
    if (item?.driver) {
      setTruckDriverQuery(item.driver.attributes.ic)
      setValue('truckDriverID', {
        label: item.driver.attributes.ic,
        value: item.driver.id,
      },
        {shouldValidate: true, shouldTouch:true},
        );
    } else {
      setValue('truckDriverID', {
        label: '',
        value: '',
      });
      setTruckDriverQuery('');
    }
  };

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleMicData) {
      reset(singleMicData);
      if (singleMicData.vehicleOwner) {
        setVehicleOwnerQuery(singleMicData.vehicleOwner.label)
      }
      if (singleMicData.truckLicensePlate) {
        setTruckQuery(singleMicData.truckLicensePlate.label)
      }
      if (singleMicData.truckDriverID) {
        setTruckDriverQuery(singleMicData.truckDriverID.label)
      }
      if (singleMicData.semitrailerLicensePlate) {
        setSemitrailerQuery(singleMicData.semitrailerLicensePlate.label)
      }
    }
  }, [singleMicData]);

  const onSubmit: SubmitHandler<AddMicInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    onSubmitForm(data);
  };

  // Create fields based in balance detail items
  useEffect(() => {
    if (crtBalanceDetails?.length > 0 && fields.length === 0) {
      crtBalanceDetails.forEach((item) => {
        append({
          description: item.merchandise.description,
          unit: item.unit,
        } as MerchandiseInputs);
      });
    }
  }, [crtBalanceDetails]);

  // File Uploader props
  const [hasElementDropped, setHasElementDropped] = useState(false);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    beforeUpload: () => false,
    onChange: (info) => setHasElementDropped(info.fileList.length > 0),
  };

  const errorsArray = Object.values(formState.errors);
  const disabledCTA =
    (micID ? false : !formState.isDirty) || isLoading || errorsArray.length > 0;

  const handleOnCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(
        `/${transformedUserRole}${buildGoBackURL(invoiceID, simID, crtID)}`,
      );
    }
  }, [onCancel]);

  const redirectBasedOnRole = (navigateTo: string) => {
    return (userRole === USER_ROLES_LIST.dispatcher || userRole === USER_ROLES_LIST.admin)
      ? navigate(`/${transformedUserRole}${navigateTo}`)
      : undefined;
  };

  const onAddNewTruck = useCallback(() => {
    return redirectBasedOnRole(URLS.addTruck);
  }, [userRole]);
  const onAddNewSemitrailer = useCallback(() => {
    return redirectBasedOnRole(URLS.addSemitrailer);
  }, [userRole]);
  const onAddNewTruckDriver = useCallback(() => {
    return redirectBasedOnRole(URLS.addTruckDriver);
  }, [userRole]);

  return (
    <div>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Typography theme="h4" as="h2">
            Datos
          </Typography>
          <StyledSubForm>
            <InputText
              {...register('micNumber', {
                required: 'Este Campo es requerido',
              })}
              $hasValue={formState.dirtyFields.micNumber}
              $invalid={!!formState.errors.micNumber}
              label="Número MIC"
              placeholder="Número MIC"
              subTextMessage={formState.errors.micNumber?.message}
            />
            <InputText
              {...register('shipmentNumber', {
                required: 'Este Campo es requerido',
              })}
              $hasValue={formState.dirtyFields.shipmentNumber}
              $invalid={!!formState.errors.shipmentNumber}
              label="Número de Remito"
              placeholder="Número de Remito"
              subTextMessage={formState.errors.shipmentNumber?.message}
            />
            <Controller
              name="issueDate"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputDatePicker
                  $hasValue={formState.dirtyFields.issueDate}
                  $invalid={!!formState.errors.issueDate}
                  handleOnChange={(date) => {
                    field.onChange(date);
                  }}
                  label="Fecha de Emisión"
                  placeholder="Fecha de Emisión"
                  subTextMessage={formState.errors.issueDate?.message}
                  value={field.value}
                  showTime={true}
                />
              )}
            />
            <InputText
              {...register('sealNumber', {
                required: 'Este Campo es requerido',
              })}
              $hasValue={formState.dirtyFields.sealNumber}
              $invalid={!!formState.errors.sealNumber}
              label="Número de Precintos"
              placeholder="Número de Precintos"
              subTextMessage={formState.errors.sealNumber?.message}
            />
            <Controller
              name="vehicleOwner"
              control={control}
              rules={{
                required: 'Este Campo es requerido',
              }}
              render={({ field }) => {
                return (
                  <InputSelectList
                    $hasValue={!!formState.dirtyFields.vehicleOwner}
                    $invalid={!!formState.errors.vehicleOwner}
                    handleOnChange={field.onChange}
                    handleOnInputChange={setVehicleOwnerQuery}
                    label="Propietario del Transporte"
                    options={vehicleOwnerOptions}
                    placeholder="Propietario del Transporte"
                    subTextMessage={formState.errors.vehicleOwner?.message}
                    value={field.value}
                  />
                );
              }}
            />
            <Controller
              name="truckLicensePlate"
              control={control}
              rules={{
                required: 'Este Campo es requerido',
              }}
              render={({ field }) => {
                return (
                  <InputSelectList
                    $hasValue={!!formState.dirtyFields.truckLicensePlate}
                    $invalid={!!formState.errors.truckLicensePlate}
                    handleOnChange={(selectedField) => {
                      setSemitrailerAndDriver(selectedField);
                      field.onChange(selectedField);
                    }}
                    handleOnInputChange={setTruckQuery}
                    label="Placa del Camión"
                    options={truckOptions}
                    placeholder="Placa del Camión"
                    subTextMessage={formState.errors.truckLicensePlate?.message}
                    value={field.value}
                    onAuxiliarButton={onAddNewTruck}
                  />
                );
              }}
            />
            <Controller
              name="semitrailerLicensePlate"
              control={control}
              rules={{
                required: 'Este Campo es requerido',
              }}
              render={({ field }) => {
                return (
                  <InputSelectList
                    $hasValue={!!formState.dirtyFields.semitrailerLicensePlate}
                    $invalid={!!formState.errors.semitrailerLicensePlate}
                    handleOnChange={field.onChange}
                    handleOnInputChange={setSemitrailerQuery}
                    label="Placa del Semiremolque"
                    options={semitrailerOptions}
                    placeholder="Placa del Semiremolque"
                    subTextMessage={
                      formState.errors.semitrailerLicensePlate?.message
                    }
                    value={field.value}
                    onAuxiliarButton={onAddNewSemitrailer}
                  />
                );
              }}
            />
            <Controller
              name="truckDriverID"
              control={control}
              rules={{
                required: 'Este Campo es requerido',
              }}
              render={({ field }) => {
                return (
                  <InputSelectList
                    $hasValue={!!formState.dirtyFields.truckDriverID}
                    $invalid={!!formState.errors.truckDriverID}
                    handleOnChange={field.onChange}
                    handleOnInputChange={setTruckDriverQuery}
                    label="Documento del Conductor"
                    options={truckDriverOptions}
                    placeholder="Documento del Conductor"
                    subTextMessage={formState.errors.truckDriverID?.message}
                    value={field.value}
                    onAuxiliarButton={onAddNewTruckDriver}
                  />
                );
              }}
            />
          </StyledSubForm>
        </div>
        <div>
          <Typography theme="h4" as="h2">
            Declaración de la Mercancía
          </Typography>
          {fields.map((field, index) => (
            <StyledSubForm key={field.id}>
              <InputText
                placeholder="Descripción"
                label="Descripción"
                disabled
                {...register(`merchandiseDeclaration.${index}.description`)}
              />
              <InputText
                placeholder="Cantidad"
                label="Cantidad"
                {...register(`merchandiseDeclaration.${index}.quantity`, {
                  required: 'Este Campo es requerido',
                  pattern: {
                    value: ONLY_FLOAT_NUMBERS_VALIDATION,
                    message: 'Este campo solo acepta números',
                  },
                  max: {
                    message:
                      'El valor ingresado no puede ser mayor que el valor del Saldo Disponible',
                    value: validateMicBalance({
                      currentMicData: singleMicData,
                      currentMicItem: crtBalanceDetails?.[index],
                      currentQuantity:
                        singleMicData?.merchandiseDeclaration?.[index]
                          ?.quantity,
                    }),
                  },
                })}
                onInput={handleOnChangeQuantity.bind({ index: index })}
                $hasValue={
                  formState.dirtyFields.merchandiseDeclaration?.[index]
                    ?.quantity
                }
                $invalid={
                  !!formState.errors.merchandiseDeclaration?.[index]?.quantity
                }
                subTextMessage={
                  formState.errors.merchandiseDeclaration?.[index]?.quantity
                    ?.message
                }
              />
              <InputText
                placeholder="Unidad de Medida"
                label="Unidad de Medida"
                disabled
                {...register(`merchandiseDeclaration.${index}.unit`)}
              />
            </StyledSubForm>
          ))}
        </div>
        <div>
          <Typography theme="h4" as="h2" $marginBottom="3rem">
            Adjuntar Archivos
          </Typography>
          {!singleMicData?.attachment && (
            <FileUploader
              props={uploadProps}
              hasFile={hasElementDropped}
              onUploadFile={onFileUpload}
            />
          )}

          {attachmentData && (
            <Link to={attachmentData.url}>
              <StyledAttachmentContainer>
                <FaFileArchive size="3rem" />
                <Typography theme="p">{attachmentData.name}</Typography>
              </StyledAttachmentContainer>
            </Link>
          )}
        </div>

        <StyledFormActions>
          <CustomButton
            $theme="success"
            text="Crear"
            type="submit"
            disabled={disabledCTA}
          />

          <CustomButton
            $theme="secondary"
            text="Cancelar"
            type="button"
            onPress={handleOnCancel}
          />
        </StyledFormActions>
      </form>

      <SelectedData data={filteredResults} />
    </div>
  );
};

export default AddMicForm;
