import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  Role,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';
import { CompanyResponseData, Inputs, SingleTruckResponse } from '../types';

type Props = {
  truckID?: string;
};

const useAddTruck = ({ truckID }: Props) => {
  const API_URL_COMPANIES_SEARCH =
      'companies?filters[business_name][$containsi]=',
    API_URL_CREATE_TRUCK = 'custom-wheeled/create',
    API_URL_GET_TRUCK = 'wheeleds/',
    API_URL_UPDATE_TRUCK = 'custom-wheeled/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const errorHandler = new ErrorHandler();

  const [inputOptions, setInputOptions] = useState<
    { value: string | number; label: string }[]
  >([]);

  const [inputQuery, setInputQuery] = useState('');
  const [singleTruckData, setSingleTruckData] = useState<Inputs | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState<string[]>([]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponseData>(`${API_URL_COMPANIES_SEARCH}${inputQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        const transformedData =
          result.length > 0
            ? result.map((item) => {
                return { value: item.id, label: item.attributes.business_name };
              })
            : [];
        setInputOptions(transformedData);
      })
      .catch((error) => {
        log.error(error);
      });
  }, [inputQuery]);

  const handleRedirect = () => {
    if (Role.Admin == transformedUserRole) {
      navigate(-1)
    } else {
      navigate(`/${transformedUserRole}${URLS.trucks}`)
    }
  }
  // Create Truck
  const handleAddTruck = async (data: Inputs) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      if (truckID) {
        // Update Mode
        const truckData = {
          insurance_policy: {
            number: data?.insurancePolicyNumber,
            expiration_date: data?.insurancePolicyExpiration,
          },
          wheeled: {
            license_plate: data?.licensePlate,
            brand: data?.brand,
            chassis_number: data?.chassisNumber,
            year: data?.model,
            type: 'Truck',
            company_id: data?.vehicleOwner?.value,
            comment: data?.comment,
            // insurance_policy: 4, // TODO: Analyze this behavior
          },
        };

        await axios.put(
          API_URL_UPDATE_TRUCK.replace('{id}', truckID),
          { ...truckData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });
      } else {
        // Create Mode
        const truckData = {
          insurance_policy: {
            number: data?.insurancePolicyNumber,
            expiration_date: data?.insurancePolicyExpiration,
          },
          wheeled: {
            license_plate: data?.licensePlate,
            brand: data?.brand,
            chassis_number: data?.chassisNumber,
            year: data?.model,
            type: 'Truck',
            company_id: data?.vehicleOwner?.value,
            comment: data?.comment,
          },
        };

        await axios.post(
          API_URL_CREATE_TRUCK,
          { ...truckData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });
      }

      setTimeout(() => {
        handleRedirect();
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  // Get Truck Data
  const handleGetTruckData = async (truckID: string) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<SingleTruckResponse>(`${API_URL_GET_TRUCK}${truckID}?populate=*`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;

        const transformedData = {
          vehicleOwner: {
            label:
              result?.attributes?.company_id?.data?.attributes?.business_name,
            value: result?.attributes?.company_id?.data?.id,
          },
          licensePlate: result?.attributes?.license_plate,
          brand: result?.attributes?.brand,
          chassisNumber: result?.attributes?.chassis_number,
          model: result?.attributes?.year,
          comment: result?.attributes?.comment,
          insurancePolicyNumber:
            result?.attributes?.insurance_policy?.data?.attributes?.number,
          insurancePolicyExpiration:
            result?.attributes?.insurance_policy?.data?.attributes?.expiration_date.toString(),
        };
        setSingleTruckData(transformedData);
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (truckID) handleGetTruckData(truckID);
  }, []);

  return {
    customError,
    handleAddTruck,
    inputOptions,
    isLoading,
    setInputQuery,
    singleTruckData,
  };
};

export default useAddTruck;
