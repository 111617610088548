import { UploadProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaFileArchive } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  FileUploader,
  Loader,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, URLS } from '../../utils';
import { StyledAttachmentContainer } from '../../utils/styles';
import useStore from '../../zustand';
import CompanyInfoModal from '../Invoices/components/CompanyInfoModal';
import DocumentsTable from './components/DocumentsTable';
import HeaderActions from './components/HeaderActions';
import ItemsTable from './components/ItemsTable';
import OperableState from './components/OperableState';
import useInvoiceDetail from './hooks/useInvoiceDetail';
import { StyledInvoiceDataContaner } from './invoiceDetail.styled';

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id || String(),
      ),
      name: 'Detalle Factura',
      withUserRole: true,
    },
  ];

  const { data, isLoading, handleDeleteInvoice, handleUploadFile } =
    useInvoiceDetail({
      invoiceID: params.invoice_id,
    });

  const exporterCompanyName = data?.exporter_id.business_name as string;
  const importerCompanyName = data?.importer_id.business_name as string;

  const handleOnEdit = React.useCallback(() => {
    navigate(`editar`);
  }, []);

  const handleOnDelete = React.useCallback(() => {
    handleDeleteInvoice(params.invoice_id);
  }, []);

  const [showFileUploadComponent, setShowFileUploadComponent] = useState(false);

  const handleOnFileUpload = React.useCallback(() => {
    setShowFileUploadComponent(true);
  }, []);

  const [hasElementDropped, setHasElementDropped] = useState(false);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    beforeUpload: () => false,
    onChange: (info) => setHasElementDropped(info.fileList.length > 0),
  };

  useEffect(() => {
    setShowFileUploadComponent(false);
  }, [data]);

  const handleOnGoBack = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.listInvoices}`);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader isFullOpacity />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        Factura {data?.invoice_number}
      </Typography>

      <HeaderActions
        data={data}
        handleOnEdit={handleOnEdit}
        handleOnDelete={handleOnDelete}
        handleOnFileUpload={handleOnFileUpload}
      />

      {showFileUploadComponent && (
        <FileUploader
          props={uploadProps}
          hasFile={hasElementDropped}
          onUploadFile={handleUploadFile}
        />
      )}

      {data?.attachment && (
        <Link to={data.attachment.url}>
          <StyledAttachmentContainer>
            <FaFileArchive size="3rem" />
            <Typography theme="p">{data?.attachment.name}</Typography>
          </StyledAttachmentContainer>
        </Link>
      )}

      <StyledInvoiceDataContaner>
        <li>
          <Typography theme="p">
            <strong>Fecha:</strong> {data?.date.toString()}
          </Typography>
        </li>
        <li>
          <Typography theme="p">
            <strong>Condición de Venta:</strong> {data?.sale_condition}
          </Typography>
        </li>
        <li>
          <Typography theme="p">
            <strong>Exportador:&nbsp;</strong>
          </Typography>
          <CompanyInfoModal
            agentCompanyInfo={data?.exporter_id}
            titleCompanyName={exporterCompanyName}
          >
            <Typography theme="p" as="a">
              {exporterCompanyName}
            </Typography>
          </CompanyInfoModal>
        </li>
        <li>
          <Typography theme="p">
            <strong>Importador:&nbsp;</strong>
          </Typography>
          <CompanyInfoModal
            agentCompanyInfo={data?.importer_id}
            titleCompanyName={importerCompanyName}
          >
            <Typography theme="p" as="a">
              {importerCompanyName}
            </Typography>
          </CompanyInfoModal>
        </li>
        <li>
          <Typography theme="p">
            <strong>Operable:&nbsp;</strong>
          </Typography>
          <OperableState isOperable={data?.operable} />
        </li>
      </StyledInvoiceDataContaner>

      <div>
        <Typography theme="h3" as="h2" $marginBottom="4rem">
          Items
        </Typography>
        <ItemsTable data={data?.invoice_items} />
      </div>

      <div>
        <Typography theme="h3" as="h2" $marginBottom="4rem">
          Documentos relacionados
        </Typography>
        <DocumentsTable data={data?.related_docs} />
      </div>

      <CustomButton
        text="Volver"
        $isMini
        $marginTop="4rem"
        onPress={handleOnGoBack}
      />
    </BoxContainer>
  );
};

export default PageContent;
