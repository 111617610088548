import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomButton, InputText } from '../../../../components';
import { URLS } from '../../../../utils';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import useChangePassword from '../../hooks/useChangePassword';
import { ChangePasswordInputs } from '../../types';

const ChangePasswordForm: FC = () => {
  const { change_password_code } = useParams();

  const { isLoading, handleChangePassword } = useChangePassword();

  const { register, handleSubmit, formState, watch, resetField } =
    useForm<ChangePasswordInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const navigate = useNavigate();

  const handleOnCancel = React.useCallback(() => {
    navigate(`${URLS.landing}`);
  }, []);

  const onSubmit: SubmitHandler<ChangePasswordInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    const payload = {
      code: change_password_code!!,
      password: data.password,
    };
    handleChangePassword(payload);
    resetField('password');
    resetField('confirm_password');
  };

  const disabledCTA = !formState.isDirty || isLoading;
  const passMinLength = 8;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubForm>
          <InputText
            $hasValue={formState.dirtyFields.password}
            $invalid={!!formState.errors.password}
            label="Contraseña"
            placeholder="Contraseña"
            subTextMessage={formState.errors.confirm_password?.message}
            type="password"
            {...register('password', {
              minLength: {
                value: passMinLength,
                message: 'La contraseña necesita al menos 8 caracteres',
              },
            })}
          />
          <InputText
            $hasValue={formState.dirtyFields.confirm_password}
            $invalid={!!formState.errors.confirm_password}
            label="Confirme la contraseña"
            placeholder="Confirme la contraseña"
            subTextMessage={formState.errors.confirm_password?.message}
            type="password"
            {...register('confirm_password', {
              validate: (passwordValue: string) => {
                if (passwordValue.length < passMinLength) {
                  return 'La contraseña necesita al menos 8 caracteres';
                } else if (watch('password') !== passwordValue) {
                  return 'Las contraseñas no coinciden';
                }
              },
            })}
          />
        </StyledSubForm>
        <StyledFormActions>
          <CustomButton disabled={disabledCTA} text="Guardar" type="submit" />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default ChangePasswordForm;
