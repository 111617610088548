import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationMessages, URLS, WAIT_TIMES } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';

type UserData = {
  id: number;
  username: string;
  lastName: string;
  email: string;
  name: string;
  nickname: string;
};

type Inputs = {
  name?: string;
  lastName?: string;
  email?: string;
  password?: string;
};

const useProfile = () => {
  const API_URL_GET_PROFILE = 'users/me';
  const API_URL_UPDATE_PROFILE = 'custom-user/me';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  const [setUserName] = useStore((state) => [state.setUserName]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    setIsLoading(true);

    axios
      .get<UserData>(API_URL_GET_PROFILE, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleUpdateUserData = async (data: Inputs) => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      setIsLoading(true);
      await axios.put(API_URL_UPDATE_PROFILE, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUserName(data.name || String());

      showNotification({
        message: 'Los datos fueron actualizados satisfactoriamente',
      });
      setTimeout(() => {
        navigate(URLS.dashboard);
      }, WAIT_TIMES.sm);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      setIsLoading(false);
    }
  };

  return {
    userData,
    isLoading,
    handleUpdateUserData,
  };
};

export default useProfile;
