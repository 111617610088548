import React, { FC, useEffect } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import {
  CustomButton,
  InputDatePicker,
  InputSelect,
  InputSelectList,
  InputText,
  Typography,
} from '../../../../components';
import { ONLY_FLOAT_NUMBERS_VALIDATION } from '../../../../utils/regexp';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import { InputSelectListOption } from '../../../../utils/types';
import { SimItem } from '../../../../utils/types/sims';
import { AddCrtInputs, MerchandiseInputs } from '../../types';
import {
  StyledMerchandiseSectionForm,
  StyledSubSectionForm,
} from './AddCrtForm.styled';
import { countries, currencies } from './utils';

type Props = {
  ctaText: string;
  handleOnFormCancel: () => void;
  handleOnSubmit: (data: AddCrtInputs) => void;
  handleOnCarrierCompanyQuery: (value: string) => void;
  simItems: SimItem[];
  singleCrtData?: AddCrtInputs;
  carrierCompanyOptions: InputSelectListOption[];
};

const AddSimForm: FC<Props> = ({
  ctaText,
  simItems,
  singleCrtData,
  carrierCompanyOptions,
  handleOnSubmit,
  handleOnFormCancel,
  handleOnCarrierCompanyQuery,
}) => {
  const { register, handleSubmit, reset, formState, control } =
    useForm<AddCrtInputs>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const { fields, append } = useFieldArray({
    name: 'merchandiseDeclaration',
    control,
  });

  const onSubmit: SubmitHandler<AddCrtInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleOnSubmit(data);
  };

  useEffect(() => {
    if (simItems?.length > 0 && fields.length === 0) {
      simItems.forEach((item) => {
        append({
          description: item.merchandise_id.description,
          quantity: item.quantity,
          unit: item.unit,
        } as MerchandiseInputs);
      });
    }
  }, [simItems]);

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleCrtData) {
      reset(singleCrtData);
    }
  }, [singleCrtData]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography theme="p" as="h2">
          Datos
        </Typography>
        <StyledSubForm>
          <InputText
            placeholder="Numero"
            label="Numero"
            $hasValue={formState.dirtyFields.number}
            $invalid={!!formState.errors.number}
            {...register('number', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.number?.message}
          />
          <Controller
            name="carrierCompany"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
            }}
            render={({ field }) => {
              return (
                <InputSelectList
                  placeholder="Transportador"
                  label="Transportador"
                  options={carrierCompanyOptions}
                  $hasValue={formState.dirtyFields.carrierCompany?.value}
                  $invalid={!!formState.errors.carrierCompany}
                  handleOnChange={field.onChange}
                  handleOnInputChange={handleOnCarrierCompanyQuery}
                  subTextMessage={formState.errors.carrierCompany?.message}
                  value={field.value}
                />
              );
            }}
          />
        </StyledSubForm>

        <StyledSubForm>
          <StyledSubSectionForm>
            <Typography theme="p" as="h2">
              Lugar, país y fecha de recepción de las mercancías
            </Typography>
            <InputText
              placeholder="Lugar de recepción"
              label="Lugar de recepción"
              $hasValue={formState.dirtyFields.reception?.place}
              $invalid={!!formState.errors.number}
              {...register('reception.place', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.number?.message}
            />

            <InputSelect
              options={countries}
              placeholder="País de recepción"
              label="País de recepción"
              $hasValue={formState.dirtyFields.reception?.country}
              $invalid={!!formState.errors.reception?.country}
              {...register('reception.country', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.reception?.country?.message}
            />

            <Controller
              name="reception.date"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputDatePicker
                  label="Fecha de recepción"
                  placeholder="Fecha de recepción"
                  $hasValue={formState.dirtyFields.reception?.date}
                  $invalid={!!formState.errors.reception?.date}
                  handleOnChange={(date) => {
                    field.onChange(date);
                  }}
                  subTextMessage={formState.errors.reception?.date?.message}
                  value={field.value}
                />
              )}
            />
          </StyledSubSectionForm>

          <StyledSubSectionForm>
            <Typography theme="p" as="h2">
              Lugar, país y plazo de entrega
            </Typography>
            <InputText
              placeholder="Lugar de entrega"
              label="Lugar de entrega"
              $hasValue={formState.dirtyFields.delivery?.place}
              $invalid={!!formState.errors.delivery?.place}
              {...register('delivery.place', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.delivery?.place?.message}
            />

            <InputSelect
              placeholder="País de entrega"
              label="País de entrega"
              options={countries}
              {...register('delivery.country', {
                required: 'Este Campo es requerido',
              })}
              $hasValue={formState.dirtyFields.delivery?.country}
              $invalid={!!formState.errors.delivery?.country}
              subTextMessage={formState.errors.delivery?.country?.message}
            />

            <Controller
              name="delivery.date"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputDatePicker
                  label="Fecha de entrega"
                  placeholder="Fecha de entrega"
                  $hasValue={formState.dirtyFields.delivery?.date}
                  $invalid={!!formState.errors.delivery?.date}
                  handleOnChange={(date) => {
                    field.onChange(date);
                  }}
                  subTextMessage={formState.errors.delivery?.date?.message}
                  value={field.value}
                />
              )}
            />
          </StyledSubSectionForm>
        </StyledSubForm>

        <div style={{ marginBottom: '5rem' }}>
          <Typography theme="p" as="h2">
            Declaración de la Mercadería
          </Typography>
          {fields.map((field, index) => (
            <StyledMerchandiseSectionForm key={field.id}>
              <InputText
                placeholder="Descripción"
                label="Descripción"
                disabled
                {...register(`merchandiseDeclaration.${index}.description`)}
              />

              <InputText
                placeholder="Cantidad"
                label="Cantidad"
                {...register(`merchandiseDeclaration.${index}.quantity`, {
                  required: 'Este Campo es requerido',
                  max: {
                    message: 'La cantidad no puede exceder el saldo actual',
                    value: simItems?.[index]?.quantity,
                  },
                  pattern: {
                    value: ONLY_FLOAT_NUMBERS_VALIDATION,
                    message: 'Este campo solo acepta números',
                  },
                })}
                $hasValue={
                  formState.dirtyFields.merchandiseDeclaration?.[index]
                    ?.quantity
                }
                $invalid={
                  !!formState.errors.merchandiseDeclaration?.[index]?.quantity
                }
                subTextMessage={
                  formState.errors.merchandiseDeclaration?.[index]?.quantity
                    ?.message
                }
              />

              <InputText
                placeholder="Unidad"
                label="Unidad"
                disabled
                {...register(`merchandiseDeclaration.${index}.unit`)}
              />
            </StyledMerchandiseSectionForm>
          ))}
        </div>
        <div style={{ marginBottom: '5rem' }}>
          <Typography theme="p" as="h2">
            Costo de envío
          </Typography>

          <StyledSubForm>
            <InputText
              placeholder="Valor del Flete"
              label="Valor del Flete"
              $hasValue={formState.dirtyFields.totalCostForRecipient}
              $invalid={!!formState.errors.totalCostForRecipient}
              subTextMessage={formState.errors.totalCostForRecipient?.message}
              {...register(`totalCostForRecipient`, {
                pattern: {
                  value: ONLY_FLOAT_NUMBERS_VALIDATION,
                  message: 'Este campo solo acepta números',
                },
              })}
            />

            <InputSelect
              options={currencies}
              placeholder="Moneda"
              label="Moneda"
              {...register('currency', {
                required: 'Este Campo es requerido',
              })}
              subTextMessage={formState.errors.currency?.message}
              $hasValue={!!formState.dirtyFields.currency}
              $invalid={!!formState.errors.currency}
            />
          </StyledSubForm>
        </div>

        <StyledFormActions>
          <CustomButton $theme="success" text={ctaText} type="submit" />
          <CustomButton
            $theme="secondary"
            onPress={handleOnFormCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </div>
  );
};

export default AddSimForm;
