import React from 'react';

import { Footer, Header, PageLayout } from '../../components';
import PageContent from '../../features/AddTruckDriver';
import { useAuthValidation } from '../../utils/hooks';

const AddTruckDriverPage = () => {
  useAuthValidation();

  return (
    <>
      <Header />
      <PageLayout>
        <PageContent />
      </PageLayout>
      <Footer />
    </>
  );
};

export default AddTruckDriverPage;
