import { AxiosResponse } from 'axios';
import log from 'loglevel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { URLS } from '../../../utils';
import { useAxios } from '../../../utils/hooks';
import { PlainCompanyData } from '../../../utils/types/company';
import useStore from '../../../zustand';

type Inputs = {
  email: string;
  password: string;
};

type GetUserRoleData = {
  id: number;
  username: string;
  name: string;
  lastName: string;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
    createdAt: null;
    updatedAt: Date;
  };
  company_id?: PlainCompanyData;
};

const useLogin = () => {
  const API_URL_LOGIN = 'auth/local';
  const API_URL_GET_USER_ROLE = 'users/me?populate=*';

  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<AxiosResponse | null | void>(null);
  const [isLoading, setIsLoading] = useState(false);

  const axios = useAxios();
  const navigate = useNavigate();

  const [
    setUserName,
    setUserRole,
    setUserCompanyID,
    setUserCompanyType,
    setUserCompanyName,
    setUserAuthenticated,
  ] = useStore((state) => [
    state.setUserName,
    state.setUserRole,
    state.setUserCompanyID,
    state.setUserCompanyType,
    state.setUserCompanyName,
    state.setUserIsAuthenticated,
  ]);

  const getUserRole = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    try {
      const response = await axios.get<GetUserRoleData>(API_URL_GET_USER_ROLE, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userRole = response?.data?.role?.name ?? 'none'; // TODO: Fix this fallback
      setData(response);
      setUserName(response.data.name);
      setUserRole(userRole);
      setUserCompanyID(response?.data?.company_id?.id || null);
      setUserCompanyName(response?.data?.company_id?.business_name || null);
      setUserCompanyType(response?.data?.company_id?.type || null);
      setUserAuthenticated(!!token);
      // Navigate to correct Landing Page user-role-based
      if (userRole === 'Despachante') {
        navigate(URLS.dispatcherLanding);
      } else if (userRole === 'Recepcionista') {
        navigate(URLS.receiverLanding);
      } else {
        navigate(URLS.dashboard);
      }
    } catch (error) {
      log.error(error);
      setHasError(true);
    }
  };

  const handleLogin = async (data: Inputs) => {
    setIsLoading(true);
    setHasError(false);

    const payload = {
      identifier: data.email,
      password: data.password,
    };

    axios
      .post(API_URL_LOGIN, payload)
      .then(async (response) => {
        sessionStorage.setItem('token', response.data.jwt);
        await getUserRole();
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { data, handleLogin, hasError, isLoading };
};

export default useLogin;
