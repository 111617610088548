import { styled } from 'styled-components';

import { theme } from '../../styles';
import { transitionAnimation } from '../../utils/styles';

export const StyledHeaderContainer = styled.header`
  background-color: ${theme.colors.defaultBlack};
  color: ${theme.colors.defaultWhite};
  display: flex;
  justify-content: space-between;
  padding: 2.4rem 2rem;
  position: relative;
  width: 100%;
  z-index: ${theme.zIndex.headerFooter};
`;

export const StyledMenuLogoContainer = styled.button`
  align-items: center;
  display: flex;
`;

export const StyledMenuLogo = styled.div`
  display: flex;
  flex-direction: column;
  height: 1.2rem;
  justify-content: space-between;
  position: relative;
  width: 2.2rem;

  span {
    ${transitionAnimation}
    background-color: ${theme.colors.defaultWhite};
    border-radius: 0.5rem;
    display: block;
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
    }
    &:nth-child(3) {
      top: 100%;
    }
  }

  &.menu-open {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    height: 1.5rem;
    width: 3.2rem;
  }
`;

export const StyledSideMenuContainer = styled.div`
  ${transitionAnimation}
  max-width: 25rem;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden;
  width: 60%;
  &.menu-open {
    opacity: 1;
    visibility: visible;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    max-width: 30rem;
  }
`;

export const StyledUserNameContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.5rem;
  p {
    opacity: 0.35;
  }
`;
