import { Pagination } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, Typography } from '../../components';
import { StyledPaginationContainer } from '../../utils/styles';
import { PaginationData } from '../../utils/types';
import FileItem from './components/FileItem';
import {
  StyledContentContainer,
  StyledFilesList,
  StyledFilesMainContainer,
  StyledNoContentMessage,
} from './Files.styled';
import useGetFileList from './hooks/useGetFileList';
import { getPageCategory, getPageTitle } from './utils';

const PageContent = () => {
  const params = useParams();

  const category = useMemo(
    () => getPageCategory(params.report_type, params.report_folder),
    [params.report_type, params.report_folder],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 20,
    current: currentPage,
  };

  const { files, filesCount, isLoading, handleOnDeleteFile } = useGetFileList({
    category,
    paginationData,
  });

  const content =
    files.length > 0 ? (
      <StyledFilesList>
        {files.map((file) => (
          <li key={file.id}>
            <FileItem data={file} onDeleteFile={handleOnDeleteFile} />
          </li>
        ))}
      </StyledFilesList>
    ) : (
      <StyledNoContentMessage>
        <Typography theme="p" as="h6">
          Aún no hay contenido para esta categoría
        </Typography>
      </StyledNoContentMessage>
    );

  return (
    <StyledFilesMainContainer>
      {isLoading && <Loader />}
      <Typography theme="h1" as="h1">
        {getPageTitle(params.report_type, params.report_folder)}
      </Typography>
      <StyledContentContainer>{content}</StyledContentContainer>
      <StyledPaginationContainer>
        <Pagination
          defaultCurrent={1}
          total={filesCount}
          pageSize={paginationData?.pageSize || 1}
          current={currentPage}
          onChange={setCurrentPage}
        />
      </StyledPaginationContainer>
    </StyledFilesMainContainer>
  );
};

export default PageContent;
