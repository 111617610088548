import log from 'loglevel';
import { useEffect, useState } from 'react';

import { NotificationMessages } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { PaginationData } from '../../../utils/types';
import { Archive } from '../../../utils/types/archive';
import { FilesResponse } from '../types';

type Props = {
  category: string;
  paginationData: PaginationData;
};

const useGetFileList = ({ category, paginationData }: Props) => {
  const API_URL_FILE_DELETE = '/upload/files/{fileID}';
  const API_URL_FILE_LIST = '/custom-archives?file_category=';

  const paginationParams = `&page=${paginationData.current}&per_page=${paginationData.pageSize}`;

  const axios = useAxios();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [files, setFiles] = useState<Archive[]>([]);
  const [filesCount, setFilesCount] = useState<number>(0);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getReportsPO = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No token provided');

    const result = await axios.get<FilesResponse>(
      API_URL_FILE_LIST + category + paginationParams,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setFiles(result.data.archives);
    setFilesCount(result.data.archives_count);
    setIsLoading(false);
  };
  useEffect(() => {
    getReportsPO().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [category, paginationData.current]);

  const handleOnDeleteFile = async (fileID: number) => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      if (!token) throw new Error('No token provided');

      await axios.delete(
        API_URL_FILE_DELETE.replace('{fileID}', fileID.toString()),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      showNotification({
        message: 'El archivo fue borrado correctamente',
      });

      // Reload data
      getReportsPO();
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  return { isLoading, hasError, files, filesCount, handleOnDeleteFile };
};

export default useGetFileList;
