import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BoxContainer, Breadcrumb, Loader, Typography } from '../../components';
import { URLS } from '../../utils';
import { StyledErrorMessage } from '../../utils/styles';
import { buildCrtRelatedMicsURL } from '../CrtRelatedMics/utils';
import { StyledBalanceInfo, StyledHeaderBalanceInfo } from './AddMic.styled';
import AddMicForm from './components/AddMicForm';
import useAddMicForm from './hooks/useAddMicForm';
import { calculateMicBalance, CurrentMicBalance, renderIcon } from './utils';

const PageContent = () => {
  const params = useParams();

  const crtID = params.crt_id,
    micID = params.mic_id,
    invoiceID = params.invoice_id,
    simID = params.sim_id;

  const {
    handleUploadFile,
    handleAddMic,
    crtData,
    isLoading,
    customError,
    singleMicData,
    attachmentData,
  } = useAddMicForm({
    crtID,
    micID,
    invoiceID,
    simID,
  });

  const newOrEditTitle = micID ? 'Editar' : 'Nuevo';

  const currentURL = micID
    ? `${buildCrtRelatedMicsURL(invoiceID, simID, crtID)}/${micID}/editar`
    : `${buildCrtRelatedMicsURL(invoiceID, simID, crtID)}/nuevo`;

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.invoiceDetail.replace(
        '{invoiceID}',
        invoiceID || String(),
      ),
      name: 'Detalle Factura',
      withUserRole: true,
    },
    {
      location: buildCrtRelatedMicsURL(invoiceID, simID, crtID),
      name: 'MICs',
      withUserRole: true,
    },
    {
      location: currentURL,
      name: `${newOrEditTitle} MIC`,
      withUserRole: true,
    },
  ];

  const balanceDetails = crtData?.balance_details || [];

  const [newMicBalance, setNewMicBalance] = useState<CurrentMicBalance[]>([]);

  const handleOnChangeQuantity = React.useCallback(
    (index: number, quantity: number) => {
      const isAbleToUpdateBalance = newMicBalance.some(
        (balance) => balance.index === index,
      );
      if (isAbleToUpdateBalance) {
        const updatedCurrentBalance = newMicBalance.map((balance) => {
          return balance.index === index ? { ...balance, quantity } : balance;
        });
        setNewMicBalance(updatedCurrentBalance);
      } else {
        setNewMicBalance([...newMicBalance, { index, quantity }]);
      }
    },
    [newMicBalance],
  );

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />

      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {newOrEditTitle} MIC
      </Typography>

      <StyledHeaderBalanceInfo>
        <Typography theme="p" as="h2" $marginBottom="2rem">
          Saldos Disponibles
        </Typography>
        <ul>
          {balanceDetails.map((item, index) => (
            <StyledBalanceInfo
              key={`${item.merchandise.id}${item.joined_balance}`}
            >
              {renderIcon(item.joined_balance)}
              <Typography theme="p">{item.merchandise.description}:</Typography>
              <Typography theme="p">
                {calculateMicBalance({
                  index,
                  currentMicData: singleMicData,
                  currentMicQuantities: newMicBalance,
                  currentCrtData: balanceDetails,
                })}
              </Typography>
              <Typography theme="p">{item.unit}</Typography>
            </StyledBalanceInfo>
          ))}
        </ul>
      </StyledHeaderBalanceInfo>

      {customError.length > 0 && (
        <StyledErrorMessage>
          {customError.map((item) => (
            <div key={item.replaceAll(' ', '')}>
              <Typography theme="p" $marginBottom="1.5rem">
                {item}
              </Typography>
            </div>
          ))}
        </StyledErrorMessage>
      )}
      <AddMicForm
        onFileUpload={handleUploadFile}
        onSubmitForm={handleAddMic}
        crtBalanceDetails={balanceDetails}
        singleMicData={singleMicData}
        attachmentData={attachmentData}
        onChangeQuantity={handleOnChangeQuantity}
      />
    </BoxContainer>
  );
};

export default PageContent;
