import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledMainContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  a {
    color: ${theme.colors.primary};
  }
`;
