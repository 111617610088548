import ConfigProvider from 'antd/es/config-provider';
import locale from 'antd/locale/es_ES';
import React, { FC, ReactNode, useLayoutEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import {
  AddCompanyPage,
  ChangePasswordPage,
  DashboardPage,
  DispatcherLandingPage,
  FilesPage,
  LoginPage,
  ProfilePage,
} from './pages';
import ReceiverLandingPage from './pages/ReceiverLanding';
import GlobalStyle from './styles/global-styles';
import { isAdmin, isImpExpAdmin, isTransporter } from './utils';
import GuardRoute from './utils/guards';
import AppRouter from './utils/routes';
import useStore from './zustand';

type Props = {
  children: ReactNode;
};

// Scroll to top component, need it for scroll restoration
const Wrapper: FC<Props> = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

function App() {
  const [useRole, userIsAuthenticated] = useStore((store) => [
    store.userRole,
    store.userIsAuthenticated,
  ]);

  return (
    <>
      <GlobalStyle />
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <Wrapper>
            <Routes>
              {/* Invalid */}
              <Route path="*" element={<Navigate to="/" />}></Route>

              {/* Common */}
              <Route
                path="/"
                element={
                  <GuardRoute
                    userRole={useRole}
                    isAvailable={!userIsAuthenticated}
                    component={<Navigate to="/login" />}
                  />
                }
              />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route
                path="/login"
                element={
                  <GuardRoute
                    userRole={useRole}
                    isAvailable={!userIsAuthenticated}
                    component={<LoginPage />}
                  />
                }
              />
              <Route path="/usuario/perfil" element={<ProfilePage />} />
              <Route
                path="/cambio-password/:change_password_code"
                element={<ChangePasswordPage />}
              />

              {/* Company */}
              {AppRouter(useRole).companies.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              <Route
                path="/empresas/:company_id/editar"
                element={
                  <GuardRoute
                    isAvailable={
                      isAdmin(useRole) ||
                      isTransporter(useRole) ||
                      isImpExpAdmin(useRole)
                    }
                    component={<AddCompanyPage />}
                  />
                }
              />

              {/* Truck */}
              {AppRouter(useRole).trucks.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Policies */}
              {AppRouter(useRole).policies.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Semitrailer */}
              {AppRouter(useRole).semitrailers.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Truck Driver */}
              {AppRouter(useRole).truckDrivers.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Users */}
              {AppRouter(useRole).users.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Invoices */}
              {AppRouter(useRole).invoices.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Sims */}
              {AppRouter(useRole).sims.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Crts */}
              {AppRouter(useRole).crts.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Mics */}
              {AppRouter(useRole).mics.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Reports */}
              {AppRouter(useRole).reports.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* My Files */}
              {AppRouter(useRole).files.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  >
                    <Route path="reportes" element={<Navigate to="op" />} />
                    <Route
                      path=":report_type/:report_folder"
                      element={<FilesPage />}
                    />
                    <Route path=":report_type" element={<FilesPage />} />
                  </Route>
                );
              })}

              {/* Merchandise */}
              {AppRouter(useRole).merchandises.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <GuardRoute
                        component={route.component}
                        isAvailable={route.isAvailable}
                      />
                    }
                  />
                );
              })}

              {/* Dispatcher */}
              <Route
                path="/despachante/mic/nuevo"
                element={
                  <GuardRoute
                    isAvailable={!!userIsAuthenticated}
                    component={<DispatcherLandingPage />}
                  />
                }
              />

              {/* Receiver */}
              <Route
                path="/recepcionista/mic/registrar-recepcion"
                element={
                  <GuardRoute
                    isAvailable={!!userIsAuthenticated}
                    component={<ReceiverLandingPage />}
                  />
                }
              />
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
}

export default App;
