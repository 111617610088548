import styled from 'styled-components';

export const StyledBanner = styled.div`
  margin-top: 6rem;
  text-align: center;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;
