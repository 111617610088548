import { URLS } from '../../../../../utils';
import { CrtBalanceDetails } from '../../../../../utils/types/crt';
import { AddMicInputs } from '../../../types';

export const buildGoBackURL = (
  invoiceID?: string,
  simID?: string,
  crtID?: string,
) => {
  return URLS.simRelatedCrtDetail
    .replace('{invoiceID}', invoiceID || String())
    .replace('{simID}', simID || String())
    .replace('{crtID}', crtID || String());
};

type ValidateMicBalance = {
  currentMicData: AddMicInputs | null;
  currentMicItem: CrtBalanceDetails;
  currentQuantity?: number;
};

export const validateMicBalance = ({
  currentMicData,
  currentMicItem,
  currentQuantity,
}: ValidateMicBalance): number => {
  const hasCurrentSimData =
    currentMicData && Object.entries(currentMicData).length;

  let absoluteBalance = Number();

  if (!hasCurrentSimData) {
    absoluteBalance = currentMicItem?.joined_balance;
  } else {
    if (currentMicData.merchandiseDeclaration.length) {
      const qtty = currentQuantity ?? 0;
      absoluteBalance = currentMicItem?.joined_balance + qtty;
    } else {
      absoluteBalance = currentMicItem?.joined_balance;
    }
  }

  return absoluteBalance;
};
