import { Company } from '../../../utils/types/company';
import { countriesSelect } from '../components/AddCompanyForm/utils';
import { Inputs } from '../types';

export const transformFromCompanyToInputs = (
  company: Company | undefined,
): Inputs => {
  const buildCountry = (country: string) => {
    return country.includes(
      `${company?.attributes.country}`.toLocaleLowerCase(),
    );
  };

  return {
    business_name: company?.attributes.business_name,
    tax_id: company?.attributes.tax_id,
    address: company?.attributes.address,
    city: company?.attributes.city,
    cnrt: company?.attributes.cnrt,
    international_dr: company?.attributes.international_dr,
    type: company?.attributes.type,
    company_id: company?.id,
    country: countriesSelect.find(({ value }) => {
      return buildCountry(value.toLocaleLowerCase());
    })?.value,
  } as Inputs;
};
