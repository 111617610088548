import { FaUniversity } from 'react-icons/fa';
import { styled } from 'styled-components';

import { theme } from '../../../../styles';

type StyledProps = {
  $hasLink: boolean;
};

export const StyledContenChildren = styled.div<StyledProps>`
  cursor: pointer;
  display: inline;
  &:hover {
    text-decoration: ${(props) => (props.$hasLink ? 'underline' : 'none')};
  }
`;

export const StyledModalHeader = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 0.9rem;
  border-bottom: 1px solid ${theme.colors.defaultGray};
`;

export const StyledModalHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledModalInfoContent = styled.div`
  gap: 1rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledModalIcon = styled(FaUniversity)`
  margin-right: 1rem;
  transform: scaleX(-1);
`;
