import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CustomButton, InputText, Modal } from '../../../../components';
import Typography from '../../../../components/Typography';
import { EMAIL_VALIDATION } from '../../../../utils/regexp';
import { StyledSubForm } from '../../../AddCompany/components/AddCompanyForm/AddCompanyForm.styled';
import useRecoveryPassword from '../../hooks/useRecoveryPassword';
import { RecoveryPasswordInputs } from '../../types';
import {
  StyledModalActions,
  StyledModalElementsContainer,
} from './RecoveryPassword.styled';

type Props = {
  emailValue: string;
};

const RecoveryPassword = ({ emailValue }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { register, handleSubmit, formState, setValue } =
    useForm<RecoveryPasswordInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  useEffect(() => {
    setValue('email', emailValue || String());
  }, [emailValue]);

  const { handleRecoveryPassword, isLoading } = useRecoveryPassword({
    setShowModal,
  });

  const closeModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const openModal = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA =
    !emailValue && (!formState.isDirty || isLoading || errorsArray.length > 0);

  return (
    <>
      <Modal showModal={showModal} handleClose={closeModal}>
        <StyledModalElementsContainer>
          <form onSubmit={handleSubmit(handleRecoveryPassword)}>
            <Typography theme="h4" as="h2">
              Recupere su cuenta
            </Typography>
            <Typography theme="p" $marginTop="2rem">
              Ingrese su dirección de correo electrónico así podremos enviarle
              los pasos para reestablecer su contraseña.
            </Typography>
            <StyledSubForm>
              <InputText
                placeholder="Email"
                label="Email"
                $hasValue={formState.dirtyFields.email}
                $invalid={!!formState.errors.email}
                {...register('email', {
                  required: 'Este Campo es requerido',
                  pattern: {
                    value: EMAIL_VALIDATION,
                    message: 'El email no es válido',
                  },
                })}
                subTextMessage={formState.errors.email?.message}
              />
            </StyledSubForm>
            <StyledModalActions>
              <CustomButton
                $theme="primary"
                $isMini
                text="Restablecer"
                disabled={disabledCTA}
                type="submit"
              />
              <CustomButton
                $theme="secondary"
                $isMini
                text="Cancelar"
                onPress={() => setShowModal(false)}
              />
            </StyledModalActions>
          </form>
        </StyledModalElementsContainer>
      </Modal>
      <CustomButton
        $theme="link"
        text="¿Olvidó su contraseña?"
        $marginTop="2rem"
        $textAlign="left"
        onPress={openModal}
      />
    </>
  );
};

export default RecoveryPassword;
