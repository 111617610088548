import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  ReportMessages,
  URLS,
} from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { CompanyResponse } from '../../../utils/types/company';
import { InputSelectListOption } from '../../../utils/types/index';
import useStore from '../../../zustand';
import { CrtReportsResponse } from '../types';

type Props = {
  paginationCurrentPage: number;
};

const useCrtReports = ({ paginationCurrentPage }: Props) => {
  const API_URL_CRTS = 'reports/crt',
    API_URL_CRT_REPORT_GENERATION_PDF = '/reports/format/pdf/crt',
    API_URL_CRT_REPORT_GENERATION_XLSX = '/reports/format/xlsx/crt',
    API_URL_GET_CLIENTS =
      '/companies?filters[type][$eq]=${company_type}&filters[business_name][$containsi]=';

  const paginationParams = `?page=${paginationCurrentPage}&per_page=10`;

  const filterSortQuery = '&sort=',
    filterOrderQuery = '&order=',
    filterDateFromQuery = '&date_from=',
    filterDateToQuery = '&date_to=',
    filterCarrierCompanyQuery = '&carrier_company_id=',
    filterImporterQuery = '&importer_id=',
    filterExporterTextQuery = '&exporter_id=';

  const axios = useAxios();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<CrtReportsResponse | null>(null);
  const [companyImporterExporterOptions, setCompanyImporterExporterOptions] =
    useState<InputSelectListOption[]>([]);
  const [companyTransporterOptions, setCompanyTransporterOptions] = useState<
    InputSelectListOption[]
  >([]);

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Filter Controls
  const [filterSortValue, setFilterSortValue] = useState('');
  const [filterOrderValue, setFilterOrderValue] = useState('');
  const [filterDateFromValue, setFilterDateFromValue] = useState('');
  const [filterDateUntilValue, setFilterDateUntilValue] = useState('');
  const [filterTransporterValue, setFilterTransporterValue] = useState('');
  const [filterExporterValue, setFilterExporterValue] = useState('');
  const [filterImporterValue, setFilterImporterValue] = useState('');
  const [filterCompanyType, setfilterCompanyTypeValue] = useState('');
  const [filterCompanyQuery, setFilterCompanyQuery] = useState('');

  // Build final API URL with filters
  const finalURL = (apiGenerateFileUrl: string = String()) => {
    const queryParams = [];
    if (filterSortValue) {
      queryParams.push(`${filterSortQuery}${filterSortValue}`);
    }

    if (filterOrderValue) {
      queryParams.push(`${filterOrderQuery}${filterOrderValue}`);
    }

    if (filterDateFromValue) {
      queryParams.push(`${filterDateFromQuery}${filterDateFromValue}`);
    }

    if (filterDateUntilValue) {
      queryParams.push(`${filterDateToQuery}${filterDateUntilValue}`);
    }

    if (filterTransporterValue) {
      queryParams.push(`${filterCarrierCompanyQuery}${filterTransporterValue}`);
    }

    if (filterImporterValue) {
      queryParams.push(`${filterImporterQuery}${filterImporterValue}`);
    }

    if (filterExporterValue) {
      queryParams.push(`${filterExporterTextQuery}${filterExporterValue}`);
    }

    const queryString = queryParams.join('');

    const API_URL = apiGenerateFileUrl || API_URL_CRTS;
    return `${API_URL}${paginationParams}${queryString}`;
  };

  // Get CRT Reports
  const getCrtReports = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);
    axios
      .get<CrtReportsResponse>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Get Companies
  const getCompanyOption = (companyType?: string) => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    const currentCompanyType = companyType ?? filterCompanyType;

    axios
      .get<CompanyResponse>(
        `${API_URL_GET_CLIENTS.replace(
          '${company_type}',
          currentCompanyType,
        )}${filterCompanyQuery}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { value: item.id, label: item.attributes.business_name };
              })
            : [];

        if (currentCompanyType.includes('Importadora/Exportadora'))
          setCompanyImporterExporterOptions(results);
        else setCompanyTransporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  const dispatchGetTransportCompanies = () => {
    getCompanyOption('Transportista');
  };

  const dispatchGetImporterExporterCompanies = () => {
    getCompanyOption('Importadora/Exportadora');
  };

  useEffect(() => {
    dispatchGetTransportCompanies();
    dispatchGetImporterExporterCompanies();
  }, []);

  useEffect(() => {
    getCrtReports();
  }, [
    paginationCurrentPage,
    filterOrderValue,
    filterSortValue,
    filterDateUntilValue,
    filterDateFromValue,
    filterImporterValue,
    filterExporterValue,
    filterTransporterValue,
  ]);

  useEffect(() => {
    if (filterCompanyQuery) {
      getCompanyOption();
    }
  }, [filterCompanyQuery]);

  // Generate CRT Reports
  const generateReport = async (type: 'pdf' | 'xlxs') => {
    const token = sessionStorage.getItem('token');

    const urlToUse =
      type === 'pdf'
        ? API_URL_CRT_REPORT_GENERATION_PDF
        : API_URL_CRT_REPORT_GENERATION_XLSX;

    setIsLoading(true);

    try {
      if (!token) {
        throw new Error('No Token provided');
      }

      await axios.get(finalURL(urlToUse), {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoading(false);
      showNotification({
        message: ReportMessages.SUCCESS,
      });
      navigate(`/${transformedUserRole}${URLS.filesCrt}`);
    } catch (error) {
      log.error(error);
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      setHasError(true);
    }
  };

  const generateReportPDF = () => {
    generateReport('pdf');
  };

  const generateReportXLXS = () => {
    generateReport('xlxs');
  };

  const crtReportsFilters = {
    sort: setFilterSortValue,
    order: setFilterOrderValue,
    dateFrom: setFilterDateFromValue,
    dateUntil: setFilterDateUntilValue,
    transporter: setFilterTransporterValue,
    importer: setFilterImporterValue,
    exporter: setFilterExporterValue,
    companyType: setfilterCompanyTypeValue,
    companyQuery: setFilterCompanyQuery,
  };

  const crtReportsFilterOptions = {
    transport: companyTransporterOptions,
    importer: companyImporterExporterOptions,
    exporter: companyImporterExporterOptions,
  };

  return {
    data,
    hasError,
    isLoading,
    crtReportsFilters,
    generateReportPDF,
    generateReportXLXS,
    crtReportsFilterOptions,
  };
};

export default useCrtReports;
