import { InvoiceItems } from '../../../../../utils/types/invoice';
import { TableData } from '../types';

export const transformData = (data?: InvoiceItems[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      description: item.description,
      quantity: item.quantity,
      balance: item.balance,
      UM: item.unit,
      currency: item.currency,
      unitValue: item.unit_price,
      amount: item.amount,
    };
  });

  return transformedData;
};
