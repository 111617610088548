import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledFileUploaderContainer = styled.div`
  margin-bottom: 4rem;
  width: 100%;
  span {
    width: 100%;
  }
`;

export const StyledFileUploader = styled.div`
  align-items: center;
  background-color: ${theme.colors.lightGray};
  border-radius: 1rem;
  border: 0.1rem dashed ${theme.colors.darkGray};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 3rem;
  width: 100%;
  * {
    color: ${theme.colors.darkGray};
  }
`;
