import React from 'react';
import { Link } from 'react-router-dom';

import imgSM from '../../design/Patagonian-768.png';
import imgLG from '../../design/Patagonian-1200.png';
import { useWindowSize } from '../../utils/hooks';
import { StyledBanner } from './CustomBanner.styled';

const CustomBanner = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 768;

  const actualIMG = isDesktop ? imgLG : imgSM;
  const patagonianWebPage = 'https://patagonian.com/';
  return (
    <StyledBanner>
      <Link to={patagonianWebPage} target="_blank" rel="noopener noreferrer">
        <img src={actualIMG} alt="Patagonian" />
      </Link>
    </StyledBanner>
  );
};

export default CustomBanner;
