import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledMultiselect = styled.div`
  border-radius: 0.5rem;
  border: 0.2rem solid ${theme.colors.defaultGray};
  color: ${theme.colors.darkGray};
  color: ${theme.colors.defaultBlack};
  display: flex;
  padding: 0.8rem 1rem;
  width: 100%;

  .rw-popup-container {
    display: none !important;
  }
  &.is-open {
    .rw-popup-container {
      display: block !important;
    }
  }

  input {
    border-radius: 0.4rem;
    height: 2rem;
    width: 100%;
  }

  > div {
    width: 100%;
  }

  .rw-multiselect-taglist {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;

    /* Tags */
    > div {
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      gap: 0.5rem;
      justify-content: center;
      padding: 0.5rem;
      position: relative;

      .rw-multiselect-tag-label,
      button {
        position: relative;
        z-index: 2;
        svg {
          margin-top: 0.4rem;
        }
        color: ${theme.colors.defaultBlack};
      }
      &::before {
        content: '';
        background-color: ${theme.colors.successCta};
        border-radius: 0.4rem;
        height: 100%;
        opacity: 0.3;
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
  }
`;
