import React, { FC } from 'react';
import { FaCircleNotch } from 'react-icons/fa';

import { theme } from '../../styles';
import { StyledLoaderContainer } from './Loader.styled';

type Props = {
  isFullOpacity?: boolean;
};
const Loader: FC<Props> = ({ isFullOpacity = false }) => {
  return (
    <StyledLoaderContainer className={`${isFullOpacity ? 'full-opacity' : ''}`}>
      <FaCircleNotch size="5rem" color={theme.colors.primary} />
    </StyledLoaderContainer>
  );
};

export default Loader;
