import React, { FC } from 'react';

import { CSSProps } from '../../utils/types';
import Typography from '../Typography';
import { StyledButton, StyledButtonLink } from './CustomButton.styled';

type Props = CSSProps & {
  $isMini?: boolean;
  $theme?: 'primary' | 'secondary' | 'link' | 'danger' | 'success' | 'icon';
  disabled?: boolean;
  onPress?: () => void;
  text?: string;
  type?: 'button' | 'submit';
  children?: React.ReactNode;
  title?: string;
};

const CustomButton: FC<Props> = ({
  text,
  onPress,
  $theme,
  $isMini,
  /** Use only to render icons and not to render text */
  children,
  ...rest
}) => {
  const typographyTheme = $isMini ? 'subtext' : 'button';

  if ($theme === 'link') {
    return (
      <StyledButtonLink {...rest} onClick={onPress}>
        <Typography theme="a">{text}</Typography>
      </StyledButtonLink>
    );
  }

  return (
    <StyledButton onClick={onPress} $theme={$theme} $isMini={$isMini} {...rest}>
      {children}
      {text && <Typography theme={typographyTheme}>{text}</Typography>}
    </StyledButton>
  );
};

CustomButton.defaultProps = {
  $theme: 'primary',
  disabled: false,
  type: 'button',
};

export default CustomButton;
