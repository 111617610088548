import React, { ChangeEventHandler, FC, useEffect } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import {
  CustomButton,
  InputDatePicker,
  InputSelectList,
  InputText,
  Typography,
} from '../../../../components';
import { CUSTOMS_DESTINATIONS } from '../../../../utils';
import {
  ONLY_FLOAT_NUMBERS_VALIDATION,
  ONLY_LETTERS_VALIDATION,
  ONLY_NUMBERS_VALIDATION,
} from '../../../../utils/regexp';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import { InvoiceItems } from '../../../../utils/types/invoice';
import { AddSimInputs, MerchandiseInputs } from '../../types';
import { validateInvoiceBalance } from './utils';

type Props = {
  ctaText: string;
  handleOnFormCancel: () => void;
  handleOnSubmit: (data: AddSimInputs) => void;
  invoiceItems: InvoiceItems[];
  singleSimData?: AddSimInputs;
  onChangeQuantity: (index: number, quantity: number) => void;
};

const AddSimForm: FC<Props> = ({
  invoiceItems,
  handleOnSubmit,
  handleOnFormCancel,
  onChangeQuantity,
  ctaText,
  singleSimData,
}) => {
  const { register, handleSubmit, reset, formState, control } =
    useForm<AddSimInputs>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const { fields, append } = useFieldArray({
    name: 'merchandiseDeclaration',
    control,
  });

  const onSubmit: SubmitHandler<AddSimInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleOnSubmit(data);
  };

  const handleOnChangeQuantity: ChangeEventHandler<HTMLInputElement> =
    function (this: { index: number }, input) {
      const { index } = this;
      onChangeQuantity(index, Number(input.target.value));
    };

  useEffect(() => {
    if (invoiceItems?.length > 0 && fields.length === 0) {
      invoiceItems.forEach((item) => {
        append({
          description: item.description,
        } as MerchandiseInputs);
      });
    }
  }, [invoiceItems]);

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleSimData) {
      reset(singleSimData);
    }
  }, [singleSimData]);

  return (
    <div>
      <Typography theme="p" as="h2">
        Datos
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubForm>
          <InputText
            placeholder="Año"
            label="Año"
            $hasValue={formState.dirtyFields.year}
            $invalid={!!formState.errors.year}
            {...register('year', {
              required: 'Este Campo es requerido',
              maxLength: {
                message: 'Este campo solo acepta dos dígitos',
                value: 2,
              },
              minLength: {
                message: 'Este campo solo acepta dos dígitos',
                value: 2,
              },
              pattern: {
                value: ONLY_NUMBERS_VALIDATION,
                message: 'Este campo solo acepta números',
              },
            })}
            subTextMessage={formState.errors.year?.message}
          />
          <InputText
            placeholder="Aduana"
            label="Aduana"
            $hasValue={formState.dirtyFields.customs}
            $invalid={!!formState.errors.customs}
            {...register('customs', {
              required: 'Este Campo es requerido',
              maxLength: {
                message: 'Este campo solo acepta tres dígitos',
                value: 3,
              },
              minLength: {
                message: 'Este campo solo acepta tres dígitos',
                value: 3,
              },
              pattern: {
                value: ONLY_NUMBERS_VALIDATION,
                message: 'Este campo solo acepta números',
              },
            })}
            subTextMessage={formState.errors.customs?.message}
          />
          <InputText
            placeholder="Tipo"
            label="Tipo"
            $hasValue={formState.dirtyFields.type}
            $invalid={!!formState.errors.type}
            {...register('type', {
              required: 'Este Campo es requerido',
              maxLength: {
                message: 'Este campo acepta máximo seis caracteres',
                value: 6,
              },
            })}
            subTextMessage={formState.errors.type?.message}
          />
          <InputText
            placeholder="Número de Registro"
            label="Número de Registro"
            $hasValue={formState.dirtyFields.registerNumber}
            $invalid={!!formState.errors.registerNumber}
            {...register('registerNumber', {
              pattern: {
                value: ONLY_NUMBERS_VALIDATION,
                message: 'Este campo solo acepta números',
              },
              maxLength: {
                value: 6,
                message: 'Este campo solo acepta 6 dígitos',
              },
              minLength: {
                value: 6,
                message: 'Este campo solo acepta 6 dígitos',
              },
            })}
            subTextMessage={formState.errors.registerNumber?.message}
          />
          <InputText
            placeholder="DC"
            label="DC"
            $hasValue={formState.dirtyFields.DC}
            $invalid={!!formState.errors.DC}
            {...register('DC', {
              required: 'Este Campo es requerido',
              pattern: {
                value: ONLY_LETTERS_VALIDATION,
                message: 'El campo no debe tener números',
              },
              maxLength: {
                value: 1,
                message: `El campo solo debe tener una letra`,
              },
            })}
            subTextMessage={formState.errors.DC?.message}
          />
          <InputText
            placeholder="Despachante de Aduana"
            label="Despachante de Aduana"
            $hasValue={formState.dirtyFields.customsBroker}
            $invalid={!!formState.errors.customsBroker}
            {...register('customsBroker', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.customsBroker?.message}
          />
          <InputText
            placeholder="Agente de Transporte Aduanero"
            label="Agente de Transporte Aduanero"
            $hasValue={formState.dirtyFields.customsTransportAgent}
            $invalid={!!formState.errors.customsTransportAgent}
            {...register('customsTransportAgent', {
              required: 'Este Campo es requerido',
              minLength: {
                message: 'Este campo no puede tener menos de 3 caracteres',
                value: 3,
              },
            })}
            subTextMessage={formState.errors.customsTransportAgent?.message}
          />
          <Controller
            name="shipmentExpiration"
            control={control}
            rules={{
              required: { message: 'Este Campo es requerido', value: true },
            }}
            render={({ field }) => (
              <InputDatePicker
                $hasValue={formState.dirtyFields.shipmentExpiration}
                $invalid={!!formState.errors.shipmentExpiration}
                handleOnChange={(date) => {
                  field.onChange(date);
                }}
                label="Vencimiento Embarque"
                placeholder="Vencimiento Embarque"
                subTextMessage={formState.errors.shipmentExpiration?.message}
                value={field.value}
              />
            )}
          />
          <Controller
            name="customsDestination"
            control={control}
            rules={{
              required: 'Este Campo es requerido',
            }}
            render={({ field }) => {
              return (
                <InputSelectList
                  options={CUSTOMS_DESTINATIONS}
                  $hasValue={!!formState.dirtyFields.customsDestination}
                  $invalid={!!formState.errors.customsDestination}
                  label="Aduana Destino"
                  placeholder="Aduana Destino"
                  handleOnChange={field.onChange}
                  subTextMessage={formState.errors.customsDestination?.message}
                  value={field.value}
                />
              );
            }}
          />
        </StyledSubForm>

        <Typography theme="p" as="h2">
          Declaración de la Mercadería
        </Typography>
        {fields.map((field, index) => (
          <StyledSubForm key={field.id}>
            <InputText
              placeholder="Descripción"
              label="Descripción"
              disabled
              {...register(`merchandiseDeclaration.${index}.description`)}
            />
            <InputText
              placeholder="Cantidad"
              label="Cantidad"
              {...register(`merchandiseDeclaration.${index}.quantity`, {
                required: 'Este Campo es requerido',
                max: {
                  message: 'La cantidad no puede exceder el saldo actual',
                  value: validateInvoiceBalance({
                    currentSimData: singleSimData,
                    currentInvoiceItem: invoiceItems?.[index],
                    currentQuantity:
                      singleSimData?.merchandiseDeclaration?.[index]?.quantity,
                  }),
                },
                pattern: {
                  value: ONLY_FLOAT_NUMBERS_VALIDATION,
                  message: 'Este campo solo acepta números',
                },
              })}
              onInput={handleOnChangeQuantity.bind({ index: index })}
              $hasValue={
                formState.dirtyFields.merchandiseDeclaration?.[index]?.quantity
              }
              $invalid={
                !!formState.errors.merchandiseDeclaration?.[index]?.quantity
              }
              subTextMessage={
                formState.errors.merchandiseDeclaration?.[index]?.quantity
                  ?.message
              }
            />
            <InputText
              placeholder="Constancia de Exportación"
              label="Constancia de Exportación"
              {...register(
                `merchandiseDeclaration.${index}.exportCertificate`,
                {
                  pattern: {
                    value: ONLY_NUMBERS_VALIDATION,
                    message: 'Este campo solo acepta números',
                  },
                },
              )}
              $hasValue={
                formState.dirtyFields.merchandiseDeclaration?.[index]
                  ?.exportCertificate
              }
              $invalid={
                !!formState.errors.merchandiseDeclaration?.[index]
                  ?.exportCertificate
              }
              subTextMessage={
                formState.errors.merchandiseDeclaration?.[index]
                  ?.exportCertificate?.message
              }
            />
          </StyledSubForm>
        ))}

        <StyledFormActions>
          <CustomButton $theme="success" text={ctaText} type="submit" />
          <CustomButton
            $theme="secondary"
            onPress={handleOnFormCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </div>
  );
};

export default AddSimForm;
