import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import { StyledContainedTableContainer } from '../../../../utils/styles';
import { CrtLocationAttributes } from '../../../../utils/types/crt';
import { TableData } from './types';
import { transformData } from './utils';

type Props = {
  data?: CrtLocationAttributes[];
};

const ReceptionAndDeliveryTable: FC<Props> = ({ data }) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Lugar',
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: 'Pais',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return (
    <StyledContainedTableContainer>
      <Table columns={columns} dataSource={newData} pagination={false} />
    </StyledContainedTableContainer>
  );
};

export default ReceptionAndDeliveryTable;
