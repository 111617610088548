import 'dayjs/locale/es';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import React, { FC, useMemo } from 'react';

import Typography from '../Typography';
import {
  StyledInputDatePickerContainer,
  StyledInputLabel,
  SubTextContainer,
} from './InputDatePicker.styled';
import { dateFormat, formatYYYYMMDD, formatYYYYMMDDwithTime } from './utils';

dayjs.locale('es');

dayjs.extend(customParseFormat);
dayjs.extend(isLeapYear);
dayjs.extend(localeData);
dayjs.extend(weekday);

type Props = {
  $hasValue?: boolean;
  $invalid?: boolean;
  handleOnChange: (date: unknown | null, dateString: string) => void;
  label?: string;
  placeholder: string;
  subTextMessage?: string;
  value?: string;
  showTime?: boolean;
};

const InputDatePicker: FC<Props> = ({
  $hasValue,
  $invalid,
  handleOnChange,
  label,
  placeholder,
  subTextMessage,
  value,
  showTime = false,
}) => {
  const dateFormatWithTime = showTime ? 'DD/MM/YYYY HH:mm' : dateFormat;
  const dateFormatFromOnChange = showTime ? formatYYYYMMDDwithTime : dateFormat;

  const realValue = useMemo(
    () => (value ? dayjs(value, dateFormatFromOnChange) : undefined),
    [value],
  );

  const showTimeFormat = showTime ? { format: 'HH:mm' } : false;

  return (
    <div>
      {label && (
        <StyledInputLabel>
          <Typography theme="subtext">{label}</Typography>
        </StyledInputLabel>
      )}
      <StyledInputDatePickerContainer $hasValue={$hasValue} $invalid={$invalid}>
        <DatePicker
          clearIcon={false}
          onChange={(date, datestring) => {
            const formattedDate = showTime
              ? date?.format(formatYYYYMMDDwithTime)
              : date?.format(formatYYYYMMDD);
            handleOnChange(formattedDate, datestring);
          }}
          placeholder={placeholder}
          showTime={showTimeFormat}
          value={realValue}
          format={dateFormatWithTime}
        />
      </StyledInputDatePickerContainer>
      {subTextMessage && (
        <SubTextContainer>
          <Typography theme="subtext">{subTextMessage}</Typography>
        </SubTextContainer>
      )}
    </div>
  );
};

export default InputDatePicker;
