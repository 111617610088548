import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WAIT_TIMES } from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { fromUserRoleToUrl } from '../../../utils/roles';
import { PlainMicData } from '../../../utils/types/mics';
import { PlainSimAlt } from '../../../utils/types/sims';
import useStore from '../../../zustand';

type Props = {
  micID?: string;
  invoiceID?: string;
  simID?: string;
  crtID?: string;
};

const useMicDetail = ({ micID, invoiceID, simID, crtID }: Props) => {
  const API_URL_DELETE_MIC = '/custom-mic/{mic_id}/delete',
    API_URL_FILE_UPLOAD = '/upload',
    API_URL_FIND_MIC = '/custom-mic/{mic_id}/find?populate=*',
    API_URL_UPDATE_MIC = '/custom-mic/{mic_id}/crt/{crt_id}/update';

  const API_URL_FIND_SIM = '/custom-sim/{sim_id}/find';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const errorHandler = new ErrorHandler();

  const [data, setData] = useState<PlainMicData | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getMicDetail = (token: string | null) => {
    setHasError(false);
    setIsLoading(true);

    if (!micID) throw new Error('No MIC ID provided');

    axios
      .get<PlainMicData>(API_URL_FIND_MIC.replace('{mic_id}', micID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }

      getMicDetail(token);
    } catch (error) {
      log.error('No token provided');
      setHasError(true);
    }
  }, []);

  // Delete MIC
  const handleDeleteMic = async (micID?: string) => {
    setIsLoading(true);
    let errorMsg = '';

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
        throw new Error('No token provided');
      }

      if (!micID) {
        throw new Error('There is no Invoice ID');
      }

      if (!simID) {
        throw new Error('There is no SIM ID');
      }

      const findSimResponse = await axios.get<PlainSimAlt>(
        API_URL_FIND_SIM.replace('{sim_id}', simID),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const isSimClosed = findSimResponse.data.closed;

      if (isSimClosed) {
        errorMsg =
          'El SIM se encuentra cerrado. No se puede eliminar el registro.';
        throw new Error('SIM is closed');
      }

      await axios.delete(API_URL_DELETE_MIC.replace('{mic_id}', micID), {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({
        message: 'El registro fue borrado correctamente',
      });

      setTimeout(() => {
        navigate(
          `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}`,
        );
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: errorMsg,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const { file, onSuccess } = info;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!micID) throw new Error('No Mic ID');

      const filesData = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, filesData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      const micData = {
        data: {
          attachment: parseInt(attachmentID ?? null),
        },
      };

      const crtID = data?.crt_id?.id?.toString();
      if (!crtID) throw new Error('No CRT provided');

      // Update Mic data
      await axios.put(
        API_URL_UPDATE_MIC.replace('{mic_id}', micID).replace(
          '{crt_id}',
          crtID,
        ),
        { ...micData },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (onSuccess) {
        onSuccess(filesData);
        showNotification({
          message: 'El archivo se ha subido exitosamente',
        });
        getMicDetail(token);
      }
    } catch (error) {
      const mappedError = errorHandler.mapErrorMessage(error);
      showErrorNotification({
        message: mappedError,
      });
      log.error(error);
    }
  };

  return {
    data,
    hasError,
    isLoading,
    handleDeleteMic,
    handleUploadFile,
  };
};

export default useMicDetail;
