import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  CustomButton,
  InputSelect,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import { fromUserRoleToUrl, isSuperAdmin, URLS } from '../../../../utils';
import { EMAIL_VALIDATION } from '../../../../utils/regexp';
import {
  StyledErrorMessage,
  StyledFormActions,
  StyledSubForm,
} from '../../../../utils/styles';
import useStore from '../../../../zustand';
import useAddUsers from '../../hooks/useAddUser';
import { CompanyData, Inputs } from '../../types';
import { getRoleOptions } from './utils';

const AddUserForm = () => {
  const [useRole] = useStore((store) => [store.userRole]);

  const { register, handleSubmit, formState, control, watch } = useForm<Inputs>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    },
  );

  const selectedCompany = watch('company_id');

  const [selectedOption, setSelectedOption] = useState<CompanyData | undefined>(
    undefined,
  );

  const navigate = useNavigate();

  const {
    companiesArray,
    handleAddUser,
    customError,
    isLoading,
    altCompaniesInputOptions,
    setCompaniesArrayQuery,
  } = useAddUsers();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    document.documentElement.scrollTo(0, 0);

    const userData = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      role: data.role,
      uid: uuidv4(),
    };
    handleAddUser(userData, selectedCompany?.value);
  };

  const handleOnCancel = React.useCallback(() => {
    const navigateTo = fromUserRoleToUrl(useRole);
    navigate(`/${navigateTo}${URLS.users}`);
  }, []);

  useEffect(() => {
    setSelectedOption(
      companiesArray.find((item) => selectedCompany?.value === item.id),
    );
  }, [selectedCompany]);

  const roleOptions = getRoleOptions(useRole, selectedOption);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA = !formState.isDirty || isLoading || errorsArray.length > 0;

  return (
    <>
      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        Nuevo Usuario
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {customError && (
          <StyledErrorMessage>
            {customError.map((item) => (
              <div key={item.replaceAll(' ', '')}>
                <Typography theme="p" $marginBottom="1.5rem">
                  {item}
                </Typography>
              </div>
            ))}
          </StyledErrorMessage>
        )}

        <StyledSubForm>
          <InputText
            placeholder="Nombre"
            label="Nombre"
            $hasValue={formState.dirtyFields.name}
            $invalid={!!formState.errors.name}
            {...register('name', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.name?.message}
          />
          <InputText
            placeholder="Apellido"
            label="Apellido"
            $hasValue={formState.dirtyFields.lastName}
            $invalid={!!formState.errors.lastName}
            {...register('lastName', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.lastName?.message}
          />
          <InputText
            placeholder="Email"
            label="Email"
            $hasValue={formState.dirtyFields.email}
            $invalid={!!formState.errors.email}
            {...register('email', {
              required: 'Este Campo es requerido',
              pattern: {
                value: EMAIL_VALIDATION,
                message: 'El email no es válido',
              },
            })}
            subTextMessage={formState.errors.email?.message}
          />
          {isSuperAdmin(useRole) && (
            <Controller
              name="company_id"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => {
                return (
                  <InputSelectList
                    options={altCompaniesInputOptions}
                    $hasValue={!!formState.dirtyFields.company_id}
                    $invalid={!!formState.errors.company_id}
                    label="Empresa"
                    placeholder="Empresa"
                    handleOnChange={field.onChange}
                    handleOnInputChange={setCompaniesArrayQuery}
                    subTextMessage={formState.errors.company_id?.message}
                    value={field.value}
                  />
                );
              }}
            />
          )}

          <InputSelect
            placeholder="Rol"
            label="Rol"
            options={roleOptions}
            $hasValue={formState.dirtyFields.role}
            $invalid={!!formState.errors.role}
            {...register('role', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.role?.message}
            disabled={!selectedOption && isSuperAdmin(useRole)}
          />
        </StyledSubForm>

        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text="Crear"
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="submit"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default AddUserForm;
