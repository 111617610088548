import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, URLS } from '../../utils';
import { PaginationData } from '../../utils/types';
import useStore from '../../zustand';
import UsersTable from './components/UsersTable';
import useUsersTable from './hooks/useUsersTable';

const PageContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 8,
    current: currentPage,
    onChange: setCurrentPage,
  };

  const {
    data,
    handleSwitchUserActive,
    isLoading,
    onInputFilterChange,
    onSelectFilterChange,
    totalResults,
    handleTableChange,
  } = useUsersTable({ paginationData });

  const navigate = useNavigate();
  const [useRole] = useStore((store) => [store.userRole]);

  const handleOnClickNewUser = React.useCallback(() => {
    const navigateTo = fromUserRoleToUrl(useRole);
    navigate(`/${navigateTo}${URLS.addUser}`);
  }, []);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.users,
      name: 'Usuarios',
      withUserRole: true,
    },
  ];

  return (
    <BoxContainer $useMarginTop $useMarginBottom>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Usuarios
      </Typography>
      <CustomButton
        text="Nuevo Usuario"
        $marginBottom="6rem"
        $isMini
        onPress={handleOnClickNewUser}
      />
      <UsersTable
        data={data}
        isLoadingTableData={isLoading}
        paginationData={{
          ...paginationData,
          total: totalResults,
        }}
        onSelectFilterChange={onSelectFilterChange}
        onInputFilterChange={onInputFilterChange}
        onSetUserActive={handleSwitchUserActive}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
