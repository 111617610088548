import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledLoaderContainer = styled.div`
  &::before {
    content: '';
    background-color: ${theme.colors.defaultWhite};
    height: 100%;
    opacity: 0.3;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: ${theme.zIndex.loader - 1};
  }
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme.zIndex.loader};

  > svg {
    animation: rotate 1.35s ease infinite;
    z-index: ${theme.zIndex.loader + 1};
  }

  &.full-opacity {
    &::before {
      opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
