import React, { FC, ReactNode } from 'react';

import { StyledBoxContainer } from './BoxContainer.syled';

type Props = {
  $isFullWidth?: boolean;
  $useMarginBottom?: boolean;
  $useMarginTop?: boolean;
  children: ReactNode;
};

const BoxContainer: FC<Props> = ({ children, ...rest }) => {
  return <StyledBoxContainer {...rest}>{children}</StyledBoxContainer>;
};

BoxContainer.defaultProps = {
  $isFullWidth: false,
  $useMarginBottom: false,
  $useMarginTop: false,
};

export default BoxContainer;
