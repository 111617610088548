import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  isReceptionist,
  NotificationMessages,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { Attachment } from '../../../utils/types';
import { CrtPlainGenericData } from '../../../utils/types/crt';
import { PlainMicData } from '../../../utils/types/mics';
import useStore from '../../../zustand';
import { buildCrtRelatedMicsURL } from '../../CrtRelatedMics/utils';
import { AddMicInputs } from '../types';

type Props = {
  crtID?: string;
  micID?: string;
  invoiceID?: string;
  simID?: string;
};

const useAddMicForm = ({ crtID, micID, invoiceID, simID }: Props) => {
  const API_URL_CREATE_MIC = '/custom-mic/crt/{crt_id}/create',
    API_URL_CRT = '/custom-crt/{crt_id}/find',
    API_URL_FILE_UPLOAD = '/upload',
    API_URL_FIND_MIC = '/custom-mic/{mic_id}/find?populate=*',
    API_URL_UPDATE_MIC = '/custom-mic/{mic_id}/crt/{crt_id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const errorHandler = new ErrorHandler();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [singleMicData, setSingleMicData] = useState<AddMicInputs | null>(null);
  const [crtData, setCrtData] = useState<CrtPlainGenericData | null>(null);
  const [customError, setCustomError] = useState<string[]>([]);
  const [attachment, setAttachment] = useState<number | null>(null);
  const [attatchmentError, setAttatchmentError] = useState<string>('');
  const [attachmentData, setAttachmentData] = useState<Attachment | undefined>(
    undefined,
  );

  // Get CRT data
  const getCRTData = async (token: string | null) => {
    setHasError(false);
    if (!crtID) throw new Error('No Crt ID provided');
    axios
      .get<CrtPlainGenericData>(API_URL_CRT.replace('{crt_id}', crtID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setCrtData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }

      setIsLoading(true);
      getCRTData(token);
    } catch (error) {
      log.error(error);
      setHasError(true);
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
    }
  }, []);

  // Create / Update MIC
  const handleAddMic = async (data: AddMicInputs) => {
    setIsLoading(true);

    const hasInsufficientBalance = crtData?.balance_details?.every(
      ({ joined_balance }) => !joined_balance,
    );

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      if (!attachment) {
        setAttatchmentError('Debe subir un archivo');
      }

      if (hasInsufficientBalance) {
        throw new Error('The joined balance is not enough');
      }

      const issueDate = new Date(data.issueDate);

      const receptionDate = isReceptionist(useRole)
        ? crtData?.reception?.date
        : undefined;

      // Create MIC
      if (!micID) {
        const micData = {
          data: {
            number: data.micNumber,
            shipment_number: data.shipmentNumber,
            date_of_issue: issueDate.toISOString(),
            seal_number: data.sealNumber,
            vehicle_owner: data.vehicleOwner.value,
            truck: data.truckLicensePlate.value,
            semitrailer: data.semitrailerLicensePlate.value,
            truck_driver: data.truckDriverID.value,
            attachment,
            mic_items: data.merchandiseDeclaration.map((item, index) => ({
              quantity: item.quantity,
              unit: crtData?.crt_items?.[index]?.unit,
              merchandise_id: crtData?.crt_items?.[index]?.merchandise_id?.id,
            })),
            // From CRT
            reception_date: receptionDate,
            country: crtData?.delivery.country,
            place: crtData?.delivery.place,
            category: crtData?.delivery.category,
            company_id: crtData?.invoice_company_id,
          },
        };

        await axios.post(
          API_URL_CREATE_MIC.replace('{crt_id}', crtID || String()),
          { ...micData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        setIsLoading(false);

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}${buildCrtRelatedMicsURL(
              invoiceID,
              simID,
              crtID,
            )}`,
          );
        }, WAIT_TIMES.md);
      } else {
        // Update MIC
        const micData = {
          data: {
            number: data.micNumber,
            shipment_number: data.shipmentNumber,
            date_of_issue: issueDate.toISOString(),
            seal_number: data.sealNumber,
            vehicle_owner: data.vehicleOwner.value,
            truck: data.truckLicensePlate.value,
            semitrailer: data.semitrailerLicensePlate.value,
            truck_driver: data.truckDriverID.value,
            attachment: data.attachment || attachment,
            mic_items: data.merchandiseDeclaration.map((item, index) => ({
              id: item.id,
              quantity: item.quantity,
              unit: crtData?.crt_items?.[index]?.unit,
              merchandise_id: crtData?.crt_items?.[index]?.merchandise_id?.id,
            })),
            // From CRT
            reception_date: receptionDate,
            country: crtData?.delivery.country,
            place: crtData?.delivery.place,
            category: crtData?.delivery.category,
            company_id: crtData?.invoice_company_id,
          },
        };

        await axios.put(
          API_URL_UPDATE_MIC.replace('{mic_id}', micID).replace(
            '{crt_id}',
            crtID || String(),
          ),
          { ...micData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}/crts/${crtID}/mics/${micID}`,
          );
        }, WAIT_TIMES.md);
      }
    } catch (error) {
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError([...mappedError, attatchmentError]);
      setIsLoading(false);
    }
  };

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const { file, onSuccess } = info;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      const filesData = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, filesData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      if (onSuccess) {
        onSuccess(filesData);
      }

      if (attachmentID) setAttachment(parseInt(attachmentID));
    } catch (error) {
      log.error(error);
      showErrorNotification({
        message: 'Ha ocurrido un error al subir el archivo.',
      });
    }
  };

  // Get Mic Data
  const getMicData = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    if (!micID) throw new Error('No MIC id provided');

    axios
      .get<PlainMicData>(`${API_URL_FIND_MIC.replace('{mic_id}', micID)}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        setAttachmentData(result.attachment);

        const issueDate = result?.date_of_issue.toString();

        const transformedData = {
          micNumber: result?.number,
          shipmentNumber: result?.shipment_number,
          issueDate: issueDate,
          sealNumber: result?.seal_number,
          vehicleOwner: {
            label: result?.vehicle_owner?.business_name,
            value: result?.vehicle_owner?.id,
          },
          truckLicensePlate: {
            label: result?.truck?.license_plate,
            value: result?.truck?.id,
          },
          semitrailerLicensePlate: {
            label: result?.semitrailer?.license_plate,
            value: result?.semitrailer?.id,
          },
          truckDriverID: {
            label: result?.truck_driver?.ic,
            value: result?.truck_driver?.id,
          },
          attachment: result?.attachment?.id?.toString(),
          merchandiseDeclaration: result?.mic_items.map((item) => {
            return {
              id: item.id,
              description: item?.merchandise_id?.description,
              quantity: item?.quantity,
              unit: item?.unit,
            };
          }),
        };

        setSingleMicData(transformedData);
      })
      .catch((error) => {
        log.error(error);
        showErrorNotification({
          message: NotificationMessages.GENERIC_ERROR,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (micID) getMicData();
  }, []);

  return {
    crtData,
    handleAddMic,
    handleUploadFile,
    hasError,
    isLoading,
    customError,
    attachment,
    singleMicData,
    attachmentData,
  };
};

export default useAddMicForm;
