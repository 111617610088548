import React from 'react';

import { AddTruckPage, TrucksPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const trucksRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/camiones',
      component: <TrucksPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/camiones/nuevo',
      component: <AddTruckPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/camiones/:truck_id/editar',
      component: <AddTruckPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/camiones',
      component: <TrucksPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/camiones/nuevo',
      component: <AddTruckPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/camiones/:truck_id/editar',
      component: <AddTruckPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transpoter
    {
      path: '/transportista/camiones/nuevo',
      component: <AddTruckPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/camiones/:truck_id/editar',
      component: <AddTruckPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/camiones',
      component: <TrucksPage />,
      isAvailable: isTransporter(useRole),
    },
    // Dispatcher
    {
      path: '/despachante/camiones/nuevo',
      component: <AddTruckPage />,
      isAvailable: isDispatcher(useRole),
    },
    // Clients
    {
      path: '/cliente/camiones',
      component: <TrucksPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/camiones/nuevo',
      component: <AddTruckPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/camiones/:truck_id/editar',
      component: <AddTruckPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
