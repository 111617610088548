import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder, NotificationMessages } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { PaginationData } from '../../../utils/types';
import { buildOrQueryFilter } from '../components/TrucksDriverTable/utils';
import { TableData, TruckDriver, TruckDriverData } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useTrucksDriverTable = ({ paginationData }: Props) => {
  const API_URL_TRUCK_DRIVER = '/truck-drivers?populate=*';
  const API_URL_DELETE_TRUCK_DRIVER = '/truck-drivers';

  const pagination = `&pagination[page]=${paginationData.current}&pagination[pageSize]=${paginationData.pageSize}`;

  const axios = useAxios();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<TruckDriver[] | undefined>(undefined);
  const [totalResults, setTotalResults] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');
  // Filter controls
  const [inputQueryFilterValue, setInputQueryFilterValue] = useState('');

  const onInputFilterChange = (value: string) => {
    setInputQueryFilterValue(value);
  };

  const filterValue = buildOrQueryFilter(
    'first_name',
    'last_name',
    inputQueryFilterValue,
  );
  const finalURL = () => {
    return `${API_URL_TRUCK_DRIVER}${pagination}${filterValue}${sortQuery}`;
  };

  const getTruckDrivers = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No token provided');

    const response = await axios.get<TruckDriverData>(finalURL(), {
      headers: { Authorization: `Bearer ${token}` },
    });

    setIsLoading(false);
    setData(response?.data?.data);
    setTotalResults(response.data.meta.pagination.total);
  };

  useEffect(() => {
    getTruckDrivers().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [paginationData.current, inputQueryFilterValue, sortQuery]);

  // Reset to first page when filtering
  useEffect(() => {
    if (paginationData.onChange) paginationData.onChange(1);
  }, [inputQueryFilterValue]);

  // Delete Truck
  const handleDeleteTruckDriver = async (truckDriverID: string) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      await axios.delete(`${API_URL_DELETE_TRUCK_DRIVER}/${truckDriverID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({ message: 'El registro fue borrado correctamente' });
      getTruckDrivers();
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({ message: NotificationMessages.GENERIC_ERROR });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);

    const columnKey =
      sorter.columnKey === 'full_name'
        ? `sort[0]=first_name&sort[1]=last_name`
        : sorter.columnKey;

    setSortQuery(order ? `&sort=${columnKey}:${order}` : '');
  };

  return {
    data: data,
    totalResults,
    hasError,
    isLoading,
    onInputFilterChange,
    handleDeleteTruckDriver,
    handleTableChange,
  };
};

export default useTrucksDriverTable;
