import { styled } from 'styled-components';

export const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 100vh;
  > div {
    align-items: center;
    text-align: center;
  }
`;
