import React, { FC, PropsWithChildren, useState } from 'react';

import { Modal, Typography } from '../../../../components';
import useGetCompanyById from '../../../../utils/hooks/useGetCompanyById';
import { PlainCompanyData } from '../../../../utils/types/company';
import {
  StyledContenChildren,
  StyledModalHeader,
  StyledModalHeaderContent,
  StyledModalIcon,
  StyledModalInfoContent,
} from './CompanyInfoModal.styled';

type Props = {
  companyId?: number;
  titleCompanyName: string;
  agentCompanyInfo?: PlainCompanyData;
  $hasLink?: boolean;
  displayFullCompanyInfo?: boolean;
};

enum CompanyInfoModalTitle {
  City = 'Ciudad',
  Country = 'País',
  Address = 'Dirección',
  TaxId = 'ID Tributario',
  Cnrt = 'CNRT',
  InternationalLicense = 'Licencia Internacional',
}

const CompanyInfoModal: FC<PropsWithChildren<Props>> = ({
  children,
  companyId,
  titleCompanyName,
  agentCompanyInfo,
  $hasLink = true,
  displayFullCompanyInfo = false,
}) => {
  const [showModal, setShowModal] = useState(false);

  const { getCompanyById, companyInfo } = useGetCompanyById({ companyId });
  const currentCompanyInfo = agentCompanyInfo ?? companyInfo?.attributes;

  const companyDefaultInfoModalDetails = [
    {
      title: CompanyInfoModalTitle.TaxId,
      content: currentCompanyInfo?.tax_id,
    },
    {
      title: CompanyInfoModalTitle.Address,
      content: currentCompanyInfo?.address,
    },
    {
      title: CompanyInfoModalTitle.City,
      content: currentCompanyInfo?.city,
    },
    {
      title: CompanyInfoModalTitle.Country,
      content: currentCompanyInfo?.country,
    },
  ];

  const companyFullInfoModalDetails = [
    ...companyDefaultInfoModalDetails,
    {
      title: CompanyInfoModalTitle.Cnrt,
      content: currentCompanyInfo?.cnrt,
    },
    {
      title: CompanyInfoModalTitle.InternationalLicense,
      content: currentCompanyInfo?.international_dr,
    },
  ];

  const companyInfoModalDetails = displayFullCompanyInfo
    ? companyFullInfoModalDetails
    : companyDefaultInfoModalDetails;

  const closeCompanyInfoModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const showCompanyInfoModal = React.useCallback(() => {
    dispatchGetCompanyById();
    setShowModal(true);
  }, []);

  const dispatchGetCompanyById = () => {
    if (companyId) return getCompanyById();
  };

  return (
    <>
      <StyledContenChildren $hasLink={$hasLink} onClick={showCompanyInfoModal}>
        {children}
      </StyledContenChildren>
      <Modal showModal={showModal} handleClose={closeCompanyInfoModal}>
        <StyledModalHeader>
          <Typography theme="h4" as="h2">
            <StyledModalHeaderContent>
              <StyledModalIcon />
              {titleCompanyName}
            </StyledModalHeaderContent>
          </Typography>
        </StyledModalHeader>
        <div>
          {companyInfoModalDetails.map((details) => (
            <StyledModalInfoContent key={details.title}>
              <Typography theme="p" as="h4">
                {`${details.title}:`}
              </Typography>
              <Typography theme="p">{details.content}</Typography>
            </StyledModalInfoContent>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default CompanyInfoModal;
