import React from 'react';

import { BoxContainer } from '../../components';
import TruckDriverForm from './components/TruckDriverForm';

const PageContent = () => {
  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <TruckDriverForm />
    </BoxContainer>
  );
};

export default PageContent;
