import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledOverlay = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${theme.zIndex.modal};
  &::before {
    content: '';
    background-color: ${theme.colors.defaultBlack};
    height: 100%;
    opacity: 0.55;
    position: absolute;
    width: 100%;
    z-index: ${theme.zIndex.modal - 1};
  }
`;

export const StyledModalCard = styled.div`
  background-color: ${theme.colors.defaultWhite};
  border-radius: 1rem;
  display: grid;
  max-width: 55rem;
  min-height: 30rem;
  min-width: 30rem;
  opacity: 1;
  padding: 1.5rem;
  position: relative;
  width: 90%;
  z-index: ${theme.zIndex.modal};
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    max-width: 90rem;
    width: 70%;
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const StyledChildContainer = styled.div`
  margin-top: 1.5rem;
`;
