import styled from 'styled-components';

export const StyledCrtSearcher = styled.div`
  margin-bottom: 4rem;
`;

export const StyledHeaderBalanceInfo = styled.div`
  margin-bottom: 4rem;
`;

export const StyledBalanceInfo = styled.li`
  align-items: center;
  display: flex;
  gap: 1rem;
`;
