import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import useStore from '../../../zustand';
import { AdminDashboardData } from '../types';

const useAdminDashboard = () => {
  const API_URL_COMPANIES = '/companies/{companyID}/statistics';

  const axios = useAxios();

  const [userCompanyID] = useStore((state) => [state.userCompanyID]);

  const [data, setData] = useState<AdminDashboardData | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getStats = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
        setHasError(true);
      }

      if (!userCompanyID) throw new Error('No Company ID provided');

      const response = await axios.get<AdminDashboardData>(
        API_URL_COMPANIES.replace('{companyID}', userCompanyID.toString()),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return {
    data: data,
    hasError,
    isLoading,
  };
};

export default useAdminDashboard;
