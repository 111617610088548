import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { InputOption } from '../../utils/types';
import Typography from '../Typography';
import {
  StyledDropdownIcon,
  StyledInputLabel,
  StyledSelectContainer,
  StyledSelectInput,
  SubTextContainer,
} from './InputSelect.styled';

type Props = {
  $hasValue?: boolean;
  $invalid?: boolean;
  label?: string;
  options: InputOption[];
  placeholder: string;
  subTextMessage?: string;
} & InputHTMLAttributes<HTMLSelectElement>;

const InputSelect: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  { subTextMessage, options, placeholder, label, value, ...rest },
  ref,
) => {
  return (
    <div>
      {label && (
        <StyledInputLabel>
          <Typography theme="subtext">{label}</Typography>
        </StyledInputLabel>
      )}
      <StyledSelectContainer>
        <StyledSelectInput value={value} ref={ref} defaultValue="" {...rest}>
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((item) => {
            return (
              <option key={`${item.value}`} value={item.value}>
                {item.text}
              </option>
            );
          })}
        </StyledSelectInput>
        <StyledDropdownIcon>
          <FaChevronDown />
        </StyledDropdownIcon>
      </StyledSelectContainer>
      {subTextMessage && (
        <SubTextContainer>
          <Typography theme="subtext">{subTextMessage}</Typography>
        </SubTextContainer>
      )}
    </div>
  );
};

export default React.forwardRef(InputSelect);
