import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledSubForm = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin: 3rem auto 5rem;

  > div > div {
    max-width: unset;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 4rem;
`;

export const StyledFormActions = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
`;
