import React, { useState } from 'react';

import { BoxContainer, Breadcrumb, Typography } from '../../components';
import { URLS } from '../../utils';
import CardsGroup from './components/CardsGroup';
import useGetFilterOptions from './hooks/useGetFilterOptions';
import useMics from './hooks/useMics';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listMics,
      name: 'MICs',
      withUserRole: true,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    paginationData,
    isLoading,
    tableFilters,
    generateReportPDF,
    generateReportXLXS,
  } = useMics({
    paginationCurrentPage: currentPage,
  });

  const { filterOptions, filterQueries } = useGetFilterOptions();

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        MICs
      </Typography>
      <CardsGroup
        data={data}
        paginationData={paginationData}
        onPaginationChange={setCurrentPage}
        currentPage={currentPage}
        isLoading={isLoading}
        onFilter={tableFilters}
        onFilterQuery={filterQueries}
        onFilterOptions={filterOptions}
        onGenerateReportPDF={generateReportPDF}
        onGenerateReportXLXS={generateReportXLXS}
      />
    </BoxContainer>
  );
};

export default PageContent;
