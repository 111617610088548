import { AltPlainDriverData } from '../../../../../utils/types/driver';
import { TableData } from '../../../types';

export const transformData = (data?: AltPlainDriverData[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      transport: item.business_name,
      license: item.international_dr,
      paut: item.cnrt,
      firstName: item.first_name,
      lastName: item.last_name,
      ic: `${item.ic_type} ${item.ic}`,
    };
  });

  return transformedData;
};

export const sortByOptions = [
  { value: 'name', text: 'Nombre' },
  { value: 'last_name', text: 'Apellido' },
  { value: 'business_name', text: 'Transporte' },
];
