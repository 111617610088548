import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';

import { InputSelect, InputText } from '../../../../components';
import { StyledTableContainer } from '../../../../utils/styles';
import { PaginationData } from '../../../../utils/types';
import useStore from '../../../../zustand';
import { roleOptionsMap } from '../../../AddUser/components/AddUserForm/utils';
import { Data, TableData } from '../../types';
import { StyledFiltersContainer } from './UsersTable.styled';
import { transformData } from './utils';

type Props = {
  data?: Data[] | null;
  onInputFilterChange: (value: string) => void;
  onSelectFilterChange: (value: string) => void;
  onSetUserActive: (isActive: boolean, userID: string) => void;
  isLoadingTableData: boolean;
  paginationData: PaginationData;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const UsersTable: FC<Props> = ({
  data,
  paginationData,
  isLoadingTableData,
  onInputFilterChange,
  onSelectFilterChange,
  onSetUserActive,
  onTableChange,
}) => {
  const newData = transformData(data);
  const [userCompanyType] = useStore((state) => [state.userCompanyType]);
  const loggedUserCompanyType = userCompanyType
    ? userCompanyType.toString()
    : 'Despachante';
  const handleSearchByName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputFilterChange(event.target.value);
    },
    [],
  );

  const handleSelectChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onSelectFilterChange(event.target.value);
    },
    [],
  );

  const handleOnSetUserActive = (value: boolean, userID: string) => {
    onSetUserActive(value, userID);
  };

  const columns: ColumnsType<TableData> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
    },
    {
      title: 'Última Sesión',
      dataIndex: 'last_sign_in_at',
      key: 'last_sign_in_at',
      sorter: true,
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
    },
    {
      title: 'Activo',
      key: 'operation_active',
      fixed: 'right',
      width: 100,
      render: (item: TableData) => (
        <div>
          <Switch
            checked={!item.active}
            onChange={(value) => {
              handleOnSetUserActive(value, item.key.toString());
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <StyledFiltersContainer>
        <InputSelect
          placeholder="Rol de Usuario"
          onChange={handleSelectChange}
          options={roleOptionsMap[loggedUserCompanyType]}
        />
        <InputText placeholder="Nombre / Email" onChange={handleSearchByName} />
      </StyledFiltersContainer>
      <Table
        loading={isLoadingTableData}
        columns={columns}
        dataSource={newData}
        pagination={{
          showSizeChanger: false,
          position: ['bottomCenter'],
          ...paginationData,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default UsersTable;
