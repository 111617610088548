import { useNavigate } from 'react-router-dom';

import useStore from '../../zustand';
import { isDispatcher, URLS } from '..';
import ErrorHandler from '../ErrorHandler';

/**
 * It recieves a JWT and returns it's data
 * @param token Session storage token
 * @returns An object with exp property to get expiration date in miliseconds
 */
const parseToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const useRedirectOnTokenExpired = () => {
  const [userRole] = useStore((state) => [state.userRole]);

  const navigate = useNavigate();

  const errorHandler = new ErrorHandler();

  const handleOnTokenExpired = (error: unknown) => {
    if (
      errorHandler.readErrorCode(error) === 403 ||
      errorHandler.readErrorCode(error) === 401
    ) {
      let redirectUrl = '';
      const token = sessionStorage.getItem('token');
      if (token) {
        const result = parseToken(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        // If token expired or User Disabled
        if (
          currentTimestamp > result.exp ||
          errorHandler.readErrorCode(error) === 401
        ) {
          // Expired Token -> Force Log Out
          redirectUrl = URLS.landing;
          sessionStorage.clear();
        } else {
          // Not Expired Token -> Return to Dashboard
          redirectUrl = URLS.dashboard;
        }
      } else {
        redirectUrl = URLS.landing;
        sessionStorage.clear();
      }

      if (isDispatcher(userRole)) redirectUrl = URLS.dispatcherLanding;
      navigate(redirectUrl);
    }
  };

  return { handleOnTokenExpired };
};

export default useRedirectOnTokenExpired;
