import React from 'react';

import { BoxContainer, Typography } from '../../../../components';
import useStore from '../../../../zustand';

const DashboardHeader = () => {
  const [userRole] = useStore((state) => [state.userRole]);

  return (
    <BoxContainer $useMarginTop>
      <Typography theme="h1" as="h2" $marginBottom="1rem" $textAlign="left">
        Dashboard
      </Typography>
      <Typography theme="h3" as="h3" $marginBottom="4rem" $textAlign="left">
        {userRole}
      </Typography>
    </BoxContainer>
  );
};

export default DashboardHeader;
