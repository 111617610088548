import React, { useCallback, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { BoxContainer, InputSelectList, Typography } from '../../components';
import { theme } from '../../styles';
import { StyledErrorMessage } from '../../utils/styles';
import AddMicForm from '../AddMic/components/AddMicForm';
import { calculateMicBalance, CurrentMicBalance } from "../AddMic/utils";
import {
  StyledBalanceInfo,
  StyledCrtSearcher,
  StyledHeaderBalanceInfo,
} from './DispatcherLanding.styled';
import useDispatcherLanding from './hooks/useDispatcherLanding';

const renderIcon = (value: number) => {
  return value > 0 ? (
    <FaCheck size="1.5rem" color={theme.colors.success} />
  ) : (
    <FaTimes size="1.5rem" color={theme.colors.error} />
  );
};

const PageContent = () => {
  const {
    setCrtSelectQuery,
    setCrtSelectedOption,
    crtSelectOptions,
    crtSelectedOption,
    crtBalanceDetails,
    handleUploadFile,
    handleAddMic,
    customError,
  } = useDispatcherLanding();

  const onCancel = useCallback(() => {
    setCrtSelectedOption(null);
  }, []);

  const [newMicBalance, setNewMicBalance] = useState<CurrentMicBalance[]>([]);

  const handleOnChangeQuantity = React.useCallback(
    (index: number, quantity: number) => {
      const isAbleToUpdateBalance = newMicBalance.some(
        (balance) => balance.index === index,
      );
      if (isAbleToUpdateBalance) {
        const updatedCurrentBalance = newMicBalance.map((balance) => {
          return balance.index === index ? { ...balance, quantity } : balance;
        });
        setNewMicBalance(updatedCurrentBalance);
      } else {
        setNewMicBalance([...newMicBalance, { index, quantity }]);
      }
    },
    [newMicBalance],
  );

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        Nuevo MIC
      </Typography>
      <StyledCrtSearcher>
        <Typography theme="h5" as="h2" $marginBottom="3rem">
          CRT Asociado
        </Typography>
        <InputSelectList
          placeholder="CRT Asociado"
          options={crtSelectOptions}
          handleOnChange={setCrtSelectedOption}
          handleOnInputChange={setCrtSelectQuery}
        />
      </StyledCrtSearcher>

      {customError.length > 0 && (
        <StyledErrorMessage>
          {customError.map((item: string) => (
            <div key={item.replaceAll(' ', '')}>
              <Typography theme="p" $marginBottom="1.5rem">
                {item}
              </Typography>
            </div>
          ))}
        </StyledErrorMessage>
      )}

      {crtSelectedOption && (
        <div>
          <StyledHeaderBalanceInfo>
            <Typography theme="p" as="h2" $marginBottom="2rem">
              Saldos Disponibles
            </Typography>
            <ul>
              {crtBalanceDetails.map((item, index) => (
                <StyledBalanceInfo
                  key={`${item.merchandise.id}${item.joined_balance}`}
                >
                  {renderIcon(item.joined_balance)}
                  <Typography theme="p">
                    {item.merchandise.description}:
                  </Typography>
                  <Typography theme="p">
                    {calculateMicBalance({
                      index,
                      currentMicData: null,
                      currentMicQuantities: newMicBalance,
                      currentCrtData: crtBalanceDetails,
                    })}
                  </Typography>
                  <Typography theme="p">{item.unit}</Typography>
                </StyledBalanceInfo>
              ))}
            </ul>
          </StyledHeaderBalanceInfo>

          <AddMicForm
            onFileUpload={handleUploadFile}
            onSubmitForm={handleAddMic}
            crtBalanceDetails={crtBalanceDetails}
            singleMicData={null}
            onCancel={onCancel}
            onChangeQuantity={handleOnChangeQuantity}
          />
        </div>
      )}
    </BoxContainer>
  );
};

export default PageContent;
