import React from 'react';

import {
  AddInvoicePage,
  InvoiceDetailPage,
  InvoicesPage,
} from '../../../pages';
import {
  isAdmin,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const invoiceRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/facturas',
      component: <InvoicesPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id',
      component: <InvoiceDetailPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/nuevo',
      component: <AddInvoicePage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/editar',
      component: <AddInvoicePage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/facturas',
      component: <InvoicesPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id',
      component: <InvoiceDetailPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/nuevo',
      component: <AddInvoicePage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/editar',
      component: <AddInvoicePage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/facturas/nuevo',
      component: <AddInvoicePage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id',
      component: <InvoiceDetailPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/editar',
      component: <AddInvoicePage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas',
      component: <InvoicesPage />,
      isAvailable: isTransporter(useRole),
    },
    // Clients
    {
      path: '/cliente/facturas',
      component: <InvoicesPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id',
      component: <InvoiceDetailPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/nuevo',
      component: <AddInvoicePage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/editar',
      component: <AddInvoicePage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
