import React from 'react';

import { UpdatePoliciesPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isReceptionist,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const policyRoutes = (useRole: string): Routes[] => {
  return [
    {
      path: '/admin/polizas',
      component: <UpdatePoliciesPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/cliente/polizas',
      component: <UpdatePoliciesPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/transportista/polizas',
      component: <UpdatePoliciesPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/recepcionista/polizas',
      component: <UpdatePoliciesPage />,
      isAvailable: isReceptionist(useRole),
    },
    {
      path: '/despachante/polizas',
      component: <UpdatePoliciesPage />,
      isAvailable: isDispatcher(useRole),
    },
  ];
};
