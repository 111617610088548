import React, { FC } from 'react';

import { CompanyModalOpener, Typography } from '../../../../components';
import { CrtPlainGenericData } from '../../../../utils/types/crt';
import { StyledCompaniesListContainer } from './ImpExpCompanies.styled';

type Props = {
  data: CrtPlainGenericData | null;
};
const ImpExpCompanies: FC<Props> = ({ data }) => {
  if (!data) return null;

  return (
    <StyledCompaniesListContainer>
      <li>
        <Typography theme="p" as="h2">
          Importador:&nbsp;
        </Typography>
        <CompanyModalOpener data={data.importer_id} />
      </li>
      <li>
        <Typography theme="p" as="h2">
          Exportador:&nbsp;
        </Typography>
        <CompanyModalOpener data={data.exporter_id} />
      </li>
    </StyledCompaniesListContainer>
  );
};

export default ImpExpCompanies;
