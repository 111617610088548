import React, { FC } from 'react';

import { Typography } from '../../../../../../components';
import { Company } from '../../../../../../utils/types/company';
import { Semitrailer } from '../../../../../../utils/types/semitrailer';
import { Truck } from '../../../../../../utils/types/truck';
import { TruckDriver } from '../../../../../TruckDrivers/types';
import {
  StyledCardHeader,
  StyledDataList,
  StyledSelectedData,
  StyledSelectedDataCard,
  StyledSelectedDataMainContainer,
} from './SelectedData.styled';

type Props = {
  data: {
    vehicleOwner?: Company;
    truck?: Truck;
    semitrailer?: Semitrailer;
    truckDriver?: TruckDriver;
  };
};
const SelectedData: FC<Props> = ({ data }) => {
  const dataToRender = [
    {
      id: 0,
      title: 'Propietario del Transporte',
      content: [
        {
          title: 'Razón Social',
          content: data?.vehicleOwner?.attributes?.business_name,
        },
        {
          title: 'ID Tributario',
          content: data?.vehicleOwner?.attributes?.tax_id,
        },
        {
          title: 'Dirección',
          content: data?.vehicleOwner?.attributes?.address,
        },
        {
          title: 'Ciudad',
          content: data?.vehicleOwner?.attributes?.city,
        },
        {
          title: 'País',
          content: data?.vehicleOwner?.attributes?.country,
        },
        {
          title: 'CNRT',
          content: data?.vehicleOwner?.attributes?.cnrt,
        },
        {
          title: 'Licencia Internacional',
          content: data?.vehicleOwner?.attributes?.international_dr,
        },
      ],
    },
    {
      id: 1,
      title: 'Camión',
      content: [
        {
          title: 'Patente',
          content: data?.truck?.attributes?.license_plate,
        },
        {
          title: 'Marca',
          content: data?.truck?.attributes?.brand,
        },
        {
          title: 'Nro. de Chasis',
          content: data?.truck?.attributes?.chassis_number,
        },
        {
          title: 'Año',
          content: data?.truck?.attributes?.year,
        },
        {
          title: 'Nro. Poliza',
          content:
            data?.truck?.attributes?.insurance_policy?.data?.attributes?.number,
        },
        {
          title: 'Vto. Poliza',
          content:
            data?.truck?.attributes?.insurance_policy?.data?.attributes
              ?.expiration_date,
        },
        {
          title: 'Comentarios',
          content: data?.truck?.attributes?.comment,
        },
      ],
    },
    {
      id: 2,
      title: 'Semiremolque',
      content: [
        {
          title: 'Patente',
          content: data?.semitrailer?.attributes?.license_plate,
        },
        {
          title: 'Nro. Poliza',
          content:
            data?.semitrailer?.attributes?.insurance_policy?.data?.attributes
              ?.number,
        },
        {
          title: 'Vto. Poliza',
          content:
            data?.semitrailer?.attributes?.insurance_policy?.data?.attributes
              ?.expiration_date,
        },
        {
          title: 'Comentarios',
          content: data?.semitrailer?.attributes?.comment,
        },
      ],
    },
    {
      id: 3,
      title: 'Chofer',
      content: [
        {
          title: 'Tipo de Documento',
          content: data?.truckDriver?.attributes?.ic_type,
        },
        {
          title: 'Nro. de Documento',
          content: data?.truckDriver?.attributes?.ic,
        },
        {
          title: 'Nombre',
          content: data?.truckDriver?.attributes?.first_name,
        },
        {
          title: 'Apellido',
          content: data?.truckDriver?.attributes?.last_name,
        },
        {
          title: 'Comentarios',
          content: data?.truckDriver?.attributes?.comment,
        },
      ],
    },
  ];

  return (
    <StyledSelectedDataMainContainer>
      <Typography theme="h4" as="h2" $marginBottom="4rem">
        Datos Seleccionados
      </Typography>

      <StyledSelectedData>
        {dataToRender.map((item) => (
          <StyledSelectedDataCard key={item.id}>
            <StyledCardHeader>
              <Typography theme="h6" as="h3" $marginBottom="2.5rem">
                {item.title}
              </Typography>
            </StyledCardHeader>
            <StyledDataList>
              {item.content.map((contentItem) => (
                <li key={contentItem.title}>
                  <Typography theme="p" as="h4">
                    {contentItem.title}:
                  </Typography>
                  &nbsp;
                  <Typography theme="p">{contentItem.content}</Typography>
                </li>
              ))}
            </StyledDataList>
          </StyledSelectedDataCard>
        ))}
      </StyledSelectedData>
    </StyledSelectedDataMainContainer>
  );
};

export default SelectedData;
