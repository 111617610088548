import React, { FC } from 'react';

import { CSSProps } from '../../utils/types';
import Typography from '../Typography';
import { StyledLogo, StyledLogoContainer } from './Logo.styled';
import { getLogoSize, getTypographySize, Layouts } from './utils';

enum Tags {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
}

type Props = CSSProps & {
  asTag: keyof typeof Tags;
  layout?: keyof typeof Layouts;
};

const Logo: FC<Props> = ({ asTag, layout, ...rest }) => {
  const logoSize = getLogoSize(layout);
  const typographySize = getTypographySize(layout);

  return (
    <StyledLogoContainer {...rest}>
      <StyledLogo size={logoSize} />
      <Typography theme={typographySize} as={asTag}>
        Mis Despachos
      </Typography>
    </StyledLogoContainer>
  );
};

export default Logo;
