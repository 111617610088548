import { SimItem } from '../../../../../utils/types/sims';

export const mapSimDetail = (sims: SimItem[]) => {
  const mappedSimDetail = mapSims(sims, true);

  return mappedSimDetail;
};

export const mapSimBalance = (sims: SimItem[]) => {
  const mappedSimBalance = mapSims(sims, false);
  return mappedSimBalance;
};

export const mapSims = (sims: SimItem[], isQuantity: boolean) => {
  const mappedSims = sims.map((sim) => {
    const { description } = sim.merchandise_id;
    const amount = isQuantity ? sim.quantity : sim.balance;

    return `${description} - ${amount} ${sim.unit}`;
  });

  return mappedSims;
};
