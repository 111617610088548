import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { StyledContainedTableContainer } from '../../../../utils/styles';
import { RelatedDocs } from '../../types';
import { TableData } from './types';
import { transformData } from './utils';

type Props = {
  data?: RelatedDocs;
};

const DocumentsTable: FC<Props> = ({ data }) => {
  const newData = transformData(data?.docs);

  const columns: ColumnsType<TableData> = [
    {
      title: 'SIM',
      key: 'sim',
      width: '33.3%',
      render: (props: TableData) => {
        return <Link to={`sims/${props.simID}`}>{props.sim}</Link>;
      },
    },
    {
      title: 'CRT',
      key: 'crt',
      width: '33.3%',
      render: (props: TableData) => {
        return (
          <Link to={`sims/${props.simID}/crts/${props.crtID}`}>
            {props.crt}
          </Link>
        );
      },
    },
    {
      title: 'MICs',
      key: 'mics',
      width: '33.3%',
      render: (props: TableData) => {
        // Update this with Icon, tooltip and redirection
        return (
          <Link to={`sims/${props.simID}/crts/${props.crtID}/mics`}>
            {props.mics}
          </Link>
        );
      },
    },
  ];

  return (
    <StyledContainedTableContainer>
      <Table columns={columns} dataSource={newData} />
    </StyledContainedTableContainer>
  );
};

export default DocumentsTable;
