import { ColumnsType } from 'antd/es/table';
import React from 'react';

import { Data } from '../../../types';

export type DataType = {
  key: React.Key;
  business_name: string;
  tax_id: string;
  country: string;
  type: string;
};

export const transformData = (data?: Data[]) => {
  if (!data) {
    return [];
  }

  const transformedData: DataType[] = data.map((item) => {
    return {
      key: item.id,
      country: item.attributes.country,
      tax_id: item.attributes.tax_id,
      business_name: item.attributes.business_name,
      type: item.attributes.type,
    };
  });

  return transformedData;
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'Nombre de la empresa',
    dataIndex: 'business_name',
    key: 'business_name',
    sorter: true,
  },
  {
    title: 'ID Tributario',
    dataIndex: 'tax_id',
    key: 'tax_id',
    sorter: true,
  },
  {
    title: 'País',
    dataIndex: 'country',
    key: 'country',
    sorter: true,
  },
  {
    title: 'Tipo de Empresa',
    dataIndex: 'type',
    key: 'type',
    sorter: true,
  },
];
