import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { Company } from '../../../utils/types/company';
import { Semitrailer } from '../../../utils/types/semitrailer';
import { Truck } from '../../AddTruck/types';
import { TruckDriver } from '../../TruckDrivers/types';

type Props = {
  vehicleOwnerID?: string | number;
  selectedTruck: string | number;
  selectedSemitrailer: string | number;
  selectedTruckDriver: string | number;
};
const useGetSelectListOptions = ({
  vehicleOwnerID,
  selectedTruck,
  selectedSemitrailer,
  selectedTruckDriver,
}: Props) => {
  const getSemitrailerUrl = () => {
    if (semitrailerQuery) {
      return `/wheeleds?populate=*&filters[type][$eq]=Semitrailer&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[license_plate][$containsi]=${semitrailerQuery}`
    } else if (selectedTruckDriver) {
      return `/wheeleds?populate=*&filters[id][$eq]=${selectedSemitrailer}`;
    } else {
      return `/wheeleds?populate=*&filters[type][$eq]=Semitrailer&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[license_plate][$containsi]=${semitrailerQuery}`
    }
  }
  const getTruckDriverUrl = () => {
    if (truckDriverQuery) {
      return `/truck-drivers?populate=*&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[ic][$containsi]=${truckDriverQuery}`
    } else if (selectedTruckDriver) {
      return `/truck-drivers?populate=*&filters[id][$eq]=${selectedTruckDriver}`;
    } else {
      return `/truck-drivers?populate=*&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[ic][$containsi]=${truckDriverQuery}`
    }
  }

  const getTruckUrl = () => {
    if (truckQuery) {
      return `/wheeleds?populate=*&filters[type][$eq]=Truck&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[license_plate][$containsi]=${truckQuery}`;
    } else if (selectedTruck) {
      return `/wheeleds?populate=*&filters[id][$eq]=${selectedTruck}`;
    } else {
      return `/wheeleds?populate=*&filters[type][$eq]=Truck&filters[company_id][id][$eq]=${vehicleOwnerID}&filters[license_plate][$containsi]=${truckQuery}`;
    }
  }
  const getVehicleOwnerUrl = () => {
    if (vehicleOwnerQuery){
      return `/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=${vehicleOwnerQuery}`;
    } else if (vehicleOwnerID) {
      return `/companies?filters[type][$eq]=Transportista&filters[id][$eq]=${vehicleOwnerID}`;
    } else {
      return `/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=${vehicleOwnerQuery}`;
    }
  }

  const axios = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Query States
  const [vehicleOwnerQuery, setVehicleOwnerQuery] = useState('');
  const [truckQuery, setTruckQuery] = useState('');
  const [semitrailerQuery, setSemitrailerQuery] = useState('');
  const [truckDriverQuery, setTruckDriverQuery] = useState('');

  // Input Option States
  const [vehicleOwnerOptions, setVehicleOwnerOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [truckOptions, setTruckOptions] = useState<InputSelectListOption[]>([]);
  const [semitrailerOptions, setSemitrailerOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [truckDriverOptions, setTruckDriverOptions] = useState<
    InputSelectListOption[]
  >([]);

  // Fetched Results
  const [vehicleOwnerResults, setVehicleOwnerResults] = useState<Company[]>([]);
  const [truckResults, setTruckResults] = useState<Truck[]>([]);
  const [semitrailerResults, setSemitrailerResults] = useState<Semitrailer[]>(
    [],
  );
  const [truckDriverResults, setTruckDriverResults] = useState<TruckDriver[]>(
    [],
  );
  const [filteredResults, setfilteredResults] = useState({});

  const getVehicleOwnerOptions = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const reqOptions = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const vehicleOwnerOptionsResponse = await axios.get<{ data: Company[] }>(
      getVehicleOwnerUrl(),
      reqOptions,
    );

    setVehicleOwnerResults(vehicleOwnerOptionsResponse.data.data);

    const result: InputSelectListOption[] =
      vehicleOwnerOptionsResponse.data.data.map((item) => ({
        label: item.attributes.business_name,
        value: item.id,
      }));
    setVehicleOwnerOptions(result);
    setIsLoading(false);
  };
  useEffect(() => {
    getVehicleOwnerOptions().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [vehicleOwnerID, vehicleOwnerQuery]);

  const getTruckOptions = async () => {
    if (!vehicleOwnerID) {
      setTruckOptions([]);
      setIsLoading(false);
      return;
    }

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const reqOptions = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const truckOptionsResponse = await axios.get<{ data: Truck[] }>(
      getTruckUrl(),
      reqOptions,
    );
    setTruckResults(truckOptionsResponse.data.data);

    const result: InputSelectListOption[] = truckOptionsResponse.data.data.map(
      (item) => ({
        label: item.attributes.license_plate,
        value: item.id,
        semitrailer: item.attributes.last_semitrailer_id?.data,
        driver: item.attributes.last_driver_id?.data,
      }),
    );
    setTruckOptions(result);
  };
  useEffect(() => {
    getTruckOptions().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [selectedTruck, truckQuery]);

  const getSemitrailerOptions = async () => {
    if (!vehicleOwnerID) {
      setSemitrailerOptions([]);
      setIsLoading(false);
      return;
    }

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const reqOptions = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const semitrailerOptionsResponse = await axios.get<{ data: Semitrailer[] }>(
      getSemitrailerUrl(),
      reqOptions,
    );

    setSemitrailerResults(semitrailerOptionsResponse.data.data);

    const result: InputSelectListOption[] =
      semitrailerOptionsResponse.data.data.map((item) => ({
        label: item.attributes.license_plate,
        value: item.id,
      }));
    setSemitrailerOptions(result);
  };
  useEffect(() => {
    getSemitrailerOptions().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [selectedSemitrailer, semitrailerQuery]);

  const getTruckDriverOptions = async () => {
    if (!vehicleOwnerID) {
      setTruckDriverOptions([]);
      setIsLoading(false);
      return;
    }

    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    const reqOptions = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const truckDriverOptionsResponse = await axios.get<{ data: TruckDriver[] }>(
      getTruckDriverUrl(),
      reqOptions,
    );

    setTruckDriverResults(truckDriverOptionsResponse.data.data);

    const result: InputSelectListOption[] =
      truckDriverOptionsResponse.data.data.map((item) => ({
        label: item.attributes.ic,
        value: item.id,
      }));
    setTruckDriverOptions(result);
  };
  useEffect(() => {
    getTruckDriverOptions().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [selectedTruckDriver, truckDriverQuery]);

  const getInitialOptions = async () => {
    setIsLoading(true);
    await getTruckOptions();
    await getSemitrailerOptions();
    await getTruckDriverOptions();
    setIsLoading(false);
  };
  // this is used to load data in edit mode
  useEffect(() => {
    getInitialOptions().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, [vehicleOwnerID]);

  // Get fetched results
  const getDataForNewMode = () => {
    const result = {
      vehicleOwner: vehicleOwnerResults.find(
        (item) => item.id === vehicleOwnerID,
      ),
      truck: truckResults.find((item) => item.id === selectedTruck),
      semitrailer: semitrailerResults.find(
        (item) => item.id === selectedSemitrailer,
      ),
      truckDriver: truckDriverResults.find(
        (item) => item.id === selectedTruckDriver,
      ),
    };

    setfilteredResults(result);
  };

  // New Mode
  useEffect(() => {
    getDataForNewMode();
  }, [vehicleOwnerResults, semitrailerResults, truckResults, truckDriverResults]);

  return {
    isLoading,
    hasError,
    setVehicleOwnerQuery,
    setTruckQuery,
    setSemitrailerQuery,
    setTruckDriverQuery,
    vehicleOwnerOptions,
    truckOptions,
    semitrailerOptions,
    truckDriverOptions,
    filteredResults,
  };
};

export default useGetSelectListOptions;
