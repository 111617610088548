import { styled } from 'styled-components';

import { theme } from '../../../../styles';
import { limitWidth } from '../../../../utils/styles';

export const StyledTableContainer = styled.div`
  ${limitWidth}
  margin: 0 auto 4rem;
  overflow-x: scroll;
  width: 90%;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    margin: 0 auto 7rem;
    overflow-x: visible;
    width: 85%;
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    > div {
      max-width: 18rem;
      width: 100%;
    }
  }
`;
