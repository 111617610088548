import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fromUserRoleToUrl, URLS, WAIT_TIMES } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';
import { Crt } from '../types';

type Props = {
  crtID?: string;
};

const useCrtDetails = ({ crtID }: Props) => {
  const API_URL_FILE_UPLOAD = '/upload',
    API_URL_CRT = 'custom-crt/{crt_id}/find',
    API_URL_DELETE_CRT = '/custom-crt/{id}/delete',
    API_URL_UPDATE_CRT = '/custom-crt/{crt_id}/sim/{sim_id}/update';

  const [data, setData] = useState<Crt | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const getCrtDetail = async (token: string | null) => {
    setHasError(false);
    if (!crtID) throw new Error('No Crt ID provided');
    axios
      .get<Crt>(API_URL_CRT.replace('{crt_id}', crtID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }

      setIsLoading(true);
      getCrtDetail(token);
    } catch (error) {
      log.error('No token provided');
      setHasError(true);
    }
  }, []);

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const simID = data?.related_documents?.sim.id;
    const { file, onSuccess } = info;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!crtID) throw new Error('No Crt ID');
      if (!simID) throw new Error('No Sim ID');

      const dataFile = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, dataFile, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      const invoiceData = {
        data: {
          attachment: parseInt(attachmentID ?? null),
        },
      };

      // Update Invoice data
      await axios.put(
        API_URL_UPDATE_CRT.replace('{crt_id}', crtID).replace(
          '{sim_id}',
          `${simID}`,
        ),
        { ...invoiceData },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (onSuccess) onSuccess(data);

      getCrtDetail(token);
    } catch (error) {
      log.error(error);
    }
  };

  // Delete Crt
  const handleDeleteCrt = async (crtID?: string) => {
    setIsLoading(true);
    let errorMsg = 'Ha ocurrido un error';
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!crtID) throw new Error('There is no Crt ID');

      if (data?.mics_count && parseInt(data?.mics_count) !== 0) {
        errorMsg = 'La Factura aún tiene documentos relacionados';
        throw new Error('Invoice has related docs');
      }

      await axios.delete(API_URL_DELETE_CRT.replace('{id}', crtID), {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({
        message: 'El registro fue borrado correctamente',
      });

      setTimeout(() => {
        navigate(`/${transformedUserRole}${URLS.listCrts}`);
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: errorMsg,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  return { data, isLoading, hasError, handleDeleteCrt, handleUploadFile };
};

export default useCrtDetails;
