import React, { FC } from 'react';

import { Typography } from '../../../../components';
import { PoliciesType } from '../../types';
import { StyledMainContainer } from './MultiselectOptionItem.styled';

type Props = {
  data: PoliciesType;
};

const MultiselectOptionItem: FC<Props> = ({ data }) => {
  return (
    <StyledMainContainer>
      <Typography theme="p" as="p">
        {data?.attributes?.license_plate}
      </Typography>
      <ul>
        <li>
          <Typography theme="subtext" as="p">
            Marca:&nbsp;
          </Typography>
          <Typography theme="subtext">{data?.attributes?.brand}</Typography>
        </li>
        <li>
          <Typography theme="subtext" as="p">
            Nro. Chasis:&nbsp;
          </Typography>
          <Typography theme="subtext">
            {data?.attributes?.chassis_number}
          </Typography>
        </li>
        <li>
          <Typography theme="subtext" as="p">
            Modelo:&nbsp;
          </Typography>
          <Typography theme="subtext">{data?.attributes?.year}</Typography>
        </li>
      </ul>
    </StyledMainContainer>
  );
};

export default MultiselectOptionItem;
