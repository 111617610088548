import { TableData, Truck } from '../../../types';

// TODO: Update this function from utils
const checkForValue = (value: string) => {
  return value ? value : '';
};

export const transformData = (data?: Truck[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      id: item.id,
      license_plate: item.attributes.license_plate || String(),
      brand: item.attributes.brand || String(),
      owner: checkForValue(
        item?.attributes?.company_id?.data?.attributes?.business_name,
      ),
      insurancePolicyNumber:
        item?.attributes?.insurance_policy?.data?.attributes?.number ||
        String(),
      insurancePolicyIsExpired: item?.attributes?.insurance_policy_is_expired,
    };
  });

  return transformedData;
};
