import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledTagContainer = styled.div`
  background-color: gray;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: ${theme.colors.defaultWhite};
  display: inline-flex;

  &.inactivo {
    color: ${theme.colors.defaultBlack};
  }
`;

export const StyledTagMainContainer = styled.div`
  display: inline-flex;
`;
