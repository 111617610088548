import { styled } from 'styled-components';

export const StyledTableIconCell = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  &.center {
    justify-content: center;
  }
`;
