import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { FaTimes } from 'react-icons/fa';

import {
  StyledChildContainer,
  StyledCloseButton,
  StyledModalCard,
  StyledOverlay,
} from './Modal.styled';

type Props = {
  children: ReactNode;
  handleClose: () => void;
  showModal: boolean;
};

const portalID = 'react-modal';

/**
 * If ID selected div does not exist, create it
 *
 * @returns HTMLDivElement
 */
const createPortalDiv = () => {
  const portalElement = document.createElement('div');
  portalElement.setAttribute('id', portalID);
  document.body.appendChild(portalElement);
  return portalElement;
};

const Modal: FC<Props> = ({ children, handleClose, showModal = false }) => {
  const onClickExit = React.useCallback(() => {
    handleClose();
    if (showModal) showModal = false;
  }, []);

  if (!showModal) return null;

  const modal = (
    <StyledOverlay>
      <StyledModalCard>
        <StyledCloseButton onClick={onClickExit}>
          <FaTimes />
        </StyledCloseButton>
        <StyledChildContainer>{children}</StyledChildContainer>
      </StyledModalCard>
    </StyledOverlay>
  );

  const portal = document.getElementById(portalID) || createPortalDiv();

  return createPortal(modal, portal);
};

export default Modal;
