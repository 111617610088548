import { styled } from 'styled-components';

import { theme } from '../../../../styles';
import { limitWidth } from '../../../../utils/styles';

export const StyledCardsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
  ${limitWidth}

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    align-items: stretch;
    justify-content: center;
    margin: 0 auto 5rem auto;
    width: 85%;
  }

  @media only screen and (min-width: ${theme.breakpoints.lg}) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;
