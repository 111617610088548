import { Popconfirm } from 'antd';
import React, { FC } from 'react';
import { FaPaperclip } from 'react-icons/fa';

import { Alert, CustomButton } from '../../../../components';
import { Role } from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { PlainMicData } from '../../../../utils/types/mics';
import {
  StyledActionsBtnsContainer,
  StyledActionsContainer,
} from './HeaderActions.styled';

type Props = {
  data: PlainMicData | null;
  onDelete: () => void;
  onEdit: () => void;
  onFileUpload: () => void;
};
const HeaderActions: FC<Props> = ({ data, onDelete, onEdit, onFileUpload }) => {
  const canDeleteOrUpdate = data?.can_delete_or_update;
  return (
    <div>
      <Alert
        canShow={!canDeleteOrUpdate}
        icon="warning"
        message="El SIM se encuentra cerrado, por lo que este MIC no puede ser borrado o editado."
        type="warning"
      />
      <StyledActionsContainer>
        <StyledActionsBtnsContainer>
          <AvailableComponent availableFor={[Role.Admin]}>
            {!data?.attachment && (
              <CustomButton
                $isMini
                text="Adjuntar Archivo"
                $theme="success"
                onPress={onFileUpload}
              >
                <FaPaperclip size="1.5rem" />
              </CustomButton>
            )}
          </AvailableComponent>
        </StyledActionsBtnsContainer>

        {canDeleteOrUpdate && (
          <AvailableComponent availableFor={[Role.Admin]}>
            <StyledActionsBtnsContainer>
              <CustomButton
                $isMini
                text="Editar"
                $theme="secondary"
                onPress={onEdit}
              />
              <Popconfirm
                title="Precaución"
                description="¿Está seguro que quiere borrar el elemento? Este cambio es irreversible."
                onConfirm={onDelete}
                okText="Eliminar"
                cancelText="Cancelar"
                okButtonProps={{ danger: true }}
              >
                <div>
                  <CustomButton $isMini text="Borrar" $theme="danger" />
                </div>
              </Popconfirm>
            </StyledActionsBtnsContainer>
          </AvailableComponent>
        )}
      </StyledActionsContainer>
    </div>
  );
};

export default HeaderActions;
