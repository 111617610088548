import { AltPlainTruckData } from '../../../../../utils/types/truck';
import { TableData } from '../../../types';

export const transformData = (data?: AltPlainTruckData[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      transport: item.business_name,
      license: item.international_dr,
      paut: item.cnrt,
      licensePlate: item.license_plate,
      chasisNumber: item.chassis_number,
      brand: item.brand,
      year: item.year,
      insurancePolicyNumber: item.policy_number,
      insurancePolicyExpirationDate: item.policy_expiration,
    };
  });

  return transformedData;
};

export const sortByOptions = [
  { value: 'license_plate', text: 'Patente' },
  { value: 'brand', text: 'Marca' },
  { value: 'business_name', text: 'Transporte' },
  { value: 'year', text: 'Año' },
  { value: 'policy_expiration', text: 'Vto. Póliza' },
];
