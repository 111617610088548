import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  Role,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';
import {
  CompanyResponse,
  TruckDriverInputs,
  TruckDriverRequestData,
  TruckDriverResponseData,
} from '../types/index';
import { CompanyData } from '../types/index';

type Props = {
  truckDriverId?: string;
};

const useAddTruckDriver = ({ truckDriverId }: Props) => {
  const API_URL_TRUCK_DRIVER = '/truck-drivers';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const [inputOptions, setInputOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [inputQuery, setInputQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [truckDriverData, setTruckDriverData] =
    useState<TruckDriverInputs | null>(null);

  // if edit we look for the driver
  useEffect(() => {
    if (truckDriverId) {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      setIsLoading(true);
      axios
        .get<TruckDriverResponseData>(
          `${API_URL_TRUCK_DRIVER}/${truckDriverId}?populate=*`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((response) => {
          setTruckDriverData(new TruckDriverInputs(response.data));
        })
        .catch((error) => {
          log.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

    const handleRedirect = () => {
        if (Role.Admin == transformedUserRole) {
            navigate(-1)
        } else {
            navigate(`/${transformedUserRole}${URLS.truckDriver}`);
        }
    }

  const handleAddTruckDriverData = async (data: TruckDriverRequestData) => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      setIsLoading(true);
      let message = 'Los datos fueron actualizados satisfactoriamente';
      if (truckDriverId) {
        await axios.put(`${API_URL_TRUCK_DRIVER}/${truckDriverId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        await axios.post(API_URL_TRUCK_DRIVER, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        message = 'Los datos fueron registrados satisfactoriamente';
      }
      setTimeout(() => {
        handleRedirect();
      }, WAIT_TIMES.sm);
      showNotification({
        message: message,
      });
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useSearchCompany(setInputOptions, inputQuery);

  return {
    truckDriverData,
    isLoading,
    handleAddTruckDriverData,
    inputOptions,
    setInputQuery,
  };
};

const useSearchCompany = (setInputOptions: any, inputQuery: string) => {
  const API_COMPANY_URL = 'companies?filters[business_name][$containsi]=';

  const axios = useAxios();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }
    axios
      .get<CompanyData>(`${API_COMPANY_URL}${inputQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const companies = response.data.data;
        const transformedData =
          companies.length > 0
            ? companies.map((item: CompanyResponse) => {
                return { value: item.id, label: item.attributes.business_name };
              })
            : [];
        setInputOptions(transformedData);
      })
      .catch((error) => {
        log.error(error);
      });
  }, [inputQuery]);
};

export default useAddTruckDriver;
