import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import {
  GenerateReportActions,
  InputCheckbox,
  InputSelect,
  InputSelectList,
  InputText,
  Loader,
} from '../../../../components';
import { orderByOptions } from '../../../../utils';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { InputSelectListOption, PaginationData } from '../../../../utils/types';
import { AltPlainSemitrailerData } from '../../../../utils/types/semitrailer';
import { TableData } from '../../types';
import { sortByOptions, transformData } from './utils';

type Props = {
  data: AltPlainSemitrailerData[];
  isLoading: boolean;
  paginationData: PaginationData;
  onFilter: {
    isInsurancePolicyExpired: (value: boolean) => void;
    licensePlate: (value: string) => void;
    transport: (value: InputSelectListOption | null) => void;
    sortBy: (value: string) => void;
    orderBy: (value: string) => void;
  };
  transportFilterQuery: (value: string) => void;
  transportFilterOptions: InputSelectListOption[];
  onGenerateReportPDF: () => void;
  onGenerateReportXLXS: () => void;
};

const SemitrailersReportsTable: FC<Props> = ({
  isLoading,
  data,
  paginationData,
  onFilter,
  transportFilterQuery,
  transportFilterOptions,
  onGenerateReportPDF,
  onGenerateReportXLXS,
}) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    { title: 'Transporte', key: 'transport', dataIndex: 'transport' },
    { title: 'Permiso', key: 'license', dataIndex: 'license' },
    { title: 'Paut', key: 'paut', dataIndex: 'paut' },
    { title: 'Patente', key: 'licensePlate', dataIndex: 'licensePlate' },
    { title: 'Nro. Chasis', key: 'chasisNumber', dataIndex: 'chasisNumber' },
    { title: 'Marca', key: 'brand', dataIndex: 'brand' },
    { title: 'Año', key: 'year', dataIndex: 'year' },
    {
      title: 'Nro. Póliza',
      key: 'insurancePolicyNumber',
      dataIndex: 'insurancePolicyNumber',
    },
    {
      title: 'Vto. Póliza',
      key: 'insurancePolicyExpirationDate',
      dataIndex: 'insurancePolicyExpirationDate',
    },
  ];

  const handleOnFilterInsurancePolicyExpired = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilter.isInsurancePolicyExpired(event.target.checked);
    },
    [],
  );

  const handleOnFilterLicensePlate = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilter.licensePlate(event.target.value);
    },
    [],
  );

  const handleSortByOptionsSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.sortBy(event.target.value);
    },
    [],
  );

  const handleOrderBySelect = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.orderBy(event.target.value);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputCheckbox
          customID="semitrailer-report-table"
          label="Póliza Vencida"
          onChange={handleOnFilterInsurancePolicyExpired}
        />
        <InputText
          placeholder="Patente"
          label="Patente"
          onChange={handleOnFilterLicensePlate}
        />
        <InputSelectList
          handleOnChange={onFilter.transport}
          handleOnInputChange={transportFilterQuery}
          options={transportFilterOptions}
          label="Transporte"
          placeholder="Transporte"
        />
      </StyledFiltersContainer>
      <StyledFiltersContainer>
        <InputSelect
          onChange={handleSortByOptionsSelect}
          options={sortByOptions}
          placeholder="Ordenar Por"
        />
        <InputSelect
          onChange={handleOrderBySelect}
          options={orderByOptions}
          placeholder="Ordenar Por"
        />
      </StyledFiltersContainer>
      <GenerateReportActions
        onGenerateReportPDF={onGenerateReportPDF}
        onGenerateReportXLXS={onGenerateReportXLXS}
      />
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
      />
    </StyledTableContainer>
  );
};

export default SemitrailersReportsTable;
