import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CustomButton,
  InputSelect,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import { isSuperAdmin, URLS } from '../../../../utils';
import { EMAIL_VALIDATION } from '../../../../utils/regexp';
import useStore from '../../../../zustand';
import useAddCompany from '../../hooks/useAddCompany';
import { Inputs } from '../../types';
import { transformFromCompanyToInputs } from '../../utils';
import {
  StyledErrorMessage,
  StyledFormActions,
  StyledSubForm,
} from './AddCompanyForm.styled';
import { countriesSelect } from './utils';

type Props = {
  title: string;
  ctaText: string;
  companyId?: string | number;
  companyType: string | null;
};

const AddCompanyForm: FC<Props> = ({
  title,
  ctaText,
  companyType,
  companyId,
}) => {
  const navigate = useNavigate();

  const [useRole] = useStore((store) => [store.userRole]);

  const { register, handleSubmit, reset, formState } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { handleAddCompany, singleCompanyData, customError, isLoading } =
    useAddCompany({
      companyType,
      companyId,
    });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleAddCompany(data);
  };

  const handleOnCancel = React.useCallback(() => {
    navigate(URLS.dashboard);
  }, []);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA =
    isSuperAdmin(useRole) &&
    (!formState.isDirty || isLoading || errorsArray.length > 0);

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleCompanyData) {
      reset(transformFromCompanyToInputs(singleCompanyData));
    }
  }, [singleCompanyData]);

  return (
    <>
      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        {title}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {customError && (
          <StyledErrorMessage>
            {customError.map((item) => (
              <div key={item.replaceAll(' ', '')}>
                <Typography theme="p" $marginBottom="1.5rem">
                  {item}
                </Typography>
              </div>
            ))}
          </StyledErrorMessage>
        )}
        <Typography theme="h4" as="h2">
          Datos de la Empresa
        </Typography>
        <StyledSubForm>
          <InputText
            placeholder="Razón social"
            label="Razón social"
            $hasValue={formState.dirtyFields.business_name}
            $invalid={!!formState.errors.business_name}
            {...register('business_name', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.business_name?.message}
          />
          <InputText
            placeholder="ID Tributario"
            label="ID Tributario"
            $hasValue={formState.dirtyFields.tax_id}
            $invalid={!!formState.errors.tax_id}
            {...register('tax_id', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.tax_id?.message}
          />
          <InputText
            placeholder="Dirección"
            label="Dirección"
            $hasValue={formState.dirtyFields.address}
            $invalid={!!formState.errors.address}
            {...register('address', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.address?.message}
          />
          <InputText
            placeholder="Ciudad"
            label="Ciudad"
            $hasValue={formState.dirtyFields.city}
            $invalid={!!formState.errors.city}
            {...register('city', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.city?.message}
          />
          <InputSelect
            options={countriesSelect}
            placeholder="País"
            label="País"
            $hasValue={formState.dirtyFields.country}
            $invalid={!!formState.errors.country}
            {...register('country', {
              required: 'Este Campo es requerido',
            })}
            subTextMessage={formState.errors.country?.message}
          />

          {companyType === 'Transportista' && (
            <>
              <InputText
                placeholder="CNRT"
                label="CNRT"
                $hasValue={formState.dirtyFields.cnrt}
                $invalid={!!formState.errors.cnrt}
                {...register('cnrt', {
                  required: 'Este Campo es requerido',
                })}
                subTextMessage={formState.errors.cnrt?.message}
              />
              <InputText
                placeholder="Licencia Internacional"
                label="Licencia Internacional"
                $hasValue={formState.dirtyFields.international_dr}
                $invalid={!!formState.errors.international_dr}
                {...register('international_dr', {
                  required: 'Este Campo es requerido',
                })}
                subTextMessage={formState.errors.international_dr?.message}
              />
            </>
          )}
        </StyledSubForm>

        {isSuperAdmin(useRole) && (
          <>
            <Typography theme="h4" as="h2">
              Datos del Administrador
            </Typography>
            <StyledSubForm>
              <InputText
                placeholder="Nombre"
                label="Nombre"
                $hasValue={formState.dirtyFields.name}
                $invalid={!!formState.errors.name}
                {...register('name', {
                  required: 'Este Campo es requerido',
                })}
                subTextMessage={formState.errors.name?.message}
              />
              <InputText
                placeholder="Apellido"
                label="Apellido"
                $hasValue={formState.dirtyFields.lastName}
                $invalid={!!formState.errors.lastName}
                {...register('lastName', {
                  required: 'Este Campo es requerido',
                })}
                subTextMessage={formState.errors.lastName?.message}
              />
              <InputText
                placeholder="Email"
                label="Email"
                $hasValue={formState.dirtyFields.email}
                $invalid={!!formState.errors.email}
                {...register('email', {
                  required: 'Este Campo es requerido',
                  pattern: {
                    value: EMAIL_VALIDATION,
                    message: 'El email no es válido',
                  },
                })}
                subTextMessage={formState.errors.email?.message}
              />
            </StyledSubForm>
          </>
        )}

        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text={ctaText}
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default AddCompanyForm;
