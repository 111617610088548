import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import { Typography } from '../../../../components';
import { StyledContainedTableContainer } from '../../../../utils/styles';
import { InvoiceItems } from '../../../../utils/types/invoice';
import { TableData } from './types';
import { transformData } from './utils';

type Props = {
  data?: InvoiceItems[];
};

const ItemsTable: FC<Props> = ({ data }) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'UM',
      dataIndex: 'UM',
      key: 'UM',
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Valor Unitario',
      dataIndex: 'unitValue',
      key: 'unitValue',
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return (
    <StyledContainedTableContainer>
      <Table
        columns={columns}
        dataSource={newData}
        summary={(data) => {
          let totalAmount = 0;
          data.forEach(({ amount }) => {
            totalAmount += amount;
          });
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {/* TODO: Fix this empty cells */}
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5}>
                  <Typography theme="p">Total</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Typography theme="p">{totalAmount}</Typography>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </StyledContainedTableContainer>
  );
};

export default ItemsTable;
