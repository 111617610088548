import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';
import { FaCheck, FaList, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {
  InputAutocomplete,
  InputCheckbox,
  InputText,
  Loader,
} from '../../../../components';
import { theme } from '../../../../styles';
import { Role } from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import {
  InputAutocompleteOption,
  Pagination as PaginationType,
} from '../../../../utils/types/index';
import useStore from '../../../../zustand';
import CompanyInfoModal from '../../../Invoices/components/CompanyInfoModal';
import { Crt, TableData } from '../../types';
import { handleGoToCrtDetails } from '../../utils';
import {
  StyledTableIconCell,
  StyledTableIconCellTooltip,
} from './CrtsTable.styled';
import { transformData } from './utils';

type Props = {
  data?: Crt[] | null;
  pagination: PaginationType;
  onFilterSimNumberChange: (value: string) => void;
  onFilterCrtNumberChange: (value: string) => void;
  onFilterTransporterChange: (value: string) => void;
  onChangeTransporterQuery: (value: string) => void;
  onInputCheckboxChange: (value: boolean) => void;
  onPaginationChange: (page: number) => void;
  transporterOptions: InputAutocompleteOption[];
  filterCheckboxValue: boolean;
  currentPage: number;
  isLoading: boolean;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const CrtsTable: FC<Props> = ({
  data,
  pagination,
  onFilterSimNumberChange,
  onFilterCrtNumberChange,
  onFilterTransporterChange,
  onChangeTransporterQuery,
  onInputCheckboxChange,
  onPaginationChange,
  filterCheckboxValue,
  transporterOptions,
  currentPage,
  isLoading,
  onTableChange,
}) => {
  const newData = transformData(data);

  const paginationData = {
    current: currentPage,
    defaultCurrent: Number(true),
    total: pagination?.total || Number(),
    pageSize: pagination?.pageSize || Number(),
  };

  const [userRole] = useStore((state) => [state.userRole]);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Número',
      key: 'number',
      sorter: true,
      align: 'left',
      render: (props: TableData) => {
        return (
          <Link
            to={handleGoToCrtDetails({
              userRole,
              invoiceId: props?.invoiceId,
              simId: props?.simId,
              crtId: `${props?.key}`,
            })}
          >
            {props.number}
          </Link>
        );
      },
    },
    {
      title: 'Exportador',
      key: 'exporter',
      // sorter: true, // TODO: Fix nested sorter
      align: 'left',
      render: (props: TableData) => {
        const exporterCompanyName = props.exporter.business_name;
        return (
          <CompanyInfoModal
            agentCompanyInfo={props.exporter}
            titleCompanyName={exporterCompanyName}
          >
            <StyledTableIconCell>{exporterCompanyName}</StyledTableIconCell>
          </CompanyInfoModal>
        );
      },
    },
    {
      title: 'Importador',
      key: 'importer',
      // sorter: true, // TODO: Fix nested sorter
      align: 'left',
      render: (props: TableData) => {
        const importerCompanyName = props.importer.business_name;
        return (
          <CompanyInfoModal
            agentCompanyInfo={props.importer}
            titleCompanyName={importerCompanyName}
          >
            <StyledTableIconCell>{importerCompanyName}</StyledTableIconCell>
          </CompanyInfoModal>
        );
      },
    },
    {
      title: 'Transporte',
      key: 'transporter',
      // sorter: true, // TODO: Fix nested sorter
      align: 'left',
      render: (props: TableData) => {
        const transporterCompanyName = props.transporter.business_name;
        return (
          <CompanyInfoModal
            displayFullCompanyInfo
            agentCompanyInfo={props.transporter}
            titleCompanyName={transporterCompanyName}
          >
            <StyledTableIconCell>{transporterCompanyName}</StyledTableIconCell>
          </CompanyInfoModal>
        );
      },
    },
    {
      title: 'Lugar de recepción',
      dataIndex: 'receptionPlace',
      key: 'receptionPlace',
      // sorter: true, // TODO: Fix nested sorter
      align: 'left',
      render: (value?: string) => {
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Lugar de entrega',
      dataIndex: 'deliveryPlace',
      key: 'deliveryPlace',
      // sorter: true, // TODO: Fix nested sorter
      align: 'left',
      render: (value?: string) => {
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      sorter: true,
      align: 'left',
      render: (value?: string) => {
        if (value && value.includes(',\n')) {
          return (
            <StyledTableIconCellTooltip>
              <Tooltip placement="right" title={value.replaceAll(',', '')}>
                <FaList size="2.5rem" color={theme.colors.primary} />
              </Tooltip>
            </StyledTableIconCellTooltip>
          );
        }
        return <StyledTableIconCell>{value}</StyledTableIconCell>;
      },
    },
    {
      title: 'Operable',
      dataIndex: 'active',
      key: 'active',
      sorter: true,
      align: 'center',
      render: (props: TableData) => {
        const Icon = props ? (
          <FaCheck color={theme.colors.success} />
        ) : (
          <FaTimes color={theme.colors.error} />
        );
        return (
          <StyledTableIconCell className="center">{Icon}</StyledTableIconCell>
        );
      },
    },
  ];

  const handleSearchBySimNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilterSimNumberChange(event.target.value);
    },
    [],
  );

  const handleSearchByCrtNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilterCrtNumberChange(event.target.value);
    },
    [],
  );

  const handleFilterByCheckbox = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputCheckboxChange(event.target.checked);
    },
    [],
  );

  const handleOnSearchTransporterQuery = React.useCallback((value: string) => {
    onChangeTransporterQuery(value);
  }, []);

  const handleOnSelectTransporterQuery = React.useCallback(
    (_value: string, option: InputAutocompleteOption) => {
      onFilterTransporterChange(option.key);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputText
          placeholder="Número de SIM"
          onChange={handleSearchBySimNumber}
        />
        <InputText
          placeholder="Número de CRT"
          onChange={handleSearchByCrtNumber}
        />

        <AvailableComponent availableFor={[Role.Admin, Role.ImpExpAdmin]}>
          <InputAutocomplete
            options={transporterOptions}
            placeholder="Transporte"
            onSearch={handleOnSearchTransporterQuery}
            onSelect={handleOnSelectTransporterQuery}
          />
        </AvailableComponent>

        <InputCheckbox
          label="Operable"
          checked={filterCheckboxValue}
          onChange={handleFilterByCheckbox}
          customID="ctrs-filter"
        />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          ...paginationData,
          showSizeChanger: false,
          position: ['bottomCenter'],
          onChange: onPaginationChange,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default CrtsTable;
