import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  checkForValue,
  fromUserRoleToUrl,
  NotificationMessages,
  ReportMessages,
  URLS,
} from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption, Pagination } from '../../../utils/types';
import { Mic } from '../../../utils/types/mics';
import useStore from '../../../zustand';

type Props = {
  paginationCurrentPage: number;
};

const useMics = ({ paginationCurrentPage }: Props) => {
  const API_URL_SIMS = 'custom-mic/all?populate=*';

  // Report Generation
  const API_URL_MIC_REPORT_GENERATION_PDF = '/reports/format/pdf/mic';
  const API_URL_MIC_REPORT_GENERATION_XLSX = '/reports/format/xlsx/mic';

  const paginationParams = `&pagination[page]=${paginationCurrentPage}&pagination[pageSize]=10`;

  const axios = useAxios();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<Mic[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState<Pagination>(
    {} as Pagination,
  );

  // Filters

  // APIs
  const API_URL_FILTERS = {
    dateFrom: '&filters[date_of_issue][$gte]=',
    dateUntil: '&filters[date_of_issue][$lte]=',
    crtNumber: '&filters[crt_id][number][$containsi]=',
    micNumber: '&filters[number][$containsi]=',
    transport: '&filters[crt_id][carrier_company][id][$eq]=',
    vehicleOwner: '&filters[vehicle_owner][id][$eq]=',
    truck: '&filters[truck][id][$eq]=',
    semitrailer: '&filters[semitrailer][id][$eq]=',
    driver: '&filters[truck_driver][id][$eq]=',
    importer: '&filters[importer_id][id][$eq]=',
    exporter: '&filters[exporter_id][id][$eq]=',
    delivered: '&filters[reception_date][$notNull]=true',
  };

  const REPORT_API_URL_FILTERS = {
    dateFrom: '&date_from=',
    dateUntil: '&date_to=',
    crtNumber: '&crt_number=',
    micNumber: '&mic_number=',
    transport: '&transport_id=',
    vehicleOwner: '&vehicle_owner_id=',
    truck: '&truck_id=',
    semitrailer: '&semitrailer_id=',
    driver: '&driver_id=',
    importer: '&importer_id=',
    exporter: '&exporter_id=',
    delivered: '&delivered=true',
  };

  // Controls
  const [filterDateFrom, setFilterDateFrom] = useState('');

  const [filterDateUntil, setFilterDateUntil] = useState('');

  const [filterCrtNumber, setFilterCrtNumber] = useState('');

  const [filterMicNumber, setFilterMicNumber] = useState('');

  const [filterTransport, setFilterTransport] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterVehicleOwner, setFilterVehicleOwner] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterTruck, setFilterTruck] = useState<InputSelectListOption | null>(
    {} as InputSelectListOption,
  );

  const [filterSemitrailer, setFilterSemitrailer] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterDriver, setFilterDriver] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterImporter, setFilterImporter] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterExporter, setFilterExporter] =
    useState<InputSelectListOption | null>({} as InputSelectListOption);

  const [filterDelivered, setFilterDelivered] = useState(false);

  // Build final URL
  const finalURL = () => {
    const DATE_FROM_URL =
      filterDateFrom && API_URL_FILTERS.dateFrom + filterDateFrom;

    const DATE_UNTIL_URL =
      filterDateUntil && API_URL_FILTERS.dateUntil + filterDateUntil;

    const CRT_URL =
      filterCrtNumber && API_URL_FILTERS.crtNumber + filterCrtNumber;

    const MIC_URL =
      filterMicNumber && API_URL_FILTERS.micNumber + filterMicNumber;

    const TRANSPORT_URL =
      filterTransport?.value &&
      API_URL_FILTERS.transport + filterTransport.value;

    const VEHICLE_OWNER_URL =
      filterVehicleOwner?.value &&
      API_URL_FILTERS.vehicleOwner + filterVehicleOwner.value;

    const TRUCK_URL =
      filterTruck?.value && API_URL_FILTERS.truck + filterTruck.value;

    const SEMITRAILER_URL =
      filterSemitrailer?.value &&
      API_URL_FILTERS.semitrailer + filterSemitrailer.value;

    const DRIVER_URL =
      filterDriver?.value && API_URL_FILTERS.driver + filterDriver.value;

    const IMPORTER_URL =
      filterImporter?.value && API_URL_FILTERS.importer + filterImporter.value;

    const EXPORTER_URL =
      filterExporter?.value && API_URL_FILTERS.exporter + filterExporter.value;

    const DELIVERED_URL = filterDelivered && API_URL_FILTERS.delivered;

    return (
      API_URL_SIMS +
      paginationParams +
      checkForValue(DATE_FROM_URL) +
      checkForValue(DATE_UNTIL_URL) +
      checkForValue(CRT_URL) +
      checkForValue(MIC_URL) +
      checkForValue(TRANSPORT_URL) +
      checkForValue(VEHICLE_OWNER_URL) +
      checkForValue(TRUCK_URL) +
      checkForValue(SEMITRAILER_URL) +
      checkForValue(DRIVER_URL) +
      checkForValue(IMPORTER_URL) +
      checkForValue(EXPORTER_URL) +
      checkForValue(DELIVERED_URL)
    );
  };

  const getMics = (token: string | null) => {
    axios
      .get<{ data: Mic[]; meta: { pagination: Pagination } }>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        setData(result.data);
        setPaginationData(result.meta.pagination);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    getMics(token);
  }, [
    filterDateFrom,
    filterDateUntil,
    filterCrtNumber,
    filterMicNumber,
    filterTransport,
    filterVehicleOwner,
    filterTruck,
    filterSemitrailer,
    filterDriver,
    filterImporter,
    filterExporter,
    filterDelivered,
    paginationCurrentPage,
  ]);

  const tableFilters = {
    dateFrom: setFilterDateFrom,
    dateUntil: setFilterDateUntil,
    crtNumber: setFilterCrtNumber,
    micNumber: setFilterMicNumber,
    transport: setFilterTransport,
    vehicleOwner: setFilterVehicleOwner,
    truck: setFilterTruck,
    semitrailer: setFilterSemitrailer,
    driver: setFilterDriver,
    importer: setFilterImporter,
    exporter: setFilterExporter,
    delivered: setFilterDelivered,
  };

  // Report Generation
  const buildReportGenerationURL = (apiToUse: string) => {
    const DATE_FROM_URL =
      filterDateFrom && REPORT_API_URL_FILTERS.dateFrom + filterDateFrom;

    const DATE_UNTIL_URL =
      filterDateUntil && REPORT_API_URL_FILTERS.dateUntil + filterDateUntil;

    const CRT_URL =
      filterCrtNumber && REPORT_API_URL_FILTERS.crtNumber + filterCrtNumber;

    const MIC_URL =
      filterMicNumber && REPORT_API_URL_FILTERS.micNumber + filterMicNumber;

    const TRANSPORT_URL =
      filterTransport?.value &&
      REPORT_API_URL_FILTERS.transport + filterTransport.value;

    const VEHICLE_OWNER_URL =
      filterVehicleOwner?.value &&
      REPORT_API_URL_FILTERS.vehicleOwner + filterVehicleOwner.value;

    const TRUCK_URL =
      filterTruck?.value && REPORT_API_URL_FILTERS.truck + filterTruck.value;

    const SEMITRAILER_URL =
      filterSemitrailer?.value &&
      REPORT_API_URL_FILTERS.semitrailer + filterSemitrailer.value;

    const DRIVER_URL =
      filterDriver?.value && REPORT_API_URL_FILTERS.driver + filterDriver.value;

    const IMPORTER_URL =
      filterImporter?.value && REPORT_API_URL_FILTERS.importer + filterImporter.value;

    const EXPORTER_URL =
      filterExporter?.value && REPORT_API_URL_FILTERS.exporter + filterExporter.value;

    const DELIVERED_URL = filterDelivered && REPORT_API_URL_FILTERS.delivered;

    const result =
      apiToUse +
      checkForValue(DATE_FROM_URL) +
      checkForValue(DATE_UNTIL_URL) +
      checkForValue(CRT_URL) +
      checkForValue(MIC_URL) +
      checkForValue(TRANSPORT_URL) +
      checkForValue(VEHICLE_OWNER_URL) +
      checkForValue(TRUCK_URL) +
      checkForValue(SEMITRAILER_URL) +
      checkForValue(DRIVER_URL) +
      checkForValue(IMPORTER_URL) +
      checkForValue(EXPORTER_URL) +
      checkForValue(DELIVERED_URL);
    return result.replace('&', '?');
  };

  const generateReport = async (type: 'pdf' | 'xlxs') => {
    const token = sessionStorage.getItem('token');

    const urlToUse =
      type === 'pdf'
        ? API_URL_MIC_REPORT_GENERATION_PDF
        : API_URL_MIC_REPORT_GENERATION_XLSX;

    setIsLoading(true);

    try {
      if (!token) {
        throw new Error('No Token provided');
      }

      await axios.get(buildReportGenerationURL(urlToUse), {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoading(false);
      showNotification({
        message: ReportMessages.SUCCESS,
      });
      navigate(`/${transformedUserRole}${URLS.filesMic}`);
    } catch (error) {
      log.error(error);
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      setHasError(true);
    }
  };

  const generateReportPDF = () => {
    generateReport('pdf');
  };

  const generateReportXLXS = () => {
    generateReport('xlxs');
  };

  return {
    isLoading,
    hasError,
    data,
    paginationData,
    tableFilters,
    generateReportPDF,
    generateReportXLXS,
  };
};

export default useMics;
