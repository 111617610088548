import React from 'react';
import { FaArrowAltCircleRight, FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { BoxContainer, Typography } from '../../../../../components';
import CustomBanner from '../../../../../components/CustomBanner';
import { URLS } from '../../../../../utils';
import useStore from '../../../../../zustand';
import useTranspoterDashboard from '../../../hooks/useTransporterDashboard';
import { ActionCardData } from '../../../types';
import DashboardCards from '../../DashboardCards';
import DashboardHeader from '../../DashboardHeader';
import {
  StyledAdminDashboardContainer,
  StyledReportsContainer,
  StyledReportsTitle,
  StyledWelcomeContainer,
} from './TransporterDashboard.styled';

const TransporterDashboard = () => {
  const { data } = useTranspoterDashboard();

  const [userName] = useStore((state) => [state.userName]);

  const cards: ActionCardData[] = [
    {
      color: 'red',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.crts_count,
      title: 'CRTs',
      url: `/transportista${URLS.listCrts}`,
    },
    {
      color: 'blue',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.mics_count,
      title: 'MICs',
      url: `/transportista${URLS.mics}`,
    },
  ];

  const reportLinks = [
    {
      title: 'Reporte de CRT',
      url: `/transportista${URLS.reportCRT}`,
    },
    {
      title: 'Reporte de MIC',
      url: `/transportista${URLS.mics}`,
    },
    {
      title: 'Reporte de Camiones',
      url: `/transportista${URLS.reportTrucks}`,
    },
    {
      title: 'Reporte de Semiremolques',
      url: `/transportista${URLS.reportSemitrailers}`,
    },
    {
      title: 'Reporte de Choferes',
      url: `/transportista${URLS.reportTruckDrivers}`,
    },
  ];

  return (
    <div>
      <DashboardHeader />
      <DashboardCards cards={cards} />
      <BoxContainer $useMarginBottom>
        <StyledAdminDashboardContainer>
          <StyledReportsContainer>
            <StyledReportsTitle>
              <FaFileAlt size="2rem" />
              <Typography theme="h4">Reportes</Typography>
            </StyledReportsTitle>
            <ul>
              {reportLinks.map((item) => (
                <li key={item.url}>
                  <Link to={item.url}>
                    <Typography theme="p">{item.title}</Typography>
                    <FaArrowAltCircleRight size="1.5rem" />
                  </Link>
                </li>
              ))}
            </ul>
          </StyledReportsContainer>
          <StyledWelcomeContainer>
            <Typography theme="h4" $marginBottom="2.5rem">
              Bienvenido {userName}
            </Typography>
            <Typography theme="p">
              Mis Despachos es la plataforma para despachantes de aduana y
              empresas relacionadas al rubro que te permitirá gestionar todos
              los datos de tu negocio, así como también realizar consultas
              rápidas sobre documentación y saldos. Cualquier inquietud o
              solicitud, por favor canalícela a través de nuestro{' '}
              <a href="mailto:soporte@misdespachos.com">equipo de soporte</a>.
            </Typography>
          </StyledWelcomeContainer>
        </StyledAdminDashboardContainer>
        <CustomBanner />
      </BoxContainer>
    </div>
  );
};

export default TransporterDashboard;
