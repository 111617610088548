import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import { StyledContainedTableContainer } from '../../../../utils/styles';
import { CrtPlainItems } from '../../../../utils/types/crt';
import { TableData } from './types';
import { transformData } from './utils';

type Props = {
  data?: CrtPlainItems[];
};

const ItemsTable: FC<Props> = ({ data }) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Mercadería',
      dataIndex: 'merchandise',
      key: 'merchandise',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  return (
    <StyledContainedTableContainer>
      <Table columns={columns} dataSource={newData} pagination={false} />
    </StyledContainedTableContainer>
  );
};

export default ItemsTable;
