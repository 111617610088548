import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { CustomButton, InputCheckbox, InputText } from '../../../../components';
import { theme } from '../../../../styles';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { PaginationData } from '../../../../utils/types';
import { TableData, Truck } from '../../types';
import { StyledTableIconCell } from './TrucksTable.styled';
import { transformData } from './utils';

type Props = {
  data?: Truck[] | null;
  onInputFilterChange: (value: string) => void;
  onInputCheckboxChange: (value: boolean) => void;
  onDeleteTruck: (truckID: string) => void;
  paginationData: PaginationData;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const TrucksTable: FC<Props> = ({
  data,
  onInputFilterChange,
  onInputCheckboxChange,
  onDeleteTruck,
  paginationData,
  onTableChange,
}) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Número de Patente',
      dataIndex: 'license_plate',
      key: 'license_plate',
      sorter: true,
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      key: 'brand',
      sorter: true,
    },
    {
      title: 'Propietario',
      dataIndex: 'owner',
      key: 'owner',
      // sorter: true, // TODO: Fix nested sorter
    },
    {
      title: 'Número de la póliza',
      dataIndex: 'insurancePolicyNumber',
      key: 'insurancePolicyNumber',
      // sorter: true, // TODO: Fix nested sorter
    },
    {
      title: 'Póliza de Seguro',
      dataIndex: 'insurancePolicyIsExpired',
      key: 'insurancePolicyIsExpired',
      // sorter: true, // TODO: Fix nested sorter
      render: (props: TableData) => {
        const Icon = props ? (
          <FaTimes color={theme.colors.error} />
        ) : (
          <FaCheck color={theme.colors.success} />
        );
        return <StyledTableIconCell>{Icon}</StyledTableIconCell>;
      },
    },
    {
      title: 'Editar',
      key: 'operation_edit',
      fixed: 'right',
      width: 100,
      render: (props: TableData) => {
        return (
          <Link to={`${props.key}/editar`}>
            <CustomButton $theme="secondary" $isMini>
              <FaPen />
            </CustomButton>
          </Link>
        );
      },
    },
    {
      title: 'Borrar',
      key: 'operation_delete',
      fixed: 'right',
      width: 100,
      render: (props: TableData) => (
        <Popconfirm
          title="Precaución"
          description="¿Está seguro que quiere borrar el elemento? Este cambio es irreversible."
          onConfirm={() => {
            onDeleteTruck(props.id.toString());
          }}
          okText="Eliminar"
          cancelText="Cancelar"
          okButtonProps={{ danger: true }}
        >
          <div>
            <CustomButton $theme="secondary" $isMini>
              <FaTrash />
            </CustomButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const handleSearchByLicenseBrand = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputFilterChange(event.target.value);
    },
    [],
  );

  const handleFilterByCheckbox = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputCheckboxChange(event.target.checked);
    },
    [],
  );

  return (
    <StyledTableContainer>
      <StyledFiltersContainer>
        <InputCheckbox
          label="Póliza Vencida"
          customID="trucks-filter"
          onChange={handleFilterByCheckbox}
        />
        <InputText
          placeholder="Patente / Marca"
          onChange={handleSearchByLicenseBrand}
        />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default TrucksTable;
