import styled from 'styled-components';

export const StyledModalElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StyledModalActions = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
`;
