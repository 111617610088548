import { CrtLocationAttributes } from '../../../utils/types/crt';
import { PlainSimAlt } from '../../../utils/types/sims';
import { countries, currencies } from '../components/AddCrtForm/utils';
import { AddCrtInputs, Crt, CrtPayload } from '../types';

export const transformFromCrtToAddCrtInputs = (data: Crt): AddCrtInputs => {
  const buildLocation = (location: CrtLocationAttributes) => {
    return {
      id: location.id,
      place: location.place,
      date: `${location.date}`,
      country: `${buldOptions(countries, location.country)}`,
    };
  };

  const buldOptions = (
    options: { value: string; text: string }[],
    filter: string,
  ) => {
    return options.find(({ value }) => value.includes(filter.toUpperCase()))
      ?.value;
  };

  const buildCrtItems = data.crt_items.map((crtItem) => {
    return {
      id: crtItem.id,
      unit: crtItem.unit,
      quantity: crtItem.quantity,
      balance: crtItem.balance,
      createdAt: crtItem.createdAt,
      updatedAt: crtItem.updatedAt,
      publishedAt: crtItem.publishedAt,
      quantityDeclaredInSim: crtItem.quantity_declared_in_sim,
      merchandiseID: crtItem.merchandise_id?.id as number,
      description: `${crtItem.merchandise_id?.code} - ${crtItem.merchandise_id?.description}`,
    };
  });

  return {
    number: data.number,
    totalCostForRecipient: data.total_cost_for_recipient,
    currency: `${buldOptions(currencies, data.currency)}`,
    carrierCompany: {
      value: data.carrier_company.id,
      label: data.carrier_company.business_name,
    },
    reception: buildLocation(data.reception),
    delivery: buildLocation(data.delivery),
    crtItems: buildCrtItems,
    merchandiseDeclaration: data.crt_items.map((item) => ({
      id: item.id,
      description: `${item?.merchandise_id?.description}`,
      quantity: item.quantity,
      unit: item.unit,
    })),
  };
};

export const transformFromAddCrtInputsToCrt = (
  data: AddCrtInputs,
  simDetails?: PlainSimAlt | null,
): CrtPayload => {
  const simDeclaredMerchandise = simDetails?.sim_items;
  const commonMerchandise = data?.crtItems ?? simDeclaredMerchandise;
  return {
    number: data.number,
    total_cost_for_recipient: parseInt(`${data.totalCostForRecipient}`),
    currency: data.currency,
    crt_items: commonMerchandise.map((item, index) => {
      const merchandiseId =
        item.merchandiseID ??
        simDeclaredMerchandise?.at(index)?.merchandise_id?.id;

      const quantityDeclaredInSim =
        item.quantityDeclaredInSim ??
        simDeclaredMerchandise?.at(index)?.quantity;

      const merchandiseItems = {
        unit: item.unit,
        balance: item.balance,
        createdAt: item.createdAt,
        updatedAt: item.createdAt,
        publishedAt: item.createdAt,
        merchandise_id: merchandiseId,
        quantity_declared_in_sim: quantityDeclaredInSim,
        quantity: data?.merchandiseDeclaration[index].quantity,
      };
      if (simDeclaredMerchandise)
        return {
          ...merchandiseItems,
        };

      return { id: item.id, ...merchandiseItems };
    }),
    reception: { ...data.reception },
    delivery: { ...data.delivery },
    carrier_company: data.carrierCompany.value as number,
  };
};
