import { CrtReportsResponse, TableData } from '../../../types';

export const transformData = (data?: CrtReportsResponse | null) => {
  if (!data || !data.operatives.length) {
    return [];
  }

  const transformedData: TableData[] = data.operatives.map((item) => {
    return {
      key: item.id,
      crt: item.number,
      condition: item.sale_condition,
      importer: item.importer,
      exporter: item.exporter,
      transport: item.carrier_company,
      product: item.description,
      invoice: item.invoice_number,
      sim: item.sim_number,
      balance: item.joined_balance,
      destination: `${item.place}, ${item.country}`.toUpperCase(),
    };
  });

  return transformedData;
};

export const sortByOptions = [
  { text: 'Fecha', value: 'date' },
  { text: 'Saldo', value: 'joined_balance' },
  { text: 'Transporte', value: 'carrier_company' },
];

export const orderByOptions = [
  { text: 'Ascendente', value: 'ASC' },
  { text: 'Descendente', value: 'DESC' },
];
