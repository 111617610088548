import React from 'react';

import { AddSimPage, SimDetailPage, SimsPage } from '../../../pages';
import {
  isAdmin,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const simRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/sims',
      component: <SimsPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/',
      component: <SimDetailPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/nuevo',
      component: <AddSimPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/editar',
      component: <AddSimPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/sims',
      component: <SimsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/',
      component: <SimDetailPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/nuevo',
      component: <AddSimPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/editar',
      component: <AddSimPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/sims',
      component: <SimsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/nuevo',
      component: <AddSimPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/:sim_id/',
      component: <SimDetailPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/:sim_id/editar',
      component: <AddSimPage />,
      isAvailable: isTransporter(useRole),
    },
    // Clients
    {
      path: '/cliente/sims',
      component: <SimsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/',
      component: <SimDetailPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/nuevo',
      component: <AddSimPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/editar',
      component: <AddSimPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
