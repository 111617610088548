import { Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import {
  UploadRequestMethod,
  UploadRequestOption as RcCustomRequestOptions,
} from 'rc-upload/lib/interface';
import React, { FC, useState } from 'react';
import { FaInbox } from 'react-icons/fa';

import CustomButton from '../CustomButton';
import Loader from '../Loader';
import Typography from '../Typography';
import {
  StyledFileUploader,
  StyledFileUploaderContainer,
} from './FileUploader.styled';
import { ALLOWED_CONTENT_TYPES } from './utils';

type Props = {
  props: UploadProps;
  hasFile: boolean;
  onUploadFile: (info: RcCustomRequestOptions) => Promise<void>;
};

enum FileUploaderActions {
  Upload = 'Subir archivo',
  Select = 'Selecciona un archivo',
}

enum FileUploadPayload {
  Method = 'post',
  Action = 'upload',
}

const FileUploader: FC<Props> = ({ props, hasFile, onUploadFile }) => {
  const uploadChildrenRef = React.createRef<HTMLDivElement>();
  const uploadContainerRef = React.createRef<{ fileList: UploadFile[] }>();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const handleOnSelectFile = () => {
    uploadChildrenRef.current?.click();
  };

  const handleOnUploadFile = () => {
    setIsFileUploading(true);
    const [file] = uploadContainerRef.current?.fileList as UploadFile[];

    const newFileUploadPayload = {
      file: file.originFileObj as RcFile,
      action: FileUploadPayload.Action,
      method: FileUploadPayload.Method as UploadRequestMethod,
    };
    onUploadFile(newFileUploadPayload).finally(() => {
      setIsFileUploading(false);
    });
  };

  const fileUploadActionContent = !hasFile
    ? FileUploaderActions.Select
    : FileUploaderActions.Upload;

  const handleUploadAction = React.useCallback(
    !hasFile ? handleOnSelectFile : handleOnUploadFile,
    [hasFile],
  );

  return (
    <StyledFileUploaderContainer>
      {isFileUploading && <Loader />}
      <Upload
        {...props}
        ref={uploadContainerRef}
        accept={ALLOWED_CONTENT_TYPES.join()}
      >
        <StyledFileUploader ref={uploadChildrenRef}>
          <>
            <FaInbox size="5rem" />
            <Typography theme="p">Arrastre un archivo para subir</Typography>
          </>
        </StyledFileUploader>
      </Upload>

      <CustomButton
        text={fileUploadActionContent}
        $theme="primary"
        $isMini
        $marginTop={hasFile ? '1rem' : 'unset'}
        disabled={isFileUploading}
        onPress={handleUploadAction}
      />
    </StyledFileUploaderContainer>
  );
};

export default FileUploader;
