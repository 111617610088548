import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { theme } from '../../../styles';
import {CrtBalanceDetails} from "../../../utils/types/crt";
import {AddMicInputs} from "../types";


export const renderIcon = (value: number) => {
  return value > 0 ? (
    <FaCheck size="1.5rem" color={theme.colors.success} />
  ) : (
    <FaTimes size="1.5rem" color={theme.colors.error} />
  );
};

export type CurrentMicBalance = {
  index: number;
  quantity: number;
};

type CalculateBalanceParams = {
  index: number;
  currentMicData: AddMicInputs | null;
  currentCrtData: CrtBalanceDetails[];
  currentMicQuantities: CurrentMicBalance[];
};

export const calculateMicBalance = ({
                                      index,
                                      currentMicData,
                                      currentCrtData,
                                      currentMicQuantities,
                                    }: CalculateBalanceParams) => {
  const hasCurrentMicData = currentMicData?Object.entries(currentMicData!!).length:false;

  const currentSimQuantity = currentMicQuantities.find(
    (currentSimQuantity) => currentSimQuantity.index === index,
  );

  let absoluteBalance = Number();

  if (!hasCurrentMicData) {
    absoluteBalance = currentCrtData[index].joined_balance;
  } else {
    if (currentMicData?.merchandiseDeclaration.length) {
      absoluteBalance =
        currentCrtData[index].joined_balance +
        currentMicData.merchandiseDeclaration[index].quantity;
    } else {
      absoluteBalance = currentCrtData[index].joined_balance;
    }
  }

  if (currentSimQuantity) {
    if (!currentSimQuantity.quantity) {
      if (currentMicData?.merchandiseDeclaration?.length) {
        return Number(
          currentCrtData[index].joined_balance +
          currentMicData.merchandiseDeclaration[index].quantity
        ).toFixed(2);
      } else {
        return currentCrtData[index].joined_balance;
      }
    } else if (currentSimQuantity.quantity <= absoluteBalance) {
      return  Number((absoluteBalance - currentSimQuantity.quantity)
        .toFixed(2))
    }
    return Number();
  } else {
    return currentCrtData[index].joined_balance;
  }
};
