import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Breadcrumb,
  CustomButton,
  InputArea,
  InputDatePicker,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import {
  fromUserRoleToUrl,
  Role,
  URLS,
  USER_ROLES_LIST,
} from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { ONLY_NUMBERS_VALIDATION } from '../../../../utils/regexp';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import useStore from '../../../../zustand';
import useAddTruck from '../../hooks/useAddTruck';
import { Inputs } from '../../types';

const AddTruckForm = () => {
  const params = useParams();

  const yearLimit = { min: 1900, max: 3000 };

  const navigate = useNavigate();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const {
    handleAddTruck,
    inputOptions,
    setInputQuery,
    singleTruckData,
    isLoading,
  } = useAddTruck({ truckID: params.truck_id });

  const { register, handleSubmit, reset, formState, control } = useForm<Inputs>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    },
  );

  // If it's edit mode, reset fields to API data
  useEffect(() => {
    if (singleTruckData) {
      reset(singleTruckData);
    }
  }, [singleTruckData]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    handleAddTruck(data);
  };

  const handleOnCancel = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.trucks}`);
  }, []);

  const errorsArray = Object.values(formState.errors);
  const disabledCTA = !formState.isDirty || isLoading || errorsArray.length > 0;

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    { location: URLS.trucks, name: 'Camiones', withUserRole: true },
    {
      location: URLS.addTruck,
      name: `${params.truck_id ? 'Editar' : 'Nuevo'} Camión`,
      withUserRole: true,
    },
  ];

  const breadcrumbItemsForDispatcher = [
    { location: URLS.addMic, name: 'Nuevo MIC', withUserRole: true },
    { location: URLS.addTruck, name: 'Nuevo Camión', withUserRole: true },
  ];

  const selectedBreadcrumbItems =
    useRole === USER_ROLES_LIST.dispatcher
      ? breadcrumbItemsForDispatcher
      : breadcrumbItems;
  return (
    <>
      <Breadcrumb items={selectedBreadcrumbItems} />

      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {params.truck_id ? 'Editar' : 'Nuevo'} camión
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AvailableComponent availableFor={[Role.SuperAdmin, Role.Dispatcher, Role.Admin]}>
          <div>
            <Typography theme="h3" as="h2">
              Propietario del vehículo
            </Typography>
            <StyledSubForm>
              <div>
                <Controller
                  name="vehicleOwner"
                  control={control}
                  rules={{
                    required: {
                      message: 'Este Campo es requerido',
                      value: true,
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <InputSelectList
                        options={inputOptions}
                        $hasValue={!!formState.dirtyFields.vehicleOwner}
                        $invalid={!!formState.errors.vehicleOwner}
                        label="Propietario"
                        placeholder="Propietario"
                        handleOnChange={(event) => {
                          field.onChange(
                            event as { label: string; value: string | number },
                          );
                        }}
                        handleOnInputChange={(event) => {
                          setInputQuery(event);
                        }}
                        subTextMessage={formState.errors.vehicleOwner?.message}
                        value={field.value}
                      />
                    );
                  }}
                />
              </div>
            </StyledSubForm>
          </div>
        </AvailableComponent>
        <div>
          <Typography theme="h3" as="h2">
            Datos del Vehículo
          </Typography>
          <StyledSubForm>
            <InputText
              placeholder="Patente"
              $hasValue={formState.dirtyFields.licensePlate}
              $invalid={!!formState.errors.licensePlate}
              label="Patente"
              subTextMessage={formState.errors.licensePlate?.message}
              {...register('licensePlate', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputText
              placeholder="Marca"
              $hasValue={formState.dirtyFields.brand}
              $invalid={!!formState.errors.brand}
              label="Marca"
              subTextMessage={formState.errors.brand?.message}
              {...register('brand', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputText
              placeholder="Número de chasis"
              $hasValue={formState.dirtyFields.chassisNumber}
              $invalid={!!formState.errors.chassisNumber}
              label="Número de chasis"
              subTextMessage={formState.errors.chassisNumber?.message}
              {...register('chassisNumber', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputText
              placeholder="Año"
              $hasValue={formState.dirtyFields.model}
              $invalid={!!formState.errors.model}
              label="Año"
              type="number"
              subTextMessage={formState.errors.model?.message}
              {...register('model', {
                required: 'Este Campo es requerido',
                pattern: {
                  value: ONLY_NUMBERS_VALIDATION,
                  message: 'El campo solo debe tener números',
                },
                min: {
                  value: yearLimit.min,
                  message: `El valor debe ser mayor que ${yearLimit.min}`,
                },
                max: {
                  value: yearLimit.max,
                  message: `El valor debe ser menor que ${yearLimit.max}`,
                },
              })}
            />
            <InputArea
              $hasValue={formState.dirtyFields.comment}
              $invalid={!!formState.errors.comment}
              label="Comentarios"
              maxLength={1000}
              placeholder="Comentarios"
              subTextMessage={formState.errors.comment?.message}
              {...register('comment')}
            />
          </StyledSubForm>
        </div>
        <div>
          <Typography theme="h3" as="h2">
            Póliza de Seguro
          </Typography>
          <StyledSubForm>
            <InputText
              $hasValue={formState.dirtyFields.insurancePolicyNumber}
              $invalid={!!formState.errors.insurancePolicyNumber}
              label="Número de Póliza"
              placeholder="Número de Póliza"
              subTextMessage={formState.errors.insurancePolicyNumber?.message}
              {...register('insurancePolicyNumber', {
                required: 'Este Campo es requerido',
              })}
            />
            <Controller
              name="insurancePolicyExpiration"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputDatePicker
                  $hasValue={formState.dirtyFields.insurancePolicyExpiration}
                  $invalid={!!formState.errors.insurancePolicyExpiration}
                  handleOnChange={(date) => {
                    field.onChange(date);
                  }}
                  label="Vencimiento de Poliza"
                  placeholder="Vencimiento de Poliza"
                  subTextMessage={formState.errors.vehicleOwner?.message}
                  value={field.value}
                />
              )}
            />
          </StyledSubForm>
        </div>
        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text={params.truck_id ? 'Actualizar' : 'Crear'}
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default AddTruckForm;
