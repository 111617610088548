import Table, { ColumnsType } from 'antd/es/table';
import React, { FC, useState } from 'react';

import {
  GenerateReportActions,
  InputDatePicker,
  InputSelect,
  InputSelectList,
  Loader,
} from '../../../../components';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { InputSelectListOption, PaginationData } from '../../../../utils/types';
import { PO } from '../../../../utils/types/po';
import { TableData } from '../../types';
import { transformData } from './utils';

type Props = {
  data: PO[];
  isLoading: boolean;
  paginationData: PaginationData;
  onGenerateReportPDF: () => void;
  onGenerateReportXLXS: () => void;
  onFilter: {
    dateFrom: (value: string) => void;
    dateTo: (value: string) => void;
    transport: (value: InputSelectListOption | null) => void;
    importer: (value: InputSelectListOption | null) => void;
    exporter: (value: InputSelectListOption | null) => void;
    orderOptions: (value: string) => void;
    order: (value: string) => void;
  };
  onFilterQuery: {
    transport: (value: string) => void;
    importer: (value: string) => void;
    exporter: (value: string) => void;
  };
  onFilterOptions: {
    transport: InputSelectListOption[];
    importer: InputSelectListOption[];
    exporter: InputSelectListOption[];
  };
};

const OpTable: FC<Props> = ({
  data,
  isLoading,
  onGenerateReportPDF,
  onGenerateReportXLXS,
  onFilter,
  onFilterQuery,
  onFilterOptions,
  paginationData,
}) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    { title: 'OP', key: 'op', dataIndex: 'op' },
    { title: 'Condición', key: 'condition', dataIndex: 'condition' },
    { title: 'Importador', key: 'importer', dataIndex: 'importer' },
    { title: 'Exportador', key: 'exporter', dataIndex: 'exporter' },
    { title: 'Transporte', key: 'transport', dataIndex: 'transport' },
    { title: 'Destino', key: 'destination', dataIndex: 'destination' },
    { title: 'Producto', key: 'product', dataIndex: 'product' },
    { title: 'SIM', key: 'sim', dataIndex: 'sim' },
    { title: 'Saldo', key: 'balance', dataIndex: 'balance' },
  ];

  const [dateFromValue, setDateFromValue] = useState<string | undefined>(
    undefined,
  );
  const [dateToValue, setDateToValue] = useState<string | undefined>(undefined);

  // Date From
  const handleOnFilterDateFrom = React.useCallback(
    (date: unknown, dateString: string) => {
      setDateFromValue(dateString);
      onFilter.dateFrom(dateString);
    },
    [],
  );

  // Date To
  const handleOnFilterDateTo = React.useCallback(
    (date: unknown, dateString: string) => {
      setDateToValue(dateString);
      onFilter.dateTo(dateString);
    },
    [],
  );

  const handleOrderOptionsSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.orderOptions(event.target.value);
    },
    [],
  );

  const handleOrderSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.order(event.target.value);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputDatePicker
          label="Fecha Desde"
          placeholder="Fecha Desde"
          handleOnChange={handleOnFilterDateFrom}
          value={dateFromValue}
        />
        <InputDatePicker
          label="Fecha Hasta"
          placeholder="Fecha Hasta"
          handleOnChange={handleOnFilterDateTo}
          value={dateToValue}
        />
        <InputSelectList
          handleOnChange={onFilter.transport}
          handleOnInputChange={onFilterQuery.transport}
          options={onFilterOptions.transport}
          label="Transporte"
          placeholder="Transporte"
        />
        <InputSelectList
          handleOnChange={onFilter.importer}
          handleOnInputChange={onFilterQuery.importer}
          options={onFilterOptions.importer}
          label="Importador"
          placeholder="Importador"
        />
        <InputSelectList
          handleOnChange={onFilter.exporter}
          handleOnInputChange={onFilterQuery.exporter}
          options={onFilterOptions.exporter}
          label="Exportador"
          placeholder="Exportador"
        />
      </StyledFiltersContainer>
      <StyledFiltersContainer>
        <InputSelect
          onChange={handleOrderOptionsSelect}
          options={[
            { value: 'date', text: 'Fecha' },
            { value: 'joined_balance', text: 'Saldo' },
            { value: 'carrier_company', text: 'Transporte' },
          ]}
          placeholder="Ordenar Por"
        />
        <InputSelect
          onChange={handleOrderSelect}
          options={[
            { value: 'ASC', text: 'Ascendente' },
            { value: 'DESC', text: 'Descendente' },
          ]}
          placeholder="Ordenar Por"
        />
      </StyledFiltersContainer>
      <GenerateReportActions
        onGenerateReportPDF={onGenerateReportPDF}
        onGenerateReportXLXS={onGenerateReportXLXS}
      />
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
      />
    </StyledTableContainer>
  );
};

export default OpTable;
