import React, { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { BoxContainer, Breadcrumb, Loader, Typography } from '../../components';
import { theme } from '../../styles';
import { fromUserRoleToUrl, URLS } from '../../utils';
import { StyledErrorMessage } from '../../utils/styles';
import useStore from '../../zustand';
import { StyledBalanceInfo, StyledHeaderBalanceInfo } from './AddSim.styled';
import AddSimForm from './components/AddSimForm';
import useAddSim from './hooks/useAddSim';
import { calculateSimBalance, CurrentSimBalance } from './utils';

const renderIcon = (value: number) => {
  return value > 0 ? (
    <FaCheck size="1.5rem" color={theme.colors.success} />
  ) : (
    <FaTimes size="1.5rem" color={theme.colors.error} />
  );
};

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id || String(),
      ),
      name: 'Detalle Factura',
      withUserRole: true,
    },
    {
      location: URLS.addInvoice,
      name: `${params.sim_id ? 'Editar' : 'Nuevo'} SIM`,
      withUserRole: true,
    },
  ];

  const { invoiceData, handleAddSim, singleSimData, customError, isLoading } =
    useAddSim({
      invoiceID: params.invoice_id,
      simID: params.sim_id,
    });

  const handleOnCancel = React.useCallback(() => {
    navigate(
      `/${transformedUserRole}${URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id ?? String(),
      )}`,
    );
  }, []);

  const [newSimBalance, setNewSimBalance] = useState<CurrentSimBalance[]>([]);

  const handleOnChangeQuantity = React.useCallback(
    (index: number, quantity: number) => {
      const isAbleToUpdateBalance = newSimBalance.some(
        (balance) => balance.index === index,
      );
      if (isAbleToUpdateBalance) {
        const updatedCurrentBalance = newSimBalance.map((balance) => {
          return balance.index === index ? { ...balance, quantity } : balance;
        });
        setNewSimBalance(updatedCurrentBalance);
      } else {
        setNewSimBalance([...newSimBalance, { index, quantity }]);
      }
    },
    [newSimBalance],
  );

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />
      <StyledHeaderBalanceInfo>
        <Typography theme="p" as="h2" $marginBottom="2rem">
          Saldos en Factura
        </Typography>
        <ul>
          {invoiceData.invoice_items?.map((item, index) => (
            <StyledBalanceInfo key={item.id}>
              {renderIcon(item.balance)}
              <Typography theme="p">{item.description}:</Typography>
              <Typography theme="p">
                {calculateSimBalance({
                  index,
                  currentSimData: singleSimData,
                  currentSimQuantities: newSimBalance,
                  currentInvoiceData: invoiceData.invoice_items,
                })}
              </Typography>
              <Typography theme="p">{item.unit}</Typography>
            </StyledBalanceInfo>
          ))}
        </ul>
      </StyledHeaderBalanceInfo>

      {customError.length > 0 && (
        <StyledErrorMessage>
          {customError.map((item) => (
            <div key={item.replaceAll('\u0020', String())}>
              <Typography theme="p" $marginBottom="1.5rem">
                {item}
              </Typography>
            </div>
          ))}
        </StyledErrorMessage>
      )}

      <AddSimForm
        singleSimData={singleSimData}
        invoiceItems={invoiceData?.invoice_items}
        handleOnSubmit={handleAddSim}
        handleOnFormCancel={handleOnCancel}
        onChangeQuantity={handleOnChangeQuantity}
        ctaText={params.sim_id ? 'Actualizar' : 'Crear'}
      />
    </BoxContainer>
  );
};

export default PageContent;
