import { ColumnsType } from 'antd/es/table';

import { formatDate } from '../../../../../utils/dates';
import { Data, TableData } from '../../../types';

export const transformData = (data?: Data[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      name: item.name,
      email: item.email,
      company: item.company_id?.business_name || String(),
      last_sign_in_at: formatDate(item?.last_sign_in_at, 'withTime'),
      role: item.role.name || String(),
      active: item.blocked,
    };
  });

  return transformedData;
};

export const columns: ColumnsType<TableData> = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Empresa',
    dataIndex: 'company',
    key: 'company',
    sorter: true,
  },
  {
    title: 'Última Sesión',
    dataIndex: 'lastSignIn',
    key: 'lastSignIn',
    sorter: true,
  },
  {
    title: 'Rol',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
  },
  {
    title: 'Activo',
    dataIndex: 'isActive',
    key: 'isActive',
  },
];

export const USER_ROLES_SELECT = [
  { value: 3, text: 'Administrador Despachante' },
  { value: 8, text: 'Administrador Imp/Exp' },
  { value: 5, text: 'Despachante' },
  { value: 6, text: 'Recepcionista' },
  { value: 7, text: 'Transportista' },
  { value: '', text: 'Todos los roles' },
];
