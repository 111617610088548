export const currencies = [
  { value: 'USD', text: 'DÓLAR (USD)' },
  { value: 'EUR', text: 'EURO (EUR)' },
  { value: 'CNY', text: 'YUAN (CNY)' },
  { value: 'ARP', text: 'PESO (ARP)' },
  { value: 'BRL', text: 'REAL (BRL)' },
  { value: 'GBP', text: 'LIBRA ESTERLINA (GBP)' },
];

export const countries = [
  { value: 'ARGENTINA', text: 'ARGENTINA (200)' },
  { value: 'BOLIVIA', text: 'BOLIVIA (202)' },
  { value: 'BRASIL', text: 'BRASIL (203)' },
  { value: 'CHILE', text: 'CHILE (208)' },
  { value: 'PARAGUAY', text: 'PARAGUAY (221)' },
  { value: 'PERU', text: 'PERU (222)' },
  { value: 'URUGUAY', text: 'URUGUAY (225)' },
];
