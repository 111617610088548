import log from 'loglevel';

const timeZone = 'America/Argentina/Buenos_Aires';

const dateFormatterSimple = new Intl.DateTimeFormat('es-AR', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone,
});

const dateFormatterWithTime = new Intl.DateTimeFormat('es-AR', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone,
});

enum DateTypes {
  'simple',
  'withTime',
}

const mappedDates: Record<keyof typeof DateTypes, Intl.DateTimeFormat> = {
  simple: dateFormatterSimple,
  withTime: dateFormatterWithTime,
};

export const formatDate = (
  date: Date,
  format: keyof typeof DateTypes,
): string => {
  let result = '';
  const dateFormat = mappedDates[format];

  try {
    result = date ? dateFormat.format(new Date(date)) : '';
    result = result.replace(',', ' -');
  } catch (error) {
    log.error(error);
    result = '';
  }

  return result;
};

export const END_OF_DAY_COMPLEMENT = 'T23:59:59';
