import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder } from '../../../utils';
import { useAxios } from '../../../utils/hooks';
import { PaginationData } from '../../../utils/types';
import { DataType } from '../components/DashboardTable/utils';
import { DashboardData } from '../types';

type Props = {
  paginationData: PaginationData;
};

const useDashboard = ({ paginationData }: Props) => {
  const API_URL_COMPANIES = '/companies';

  const paginationParams = `?pagination[page]=${paginationData.current}&pagination[pageSize]=${paginationData.pageSize}`;

  const filterSelectText = `&filters[type][$eq]=`,
    filterQueryText = `&filters[business_name][$containsi]=`;

  const axios = useAxios();

  const [data, setData] = useState<DashboardData | null>(null);
  const [totalResults, setTotalResults] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectFilterValue, setSelectFilterValue] = useState('');
  const [inputQueryFilterValue, setInputQueryFilterValue] = useState('');
  const [sortQuery, setSortQuery] = useState('');

  const onInputFilterChange = (value: string) => {
    setInputQueryFilterValue(value);
  };

  const onSelectFilterChange = (value: string) => {
    setSelectFilterValue(value);
  };

  const finalURL = () => {
    return `${API_URL_COMPANIES}${paginationParams}${
      selectFilterValue && filterSelectText + selectFilterValue
    }${
      inputQueryFilterValue && filterQueryText + inputQueryFilterValue
    }${sortQuery}`;
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    axios
      .get<DashboardData>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
        setTotalResults(response.data.meta.pagination.total);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    paginationData.current,
    selectFilterValue,
    inputQueryFilterValue,
    sortQuery,
  ]);

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<DataType> | SorterResult<DataType>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    data: data?.data,
    stats: data?.statistics,
    hasError,
    isLoading,
    totalResults,
    onInputFilterChange,
    onSelectFilterChange,
    handleTableChange,
  };
};

export default useDashboard;
