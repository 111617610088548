import React from 'react';

import { BoxContainer, Breadcrumb } from '../../components';
import { URLS } from '../../utils';
import AddUserForm from './components/AddUserForm';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.users,
      name: 'Usuarios',
      withUserRole: true,
    },
    {
      location: URLS.addUser,
      name: 'Nuevo Usuario',
      withUserRole: true,
    },
  ];

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <AddUserForm />
    </BoxContainer>
  );
};

export default PageContent;
