import React from 'react';

import { MyFilesPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isReceptionist,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const fileRoutes = (useRole: string): Routes[] => {
  return [
    {
      path: '/admin/mis-archivos',
      component: <MyFilesPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/cliente/mis-archivos',
      component: <MyFilesPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/transportista/mis-archivos',
      component: <MyFilesPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/recepcionista/mis-archivos',
      component: <MyFilesPage />,
      isAvailable: isReceptionist(useRole),
    },
    {
      path: '/despachante/mis-archivos',
      component: <MyFilesPage />,
      isAvailable: isDispatcher(useRole),
    },
  ];
};
