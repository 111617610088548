import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  Role,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';
import {
  CompanyResponseData,
  SemitrailerFormInputs,
  SingleSemitrailerResponse,
} from '../types';

type Props = {
  semitrailerID?: string;
};

const useAddSemitrailer = ({ semitrailerID }: Props) => {
  const API_URL_COMPANIES_SEARCH =
      'companies?filters[business_name][$containsi]=',
    API_URL_CREATE_SEMITRAILER = 'custom-wheeled/create',
    API_URL_GET_SEMITRAILER = 'wheeleds/',
    API_URL_UPDATE_SEMITRAILER = 'custom-wheeled/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const errorHandler = new ErrorHandler();

  const [inputOptions, setInputOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [inputQuery, setInputQuery] = useState('');
  const [singleSemitrailerData, setSingleSemitrailerData] =
    useState<SemitrailerFormInputs | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState<string[]>([]);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponseData>(`${API_URL_COMPANIES_SEARCH}${inputQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        const transformedData =
          result.length > 0
            ? result.map((item) => {
                return { value: item.id, label: item.attributes.business_name };
              })
            : [];
        setInputOptions(transformedData);
      })
      .catch((error) => {
        log.error(error);
      });
  }, [inputQuery]);

  const handleRedirect = () => {
    if (Role.Admin == transformedUserRole) {
      navigate(-1)
    } else {
      navigate(`/${transformedUserRole}${URLS.semitrailers}`)
    }
  }

  // Create/Update Semitrailer
  const handleAddSemitrailer = async (data: SemitrailerFormInputs) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      const semitrailerData = {
        insurance_policy: {
          number: data?.insurancePolicyNumber,
          expiration_date: data?.insurancePolicyExpiration,
        },
        wheeled: {
          license_plate: data?.licensePlate,
          type: 'Semitrailer',
          company_id: data?.vehicleOwner?.value,
          comment: data?.comment,
        },
      };

      if (semitrailerID) {
        // Update Mode
        await axios.put(
          API_URL_UPDATE_SEMITRAILER.replace('{id}', semitrailerID),
          { ...semitrailerData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });
      } else {
        // Create Mode
        await axios.post(
          API_URL_CREATE_SEMITRAILER,
          { ...semitrailerData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });
      }

      setTimeout(() => {
        handleRedirect();
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  // Get Semitrailer Data
  const handleGetSemitrailerData = async (semitrailerID: string) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<SingleSemitrailerResponse>(
        `${API_URL_GET_SEMITRAILER}${semitrailerID}?populate=*`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const result = response.data.data;

        const transformedData = {
          vehicleOwner: {
            label:
              result?.attributes?.company_id?.data?.attributes?.business_name,
            value: result?.attributes?.company_id?.data?.id,
          },
          licensePlate: result?.attributes?.license_plate,
          comment: result?.attributes?.comment,
          insurancePolicyNumber:
            result?.attributes?.insurance_policy?.data?.attributes?.number,
          insurancePolicyExpiration:
            result?.attributes?.insurance_policy?.data?.attributes?.expiration_date.toString(),
        };
        setSingleSemitrailerData(transformedData);
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (semitrailerID) handleGetSemitrailerData(semitrailerID);
  }, []);

  return {
    customError,
    handleAddSemitrailer,
    inputOptions,
    isLoading,
    setInputQuery,
    singleSemitrailerData,
  };
};

export default useAddSemitrailer;
