import { AxiosRequestConfig } from 'axios';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  NotificationMessages,
  URLS,
  USER_ROLES_MAP,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useGetCompanyById from '../../../utils/hooks/useGetCompanyById';
import { InputCompany, Inputs, InputsToSend, InputUser } from '../types';

type Props = {
  companyId?: number | string;
  companyType?: string | null;
};

const API_CREATE_COMPANY = 'custom-company/create';
const API_EDIT_COMPANY = 'companies/{companyId}';

const useAddCompany = ({ companyType, companyId }: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState<string[]>([]);

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const { getCompanyById, companyInfo: singleCompanyData } = useGetCompanyById({
    companyId,
  });

  useEffect(() => {
    if (companyId) {
      setIsLoading(true);
      getCompanyById().finally(() => setIsLoading(false));
    }
  }, []);

  const errorHandler = new ErrorHandler();

  const sendData = async (data: InputsToSend, config: AxiosRequestConfig) => {
    try {
      await axios.post(API_CREATE_COMPANY, { ...data }, config);
      showNotification({
        message: NotificationMessages.MY_COMPANY_EDIT_SUCCESS,
      });
      setTimeout(() => {
        navigate(URLS.dashboard);
      }, WAIT_TIMES.md);
    } catch (error: unknown) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setHasError(true);
    }
  };

  const updateData = async (
    company: InputCompany,
    config: AxiosRequestConfig,
  ) => {
    try {
      await axios.put(
        API_EDIT_COMPANY.replace('{companyId}', companyId as string),
        { data: { ...company } },
        config,
      );
      showNotification({
        message: 'Ha sido modificado satisfactoriamente',
      });
      setTimeout(() => {
        navigate(URLS.dashboard);
      }, WAIT_TIMES.md);
    } catch (error: unknown) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setHasError(true);
    }
  };

  const handleAddCompany = async (data: Inputs) => {
    try {
      setHasError(false);
      setIsLoading(true);

      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (!companyId) {
        // Create Company
        if (!companyType) throw Error('No Company Type');

        const userRole = USER_ROLES_MAP(companyType)?.id;
        if (!userRole) throw Error('User role is not valid');

        const companyData: InputCompany = {
          business_name: data.business_name,
          tax_id: data.tax_id,
          address: data.address,
          city: data.city,
          country: data.country,
          cnrt: data.cnrt,
          international_dr: data.international_dr,
          type: companyType.replace('-', '/'),
        };
        const userData: InputUser = {
          name: data.name,
          lastName: data.lastName,
          email: data.email,
          role: userRole,
          uid: uuidv4(),
        };
        const dataToSend = {
          company: companyData,
          user: userData,
        };

        await sendData(dataToSend, config);
      } else {
        // Update Company
        const companyData: InputCompany = {
          business_name: data.business_name,
          tax_id: data.tax_id,
          address: data.address,
          city: data.city,
          country: data.country,
          cnrt: data.cnrt,
          international_dr: data.international_dr,
        };
        await updateData(companyData, config);
      }
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
      log.error(error);
    }
  };

  return {
    handleAddCompany,
    hasError,
    isLoading,
    customError,
    singleCompanyData,
  };
};

export default useAddCompany;
