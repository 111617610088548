import React from 'react';

import { AddTruckDriversPage, TruckDriversPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const truckDriversRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/choferes',
      component: <TruckDriversPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/choferes/nuevo',
      component: <AddTruckDriversPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/choferes/:truck_driver_id/editar',
      component: <AddTruckDriversPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/choferes',
      component: <TruckDriversPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/choferes/nuevo',
      component: <AddTruckDriversPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/choferes/:truck_driver_id/editar',
      component: <AddTruckDriversPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/choferes',
      component: <TruckDriversPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/choferes/nuevo',
      component: <AddTruckDriversPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/choferes/:truck_driver_id/editar',
      component: <AddTruckDriversPage />,
      isAvailable: isTransporter(useRole),
    },
    // Dispatcher
    {
      path: '/despachante/choferes/nuevo',
      component: <AddTruckDriversPage />,
      isAvailable: isDispatcher(useRole),
    },
    // Clients
    {
      path: '/cliente/choferes',
      component: <TruckDriversPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/choferes/nuevo',
      component: <AddTruckDriversPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/choferes/:truck_driver_id/editar',
      component: <AddTruckDriversPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
