import React from 'react';

import { Footer, Header, PageLayout } from '../../components';
import PageContent from '../../features/Crts';

const CrtsPage = () => {
  return (
    <>
      <Header />
      <PageLayout>
        <PageContent />
      </PageLayout>
      <Footer />
    </>
  );
};

export default CrtsPage;
