import log from 'loglevel';
import { useState } from 'react';

import { WAIT_TIMES } from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { RecoveryPasswordInputs } from '../types';

type Props = {
  setShowModal: Function;
};

const useRecoveryPassword = ({ setShowModal }: Props) => {
  const API_RECOVERY_PASSWORD_URL = 'custom-user/recovery-pass';

  const axios = useAxios();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRecoveryPassword = async (rpData: RecoveryPasswordInputs) => {
    setIsLoading(true);
    setHasError(false);
    try {
      await axios.post(API_RECOVERY_PASSWORD_URL, { ...rpData });
      showNotification({
        message: 'El email ha sido enviado satisfactoriamente.',
      });
      setTimeout(() => {
        setShowModal(false);
      }, WAIT_TIMES.xsm);
    } catch (error) {
      const errorHandler = new ErrorHandler();
      let message = 'Ha ocurrido un error';
      if (404 == errorHandler.readErrorCode(error)) {
        message =
          'No existe ningun usuario con este email. Por favor, vuelva a intentarlo.';
      }
      showErrorNotification({
        message: message,
      });
      log.error(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleRecoveryPassword, hasError, isLoading };
};

export default useRecoveryPassword;
