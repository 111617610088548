import log from 'loglevel';
import { useState } from 'react';

import { Company, SingleCompanyResponse } from '../types/company';
import useAxios from './useAxios';

type Props = {
  companyId?: number | string;
};

const useGetCompanyById = ({ companyId }: Props) => {
  const API_SEARCH_COMPANIES = '/companies';

  const axios = useAxios();
  const [companyInfo, setCompanyInfo] = useState<Company>();

  const getCompanyById = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get<SingleCompanyResponse>(
        `${API_SEARCH_COMPANIES}/${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const rawResponse = response.data.data;
      setCompanyInfo(rawResponse);
    } catch (error) {
      log.error(error);
    }
  };

  return { getCompanyById, companyInfo };
};

export default useGetCompanyById;
