import { TableData, TruckDriver } from '../../../types';

export const buildOrQueryFilter = (
  firstFilterName: string,
  secondFilterName: string,
  toSearch: string,
) => {
  return toSearch
    ? `&filters[$or][0][${firstFilterName}][$containsi]=${toSearch}&filters[$or][1][${secondFilterName}][$containsi]=${toSearch}`
    : '';
};

export const transformData = (data?: TruckDriver[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      full_name: `${item.attributes.first_name}  ${item.attributes.last_name}`,
      ic: item.attributes.ic,
      ic_type: item.attributes.ic_type,
      company: item.attributes.company_id.data.attributes.business_name,
    };
  });
  return transformedData;
};
