import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';
import { FaCheck, FaList, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {
  InputCheckbox,
  InputSelectList,
  InputText,
  Loader,
} from '../../../../components';
import { theme } from '../../../../styles';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { InputSelectListOption, PaginationData } from '../../../../utils/types';
import { Invoice, TableData } from '../../types';
import CompanyInfoModal from '../CompanyInfoModal';
import {
  StyledTableIconCell,
  StyledTableIconCellTooltip,
} from './InvoicesTable.styled';
import { transformData } from './utils';

type Props = {
  data?: Invoice[] | null;
  isLoading: boolean;
  onFilterInvoiceNumberChange: (value: string) => void;
  onInputCheckboxChange: (value: boolean) => void;
  onSelectOptionChange: (value: InputSelectListOption | null) => void;
  onSelectQueryChange: (value: string) => void;
  selectListOptions: InputSelectListOption[];
  paginationData: PaginationData;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => void;
};

const InvoicesTable: FC<Props> = ({
  data,
  isLoading,
  onFilterInvoiceNumberChange,
  onInputCheckboxChange,
  onSelectQueryChange,
  onSelectOptionChange,
  selectListOptions,
  paginationData,
  onTableChange,
}) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    {
      title: 'Número de Factura',
      key: 'invoice_number',
      align: 'left',
      sorter: true,
      render: (props: TableData) => {
        return <Link to={`${props.key}`}>{props.invoice_number}</Link>;
      },
    },
    {
      title: 'Exportador',
      key: 'exporter',
      align: 'left',
      // sorter: true, // TODO: Fix nested sorter
      render: (props: TableData) => {
        const exporterCompanyName = props.exporter;
        return (
          <CompanyInfoModal
            companyId={props.exporterCompanyId}
            titleCompanyName={exporterCompanyName}
          >
            <StyledTableIconCell className="left">
              {exporterCompanyName}
            </StyledTableIconCell>
          </CompanyInfoModal>
        );
      },
    },
    {
      title: 'Importador',
      key: 'importer',
      align: 'left',
      // sorter: true, // TODO: Fix nested sorter
      render: (props: TableData) => {
        const importerCompanyName = props.importer;
        return (
          <CompanyInfoModal
            companyId={props.importerCompanyId}
            titleCompanyName={importerCompanyName}
          >
            <StyledTableIconCell className="left">
              {importerCompanyName}
            </StyledTableIconCell>
          </CompanyInfoModal>
        );
      },
    },
    {
      title: 'Detalle',
      dataIndex: 'detail',
      key: 'detail',
      align: 'left',
      render: (value?: string) => {
        if (value && value.includes(',\n')) {
          return (
            <StyledTableIconCellTooltip className="left">
              <Tooltip
                placement="right"
                title={value.replaceAll(',', '').trim()}
              >
                <FaList size="2.5rem" color={theme.colors.primary} />
              </Tooltip>
            </StyledTableIconCellTooltip>
          );
        }
        return (
          <StyledTableIconCell className="left">{value}</StyledTableIconCell>
        );
      },
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      align: 'left',
      render: (value?: string) => {
        if (value && value.includes(',\n')) {
          return (
            <StyledTableIconCellTooltip className="left">
              <Tooltip placement="right" title={value.replaceAll(',', '')}>
                <FaList size="2.5rem" color={theme.colors.primary} />
              </Tooltip>
            </StyledTableIconCellTooltip>
          );
        }
        return (
          <StyledTableIconCell className="left">{value}</StyledTableIconCell>
        );
      },
    },
    {
      title: 'Operable',
      dataIndex: 'operable',
      key: 'operable',
      align: 'left',
      sorter: true,
      render: (props: TableData) => {
        const Icon = props ? (
          <FaCheck color={theme.colors.success} />
        ) : (
          <FaTimes color={theme.colors.error} />
        );
        return <StyledTableIconCell>{Icon}</StyledTableIconCell>;
      },
    },
  ];

  const handleSearchByInvoiceNumber = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilterInvoiceNumberChange(event.target.value);
    },
    [],
  );

  const handleFilterByCheckbox = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputCheckboxChange(event.target.checked);
    },
    [],
  );

  // Select List Handlers
  const handleSelectQueryChange = React.useCallback((event: string) => {
    onSelectQueryChange(event);
  }, []);

  const handleSelectOptionChange = React.useCallback(
    (event: InputSelectListOption | null) => {
      onSelectOptionChange(event);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputText
          placeholder="Número de Factura"
          onChange={handleSearchByInvoiceNumber}
        />
        <InputSelectList
          options={selectListOptions}
          placeholder="Cliente"
          handleOnChange={handleSelectOptionChange}
          handleOnInputChange={handleSelectQueryChange}
        />
        <InputCheckbox
          label="Operable"
          onChange={handleFilterByCheckbox}
          customID="invoices-filter"
        />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default InvoicesTable;
