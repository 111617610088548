import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BoxContainer, Breadcrumb, Loader, Typography } from '../../components';
import { fromUserRoleToUrl, URLS } from '../../utils';
import { StyledErrorMessage } from '../../utils/styles';
import useStore from '../../zustand';
import AddMerchandiseForm from './components/AddMerchandiseForm';
import useAddMerchandise from './hooks/useAddMerchandise';

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listMerchandise,
      name: 'Mercaderias',
      withUserRole: true,
    },
    {
      location: URLS.addMerchandise,
      name: `${params.merchandise_id ? 'Editar' : 'Nueva'} Mercaderia`,
      withUserRole: true,
    },
  ];

  const {
    customError,
    singleMerchandiseData,
    isLoading,
    handleAddMerchandise,
  } = useAddMerchandise({
    merchandiseID: params.merchandise_id,
  });

  const handleOnCancel = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.merchandise}`);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {params.merchandise_id ? 'Editar' : 'Nueva'} Mercaderia
      </Typography>

      {customError.length > 0 && (
        <StyledErrorMessage>
          {customError.map((item) => (
            <div key={item.replaceAll(' ', '')}>
              <Typography theme="p" $marginBottom="1.5rem">
                {item}
              </Typography>
            </div>
          ))}
        </StyledErrorMessage>
      )}

      <AddMerchandiseForm
        singleMerchandiseData={singleMerchandiseData}
        handleOnSubmit={handleAddMerchandise}
        handleOnFormCancel={handleOnCancel}
        ctaText={params.merchandise_id ? 'Actualizar' : 'Crear'}
      />
    </BoxContainer>
  );
};

export default PageContent;
