import styled, { css } from 'styled-components';

import { theme } from '../../styles';
import { TypesList } from '.';

type AlertProps = {
  type?: keyof typeof TypesList;
};

export const StyledAlertContainer = styled.div<AlertProps>`
  align-items: center;
  color: ${theme.colors.defaultWhite};
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
  > div {
    display: flex;
    gap: 1rem;
    position: relative;
    z-index: 2;
  }
  &::before {
    content: '';
    border-radius: 0.5rem;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    ${({ type }) => {
      switch (type) {
        case 'warning':
          return css`
            background-color: ${theme.colors.warning};
          `;

        case 'error':
          return css`
            background-color: ${theme.colors.error};
          `;

        default:
          return css`
            background-color: ${theme.colors.primary};
          `;
      }
    }}
  }
`;
