import React from 'react';

import { AddSemitrailerPage, SemitrailersPage } from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const semitrailerRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/semiremolques',
      component: <SemitrailersPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/semiremolques/nuevo',
      component: <AddSemitrailerPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/semiremolques/:semitrailer_id/editar',
      component: <AddSemitrailerPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/semiremolques',
      component: <SemitrailersPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/semiremolques/nuevo',
      component: <AddSemitrailerPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/semiremolques/:semitrailer_id/editar',
      component: <AddSemitrailerPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/semiremolques',
      component: <SemitrailersPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/semiremolques/nuevo',
      component: <AddSemitrailerPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/semiremolques/:semitrailer_id/editar',
      component: <AddSemitrailerPage />,
      isAvailable: isTransporter(useRole),
    },
    // Dispatcher
    {
      path: '/despachante/semiremolques/nuevo',
      component: <AddSemitrailerPage />,
      isAvailable: isDispatcher(useRole),
    },
    // Clients
    {
      path: '/cliente/semiremolques',
      component: <SemitrailersPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/semiremolques/nuevo',
      component: <AddSemitrailerPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/semiremolques/:semitrailer_id/editar',
      component: <AddSemitrailerPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
