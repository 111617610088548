import React, { FC } from 'react';

import Typography from '../Typography';
import { StyledEmptyMessage } from './EmptyMessage.styled';

type Props = {
  canShow: boolean;
};

const EmptyMessage: FC<Props> = ({ canShow }) => {
  if (!canShow) return null;
  return (
    <StyledEmptyMessage>
      <Typography theme="h6" as="p">
        No hay datos
      </Typography>
    </StyledEmptyMessage>
  );
};

export default EmptyMessage;
