import styled from 'styled-components';

import { theme } from '../../../../styles';

export const StyledActionsContaner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 4rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  button {
    min-width: 13rem;
    min-height: 3.8rem;
  }

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;

    > div {
      flex-direction: row;
      flex: 1 1 50%;
      min-width: unset;
      &:nth-child(1) {
        justify-content: flex-start;
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }
`;
