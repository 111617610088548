import { Router } from '../types/route';
import { companyRoutes } from './Company';
import { crtRoutes } from './Crts';
import { fileRoutes } from './Files';
import { invoiceRoutes } from './Invoices';
import { merchandiseRoutes } from './Merchandise';
import { micsRoutes } from './Mics';
import { policyRoutes } from './Policies';
import { reportRoutes } from './Reports';
import { semitrailerRoutes } from './Semitrailers';
import { simRoutes } from './Sims';
import { truckDriversRoutes } from './TruckDrivers';
import { trucksRoutes } from './Trucks';
import { userRoutes } from './Users';

const AppRouter = (useRole: string): Router => {
  return {
    crts: crtRoutes(useRole),
    mics: micsRoutes(useRole),
    sims: simRoutes(useRole),
    users: userRoutes(useRole),
    files: fileRoutes(useRole),
    reports: reportRoutes(useRole),
    trucks: trucksRoutes(useRole),
    invoices: invoiceRoutes(useRole),
    policies: policyRoutes(useRole),
    companies: companyRoutes(useRole),
    merchandises: merchandiseRoutes(useRole),
    semitrailers: semitrailerRoutes(useRole),
    truckDrivers: truckDriversRoutes(useRole),
  };
};

export default AppRouter;
