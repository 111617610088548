import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Loader,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, isTransporter, Role } from '../../utils';
import AvailableComponent from '../../utils/AvailableComponent';
import useStore from '../../zustand';
import MicsTable from './components/MicsTable';
import { StyledMainContainer } from './CrtRelatedMics.styled';
import useCrtRelatedMics from './hooks/useCrtRelatedMics';
import { breadcrumbs, handleGoToCrtDetails } from './utils';

const PageContent = () => {
  const params = useParams();

  const crtID = params.crt_id;
  const invoiceID = params.invoice_id;
  const simID = params.sim_id;

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const breadcrumbItems = breadcrumbs({
    userRole,
    params,
    invoiceID,
    simID,
    crtID,
  });

  const { data, setCheckboxFilterValue, setNumberFilterValue, isLoading } =
    useCrtRelatedMics({
      crtID: params.crt_id,
    });

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        MICs
      </Typography>
      <AvailableComponent availableFor={[Role.Admin]}>
        <Link to="nuevo">
          <CustomButton
            text="Nuevo MIC"
            $isMini
            $marginBottom="4rem"
            $marginTop="0"
          />
        </Link>
      </AvailableComponent>
      <StyledMainContainer>
        {!isTransporter(userRole) && (
          <li>
            <Typography theme="p" as="h2">
              SIM:
            </Typography>
            &nbsp;
            <Link
              to={`/${transformedUserRole}/facturas/${invoiceID}/sims/${simID}`}
            >
              <Typography theme="p">{data?.related_documents?.sim}</Typography>
            </Link>
          </li>
        )}
        <li>
          <Typography theme="p" as="h2">
            CRT:
          </Typography>
          &nbsp;
          <Link
            to={handleGoToCrtDetails({
              userRole,
              invoiceID,
              simID,
              crtID,
              transformedUserRole,
            })}
          >
            <Typography theme="p">{data?.related_documents?.crt}</Typography>
          </Link>
        </li>
      </StyledMainContainer>
      <MicsTable
        data={data?.mics}
        onCheckboxFilterChange={setCheckboxFilterValue}
        onNumberFilterChange={setNumberFilterValue}
      />
    </BoxContainer>
  );
};

export default PageContent;
