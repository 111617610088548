import React, { FC } from 'react';
import { FaPlus } from 'react-icons/fa';
import Select, { SingleValue } from 'react-select';

import { theme } from '../../styles';
import Typography from '../Typography';
import {
  StyledInputLabel,
  StyledSelectContainer,
  SubTextContainer,
} from './InputSelectList.styled';

type Props = {
  $hasValue?: boolean;
  $invalid?: boolean;
  handleOnChange: (
    event: SingleValue<{ label: string; value: string | number }>,
  ) => void;
  handleOnInputChange?: (value: string) => void;
  label?: string;
  options: { label: string; value: string | number }[];
  subTextMessage?: string;
  placeholder: string;
  value?: { label: string; value: string | number };
  isSearchable?: boolean;
  isClearable?: boolean;
  onAuxiliarButton?: () => void;
  isDisabled?: boolean;
};

const InputSelectList: FC<Props> = ({
  $hasValue,
  $invalid,
  handleOnChange,
  handleOnInputChange,
  label,
  options,
  placeholder,
  subTextMessage,
  value,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  onAuxiliarButton,
}) => {
  return (
    <div>
      {label && (
        <StyledInputLabel>
          <Typography theme="subtext">{label}</Typography>
        </StyledInputLabel>
      )}
      <StyledSelectContainer $hasValue={$hasValue} $invalid={$invalid}>
        <Select
          placeholder={placeholder}
          options={options}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          value={value}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={isDisabled}
        />
        {onAuxiliarButton && (
          <button onClick={onAuxiliarButton} type="button">
            <FaPlus color={theme.colors.defaultGray} />
          </button>
        )}
      </StyledSelectContainer>
      {subTextMessage && (
        <SubTextContainer>
          <Typography theme="subtext">{subTextMessage}</Typography>
        </SubTextContainer>
      )}
    </div>
  );
};

export default InputSelectList;
