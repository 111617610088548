import { PoliciesType } from '../types';

export const filterResults = (
  resultsArray: PoliciesType[],
  filterArray: PoliciesType[],
) => {
  return resultsArray.filter((resultsItem) => {
    return !filterArray.some((filterItem) => filterItem.id === resultsItem.id);
  });
};
