import React from 'react';

import { BoxContainer, Logo } from '../../components';
import LoginForm from '../../features/Login/components/LoginForm';
import { MainWrapper } from './Login.styled';

const LoginPage = () => {
  return (
    <MainWrapper>
      <BoxContainer $isFullWidth>
        <Logo asTag="h1" $marginBottom="6.5rem" layout="desktop" />
        <LoginForm />
      </BoxContainer>
    </MainWrapper>
  );
};

export default LoginPage;
