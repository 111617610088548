import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { CompanyResponse } from '../../../utils/types/company';

const useGetFilterOptions = () => {
  const axios = useAxios();
  const token = sessionStorage.getItem('token');

  // URLS
  const TRANSPORT_URL =
    '/companies?filters[type][$eq]=Transportista&filters[business_name][$containsi]=';
  const IMPORTER_EXPORTER_URL =
    '/companies?filters[type][$eq]=Importadora/Exportadora&filters[business_name][$containsi]=';

  // Filter Queries
  const [transportrQuery, setTransportQuery] = useState('');
  const [importerQuery, setImporterQuery] = useState('');
  const [exporterQuery, setExporterQuery] = useState('');

  // Options States
  const [transportOptions, setTransportOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [importerOptions, setImporterOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [exporterOptions, setExporterOptions] = useState<
    InputSelectListOption[]
  >([]);

  // Fetch Options
  // Transport
  const getTransportOptions = () => {
    axios
      .get<CompanyResponse>(`${TRANSPORT_URL}${transportrQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setTransportOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getTransportOptions();
  }, [transportrQuery]);

  // Importer
  const getImporterOptions = () => {
    axios
      .get<CompanyResponse>(`${IMPORTER_EXPORTER_URL}${importerQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setImporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getImporterOptions();
  }, [importerQuery]);

  // Exporter
  const getExporterOptions = () => {
    axios
      .get<CompanyResponse>(`${IMPORTER_EXPORTER_URL}${exporterQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setExporterOptions(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };
  useEffect(() => {
    getExporterOptions();
  }, [exporterQuery]);

  // Results
  const filterQueries = {
    transport: setTransportQuery,
    importer: setImporterQuery,
    exporter: setExporterQuery,
  };

  const filterOptions = {
    transport: transportOptions,
    importer: importerOptions,
    exporter: exporterOptions,
  };

  return {
    filterOptions,
    filterQueries,
  };
};

export default useGetFilterOptions;
