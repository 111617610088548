import { CrtPlainItems } from '../../../../../utils/types/crt';
import { TableData } from '../types';

export const transformData = (data?: CrtPlainItems[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      merchandise: item.merchandise_id?.description,
      quantity: item.quantity,
      balance: item.balance,
      unit: item.unit,
    };
  });

  return transformedData;
};
