import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CUSTOMS_DESTINATIONS,
  fromUserRoleToUrl,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { Invoice } from '../../../utils/types/invoice';
import { PlainSimDataAlt } from '../../../utils/types/sims';
import useStore from '../../../zustand';
import { AddSimInputs, MerchandiseInputs } from '../types';

type Props = {
  invoiceID?: string;
  simID?: string;
};

const useAddSim = ({ invoiceID, simID }: Props) => {
  const API_URL_CREATE_SIM = '/custom-sim/create',
    API_URL_FIND_SIM = '/custom-sim/{sim_id}/find',
    API_URL_GET_INVOICE = `/custom-invoice/{id}/find`,
    API_URL_UPDATE_SIM = '/custom-sim/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const errorHandler = new ErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [invoiceData, setInvoiceData] = useState<Invoice>({} as Invoice);
  const [singleSimData, setSingleSimData] = useState<AddSimInputs>(
    {} as AddSimInputs,
  );
  const [singleSimDataRaw, setSingleSimDataRaw] = useState<PlainSimDataAlt>(
    {} as PlainSimDataAlt,
  );
  const [customError, setCustomError] = useState<string[]>([]);

  // Get Invoice Data
  const getInvoiceData = () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      throw new Error('No token provided');
    }

    if (!invoiceID) {
      throw new Error('No invoice provided');
    }

    axios
      .get<Invoice>(
        `${API_URL_GET_INVOICE.replace('{id}', invoiceID)}?populate=*`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        setInvoiceData(response.data);
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      getInvoiceData();
    } catch (error) {
      log.error('No token provided');
    }
  }, []);

  // Get SIM Data
  const getSimData = (token: string | null, simID: string) => {
    setIsLoading(true);
    setHasError(false);

    if (!simID) throw new Error('No Sim ID provided');

    axios
      .get<PlainSimDataAlt>(API_URL_FIND_SIM.replace('{sim_id}', simID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data;
        const merchandiseItems = result.sim_items;
        const getCustomsDestination =
          CUSTOMS_DESTINATIONS.find(
            (destination) => destination.value === result.customs_destination,
          ) || ({} as InputSelectListOption);

        setSingleSimDataRaw(result);

        const transformedData = {
          year: result.year.toString(),
          customs: result.customs_code,
          type: result.operation_type,
          registerNumber: result.reg_number,
          DC: result.control_digit,
          customsBroker: result.customs_agent,
          customsTransportAgent: result.customs_agent_transport,
          shipmentExpiration: result.shipment_due_date.toString(),
          customsDestination: getCustomsDestination,
          merchandiseDeclaration: merchandiseItems.map((item, index) => ({
            description: item?.invoice_id?.invoice_items?.[index]?.description,
            quantity: item.quantity,
            exportCertificate: parseInt(item.export_record),
          })),
        };
        setSingleSimData(transformedData);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }
      if (simID) getSimData(token, simID);
    } catch (error) {
      log.error('No token provided');
      setHasError(true);
    }
  }, []);

  // Create / Update SIM
  const handleAddSim = async (data: AddSimInputs) => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      // Create SIM
      if (!simID) {
        const simData = {
          data: {
            invoice_id: invoiceData.id,
            year: data.year,
            customs_code: data.customs,
            operation_type: data.type,
            reg_number: data.registerNumber,
            control_digit: data.DC,
            customs_agent: data.customsBroker,
            customs_agent_transport: data.customsTransportAgent,
            shipment_due_date: data.shipmentExpiration,
            customs_destination: data.customsDestination.value,
            sim_items: {
              data: data.merchandiseDeclaration.map(
                (item: MerchandiseInputs, index) => ({
                  unit: invoiceData.invoice_items[index].unit,
                  quantity: item.quantity,
                  export_record:
                    item.exportCertificate > 0
                      ? item.exportCertificate
                      : undefined,
                  merchandise_id:
                    invoiceData?.invoice_items?.[index]?.merchandise_id?.id,
                }),
              ),
            },
          },
        };

        await axios.post(
          API_URL_CREATE_SIM,
          { ...simData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        setIsLoading(false);

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}${URLS.invoiceDetail.replace(
              '{invoiceID}',
              invoiceID?.toString() || String(),
            )}`,
          );
        }, WAIT_TIMES.md);
      } else {
        // Update SIM
        const simData = {
          data: {
            invoice_id: invoiceData.id,
            year: data.year,
            customs_code: data.customs,
            operation_type: data.type,
            reg_number: data.registerNumber,
            control_digit: data.DC,
            customs_agent: data.customsBroker,
            customs_agent_transport: data.customsTransportAgent,
            shipment_due_date: data.shipmentExpiration,
            customs_destination: data.customsDestination.value,
            sim_items: {
              data: data.merchandiseDeclaration.map((item, index) => ({
                id: singleSimDataRaw?.sim_items?.[index]?.id,
                unit: invoiceData.invoice_items[index].unit,
                quantity: item.quantity,
                export_record: item.exportCertificate,
                merchandise_id:
                  invoiceData?.invoice_items?.[index]?.merchandise_id?.id,
              })),
            },
          },
        };

        await axios.put(
          API_URL_UPDATE_SIM.replace('{id}', simID),
          { ...simData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });
        setTimeout(() => {
          navigate(
            `/${transformedUserRole}${URLS.invoiceDetail.replace(
              '{invoiceID}',
              invoiceID?.toString() || String(),
            )}${URLS.simDetail.replace('{simID}', simID)}`,
          );
        }, WAIT_TIMES.md);
      }
    } catch (error) {
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  return {
    handleAddSim,
    hasError,
    invoiceData,
    isLoading,
    singleSimData,
    customError,
  };
};

export default useAddSim;
