import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledTagContainer = styled.div`
  background-color: gray;
  border-radius: 0.5rem;
  display: inline-flex;
  padding: 0.5rem 1rem;
  color: ${theme.colors.defaultWhite};

  &.inactivo {
    color: ${theme.colors.defaultBlack};
  }
`;
