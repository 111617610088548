import { SelectedCrtResponse } from '../../../utils/types/crt_alt';

export const filterCrtsWithoutExpiredSim = (crts: SelectedCrtResponse[]) => {
  if (!crts.length) return crts;

  const filteredCrts = crts.map((crt) => {
    const currentTime = new Date().getTime();
    const shipmentDueDate = Date.parse(crt.attributes.sim.shipment_due_date);

    const expired = currentTime > shipmentDueDate;
    const attributes = { ...crt.attributes };

    return {
      ...crt,
      attributes: { ...attributes, sim: { ...attributes.sim, expired } },
    } as SelectedCrtResponse;
  });

  return filteredCrts.filter((crt) => !crt.attributes.sim.expired);
};
