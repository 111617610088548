import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { CompanyModalOpener, Typography } from '../../../../components';
import { formatDate } from '../../../../utils/dates';
import { Mic } from '../../../../utils/types/mics';
import useStore from '../../../../zustand';
import {
  StyledCardBottomlData,
  StyledCardBottomlDataList,
  StyledCardContainer,
  StyledCardData,
} from './CardData.styled';
import { handleGoToMicDetails } from './utils';

type Props = {
  data: Mic;
};

const CardData: FC<Props> = ({ data }) => {
  const [useRole] = useStore((state) => [state.userRole]);

  const driver = data?.attributes?.truck_driver?.data?.attributes;

  const originDate =
    formatDate(data?.attributes?.crt_id?.reception?.date, 'simple') || String();
  const destinationDate =
    formatDate(data?.attributes?.crt_id?.delivery?.date, 'simple') || String();
  const issueDate =
    formatDate(data?.attributes?.date_of_issue, 'simple') || String();
  const receptionDate =
    formatDate(data?.attributes?.reception_date, 'simple') || String();

  const generalData = [
    {
      title: 'Transporte',
      content: (
        <Typography theme="p">
          {data?.attributes?.crt_id?.carrier_company?.business_name}
        </Typography>
      ),
    },
    {
      title: 'Propietario del transporte',
      content: (
        <Typography theme="p">
          {data?.attributes?.vehicle_owner?.data?.attributes?.business_name}
        </Typography>
      ),
    },
    {
      title: 'Exportador',
      content: (
        <CompanyModalOpener
          data={data?.attributes?.exporter_id?.data?.attributes}
        />
      ),
    },
    {
      title: 'Importador',
      content: (
        <CompanyModalOpener
          data={data?.attributes?.importer_id?.data?.attributes}
        />
      ),
    },
    {
      title: 'Origen',
      content: (
        <Typography theme="p">
          {`${data?.attributes?.crt_id?.reception?.country} - ${originDate}`}
        </Typography>
      ),
    },
    {
      title: 'Destino',
      content: (
        <Typography theme="p">
          {`${data?.attributes?.crt_id?.delivery?.place}, ${data?.attributes?.crt_id?.delivery?.country} - ${destinationDate}`}
        </Typography>
      ),
    },
    {
      title: 'Despachante',
      content: (
        <Typography theme="p">
          {data?.attributes?.related_documents?.sim?.customs_agent}
        </Typography>
      ),
    },
    {
      title: 'Agente de Transporte',
      content: (
        <Typography theme="p">
          {data?.attributes?.related_documents?.sim?.customs_agent_transport}
        </Typography>
      ),
    },
    {
      title: 'Fecha de despacho',
      content: <Typography theme="p">{issueDate}</Typography>,
    },
    {
      title: 'Fecha de recepcion',
      content: <Typography theme="p">{receptionDate}</Typography>,
    },
    {
      title: 'Remito',
      content: (
        <Typography theme="p">{data?.attributes?.shipment_number}</Typography>
      ),
    },
    {
      title: 'OP',
      content: (
        <Typography theme="p">
          {data?.attributes?.related_documents?.invoice?.po}
        </Typography>
      ),
    },
    {
      title: 'Factura',
      content: (
        <Typography theme="p">
          {data?.attributes?.related_documents?.invoice?.invoice_number}
        </Typography>
      ),
    },
    {
      title: 'SIM',
      content: (
        <Typography theme="p">
          {data?.attributes?.related_documents?.sim?.number}
        </Typography>
      ),
    },
    {
      title: 'CRT',
      content: (
        <Typography theme="p">{data?.attributes?.crt_id?.number}</Typography>
      ),
    },
    {
      title: 'Chofer',
      content: (
        <Typography theme="p">{`${driver?.first_name} ${driver?.last_name} (${driver?.ic_type} ${driver?.ic})`}</Typography>
      ),
    },
    {
      title: 'Camión',
      content: (
        <Typography theme="p">
          {data?.attributes?.truck?.data?.attributes?.license_plate}
        </Typography>
      ),
    },
    {
      title: 'Semiremolque',
      content: (
        <Typography theme="p">
          {/* TODO: Confirm if its license_place or chasis_number */}
          {data?.attributes?.semitrailer?.data?.attributes?.license_plate}/
        </Typography>
      ),
    },
  ];

  return (
    <StyledCardContainer>
      <Link
        to={handleGoToMicDetails({
          useRole,
          invoiceId: data?.attributes?.related_documents?.invoice?.id,
          simId: data?.attributes?.related_documents?.sim?.id,
          crtId: data?.attributes?.crt_id?.id,
          micId: data?.id,
        })}
      >
        <Typography theme="h4" as="h2" $marginBottom="2rem">
          MIC {data?.attributes?.number}
        </Typography>
      </Link>

      <StyledCardData>
        {generalData?.map((item) => (
          <li key={item?.title}>
            <Typography theme="p" as="h3">
              {item?.title}:&nbsp;
            </Typography>
            {item?.content}
          </li>
        ))}
      </StyledCardData>
      <StyledCardBottomlData>
        <div>
          <Typography theme="p" as="h3" $marginBottom="1rem" $marginTop="1rem">
            Carga:
          </Typography>
          <StyledCardBottomlDataList>
            {data?.attributes?.description
              ?.trim()
              ?.split(',\n ')
              ?.map((item, index) => {
                return (
                  <li key={item?.replaceAll(' ', `-${index}-`)}>
                    <Typography theme="p">{item}</Typography>
                  </li>
                );
              })}
          </StyledCardBottomlDataList>
        </div>
      </StyledCardBottomlData>
    </StyledCardContainer>
  );
};

export default CardData;
