import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledFilesMainContainer = styled.div`
  margin: 0 auto;
  max-width: 160rem;
  width: 95%;
`;

export const StyledContentContainer = styled.ul`
  margin: 4rem auto 0;
`;

export const StyledFilesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const StyledNoContentMessage = styled.ul`
  color: ${theme.colors.defaultGray};
`;
