import styled from 'styled-components';

export const StyledSubSectionForm = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;

  div {
    max-width: 100%;
  }

  &:first-child div:last-child {
    align-items: self-start;
    justify-content: flex-start;
  }
`;

export const StyledMerchandiseSectionForm = styled.div`
  gap: 2rem;
  display: flex;
  margin-top: 3rem;
  width: 100%;

  div {
    flex: 1;
    max-width: 100%;
  }
`;
