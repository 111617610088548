import { styled } from 'styled-components';

export const StyledModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledModalHeader = styled.ul`
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;
