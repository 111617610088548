import { AxiosError } from 'axios';

enum ErrorType {
  DUPLICATE_ATTRIBUTE = 'This attribute must be unique',
  EMAIL_NOT_READY = 'Email already taken',
  CRT_INACTIVE = 'CRT must be active.',
  SIM_CLOSED = 'SIM must be OPEN.',
  BAD_MERCHANDISE_QUANTITY = 'Quantity and Merchandise in crt_item must to be the same has in sim_items.',
  INSUFFICIENT_BALANCE = 'The joined balance is not enough',
}
export default class ErrorHandler {
  // Variables
  private MAPPED_FIELDS: { [key: string]: string } = {
    business_name: 'Razón social',
    cnrt: 'CNRT',
    email: 'Email',
    international_dr: 'Licencia Internacional',
    invoice_number: 'Número de Factura',
    number: 'Número',
    reg_number: 'Número de registro',
    tax_id: 'ID Tributario',
  };

  private DEFAULT_ERROR = 'Ha habido un error, por favor intente después';

  // Methods
  private readError(error: unknown): string | string[][] {
    if (error instanceof AxiosError) {
      const rawErrorsArray = error.response?.data?.error?.details?.errors;

      if (rawErrorsArray) {
        const readErrorsArray: string[][] = [];
        rawErrorsArray.map((errorItem: { message: string; path: string[] }) => {
          readErrorsArray.push([errorItem.path[0], errorItem.message]);
        });
        return readErrorsArray;
      } else {
        const axiosErrorMessage = error.response?.data?.error?.message;

        const alternativeAxiosErrorMessage =
          typeof error.response?.data?.error === 'string'
            ? error.response?.data?.error
            : error.response?.data?.error?.error;
        const result = axiosErrorMessage ?? alternativeAxiosErrorMessage;

        return result ?? error.message;
      }
    }

    if (error instanceof Error) {
      return error.message;
    }

    return 'default';
  }

  public mapErrorMessage(error: unknown): string[] {
    const errorResult = this.readError(error);

    const defaultResult = [];
    if (typeof errorResult === 'string') {
      switch (errorResult) {
        case ErrorType.EMAIL_NOT_READY:
          defaultResult.push('El Email ya ha sido utilizado');
          break;

        case ErrorType.CRT_INACTIVE:
          defaultResult.push('El CRT debe estar activo');
          break;

        case ErrorType.BAD_MERCHANDISE_QUANTITY:
          defaultResult.push(
            `La cantidad de mercancia en CRT debe ser la misma que tiene el SIM`,
          );
          break;

        case ErrorType.SIM_CLOSED:
          defaultResult.push(`El SIM debe estar ABIERTO`);
          break;

        case ErrorType.INSUFFICIENT_BALANCE:
          defaultResult.push(`El saldo es insuficiente`);
          break;

        default:
          defaultResult.push(this.DEFAULT_ERROR);
          break;
      }

      return defaultResult;
    }

    const result: string[] = [];
    errorResult.forEach((item) => {
      const field = this.MAPPED_FIELDS[item[0]];

      switch (item[1]) {
        case ErrorType.DUPLICATE_ATTRIBUTE:
          result.push(`El campo ${field} debe ser único`);
          break;

        default:
          result.push(`Ha habido un error, por favor intente después`);
          break;
      }
    });

    return result;
  }

  public readErrorCode(error: unknown): number | undefined {
    if (error instanceof AxiosError) {
      return error.response?.status;
    }
    return undefined;
  }
}
