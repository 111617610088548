import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder, NotificationMessages } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { Pagination } from '../../../utils/types/index';
import { Merchandise } from '../../../utils/types/merchandise';
import { MerchandiseResponse, TableData } from '../types';

type Props = {
  paginationCurrentPage: number;
};

const useMerchandise = ({ paginationCurrentPage }: Props) => {
  const API_URL_MERCHANDISE = '/custom-merchandise/all?',
    API_URL_DELETE_MERCHANDISE = '/merchandises/{merchandise_id}';

  const paginationParams = `pagination[page]=${paginationCurrentPage}&pagination[pageSize]=10`;
  const filterMerchandiseNameQuery = '&filters[$or][0][code][$containsi]={toSearch}&filters[$or][1][description][$containsi]={toSearch}';

  const axios = useAxios();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [data, setData] = useState<Merchandise[]>([]);
  const [paginationData, setPaginationData] = useState<Pagination>(
    {} as Pagination,
  );
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');

  // Filter Controls
  const [filterMerchandiseName, setFilterMerchandiseName] = useState('');

  // Build final API URL with filters
  const finalURL = () => {
    const queryString = filterMerchandiseNameQuery.replaceAll('{toSearch}', filterMerchandiseName);
    return `${API_URL_MERCHANDISE}${paginationParams}${queryString}${sortQuery}`;
  };

  const getMerchandise = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);
    axios
      .get<MerchandiseResponse>(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data.data);
        setPaginationData(response.data.meta.pagination);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Delete Merchandise
  const handleDeleteMerchandise = async function (this: {
    merchandiseID?: string;
  }) {
    const { merchandiseID } = this;

    setIsLoading(true);
    let errorMsg = NotificationMessages.GENERIC_ERROR;
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!merchandiseID) throw new Error('There is no Merchandise ID');

      await axios.delete(
        API_URL_DELETE_MERCHANDISE.replace('{merchandise_id}', merchandiseID),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      showNotification({
        message: 'El registro fue borrado correctamente',
      });

      getMerchandise();
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: errorMsg,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMerchandise();
  }, [paginationCurrentPage, filterMerchandiseName, sortQuery]);

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    data,
    hasError,
    isLoading,
    paginationData,
    setFilterMerchandiseName,
    handleDeleteMerchandise,
    handleTableChange,
  };
};

export default useMerchandise;
