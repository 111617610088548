import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { URLS } from '..';

const useAuthValidation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (!token) navigate(URLS.landing);
  }, []);
};

export default useAuthValidation;
