import { styled } from 'styled-components';

import { theme } from '../../styles';

type StyledProps = {
  $isFullWidth?: boolean;
  $useMarginBottom?: boolean;
  $useMarginTop?: boolean;
};

export const StyledBoxContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: ${(props) => (props.$useMarginBottom ? '7rem' : '0')};
  margin-top: ${(props) => (props.$useMarginTop ? '6rem' : '0')};
  max-width: ${(props) => (props.$isFullWidth ? '100%' : '90%')};

  @media only screen and (min-width: ${theme.breakpoints.xlg}) {
    max-width: ${(props) => (props.$isFullWidth ? '100%' : '140rem')};
  }
`;
