import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const FormWrapper = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  > :nth-child(1) {
    margin-bottom: 2.5rem;
    max-width: 27.5rem;
    width: 100%;
  }
`;

export const StyledPasswordField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;
  max-width: 27.5rem;
  width: 100%;
  > div {
    width: 100%;
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${theme.colors.error};
`;
