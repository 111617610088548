import { InputOption } from '../../../../../utils/types';
import { CompanyData } from '../../../types';

export const roleOptionsMap: {
  [key: string]: InputOption[];
} = {
  Despachante: [
    { value: 3, text: 'Despachante Admin' },
    { value: 5, text: 'Despachante' },
  ],
  'Despachante Administrador': [
    { value: 3, text: 'Despachante Admin' },
    { value: 5, text: 'Despachante' },
  ],
  'Importadora/Exportadora': [
    { value: 8, text: 'Administrador Imp/Exp' },
    { value: 6, text: 'Recepcionista' },
  ],
  'Administrador Imp/Exp': [
    { value: 8, text: 'Administrador Imp/Exp' },
    { value: 6, text: 'Recepcionista' },
  ],
  Transportista: [{ value: 7, text: 'Transportista' }],
};

export const getRoleOptions = (
  userRole: string,
  selectedOption?: CompanyData,
) => {
  let result;
  const companyType = selectedOption?.attributes?.type;

  if (companyType) {
    result = roleOptionsMap[companyType] || roleOptionsMap['Despachante'];
  } else {
    result = roleOptionsMap[userRole];
  }

  return result || [];
};
