import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { InputCheckbox, InputText } from '../../../../components';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { Mic } from '../../../../utils/types/mics';
import { TableData } from '../../types';
import { transformData } from './utils';

type Props = {
  data?: Mic[];
  onCheckboxFilterChange: (value: boolean) => void;
  onNumberFilterChange: (value: string) => void;
};

const MicsTable: FC<Props> = ({
  data,
  onCheckboxFilterChange,
  onNumberFilterChange,
}) => {
  const newData = transformData(data);

  const paginationData = {
    pageSize: 10,
    total: newData.length,
    defaultCurrent: Number(true),
  };

  const columns: ColumnsType<TableData> = [
    {
      title: 'Número de MIC',
      key: 'micNumber',
      render: (props: TableData) => {
        return <Link to={props.key.toString()}>{props.micNumber}</Link>;
      },
    },
    { title: 'Fecha de Emisión', key: 'issueDate', dataIndex: 'issueDate' },
    {
      title: 'Fecha de Recepción',
      key: 'receptionDate',
      dataIndex: 'receptionDate',
    },
    { title: 'Descripción', key: 'description', dataIndex: 'description' },
  ];

  const handleOnNumberFilterChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onNumberFilterChange(event.target.value);
    },
    [],
  );
  const handleOnCheckboxFilterChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onCheckboxFilterChange(event.target.checked);
    },
    [],
  );

  return (
    <StyledTableContainer>
      <StyledFiltersContainer>
        <InputText
          placeholder="Número MIC"
          onChange={handleOnNumberFilterChange}
        />
        <InputCheckbox
          label="Operable"
          onChange={handleOnCheckboxFilterChange}
          customID="crt-related-mics-filter"
        />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          ...paginationData,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
      />
    </StyledTableContainer>
  );
};

export default MicsTable;
