import React, { FC, ReactNode } from 'react';

import { StyledMainContainer } from './PageLayout.styled';

type Props = {
  children: ReactNode;
};

const PageLayout: FC<Props> = ({ children }) => {
  return <StyledMainContainer>{children}</StyledMainContainer>;
};

export default PageLayout;
