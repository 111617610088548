import { SorterResult } from 'antd/es/table/interface';
import log from 'loglevel';
import { useEffect, useState } from 'react';

import { getOrder } from '../../../utils';
import { useAxios } from '../../../utils/hooks';
import { InputSelectListOption, PaginationData } from '../../../utils/types';
import { CompanyResponse } from '../../../utils/types/company';
import { InvoiceResponse, TableData } from '../types';

type Props = {
  selectInputQuery: string;
  paginationData: PaginationData;
};

const useInvoices = ({ selectInputQuery, paginationData }: Props) => {
  const API_URL_INVOICES = '/invoices?populate=*',
    API_URL_GET_CLIENTS =
      '/companies?filters[type][$eq]=Importadora/Exportadora&filters[business_name][$containsi]=';

  const paginationParams = `&pagination[page]=${paginationData.current}&pagination[pageSize]=${paginationData.pageSize}`;

  const filterQueryInvoiceNumber = '&filters[invoice_number][$containsi]=',
    filterSelectClient =
      '&filters[$or][0][exporter_id][id][$eq]={CLIENT_ID}&filters[$or][1][importer_id][id][$eq]={CLIENT_ID}',
    filterCheckboxText = '&filters[operable][$eq]=';

  const axios = useAxios();

  const [data, setData] = useState<InvoiceResponse | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState('');

  // Filter controls
  const [checkboxFilterValue, setCheckboxFilterValue] = useState(false);
  const [inputInvoiceNumberFilterValue, setInputInvoiceNumberFilterValue] =
    useState('');

  // Input Select List - Filter Controls
  const [clientsArray, setClientsArray] = useState<InputSelectListOption[]>([]);
  const [selectedClient, setSelectedClient] =
    useState<InputSelectListOption | null>(null);

  const finalURL = () => {
    return `${API_URL_INVOICES}${paginationParams}${
      checkboxFilterValue ? filterCheckboxText + checkboxFilterValue : ''
    }${
      inputInvoiceNumberFilterValue &&
      filterQueryInvoiceNumber + inputInvoiceNumberFilterValue
    }${
      selectedClient
        ? filterSelectClient.replaceAll(
            '{CLIENT_ID}',
            selectedClient.value.toString(),
          )
        : ''
    }${sortQuery}`;
  };

  const getInvoices = (token: string | null) => {
    axios
      .get(finalURL(), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
      setHasError(true);
    }

    setIsLoading(true);

    getInvoices(token);
  }, [
    paginationData.current,
    checkboxFilterValue,
    selectedClient,
    inputInvoiceNumberFilterValue,
    sortQuery,
  ]);

  // Reset to first page when filtering
  useEffect(() => {
    if (paginationData.onChange) paginationData.onChange(1);
  }, [checkboxFilterValue, selectedClient, inputInvoiceNumberFilterValue]);

  // Get Clients
  const getCompaniesOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompanyResponse>(`${API_URL_GET_CLIENTS}${selectInputQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;

        const results: InputSelectListOption[] =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];

        setClientsArray(results);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  useEffect(() => {
    getCompaniesOption();
  }, [selectInputQuery]);

  /**
   * Catches antd sort data and makes API responds to it
   * @param _pagination: Not used
   * @param _filters: Not used
   * @param sorter: Gets antd sort data to catch it and add it to API
   * @returns void
   */
  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<TableData> | SorterResult<TableData>[],
  ) => {
    if (Array.isArray(sorter)) return;
    const order = getOrder(sorter.order);
    setSortQuery(order ? `&sort=${sorter.columnKey}:${order}` : '');
  };

  return {
    clientsArray,
    data: data?.data,
    totalResults: data?.meta.pagination.total,
    hasError,
    isLoading,
    setCheckboxFilterValue,
    setInputInvoiceNumberFilterValue,
    setSelectedClient,
    handleTableChange,
  };
};

export default useInvoices;
