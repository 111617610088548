import { CrtBalanceDetails } from '../../../../../utils/types/crt';
import { Crt, TableData } from '../../../types';

type BalanceBuilderParams = {
  merchandise: string;
  balance: number;
  unit: string;
};

export const transformData = (data?: Crt[] | null) => {
  if (!data || !data.length) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    const locationBuilder = (place: string, country: string) => {
      return [place.toUpperCase(), country.toUpperCase()].join(',\u0020');
    };

    const balanceBuilder = (args: BalanceBuilderParams) => {
      return `${args.merchandise} - ${args.balance} ${args.unit}`;
    };

    const receptionPlace = locationBuilder(
      item?.attributes?.reception.data.attributes.place,
      item?.attributes?.reception.data.attributes.country,
    );
    const deliveryPlace = locationBuilder(
      item?.attributes?.delivery.data.attributes.place,
      item?.attributes?.delivery.data.attributes.country,
    );

    const balance = (() => {
      if (!item?.attributes?.balance_details) return String();
      const balances = item?.attributes?.balance_details as CrtBalanceDetails[];

      return balanceBuilder({
        unit: balances?.[0]?.unit,
        balance: balances?.[0]?.joined_balance,
        merchandise: balances?.[0]?.merchandise?.description,
      });
    })();

    return {
      key: item.id,
      balance,
      deliveryPlace,
      receptionPlace,
      number: item.attributes.number,
      active: item?.attributes?.active,
      simId: `${item?.attributes.sim?.id}`,
      invoiceId: `${item?.attributes?.invoice?.id}`,
      exporter: item?.attributes?.exporter_id?.data?.attributes,
      importer: item?.attributes?.importer_id?.data?.attributes,
      transporter: item?.attributes?.carrier_company.data.attributes,
    };
  });

  return transformedData;
};
