import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomButton, InputText, Loader } from '../../../../components';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import useProfile from '../../hooks/useProfile';

type Inputs = {
  id: number | string;
  name: string;
  lastName: string;
  email: string;
  password: string;
  confirm_password: string;
};

const ProfileForm: FC = () => {
  const { userData, isLoading, handleUpdateUserData } = useProfile();

  const { register, handleSubmit, formState, setValue, watch, resetField } =
    useForm<Inputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const navigate = useNavigate();

  useEffect(() => {
    setValue('name', userData?.name || String());
    setValue('lastName', userData?.lastName || String());
    setValue('email', userData?.email || String());
  }, [userData]);

  const handleOnCancel = React.useCallback(() => {
    navigate(-1);
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    const payload = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      password: data.password || undefined,
    };
    handleUpdateUserData(payload);
    resetField('password');
    resetField('confirm_password');
  };

  const disabledCTA = !formState.isDirty || isLoading;
  const passMinLength = 8;

  return (
    <>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubForm>
          <InputText
            placeholder="Nombre"
            label="Nombre"
            $hasValue={formState.dirtyFields.name}
            $invalid={!!formState.errors.name}
            {...register('name')}
          />
          <InputText
            placeholder="Apellido"
            label="Apellido"
            $hasValue={formState.dirtyFields.lastName}
            $invalid={!!formState.errors.lastName}
            {...register('lastName')}
          />
          <InputText
            placeholder="Email"
            label="Email"
            $hasValue={formState.dirtyFields.email}
            $invalid={!!formState.errors.email}
            {...register('email')}
          />
          <InputText
            $hasValue={formState.dirtyFields.password}
            $invalid={!!formState.errors.password}
            label="Contraseña"
            placeholder="Contraseña"
            subTextMessage={formState.errors.confirm_password?.message}
            type="password"
            {...register('password', {
              minLength: {
                value: passMinLength,
                message: 'La contraseña necesita al menos 8 caracteres',
              },
            })}
          />
          <InputText
            $hasValue={formState.dirtyFields.confirm_password}
            $invalid={!!formState.errors.confirm_password}
            label="Confirme la contraseña"
            placeholder="Confirme la contraseña"
            subTextMessage={formState.errors.confirm_password?.message}
            type="password"
            {...register('confirm_password', {
              validate: (passwordValue: string) => {
                if (watch('password') !== passwordValue) {
                  return 'Las contraseñas no coinciden';
                }
              },
            })}
          />
        </StyledSubForm>
        <StyledFormActions>
          <CustomButton
            disabled={disabledCTA}
            text="Actualizar"
            type="submit"
          />
          <CustomButton
            $theme="secondary"
            onPress={handleOnCancel}
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default ProfileForm;
