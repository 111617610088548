import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

import { StyledTableContainer } from '../../../../utils/styles';
import { MicItem } from '../../../../utils/types/mics';
import { TableData } from '../../types';
import { transformData } from './utils';

type Props = {
  data?: MicItem[];
};

const MerchandisesTable: FC<Props> = ({ data }) => {
  const newData = transformData(data);

  const columns: ColumnsType<TableData> = [
    { title: 'Descripción', dataIndex: 'description', key: 'description' },
    { title: 'Cantidad', dataIndex: 'quantity', key: 'quantity' },
    { title: 'UM', dataIndex: 'UM', key: 'UM' },
  ];
  return (
    <StyledTableContainer>
      <Table columns={columns} dataSource={newData} pagination={false} />
    </StyledTableContainer>
  );
};

export default MerchandisesTable;
