import React, { FC, useState } from 'react';
import { FaUniversity } from 'react-icons/fa';

import { CompanyAttributes, PlainCompanyData } from '../../utils/types/company';
import CustomButton from '../CustomButton';
import Modal from '../Modal';
import Typography from '../Typography';
import {
  StyledModalHeader,
  StyledModalList,
} from './CompanyModalOpener.styled';

type Props = {
  data: PlainCompanyData | CompanyAttributes | null;
};

const CompanyModalOpener: FC<Props> = ({ data }) => {
  if (!data) return null;

  const modalContent = [
    { title: 'ID Tributario', content: data?.tax_id },
    { title: 'Dirección', content: data?.address },
    { title: 'Ciudad', content: data?.city },
    { title: 'País', content: data?.country },
    { title: 'CNRT', content: data?.cnrt },
    { title: 'Licencia Internacional', content: data?.international_dr },
  ];

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const handleOpenModal = React.useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <div style={{ display: 'inline-block' }}>
      <CustomButton
        $theme="link"
        text={data?.business_name}
        onPress={handleOpenModal}
      />
      <Modal showModal={showModal} handleClose={handleCloseModal}>
        <StyledModalHeader>
          <FaUniversity size="4rem" />
          <Typography theme="h3" as="h2">
            {data?.business_name}
          </Typography>
        </StyledModalHeader>

        <StyledModalList>
          {modalContent.map((item) => (
            <li key={item.title}>
              <Typography theme="p" as="h3">
                {item.title}:&nbsp;
              </Typography>
              <Typography theme="p">{item.content}</Typography>
            </li>
          ))}
        </StyledModalList>
      </Modal>
    </div>
  );
};

export default CompanyModalOpener;
