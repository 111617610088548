import React, { FC } from 'react';

import { Typography } from '../../../../components';
import { PlainCompanyData } from '../../../../utils/types/company';
import { StyledCompanyList } from './CompanyInfo.styled';

type Props = {
  data?: PlainCompanyData;
};

const CompanyInfo: FC<Props> = ({ data }) => {
  if (!data) return null;

  const companyContent = [
    { title: 'Razón Social', content: data?.business_name },
    { title: 'ID Tributario', content: data?.tax_id },
    { title: 'Dirección', content: data?.address },
    { title: 'Ciudad', content: data?.city },
    { title: 'País', content: data?.country },
    { title: 'CNRT', content: data?.cnrt },
    { title: 'Licencia Internacional', content: data?.international_dr },
  ];
  return (
    <StyledCompanyList>
      {companyContent.map((item) => (
        <li key={item.title}>
          <Typography theme="p" as="h4">
            {item.title}:&nbsp;
          </Typography>
          <Typography theme="p">{item.content}</Typography>
        </li>
      ))}
    </StyledCompanyList>
  );
};

export default CompanyInfo;
