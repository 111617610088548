import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  Typography,
} from '../../components';
import { fromUserRoleToUrl, URLS } from '../../utils';
import useStore from '../../zustand';
import MerchandiseTable from './components/MerchandiseTable';
import useMerchandise from './hooks/useMerchandise';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.merchandise,
      name: 'Mercaderias',
      withUserRole: true,
    },
  ];

  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    paginationData,
    isLoading,
    setFilterMerchandiseName,
    handleDeleteMerchandise,
    handleTableChange,
  } = useMerchandise({
    paginationCurrentPage: currentPage,
  });

  const handleOnClickNewMerchandise = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.addMerchandise}`);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Mercaderias
      </Typography>
      <CustomButton
        $isMini
        $marginBottom="6rem"
        onPress={handleOnClickNewMerchandise}
        text="Nueva Mercaderia"
      />
      <MerchandiseTable
        data={data}
        isLoading={isLoading}
        pagination={paginationData}
        currentPage={currentPage}
        onPaginationChange={setCurrentPage}
        onDeleteMerchandise={handleDeleteMerchandise}
        onChangeMerchandiseName={setFilterMerchandiseName}
        onTableChange={handleTableChange}
      />
    </BoxContainer>
  );
};

export default PageContent;
