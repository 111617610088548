import React, { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Breadcrumb,
  CustomButton,
  InputArea,
  InputSelectList,
  InputText,
  Loader,
  Typography,
} from '../../../../components';
import {
  fromUserRoleToUrl,
  Role,
  URLS,
  USER_ROLES_LIST,
} from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { StyledFormActions, StyledSubForm } from '../../../../utils/styles';
import useStore from '../../../../zustand';
import useAddTruckDriver from '../../hooks/useAddTruckDriver';
import { TruckDriverInputs, TruckDriverRequestData } from '../../types/index';

const TruckDriverForm: FC = () => {
  const { truck_driver_id } = useParams();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const {
    handleAddTruckDriverData,
    inputOptions,
    setInputQuery,
    truckDriverData,
    isLoading,
  } = useAddTruckDriver({
    truckDriverId: truck_driver_id,
  });

  const { register, handleSubmit, formState, control, reset } =
    useForm<TruckDriverInputs>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const navigate = useNavigate();

  useEffect(() => {
    if (truckDriverData) {
      reset(truckDriverData);
    }
  }, [truckDriverData]);

  const handleOnCancel = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.truckDriver}`);
  }, []);

  const onSubmit: SubmitHandler<TruckDriverInputs> = (data) => {
    document.documentElement.scrollTo(0, 0);
    const payload: TruckDriverRequestData = {
      data: {
        ic: data.ic,
        ic_type: data.ic_type.value,
        first_name: data.first_name,
        last_name: data.last_name,
        company_id: data?.company_id?.value,
        comment: data.comment,
      },
    };
    handleAddTruckDriverData(payload);
  };

  const errorsArray = Object.values(formState.errors);
  const disabledCTA = isLoading || errorsArray.length > 0;
  const documentTypes = [
    { value: 'D.N.I', label: 'D.N.I' },
    { value: 'CI', label: 'CI' },
  ];

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    { location: URLS.truckDriver, name: 'Choferes', withUserRole: true },
    {
      location: URLS.addTruckDriver,
      name: `${truck_driver_id ? 'Editar' : 'Nuevo'} Chofer`,
      withUserRole: true,
    },
  ];

  const breadcrumbItemsForDispatcher = [
    { location: URLS.addMic, name: 'Nuevo MIC', withUserRole: true },
    { location: URLS.addTruck, name: 'Nuevo Camión', withUserRole: true },
  ];

  const selectedBreadcrumbItems =
    userRole === USER_ROLES_LIST.dispatcher
      ? breadcrumbItemsForDispatcher
      : breadcrumbItems;

  return (
    <>
      <Breadcrumb items={selectedBreadcrumbItems} />

      {isLoading && <Loader />}
      <Typography theme="h1" as="h1" $marginBottom="6rem">
        {`${truck_driver_id ? 'Editar ' : 'Nuevo '}Chofer`}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AvailableComponent availableFor={[Role.SuperAdmin, Role.Dispatcher, Role.Admin]}>
          <div>
            <Typography theme="h3" as="h2">
              Empresa
            </Typography>
            <StyledSubForm>
              <div>
                <Controller
                  name="company_id"
                  control={control}
                  rules={{
                    required: {
                      message: 'Este Campo es requerido',
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <InputSelectList
                      options={inputOptions}
                      $hasValue={!!formState.dirtyFields.company_id}
                      $invalid={!!formState.errors.company_id}
                      placeholder="Empresa"
                      value={field.value}
                      handleOnChange={(event) => {
                        field.onChange(
                          event as { label: string; value: string | number },
                        );
                      }}
                      handleOnInputChange={(event) => {
                        setInputQuery(event);
                      }}
                      subTextMessage={formState.errors.company_id?.message}
                    />
                  )}
                />
              </div>
            </StyledSubForm>
          </div>
        </AvailableComponent>
        <div>
          <Typography theme="h3" as="h2">
            Datos del Chofer
          </Typography>
          <StyledSubForm>
            <Controller
              name="ic_type"
              control={control}
              rules={{
                required: { message: 'Este Campo es requerido', value: true },
              }}
              render={({ field }) => (
                <InputSelectList
                  options={documentTypes}
                  $hasValue={!!formState.dirtyFields.ic_type}
                  $invalid={!!formState.errors.ic_type}
                  label="Tipo de documento"
                  placeholder="Tipo de documento"
                  value={field.value}
                  isSearchable={false}
                  handleOnChange={(event) => {
                    field.onChange(event as { label: string; value: string });
                  }}
                  handleOnInputChange={(event) => {
                    setInputQuery(event);
                  }}
                  subTextMessage={formState.errors.ic_type?.message}
                />
              )}
            />
            <InputText
              placeholder="Nro. de documento"
              label="Nro. de documento"
              $hasValue={formState.dirtyFields.ic}
              $invalid={!!formState.errors.ic}
              {...register('ic', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputText
              label="Nombre"
              placeholder="Nombre"
              $hasValue={formState.dirtyFields.first_name}
              $invalid={!!formState.errors.first_name}
              {...register('first_name', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputText
              label="Apellido"
              placeholder="Apellido"
              $hasValue={formState.dirtyFields.last_name}
              $invalid={!!formState.errors.last_name}
              {...register('last_name', {
                required: 'Este Campo es requerido',
              })}
            />
            <InputArea
              $hasValue={formState.dirtyFields.comment}
              $invalid={!!formState.errors.comment}
              label="Comentarios"
              maxLength={1000}
              placeholder="Comentarios"
              {...register('comment')}
            />
          </StyledSubForm>
        </div>
        <StyledFormActions>
          <CustomButton
            $theme="success"
            disabled={disabledCTA}
            text={`${truck_driver_id ? 'Actualizar' : 'Crear'}`}
            type="submit"
          />
          <CustomButton
            onPress={handleOnCancel}
            $theme="secondary"
            text="Cancelar"
            type="button"
          />
        </StyledFormActions>
      </form>
    </>
  );
};

export default TruckDriverForm;
