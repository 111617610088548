import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledCardContainer = styled.ul`
  background-color: ${theme.colors.defaultWhite};
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.5rem 0.2rem ${theme.colors.defaultBlack}3F;
  padding: 2rem 3rem;
  a {
    color: ${theme.colors.primary};
    &:hover {
      cursor: pointer;
      p {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledCardGeneralData = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

export const StyledCardBottomlData = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

export const StyledCardBottomlDataList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: disc;
  padding-left: 1.5rem;
`;
