import { createGlobalStyle } from 'styled-components';

import { theme } from './';

const globalStyle = createGlobalStyle`
  * {
    appearance: none;
    border: 0;
    box-sizing: border-box;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 10px;
    min-height: 100vh;
    position: relative;
  }

  body {
    color: ${theme.colors.defaultBlack};
    font-family: 'Karla', 'Open Sans', sans-serif;
    line-height: 1.2;
    min-height: 100vh;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato', 'Roboto', sans-serif;
  }

  p {
    font-family: 'Karla', 'Open Sans', sans-serif;
    display: inline;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style-type: none;
  }

  textarea {
    font-family: 'Karla', 'Open Sans', sans-serif;
  }

  .ant-tooltip-inner {
    white-space: pre;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default globalStyle;
