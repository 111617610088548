const pageTitleMap: {
  [key: string]: string;
} = {
  op: 'Reportes de OP Operativas',
  crt: 'Reportes de CRTs',
  mic: 'Reportes de MICs',
  camiones: 'Reportes de Camiones',
  semiremolques: 'Reportes de Semiremolques',
  choferes: 'Reportes de Choferes',
  facturas: 'Facturas',
  sims: 'SIMs',
  crts: 'CRTs',
  mics: 'MICs',
};

export const getPageTitle = (reportType?: string, reportFolder?: string) => {
  const value = reportFolder ?? reportType;
  if (!value) return '';

  return pageTitleMap[value] ?? '';
};

const pageCategoryMap: {
  [key: string]: string;
} = {
  op: 'po_report',
  crt: 'crt_report',
  mic: 'mic_report',
  camiones: 'truck_report',
  semiremolques: 'semitrailer_report',
  choferes: 'truck_driver_report',
  facturas: 'invoice_attachment',
  sims: 'sim_attachment',
  crts: 'crt_attachment',
  mics: 'mic_attachment',
};

export const getPageCategory = (reportType?: string, reportFolder?: string) => {
  const value = reportFolder ?? reportType;
  if (!value) return '';

  return pageCategoryMap[value] ?? '';
};
