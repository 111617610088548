import { styled } from 'styled-components';

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const StyledCardsGroupMainContainer = styled.div`
  position: relative;
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  width: 100%;
`;
