import { styled } from 'styled-components';

import { theme } from '../../../../styles';
import { transitionAnimation } from '../../../../utils/styles';

export const StyledMenu = styled.ul`
  align-items: center;
  background-color: ${theme.colors.defaultWhite};
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: -3px 3px 4px ${theme.colors.defaultBlack}3F;
  color: ${theme.colors.defaultBlack};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 1rem;

  > li {
    ${transitionAnimation}
    position: relative;
    width: 100%;

    &:hover {
      color: ${theme.colors.primary};
    }

    &:first-child {
      &::before {
        content: '';
        background-color: ${theme.colors.defaultBlack};
        bottom: -1rem;
        height: 0.1rem;
        left: 0;
        opacity: 0.2;
        position: absolute;
        width: 100%;
      }
    }
    &:last-child {
      p {
        color: ${theme.colors.defaultBlack};
      }
      &:hover {
        p {
          color: ${theme.colors.primary};
        }
      }
      &::before {
        content: '';
        background-color: ${theme.colors.defaultBlack};
        height: 0.1rem;
        left: 0;
        opacity: 0.2;
        position: absolute;
        top: -1rem;
        width: 100%;
      }
    }
  }
`;
