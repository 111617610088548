import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios } from '../../../utils/hooks';
import { FileCount, FileCountResponse } from '../types';

const useGetFilesCount = () => {
  const API_URL_GET_FILES_COUNT = '/custom-archives/counts';

  const axios = useAxios();

  const [data, setData] = useState<FileCount>({} as FileCount);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getFilesCount = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No Token provided');

    const response = await axios.get<FileCountResponse>(
      API_URL_GET_FILES_COUNT,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setData(response.data.archives_statistics);
    setIsLoading(false);
  };

  useEffect(() => {
    getFilesCount().catch((error) => {
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    });
  }, []);

  return { data, isLoading, hasError };
};

export default useGetFilesCount;
