import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledFooterContainer = styled.footer`
  background-color: ${theme.colors.defaultBlack};
  color: ${theme.colors.defaultWhite};
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2rem;
  width: 100%;
  z-index: ${theme.zIndex.headerFooter};
`;
