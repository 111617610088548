import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { LANDING_URL_MAP, URLS } from '..';

type Props = {
  component: React.ReactNode;
  isAvailable: boolean;
  userRole?: string;
};

export const GuardRoute: FC<Props> = ({
  component,
  isAvailable,
  userRole = String(),
}) => {
  const redirectTo = LANDING_URL_MAP[userRole] ?? URLS.dashboard;
  return isAvailable ? component : <Navigate to={redirectTo} />;
};

export default GuardRoute;
