import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { fromUserRoleToUrl } from '../../utils';
import useStore from '../../zustand';
import Typography from '../Typography';
import {
  StyledBreadcrumbContainer,
  StyledBreadcrumbItem,
} from './Breadcrumb.styled';

type Props = {
  items: { location: string; name: string; withUserRole?: boolean }[];
};

const Breadcrumb: FC<Props> = ({ items }) => {
  const [userRole] = useStore((state) => [state.userRole]);

  return (
    <StyledBreadcrumbContainer>
      {items.map((item) => (
        <StyledBreadcrumbItem key={item.location}>
          <Link
            to={`${item.withUserRole ? '/' + fromUserRoleToUrl(userRole) : ''}${
              item.location
            }`}
          >
            <Typography theme="p">{item.name}</Typography>
          </Link>
        </StyledBreadcrumbItem>
      ))}
    </StyledBreadcrumbContainer>
  );
};

export default Breadcrumb;
