import { PO } from '../../../../../utils/types/po';
import { TableData } from '../../../types';

export const transformData = (data?: PO[] | null) => {
  if (!data || data.length === 0) {
    return [];
  }

  const transformedData: TableData[] = data.map((item) => {
    return {
      key: item.id,
      op: item.po,
      condition: item.sale_condition,
      importer: item.importer,
      importerID: item.importer,
      exporter: item.exporter,
      exporterID: item.exporter,
      transport: item.carrier_company,
      destination: item.place + ', ' + item.country,
      product: item.description,
      sim: item.sim_number,
      balance: item.joined_balance,
    };
  });

  return transformedData;
};
