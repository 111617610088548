import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';

import Typography from '../Typography';
import {
  StyledInputContainer,
  StyledInputLabel,
  StyledMainContainer,
  SubTextContainer,
} from './InputText.styled';

export type Props = {
  $hasValue?: boolean;
  $invalid?: boolean;
  subTextMessage?: string;
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const InputText: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { subTextMessage, value, name, label, $hasValue, $invalid, ...rest },
  ref,
) => {
  return (
    <div>
      {label && (
        <StyledInputLabel>
          <Typography theme="subtext">{label}</Typography>
        </StyledInputLabel>
      )}
      <StyledMainContainer $hasValue={$hasValue || !!value} $invalid={$invalid}>
        <StyledInputContainer>
          <input
            name={name}
            value={value}
            autoComplete="on"
            ref={ref}
            {...rest}
          />
        </StyledInputContainer>
        {subTextMessage && (
          <SubTextContainer>
            <Typography theme="subtext">{subTextMessage}</Typography>
          </SubTextContainer>
        )}
      </StyledMainContainer>
    </div>
  );
};

export default React.forwardRef(InputText);
