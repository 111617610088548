import log from 'loglevel';
import { useEffect, useState } from 'react';

import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputSelectListOption } from '../../../utils/types';
import { Mic } from '../../../utils/types/mics';

const useDispatcherLanding = () => {
  const API_URL_FIND_MIC = '/custom-mic/all?filters[number][$containsi]=';
  const API_URL_REGISTER_MIC_RECEPTION =
    '/custom-mic/{mic_id}/register-reception';

  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification, showErrorNotification } = useShowNotification();

  // CRT select list
  const [selectedMicId, setSelectedMicId] = useState('');

  const [micSelectOptions, setMicSelectOptions] = useState<
    InputSelectListOption[]
  >([]);

  const [micSelectedOption, setMicSelectOption] =
    useState<InputSelectListOption | null>(null);

  const finalURL = () => {
    return `${API_URL_FIND_MIC}${selectedMicId}`;
  };

  const loadMicList = async () => {
    setIsLoading(true);

    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('No Token provided');

    const response = await axios.get<{ data: Mic[] }>(finalURL(), {
      headers: { Authorization: `Bearer ${token}` },
    });

    const result: InputSelectListOption[] = response.data.data.map(
      (item: Mic) => ({
        label: item.attributes.number,
        value: item.id,
      }),
    );
    setMicSelectOptions(result);

    setIsLoading(false);
  };
  useEffect(() => {
    loadMicList().catch((error) => {
      log.error(error);
      showErrorNotification({
        message: 'Ocurrio un error al registrar la recepción.',
      });
      setIsLoading(false);
    });
  }, [selectedMicId]);

  // Register MIC reception
  const registerMicReception = async () => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      await axios.patch(
        API_URL_REGISTER_MIC_RECEPTION.replace(
          '{mic_id}',
          micSelectedOption?.value.toString() || String(),
        ),
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setIsLoading(false);

      showNotification({
        message: 'La recepción fue registrada satisfactoriamente',
      });

      // clean selected MIC
      setMicSelectOption(null);
    } catch (error) {
      log.error(error);
      setIsLoading(false);
      // clean selected MIC
      setMicSelectOption(null);
    }
  };

  return {
    isLoading,
    setSelectedMicId,
    setMicSelectOption,
    micSelectOptions,
    micSelectedOption,
    registerMicReception,
  };
};

export default useDispatcherLanding;
