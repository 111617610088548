import React from 'react';

import { Footer, Header, PageLayout } from '../../components';
import PageContent from '../../features/InvoiceDetail';
import { useAuthValidation } from '../../utils/hooks';

const InvoiceDetailPage = () => {
  useAuthValidation();

  return (
    <>
      <Header />
      <PageLayout>
        <PageContent />
      </PageLayout>
      <Footer />
    </>
  );
};

export default InvoiceDetailPage;
