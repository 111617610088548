import { styled } from 'styled-components';

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const StyledCardsGroupMainContainer = styled.div`
  position: relative;
`;

export const StyledReportButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  width: 100%;
`;

export const StyledMultipleFiltersContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin-bottom: 4rem;
  > div {
    align-items: center;
    justify-content: center;
    display: flex;
    &:last-child {
      align-items: flex-end;
      margin-bottom: 1rem;
    }
    > * {
      width: 100%;
      > div {
        max-width: unset;
      }
    }
  }
`;
