import React, { FC, useCallback } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { Typography } from '../../../../components';
import { theme } from '../../../../styles';
import { StyledOperableStateContainer } from './OperableState.styled';

type Props = {
  isOperable?: boolean;
};

const OperableState: FC<Props> = ({ isOperable }) => {
  const getIcon = useCallback(
    (isOperable?: boolean) => (isOperable ? FaCheck : FaTimes),
    [isOperable],
  );

  const getIconColor = useCallback(
    (isOperable?: boolean) =>
      isOperable ? theme.colors.success : theme.colors.error,
    [isOperable],
  );

  const getMessage = useCallback(
    (isOperable?: boolean) => (isOperable ? 'Operable' : 'No Operable'),
    [isOperable],
  );

  const SelectedIcon = getIcon(isOperable);
  const color = getIconColor(isOperable);
  const message = getMessage(isOperable);

  return (
    <StyledOperableStateContainer>
      <Typography theme="p">{message}&nbsp;</Typography>
      <SelectedIcon size="1.5rem" color={color} />
    </StyledOperableStateContainer>
  );
};

export default OperableState;
