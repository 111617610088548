import { Tooltip } from 'antd';
import React, { FC } from 'react';

import { Typography } from '../../../../components';
import { theme } from '../../../../styles';
import { StyledTagContainer } from './StatusTag.styled';

type Props = {
  status: string;
};

const statusMappedData: {
  [key: string]: { name: string; color: string; text: string };
} = {
  Active: {
    name: 'Activo',
    color: theme.colors.primary,
    text: 'El SIM esta asociado a un CRT y hay saldo disponible para despachar camiones',
  },
  Closed: {
    name: 'Cerrado',
    color: theme.colors.darkGray,
    text: 'El SIM ha sido cerrado',
  },
  Completed: {
    name: 'Completado',
    color: theme.colors.success,
    text: 'El despacho ha sido completado en su totalidad',
  },
  Expired: {
    name: 'Expirado',
    color: theme.colors.error,
    text: 'El SIM ha expirado',
  },
  Inactive: {
    name: 'Inactivo',
    color: theme.colors.lightGray,
    text: 'El SIM no está asociado a ningún CRT',
  },
};

const StatusTag: FC<Props> = ({ status }) => {
  const data = statusMappedData[status];

  return (
    <Tooltip placement="right" title={data.text} color={data.color}>
      <StyledTagContainer
        className={data.name.toLowerCase()}
        style={{ backgroundColor: data.color }}
      >
        <Typography theme="p">{data.name}</Typography>
      </StyledTagContainer>
    </Tooltip>
  );
};

export default StatusTag;
