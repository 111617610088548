import { css, styled } from 'styled-components';

import { theme } from '../../../../../styles';
import { transitionAnimation } from '../../../../../utils/styles';

export const StyledAdminDashboardContainer = styled.div`
  gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

const baseContainer = css`
  border: 0.1rem solid ${theme.colors.defaultGray};
  padding: 2rem 3rem;
  border-radius: 1rem;
`;

export const StyledReportsContainer = styled.div`
  ${baseContainer}
  flex-grow: 1;
  li {
    ${transitionAnimation}
    display: flex;
    a {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      svg {
        margin-left: 1rem;
      }
    }
    + li {
      margin-top: 1.5rem;
    }
    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;

export const StyledWelcomeContainer = styled.div`
  ${baseContainer}
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
  justify-content: center;
  a {
    color: ${theme.colors.primary};
  }
`;

export const StyledReportsTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 2.5rem;
`;
