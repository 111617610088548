import { InvoiceItems } from '../../../../../utils/types/invoice';
import { AddSimInputs } from '../../../types';

type ValidateInvoideBalance = {
  currentSimData?: AddSimInputs;
  currentInvoiceItem: InvoiceItems;
  currentQuantity?: number;
};

export const validateInvoiceBalance = ({
  currentSimData,
  currentInvoiceItem,
  currentQuantity,
}: ValidateInvoideBalance): number => {
  const hasCurrentSimData =
    currentSimData && Object.entries(currentSimData).length;

  let absoluteBalance = Number();

  if (!hasCurrentSimData) {
    absoluteBalance = currentInvoiceItem?.balance;
  } else {
    if (currentSimData.merchandiseDeclaration.length) {
      const qtty = currentQuantity ?? 0;
      absoluteBalance = currentInvoiceItem?.balance + qtty;
    } else {
      absoluteBalance = currentInvoiceItem?.quantity;
    }
  }

  return absoluteBalance;
};
