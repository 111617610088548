import { Popconfirm } from 'antd';
import React, { FC } from 'react';
import { FaPaperclip, FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { Alert, CustomButton } from '../../../../components';
import { Role } from '../../../../utils';
import AvailableComponent from '../../../../utils/AvailableComponent';
import { Crt } from '../../types';
import {
  StyledActionsBtnsContainer,
  StyledActionsContainer,
} from './HeaderActions.styled';

type Props = {
  data: Crt | null;
  handleOnEdit: () => void;
  handleOnDelete: () => void;
  handleOnFileUpload: () => void;
};

const HeaderActions: FC<Props> = ({
  data,
  handleOnEdit,
  handleOnDelete,
  handleOnFileUpload,
}) => {
  const navigate = useNavigate();

  const hasMics = data?.mics_count && parseInt(data.mics_count) > 0;
  const urlToNavigate = hasMics ? 'mics' : 'mics/nuevo';

  const handleOnMicButtonPress = React.useCallback(() => {
    navigate(urlToNavigate);
  }, [hasMics]);

  return (
    <div>
      <Alert
        canShow={!data?.active}
        icon="warning"
        message=" El CRT se encuentra inactivo. Puede ser que el SIM este expirado, haya sido cerrado o el saldo este agotado."
        type="warning"
      />
      <StyledActionsContainer>
        <StyledActionsBtnsContainer>
          <AvailableComponent
            availableFor={[Role.Transporter, Role.ImpExpAdmin]}
          >
            {hasMics && (
              <Link to="mics">
                <CustomButton $isMini text="Ver MICs" $theme="success" />
              </Link>
            )}
          </AvailableComponent>
          <AvailableComponent availableFor={[Role.Admin]}>
            {((data?.active && !hasMics) || hasMics) && (
              <CustomButton
                $isMini
                text={hasMics ? 'Ver MICs' : 'Asociar MIC'}
                $theme="success"
                onPress={handleOnMicButtonPress}
              >
                {!hasMics && <FaPlus size="1.5rem" />}
              </CustomButton>
            )}
            {!data?.attachment && (
              <CustomButton
                $isMini
                text="Adjuntar Archivo"
                $theme="success"
                onPress={handleOnFileUpload}
              >
                <FaPaperclip size="1.5rem" />
              </CustomButton>
            )}
          </AvailableComponent>
        </StyledActionsBtnsContainer>
        <AvailableComponent availableFor={[Role.Admin]}>
          <StyledActionsBtnsContainer>
            <CustomButton
              $isMini
              text="Editar"
              $theme="secondary"
              onPress={handleOnEdit}
            />
            {'0' === data?.mics_count ? (
              <Popconfirm
                title="Precaución"
                description="¿Está seguro que quiere borrar el CRT? Esta acción es irreversible."
                onConfirm={handleOnDelete}
                okText="Eliminar"
                cancelText="Cancelar"
                okButtonProps={{ danger: true }}
              >
                <div>
                  <CustomButton $isMini text="Borrar" $theme="danger" />
                </div>
              </Popconfirm>
            ) : null}
          </StyledActionsBtnsContainer>
        </AvailableComponent>
      </StyledActionsContainer>
    </div>
  );
};

export default HeaderActions;
