import { css, styled } from 'styled-components';

import { theme } from '../../styles';

type StyledProps = {
  $hasValue?: boolean;
  $invalid?: boolean;
};

export const StyledInputContainer = styled.div`
  border-radius: 0.5rem;
  border: 2px solid ${theme.colors.defaultGray};
  color: ${theme.colors.darkGray};
  input {
    padding: 1.3rem;
    width: 100%;
  }
`;

export const StyledMainContainer = styled.div<StyledProps>`
  max-width: 27.5rem;
  width: 100%;
  ${({ $hasValue }) => {
    return (
      $hasValue &&
      css`
        > div {
          color: ${theme.colors.defaultBlack};
        }
      `
    );
  }}

  ${({ $invalid }) => {
    return (
      $invalid &&
      css`
        input {
          color: ${theme.colors.error};
        }
        ::placeholder {
          color: ${theme.colors.lightError};
        }
        label {
          color: ${theme.colors.error};
        }
        > div {
          border-color: ${theme.colors.error};
          color: ${theme.colors.error};
        }
      `
    );
  }}
`;

export const SubTextContainer = styled.div`
  color: ${theme.colors.darkGray};
  margin-top: 0.5rem;
  text-align: left;
`;

export const StyledInputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;
