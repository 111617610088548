import { styled } from 'styled-components';

import { theme } from '../../styles';
import { baseStylesCSS } from '../../utils/styles';
import { CSSProps } from '../../utils/types';

export const StyledContainer = styled.div<CSSProps>`
  display: inline-block;
  ${baseStylesCSS}
  .h1 {
    font-size: 3.6rem;
    font-weight: 700;
  }
  .h2 {
    font-size: 3rem;
    font-size: 700;
  }
  .h3 {
    font-size: 2.8rem;
  }
  .h4 {
    font-size: 2.5rem;
  }
  .h5 {
    font-size: 2.2rem;
  }
  .h6 {
    font-size: 2rem;
  }
  .p {
    font-size: 1.6rem;
  }
  .a {
    font-size: 1.6rem;
    color: ${theme.colors.primary};
  }
  .button {
    font-weight: 700;
  }
  .subtext {
    font-size: 1.4rem;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: 'Lato', 'Roboto', sans-serif;
  }
  @media only screen and (min-width: ${theme.breakpoints.md}) {
    .h1 {
      font-size: 4.5rem;
    }
    .h2 {
      font-size: 4rem;
    }
    .h3 {
      font-size: 3.6rem;
    }
    .h4 {
      font-size: 3rem;
    }
    .h5 {
      font-size: 2.8rem;
    }
    .h6 {
      font-size: 2.4rem;
    }
    .p {
      font-size: 1.8rem;
    }
    .a {
      font-size: 1.8rem;
    }
    .button {
      font-size: 1.8rem;
    }
    .subtext {
      font-size: 1.5rem;
    }
  }
`;
