import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { InputOption, InputSelectListOption } from '../../../utils/types';
import useStore from '../../../zustand';
import { CompanyData, Inputs } from '../types';

type CompaniesData = {
  data: CompanyData[];
};

const API_SEARCH_COMPANIES = 'companies?filters[business_name][$containsi]=',
  API_CREATE_USER = '/custom-user/create';

const useAddUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companiesInputOptions, setCompaniesInputOptions] = useState<
    InputOption[]
  >([]);
  const [altCompaniesInputOptions, setAltCompaniesInputOptions] = useState<
    InputSelectListOption[]
  >([]);
  const [customError, setCustomError] = useState<string[]>([]);
  const [companiesArray, setCompaniesArray] = useState<CompanyData[]>([]);
  const [companiesArrayQuery, setCompaniesArrayQuery] = useState('');

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const errorHandler = new ErrorHandler();

  const [useRole] = useStore((store) => [store.userRole]);
  const navigateTo = fromUserRoleToUrl(useRole);

  useEffect(() => {
    getCompaniesOption();
  }, [companiesArrayQuery]);

  const getCompaniesOption = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<CompaniesData>(`${API_SEARCH_COMPANIES}${companiesArrayQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const rawResponse = response.data.data;
        setCompaniesArray(rawResponse);
        const results =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { text: item.attributes.business_name, value: item.id };
              })
            : [];

        setCompaniesInputOptions(results);

        const altResults =
          rawResponse.length > 0
            ? rawResponse.map((item) => {
                return { label: item.attributes.business_name, value: item.id };
              })
            : [];
        setAltCompaniesInputOptions(altResults);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  const handleAddUser = async (data: Inputs, companyId?: string | number) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      const dataToSend = companyId
        ? {
            ...data,
            company_id: companyId,
          }
        : { ...data };

      await axios.post(
        API_CREATE_USER,
        { ...dataToSend },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      showNotification({
        message: 'Ha sido creada satisfactoriamente',
      });
      setTimeout(() => {
        navigate(`/${navigateTo}${URLS.users}`);
      }, WAIT_TIMES.sm);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  return {
    customError,
    handleAddUser,
    isLoading,
    companiesArray,
    companiesInputOptions,
    altCompaniesInputOptions,
    setCompaniesArrayQuery,
  };
};

export default useAddUsers;
