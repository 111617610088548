import React, { useState } from 'react';

import { BoxContainer, Breadcrumb, Typography } from '../../components';
import { URLS } from '../../utils';
import { PaginationData } from '../../utils/types';
import OpTable from './components/OpTable';
import useGetFilterOptions from './hooks/useGetFilterOptions';
import useOpReports from './hooks/useOpReports';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.reportOP,
      name: 'Reporte de OP Operativas',
      withUserRole: true,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 20,
    current: currentPage,
  };

  const {
    data,
    totalResults,
    tableFilters,
    isLoading,
    generateReportPDF,
    generateReportXLXS,
  } = useOpReports({
    paginationData,
  });
  const { filterOptions, filterQueries } = useGetFilterOptions();

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Reporte de OP Operativas
      </Typography>
      <OpTable
        data={data}
        isLoading={isLoading}
        onFilter={tableFilters}
        onFilterOptions={filterOptions}
        onFilterQuery={filterQueries}
        paginationData={{
          ...paginationData,
          onChange: setCurrentPage,
          total: totalResults,
        }}
        onGenerateReportPDF={generateReportPDF}
        onGenerateReportXLXS={generateReportXLXS}
      />
    </BoxContainer>
  );
};

export default PageContent;
