import React, { FC } from 'react';

import useStore from '../zustand';
import { fromUserRoleToUrl, Role } from './roles';

type Props = {
  children: React.ReactNode;
  availableFor: Role[];
};

export const AvailableComponent: FC<Props> = ({ children, availableFor }) => {
  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole) as Role;
  return availableFor.includes(transformedUserRole) ? <> {children}</> : null;
};

export default AvailableComponent;
