import { UploadProps } from 'antd';
import React, { useState } from 'react';
import { FaFileArchive } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  FileUploader,
  Loader,
  Typography,
} from '../../components';
import { getCustomsDestinationName, URLS } from '../../utils';
import { StyledAttachmentContainer } from '../../utils/styles';
import HeaderActions from './components/HeaderActions';
import MerchandiseTable from './components/MerchandiseTable';
import StatusTag from './components/StatusTag';
import useSimDetail from './hooks/useSimDetail';
import { StyledSimDataContaner } from './SimDetail.styled';

const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.listInvoices,
      name: 'Facturas',
      withUserRole: true,
    },
    {
      location: URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id || String(),
      ),
      name: 'Detalle Factura',
      withUserRole: true,
    },
    {
      location: `${URLS.invoiceDetail.replace(
        '{invoiceID}',
        params.invoice_id || String(),
      )}${URLS.simDetail.replace('{simID}', params.sim_id || String())}`,
      name: 'Detalle SIM',
      withUserRole: true,
    },
  ];

  const { data, isLoading, handleDeleteSim, handleUploadFile, handleOnClose } =
    useSimDetail({
      simID: params.sim_id,
    });

  const handleOnGoBack = React.useCallback(() => {
    navigate(-1);
  }, []);

  const handleOnEdit = React.useCallback(() => {
    navigate(`editar`);
  }, []);

  const handleOnDelete = React.useCallback(() => {
    handleDeleteSim(params.sim_id);
  }, []);

  const [showFileUploadComponent, setShowFileUploadComponent] = useState(false);
  const [hasElementDropped, setHasElementDropped] = useState(false);

  const handleOnFileUpload = React.useCallback(() => {
    setShowFileUploadComponent(true);
  }, []);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    beforeUpload: () => false,
    onChange: (info) => setHasElementDropped(info.fileList.length > 0),
  };

  const handleOnCloseAction = React.useCallback(() => {
    handleOnClose(params.sim_id);
  }, []);

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader isFullOpacity />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        SIM {data?.number}
      </Typography>

      <HeaderActions
        data={data}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        onFileUpload={handleOnFileUpload}
        onClose={handleOnCloseAction}
      />

      {showFileUploadComponent && !data?.attachment && (
        <FileUploader
          props={uploadProps}
          hasFile={hasElementDropped}
          onUploadFile={handleUploadFile}
        />
      )}

      {data?.attachment && data.attachment.url && (
        <Link to={data.attachment.url}>
          <StyledAttachmentContainer>
            <FaFileArchive size="3rem" />
            <Typography theme="p">{data?.attachment.name}</Typography>
          </StyledAttachmentContainer>
        </Link>
      )}

      <StyledSimDataContaner>
        <li>
          <Typography theme="p">
            <strong>Despachante de Aduana:</strong>&nbsp;{data?.customs_agent}
          </Typography>
        </li>
        <li>
          <Typography theme="p">
            <strong>Agente de Transporte Aduanero:</strong>&nbsp;
            {data?.customs_agent_transport}
          </Typography>
        </li>
        <li>
          <Typography theme="p">
            <strong>Vencimiento Embarque:</strong>&nbsp;
            {data?.shipment_due_date.toString()}
          </Typography>
        </li>
        <li>
          <Typography theme="p">
            <strong>Aduana Destino:</strong>&nbsp;
            {getCustomsDestinationName(data?.customs_destination)}
          </Typography>
        </li>

        <li>
          <Typography theme="p">
            <strong>Estado:</strong>&nbsp;
          </Typography>
          <StatusTag status={data?.state} />
        </li>
      </StyledSimDataContaner>

      <div>
        <Typography theme="h3" as="h2" $marginBottom="4rem">
          Declaración de la Mercadería
        </Typography>
        <MerchandiseTable data={data?.sim_items} />
      </div>

      <CustomButton
        text="Volver"
        $isMini
        $marginTop="4rem"
        onPress={handleOnGoBack}
      />
    </BoxContainer>
  );
};

export default PageContent;
