import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import useStore from '../../../zustand';
import { AddMerchandiseInputs, Merchandise } from '../types';
import {
  transformFromAddMerchandiseInputsToMerchandise,
  transformFromMerchandiseToAddMerchandiseInputs,
} from '../utils';

type Props = {
  merchandiseID?: string;
};

const useAddMerchandise = ({ merchandiseID }: Props) => {
  const API_URL_CREATE_MERCHANDISE = '/merchandises',
    API_URL_FIND_MERCHANDISE = '/merchandises/{merchandise_id}',
    API_URL_UPDATE_MERCHANDISE = '/merchandises/{merchandise_id}';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification } = useShowNotification();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  const errorHandler = new ErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [singleMerchandiseData, setSingleMerchandiseData] =
    useState<AddMerchandiseInputs>({} as AddMerchandiseInputs);

  const [customError, setCustomError] = useState<string[]>([]);

  // Get Merchandise Data
  const getMerchandiseData = (token: string | null) => {
    setIsLoading(true);
    setHasError(false);

    if (!merchandiseID) throw new Error('No Merchandise ID provided');

    axios
      .get<Merchandise>(
        API_URL_FIND_MERCHANDISE.replace('{merchandise_id}', merchandiseID),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const result = response.data.data;
        const transformedData =
          transformFromMerchandiseToAddMerchandiseInputs(result);
        setSingleMerchandiseData(transformedData);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }
      if (merchandiseID) getMerchandiseData(token);
    } catch (error) {
      log.error(error);
      setHasError(true);
    }
  }, []);

  // Create / Update Merchandise
  const handleAddMerchandise = async (data: AddMerchandiseInputs) => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      // Create Merchandise
      if (!merchandiseID) {
        const transformedData =
          transformFromAddMerchandiseInputsToMerchandise(data);
        const merchandiseData = { data: { ...transformedData } };

        await axios.post(
          API_URL_CREATE_MERCHANDISE,
          { ...merchandiseData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        setIsLoading(false);

        showNotification({
          message: NotificationMessages.MERCHANDISE_ADD_SUCCESS,
        });

        setTimeout(() => {
          navigate(`/${transformedUserRole}${URLS.merchandise}`);
        }, WAIT_TIMES.md);
      } else {
        // Update Merchandise
        const transformedData =
          transformFromAddMerchandiseInputsToMerchandise(data);
        const merchandiseData = { data: { ...transformedData } };

        await axios.put(
          API_URL_UPDATE_MERCHANDISE.replace('{merchandise_id}', merchandiseID),
          { ...merchandiseData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: NotificationMessages.MERCHANDISE_EDIT_SUCCESS,
        });

        setTimeout(() => {
          navigate(`/${transformedUserRole}${URLS.merchandise}`);
        }, WAIT_TIMES.md);
      }
    } catch (error) {
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  return {
    hasError,
    isLoading,
    customError,
    singleMerchandiseData,
    handleAddMerchandise,
  };
};

export default useAddMerchandise;
