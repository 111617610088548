import React from 'react';

import { AddCrt, CrtDetailPage, CrtsPage } from '../../../pages';
import {
  isAdmin,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const crtRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/crts',
      component: <CrtsPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id',
      component: <CrtDetailPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/crts/nuevo',
      component: <AddCrt />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/editar',
      component: <AddCrt />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/crts',
      component: <CrtsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id',
      component: <CrtDetailPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/nuevo',
      component: <AddCrt />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/crts/nuevo',
      component: <AddCrt />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/editar',
      component: <AddCrt />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/crts',
      component: <CrtsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/crts/:crt_id',
      component: <CrtDetailPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/crts/nuevo',
      component: <AddCrt />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/editar',
      component: <AddCrt />,
      isAvailable: isTransporter(useRole),
    },
    // Clients
    {
      path: '/cliente/crts',
      component: <CrtsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id',
      component: <CrtDetailPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/crts/nuevo',
      component: <AddCrt />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/editar',
      component: <AddCrt />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
