import log from 'loglevel';

const useFileDownload = () => {
  const downloadAction = (url: string, name: string) => {
    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.style.display = 'none';
          alink.download = name;
          alink.click();
          alink.remove();
        });
      })
      .catch((error) => log.error(error));
  };

  return { downloadAction };
};

export default useFileDownload;
