import { styled } from 'styled-components';

import { theme } from '../../styles';

export const StyledMainListContainer = styled.ul`
  a {
    color: ${theme.colors.primary};
    &:hover {
      > * {
        text-decoration: underline;
      }
    }
  }
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media only screen and (min-width: ${theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'main .'
      'company .'
      'truck semitrailer'
      'insurance insurance_empty'
      'driver .'
      'merchandise merchandise';
    > :nth-child(1) {
      grid-area: main;
    }
    > :nth-child(2) {
      grid-area: company;
    }
    > :nth-child(3) {
      grid-area: truck;
    }
    > :nth-child(4) {
      grid-area: semitrailer;
    }
    > :nth-child(5) {
      grid-area: insurance;
    }
    > :nth-child(6) {
      grid-area: insurance_empty;
      display: flex;
      align-items: flex-end;
    }
    > :nth-child(7) {
      grid-area: driver;
    }
    > :nth-child(8) {
      grid-area: merchandise;
    }
  }
`;
