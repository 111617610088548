import { Pagination } from 'antd';
import React, { FC } from 'react';

import {
  EmptyMessage,
  InputSelect,
  InputText,
  Loader,
} from '../../../../components';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { Pagination as PaginationType } from '../../../../utils/types/index';
import { Sim } from '../../../../utils/types/sims';
import CardData from '../CardData';
import {
  StyledCardsContainer,
  StyledCardsGroupMainContainer,
  StyledPaginationContainer,
} from './CardsGroup.styled';

type Props = {
  data: Sim[] | null;
  pagination: PaginationType;
  onInputInvoiceNumberFilter: (value: string) => void;
  onInputSimNumberFilter: (value: string) => void;
  onInputStatusFilter: (value: string) => void;
  onPaginationChange: (page: number) => void;
  currentPage: number;
  isLoading: boolean;
};

const statusOptions = [
  { text: 'Todos', value: '' },
  { text: 'Activo', value: 'Active' },
  { text: 'Cerrado', value: 'Closed' },
  { text: 'Completo', value: 'Completed' },
  { text: 'Expirado', value: 'Expired' },
  { text: 'Inactivo', value: 'Inactive' },
];

const CardsGroup: FC<Props> = ({
  data,
  onInputInvoiceNumberFilter,
  onInputSimNumberFilter,
  onInputStatusFilter,
  onPaginationChange,
  currentPage,
  pagination,
  isLoading,
}) => {
  const handleInputInvoiceNumberFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputInvoiceNumberFilter(event.target.value);
    },
    [],
  );

  const handleInputSimNumberFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputSimNumberFilter(event.target.value);
    },
    [],
  );

  const handleInputStatusFilter = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onInputStatusFilter(event.target.value);
    },
    [],
  );

  return (
    <StyledCardsGroupMainContainer>
      {isLoading && <Loader />}
      <StyledTableContainer>
        <StyledFiltersContainer>
          <InputText
            placeholder="Número de Factura"
            onChange={handleInputInvoiceNumberFilter}
          />
          <InputText
            placeholder="Número de SIM"
            onChange={handleInputSimNumberFilter}
          />
          <InputSelect
            options={statusOptions}
            placeholder="Estado"
            onChange={handleInputStatusFilter}
          />
        </StyledFiltersContainer>
        <StyledCardsContainer>
          {data?.map((item) => {
            return <CardData key={item.id} data={item} />;
          })}
        </StyledCardsContainer>

        <EmptyMessage
          canShow={data?.length === undefined || data.length <= 0}
        />

        <StyledPaginationContainer>
          <Pagination
            defaultCurrent={1}
            total={pagination?.total || 0}
            pageSize={pagination?.pageSize || 0}
            current={currentPage}
            showSizeChanger={false}
            onChange={onPaginationChange}
          />
        </StyledPaginationContainer>
      </StyledTableContainer>
    </StyledCardsGroupMainContainer>
  );
};

export default CardsGroup;
