import { styled } from 'styled-components';

import { theme } from '../../../../styles';

export const StyledMainContainer = styled.div`
  align-items: center;
  background-color: ${theme.colors.defaultWhite};
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.5rem 0.2rem ${theme.colors.defaultBlack}3F;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledTitleText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  > :nth-child(2) {
    color: ${theme.colors.darkGray};
    opacity: 0.7;
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;
