import React from 'react';
import { FaArrowAltCircleRight, FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { BoxContainer, Typography } from '../../../../../components';
import CustomBanner from '../../../../../components/CustomBanner';
import { fromUserRoleToUrl, Role, URLS } from '../../../../../utils';
import useStore from '../../../../../zustand';
import useAdminDashboard from '../../../hooks/useAdminDashboard';
import { ActionCardData } from '../../../types';
import DashboardCards from '../../DashboardCards';
import DashboardHeader from '../../DashboardHeader';
import {
  StyledAdminDashboardContainer,
  StyledReportsContainer,
  StyledReportsTitle,
  StyledWelcomeContainer,
} from './AdminDashboard.styled';

const AdminDashboard = () => {
  const { data } = useAdminDashboard();

  const [userName, userRole] = useStore((state) => [
    state.userName,
    state.userRole,
  ]);

  const transformedUserRole = fromUserRoleToUrl(userRole);

  const cards: ActionCardData[] = [
    {
      color: 'blue',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.invoices_count,
      title: 'Facturas',
      url: `/${transformedUserRole}${URLS.listInvoices}`,
    },
    {
      color: 'yellow',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.sims_count,
      title: 'SIMs',
      url: `/${transformedUserRole}${URLS.listSims}`,
    },
    {
      color: 'green',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.crts_count,
      title: 'CRTs',
      url: `/${transformedUserRole}${URLS.listCrts}`,
    },
    {
      color: 'red',
      ctaText: 'Ver listado',
      icon: 'file',
      number: data?.mics_count,
      title: 'MICs',
      url: `/${transformedUserRole}${URLS.mics}`,
    },
  ];

  const reportLinks = [
    {
      title: 'Reporte de OP',
      url: `/${transformedUserRole}${URLS.reportOP}`,
      visibleFor: [Role.Admin, Role.ImpExpAdmin],
    },
    {
      title: 'Reporte de CRT',
      url: `/${transformedUserRole}${URLS.reportCRT}`,
      visibleFor: [Role.Admin, Role.ImpExpAdmin],
    },
    {
      title: 'Reporte de MIC',
      url: `/${transformedUserRole}${URLS.mics}`,
      visibleFor: [Role.Admin, Role.ImpExpAdmin],
    },
    {
      title: 'Reporte de Camiones',
      url: `/${transformedUserRole}${URLS.reportTrucks}`,
      visibleFor: [Role.Admin],
    },
    {
      title: 'Reporte de Semiremolques',
      url: `/${transformedUserRole}${URLS.reportSemitrailers}`,
      visibleFor: [Role.Admin],
    },
    {
      title: 'Reporte de Choferes',
      url: `/${transformedUserRole}${URLS.reportTruckDrivers}`,
      visibleFor: [Role.Admin],
    },
  ];

  return (
    <div>
      <DashboardHeader />
      <DashboardCards cards={cards} />
      <BoxContainer $useMarginBottom>
        <StyledAdminDashboardContainer>
          <StyledReportsContainer>
            <StyledReportsTitle>
              <FaFileAlt size="2rem" />
              <Typography theme="h4">Reportes</Typography>
            </StyledReportsTitle>
            <ul>
              {reportLinks.map((item) => {
                if (item.visibleFor.includes(transformedUserRole as Role)) {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>
                        <Typography theme="p">{item.title}</Typography>
                        <FaArrowAltCircleRight size="1.5rem" />
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </StyledReportsContainer>
          <StyledWelcomeContainer>
            <Typography theme="h4" $marginBottom="2.5rem">
              Bienvenido {userName}
            </Typography>
            <Typography theme="p">
              Mis Despachos es la plataforma para despachantes de aduana y
              empresas relacionadas al rubro que te permitirá gestionar todos
              los datos de tu negocio, así como también realizar consultas
              rápidas sobre documentación y saldos. Cualquier inquietud o
              solicitud, por favor canalícela a través de nuestro{' '}
              <a href="mailto:soporte@misdespachos.com">equipo de soporte</a>.
            </Typography>
          </StyledWelcomeContainer>
        </StyledAdminDashboardContainer>
        <CustomBanner />
      </BoxContainer>
    </div>
  );
};

export default AdminDashboard;
