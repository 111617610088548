import log from 'loglevel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { URLS, WAIT_TIMES } from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { ChangePasswordRequest } from '../types';

const useChangePassword = () => {
  const API_URL_CHANGE_PASSWORD = 'custom-user/update-pass';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async (data: ChangePasswordRequest) => {
    try {
      setIsLoading(true);
      await axios.patch(API_URL_CHANGE_PASSWORD, data);
      showNotification({
        message: 'Su password fue actualizado satisfactoriamente.',
      });
      setTimeout(() => {
        navigate(URLS.landing);
      }, WAIT_TIMES.lg);
    } catch (error) {
      const errorHandler = new ErrorHandler();
      let message = 'Ha ocurrido un error';
      if (404 == errorHandler.readErrorCode(error)) {
        message =
          'El Link para cambio de contraseña es incorrecto o ya caduco.';
      }
      showErrorNotification({ message });
      log.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleChangePassword,
  };
};

export default useChangePassword;
