import React, { useState } from 'react';

import { BoxContainer, Breadcrumb, Typography } from '../../components';
import { URLS } from '../../utils';
import { PaginationData } from '../../utils/types';
import TruckDriversReportsTable from './components/TruckDriversReportsTable';
import useTruckDriversReports from './hooks/useTruckDriversReports';

const PageContent = () => {
  const breadcrumbItems = [
    { location: URLS.dashboard, name: 'Dashboard' },
    {
      location: URLS.reportTruckDrivers,
      name: 'Reporte de Choferes',
      withUserRole: true,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const paginationData: PaginationData = {
    pageSize: 8,
    current: currentPage,
  };

  const {
    isLoading,
    data,
    totalResults,
    tableFilters,
    setTransportQuery,
    transportOptions,
    generateReportPDF,
    generateReportXLXS,
  } = useTruckDriversReports({
    paginationData,
  });

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="2rem">
        Reporte de Choferes
      </Typography>
      <TruckDriversReportsTable
        data={data}
        isLoading={isLoading}
        onFilter={tableFilters}
        transportFilterOptions={transportOptions}
        transportFilterQuery={setTransportQuery}
        paginationData={{
          ...paginationData,
          onChange: setCurrentPage,
          total: totalResults,
        }}
        onGenerateReportPDF={generateReportPDF}
        onGenerateReportXLXS={generateReportXLXS}
      />
    </BoxContainer>
  );
};

export default PageContent;
