import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fromUserRoleToUrl, URLS, WAIT_TIMES } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { Invoice } from '../../../utils/types/invoice';
import useStore from '../../../zustand';

type Props = {
  invoiceID?: string;
};

const useInvoiceDetail = ({ invoiceID }: Props) => {
  const API_URL_FILE_UPLOAD = '/upload',
    API_URL_INVOICE = '/custom-invoice/{invoice_id}/find',
    API_URL_INVOICE_DELETE = '/custom-invoice/{invoice_id}/delete',
    API_URL_UPDATE_INVOICE = '/custom-invoice/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const [data, setData] = useState<Invoice | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getInvoiceDetail = (token: string | null) => {
    setHasError(false);
    if (!invoiceID) throw new Error('No Invoice ID provided');
    axios
      .get<Invoice>(API_URL_INVOICE.replace('{invoice_id}', invoiceID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }

      setIsLoading(true);

      getInvoiceDetail(token);
    } catch (error) {
      log.error('No token provided');
      setHasError(true);
    }
  }, []);

  // Delete Invoice
  const handleDeleteInvoice = async (invoiceID?: string) => {
    setIsLoading(true);
    let errorMsg = 'Ha ocurrido un error';
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!invoiceID) {
        throw new Error('There is no Invoice ID');
      }

      if (
        data?.related_docs.docs_count &&
        data?.related_docs.docs_count !== 0
      ) {
        errorMsg = 'La Factura aún tiene documentos relacionados';
        throw new Error('Invoice has related docs');
      }

      await axios.delete(
        API_URL_INVOICE_DELETE.replace('{invoice_id}', invoiceID),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      showNotification({
        message: 'El registro fue borrado correctamente',
      });

      setTimeout(() => {
        navigate(`/${transformedUserRole}${URLS.listInvoices}`);
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: errorMsg,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const { file, onSuccess } = info;
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!invoiceID) throw new Error('No Invoice ID');

      const data = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      const invoiceData = {
        data: {
          attachment: parseInt(attachmentID ?? null),
        },
      };

      // Update Invoice data
      await axios.put(
        API_URL_UPDATE_INVOICE.replace('{id}', invoiceID),
        { ...invoiceData },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (onSuccess) onSuccess(data);

      getInvoiceDetail(token);
    } catch (error) {
      log.error(error);
    }
  };

  return { data, hasError, isLoading, handleDeleteInvoice, handleUploadFile };
};

export default useInvoiceDetail;
