import { Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import React, { FC } from 'react';

import { InputSelect, InputText, Loader } from '../../../../components';
import { PaginationData } from '../../../../utils/types';
import { Data } from '../../types';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from './DashboardTable.styled';
import { columns, DataType, transformData } from './utils';

type Props = {
  data?: Data[];
  isLoading: boolean;
  onInputFilterChange: (value: string) => void;
  onSelectFilterChange: (value: string) => void;
  paginationData: PaginationData;
  onTableChange: (
    _pagination: any,
    _filters: any,
    sorter: SorterResult<DataType> | SorterResult<DataType>[],
  ) => void;
};

const DashboardTable: FC<Props> = ({
  data,
  isLoading,
  onInputFilterChange,
  onSelectFilterChange,
  paginationData,
  onTableChange,
}) => {
  const newData = transformData(data);

  const handleSearchByName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputFilterChange(event.target.value);
    },
    [],
  );

  const handleSelectChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onSelectFilterChange(event.target.value);
    },
    [],
  );

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputSelect
          placeholder="Tipo de Empresa"
          onChange={handleSelectChange}
          options={[
            { value: 'Despachante', text: 'Despachante' },
            { value: 'Importadora/Exportadora', text: 'Imp/Exp' },
            { value: 'Transportista', text: 'Transportista' },
            { value: '', text: 'Todos' },
          ]}
        />
        <InputText placeholder="Nombre" onChange={handleSearchByName} />
      </StyledFiltersContainer>
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          ...paginationData,
        }}
        onChange={onTableChange}
      />
    </StyledTableContainer>
  );
};

export default DashboardTable;
