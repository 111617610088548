import log from 'loglevel';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fromUserRoleToUrl, URLS, WAIT_TIMES } from '../../../utils';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { PlainSimAlt } from '../../../utils/types/sims';
import useStore from '../../../zustand';

type Props = {
  simID?: string;
};

const useSimDetail = ({ simID }: Props) => {
  const API_URL_CLOSE_SIM = '/custom-sim/{sim_id}/close',
    API_URL_FILE_UPLOAD = '/upload',
    API_URL_GET_SIM = '/custom-sim/{sim_id}/find',
    API_URL_SIM_DELETE = '/custom-sim/{sim_id}/delete',
    API_URL_UPDATE_SIM = '/custom-sim/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const [data, setData] = useState<PlainSimAlt | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getSimDetail = (token: string | null) => {
    setHasError(false);
    if (!simID) throw new Error('No Sim ID provided');
    axios
      .get<PlainSimAlt>(API_URL_GET_SIM.replace('{sim_id}', simID), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        log.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No Token provided');
      }

      setIsLoading(true);

      getSimDetail(token);
    } catch (error) {
      log.error('No token provided');
      setHasError(true);
    }
  }, []);

  // Delete SIM
  const handleDeleteSim = async (simID?: string) => {
    setIsLoading(true);
    let errorMsg = 'Ha ocurrido un error';
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!simID) {
        throw new Error('There is no Sim ID');
      }

      if (data?.crt_id.id) {
        errorMsg = 'El SIM tiene CRT relacionado';
        throw new Error('Sim has CRT');
      }

      await axios.delete(API_URL_SIM_DELETE.replace('{sim_id}', simID), {
        headers: { Authorization: `Bearer ${token}` },
      });
      showNotification({
        message: 'El registro fue borrado correctamente',
      });

      setTimeout(() => {
        navigate(`/${transformedUserRole}${URLS.listSims}`);
      }, WAIT_TIMES.md);
      setIsLoading(false);
    } catch (error) {
      showErrorNotification({
        message: errorMsg,
      });
      log.error(error);
      setHasError(true);
      setIsLoading(false);
    }
  };

  // Upload File
  const handleUploadFile = async (info: RcCustomRequestOptions) => {
    const { file, onSuccess } = info;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!simID) throw new Error('No Invoice ID');

      const data = { files: file };
      let attachmentID: string = '';

      // Upload file and retrieve ID
      const response = await axios.post(API_URL_FILE_UPLOAD, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.length) {
        attachmentID = response.data[0].id;
      }

      const simData = {
        data: {
          attachment: parseInt(attachmentID ?? null),
        },
      };

      // Update Invoice data
      await axios.put(
        API_URL_UPDATE_SIM.replace('{id}', simID),
        { ...simData },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (onSuccess) {
        onSuccess(data);
        showNotification({
          message: 'El archivo se ha subido exitosamente',
        });
      }

      getSimDetail(token);
    } catch (error) {
      log.error(error);
    }
  };

  // Close SIM
  const handleOnClose = async (simID?: string) => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        log.error('No token provided');
      }

      if (!simID) throw new Error('No SIM ID provided');

      await axios.patch(API_URL_CLOSE_SIM.replace('{sim_id}', simID), null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showNotification({
        message: 'El elemento ha sido cerrado exitosamente.',
      });
    } catch (error) {
      log.error(error);
    }
  };

  return {
    data,
    hasError,
    isLoading,
    handleDeleteSim,
    handleUploadFile,
    handleOnClose,
  };
};

export default useSimDetail;
