import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledSimDataContaner = styled.ul`
  margin-bottom: 4rem;
  a {
    color: ${theme.colors.primary};
    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }
  li + li {
    margin-top: 1rem;
  }
`;
