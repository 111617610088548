import { FaFolder } from 'react-icons/fa';
import { css, styled } from 'styled-components';

import { theme } from '../../styles';
import { transitionAnimation } from '../../utils/styles';

const StyledSideBar = css`
  background-color: ${theme.colors.defaultBlack};
  color: ${theme.colors.defaultWhite};
  padding: 0 4rem;
`;

export const StyledMyFilesMainContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 15rem);
  a {
    ${transitionAnimation}
    &:hover {
      color: ${theme.colors.primary};
      > :nth-child(2) {
        > * {
          color: ${theme.colors.defaultWhite};
        }
      }
    }
  }
  > div {
    height: auto;
  }
  > :nth-child(1) {
    ${StyledSideBar}
    ${transitionAnimation}
    flex: 0 0 30rem;
    overflow: hidden;
  }
  > :nth-child(2) {
    flex: 1 1 100%;
  }
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    > :nth-child(1) {
      flex: 0;
      width: 0;
      padding: 0;
    }
  }
`;

export const StyledContentList = styled.ul`
  ${transitionAnimation}
  display: flex;
  flex-direction: column;
  height: 0;
  margin-bottom: 0;
  opacity: 0;
  padding-left: 4rem;
  pointer-events: none;
  &.open {
    height: auto;
    opacity: 1;
    pointer-events: all;
  }
`;

export const StyledContentListItem = styled.div`
  > a {
    align-items: center;
    display: flex;
    &.active {
      color: ${theme.colors.primary};
    }
    > :nth-child(2) {
      margin-left: 0.5rem;
      padding: 0.3rem 0.5rem;
      position: relative;
      > * {
        position: relative;
        z-index: 2;
      }
      &:before {
        content: '';
        background-color: ${theme.colors.defaultGray};
        border-radius: 0.7rem;
        height: 100%;
        left: 0;
        opacity: 0.35;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
`;

export const StyledFolderIcon = styled(FaFolder)`
  vertical-align: text-bottom;
`;
