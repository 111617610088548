import { UploadProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaFileArchive } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  BoxContainer,
  Breadcrumb,
  CustomButton,
  FileUploader,
  Loader,
  Typography,
} from '../../components';
import {
  fromUserRoleToUrl,
  isAdmin,
  isImpExpAdmin,
  isTransporter,
  Role,
  simsUrl,
  URLS,
} from '../../utils';
import AvailableComponent from '../../utils/AvailableComponent';
import { StyledAttachmentContainer } from '../../utils/styles';
import useStore from '../../zustand';
import CompanyInfo from './components/CompanyInfo';
import HeaderActions from './components/HeaderActions';
import ImpExpCompanies from './components/ImpExpCompanies';
import ItemsTable from './components/ItemsTable';
import ReceptionAndDeliveryTable from './components/ReceptionAndDeliveryTable';
import {
  StyledDetailsTitleContainer,
  StyledDocumentsDataContainer,
} from './CrtsDetails.styled';
import useCrtDetails from './hook/useCrtDetails';

export const PageContent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [userRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(userRole);

  let breadcrumbItems: {
    location: string;
    name: string;
    withUserRole?: boolean;
  }[] = [];

  if (isAdmin(userRole) || isImpExpAdmin(userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: URLS.listInvoices,
        name: 'Facturas',
        withUserRole: true,
      },
      {
        location: URLS.invoiceDetail.replace(
          '{invoiceID}',
          params.invoice_id || String(),
        ),
        name: 'Detalle Factura',
        withUserRole: true,
      },
      {
        location: simsUrl(params),
        name: 'Detalle SIM',
        withUserRole: true,
      },
      {
        location: `${simsUrl(params)}/crts/${params.crt_id}`,
        name: 'Detalle CRT',
        withUserRole: true,
      },
    ];
  }

  if (isTransporter(userRole)) {
    breadcrumbItems = [
      { location: URLS.dashboard, name: 'Dashboard' },
      {
        location: `${URLS.listCrts}`,
        name: 'CRTs',
        withUserRole: true,
      },
      {
        location: `/crts/${params.crt_id}`,
        name: 'Detalle CRT',
        withUserRole: true,
      },
    ];
  }

  const { data, isLoading, handleUploadFile, handleDeleteCrt } = useCrtDetails({
    crtID: params.crt_id,
  });

  const handleOnEdit = React.useCallback(() => {
    navigate(`editar`);
  }, []);

  const handleOnDelete = React.useCallback(() => {
    handleDeleteCrt(params.crt_id);
  }, []);

  const [showFileUploadComponent, setShowFileUploadComponent] = useState(false);

  const handleOnFileUpload = React.useCallback(() => {
    setShowFileUploadComponent(true);
  }, []);

  const [hasElementDropped, setHasElementDropped] = useState(false);

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    beforeUpload: () => false,
    onChange: (info) => setHasElementDropped(info.fileList.length > 0),
  };

  useEffect(() => {
    setShowFileUploadComponent(false);
  }, [data]);

  const handleOnGoBack = React.useCallback(() => {
    navigate(`/${transformedUserRole}${URLS.listCrts}`);
  }, []);

  const receptionAndDeliveryData =
    data?.reception && data?.delivery ? [data?.reception, data?.delivery] : [];

  return (
    <BoxContainer $useMarginBottom $useMarginTop>
      {isLoading && <Loader isFullOpacity />}
      <Breadcrumb items={breadcrumbItems} />
      <Typography theme="h1" as="h1" $marginBottom="4rem">
        CRT {data?.number}
      </Typography>

      <HeaderActions
        data={data}
        handleOnEdit={handleOnEdit}
        handleOnDelete={handleOnDelete}
        handleOnFileUpload={handleOnFileUpload}
      />

      {showFileUploadComponent && (
        <FileUploader
          props={uploadProps}
          hasFile={hasElementDropped}
          onUploadFile={handleUploadFile}
        />
      )}

      {data?.attachment && (
        <Link to={data.attachment.url}>
          <StyledAttachmentContainer>
            <FaFileArchive size="3rem" />
            <Typography theme="p">{data?.attachment.name}</Typography>
          </StyledAttachmentContainer>
        </Link>
      )}

      <AvailableComponent availableFor={[Role.Admin, Role.ImpExpAdmin]}>
        <StyledDetailsTitleContainer>
          <Typography theme="h3" as="h2" $marginBottom="4rem">
            Empresa de Transporte
          </Typography>
          <CompanyInfo data={data?.carrier_company} />
        </StyledDetailsTitleContainer>
      </AvailableComponent>

      <AvailableComponent availableFor={[Role.Transporter]}>
        <StyledDetailsTitleContainer>
          <ImpExpCompanies data={data} />
        </StyledDetailsTitleContainer>
      </AvailableComponent>

      <StyledDetailsTitleContainer>
        <Typography theme="h3" as="h2" $marginBottom="4rem">
          Recepción y Entrega
        </Typography>
        <ReceptionAndDeliveryTable data={receptionAndDeliveryData} />
      </StyledDetailsTitleContainer>

      <StyledDetailsTitleContainer>
        <Typography theme="h3" as="h2" $marginBottom="4rem">
          Detalle
        </Typography>
        <div>
          <Typography theme="p" as="h4">
            Costo del Flete:&nbsp;
          </Typography>
          <Typography theme="p" $marginBottom="1rem">
            {data?.total_cost_for_recipient} - {data?.currency}
          </Typography>
        </div>
        <ItemsTable data={data?.crt_items} />
      </StyledDetailsTitleContainer>

      <AvailableComponent availableFor={[Role.Admin, Role.ImpExpAdmin]}>
        <StyledDetailsTitleContainer>
          <Typography theme="h3" as="h2" $marginBottom="4rem">
            Documentos anexos
          </Typography>
          <StyledDocumentsDataContainer>
            <li>
              <Typography theme="p">
                <strong>FACTURA:</strong>&nbsp;
              </Typography>
              <Link
                to={`/${transformedUserRole}/facturas/${data?.invoice?.id}`}
              >
                <Typography theme="p">
                  {data?.related_documents?.invoice.number}
                </Typography>
              </Link>
            </li>
            <li>
              <Typography theme="p">
                <strong>SIM:</strong>&nbsp;
              </Typography>
              <Link
                to={`/${transformedUserRole}/facturas/${data?.invoice?.id}/sims/${data?.sim?.id}`}
              >
                <Typography theme="p">
                  {data?.related_documents?.sim.number}
                </Typography>
              </Link>
            </li>
          </StyledDocumentsDataContainer>
        </StyledDetailsTitleContainer>
      </AvailableComponent>

      <CustomButton
        text="Volver"
        $isMini
        $marginTop="4rem"
        onPress={handleOnGoBack}
      />
    </BoxContainer>
  );
};

export default PageContent;
