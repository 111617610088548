import {
  FaFile,
  FaIndustry,
  FaShoppingBag,
  FaTruck,
  FaUsers,
} from 'react-icons/fa';

enum IconList {
  'bag',
  'industry',
  'truck',
  'users',
}

enum IconListFoo {
  'bag',
  'file',
  'industry',
  'truck',
  'users',
}

enum ColorList {
  'green',
  'blue',
  'yellow',
  'red',
}

export type IconListType = keyof typeof IconList;
export type IconListTypeFoo = keyof typeof IconListFoo;
export type ColorListType = keyof typeof ColorList;

export const getIconFoo = (icon: IconListTypeFoo) => {
  const ICON_LIST = {
    bag: FaShoppingBag,
    industry: FaIndustry,
    truck: FaTruck,
    users: FaUsers,
    file: FaFile,
  };

  return ICON_LIST[icon];
};

export const getIcon = (icon: IconListType) => {
  const ICON_LIST = {
    bag: FaShoppingBag,
    industry: FaIndustry,
    truck: FaTruck,
    users: FaUsers,
  };

  return ICON_LIST[icon];
};

export const getActionCardClasses = (icon: IconListType) => {
  const CLASS_LIST = {
    bag: 'green',
    industry: 'blue',
    truck: 'yellow',
    users: 'red',
  };

  return CLASS_LIST[icon];
};

export const getTitle = (icon: IconListType) => {
  const CLASS_LIST = {
    bag: 'Empresas Despachantes',
    industry: 'Empresas Imp/Exp',
    truck: 'Empresas Transportistas',
    users: 'Usuarios',
  };

  return CLASS_LIST[icon];
};
