import { FaTruck } from 'react-icons/fa';
import { styled } from 'styled-components';

import { baseStylesCSS } from '../../utils/styles';
import { CSSProps } from '../../utils/types';

export const StyledLogoContainer = styled.div<CSSProps>`
  align-items: flex-end;
  display: flex;
  position: relative;
  ${baseStylesCSS}
`;

export const StyledLogo = styled(FaTruck)`
  margin-right: 1rem;
  transform: scaleX(-1);
`;
