import React from 'react';

import {
  AddMicPage,
  CrtRelatedMicsPage,
  MicDetailPage,
  MicsPage,
} from '../../../pages';
import {
  isAdmin,
  isDispatcher,
  isImpExpAdmin,
  isSuperAdmin,
  isTransporter,
} from '../../roles';
import { Routes } from '../../types/route';

export const micsRoutes = (useRole: string): Routes[] => {
  return [
    // Super-Admin
    {
      path: '/super-admin/mics',
      component: <MicsPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id',
      component: <MicDetailPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/nuevo',
      component: <AddMicPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id/editar',
      component: <AddMicPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    {
      path: '/super-admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics',
      component: <CrtRelatedMicsPage />,
      isAvailable: isSuperAdmin(useRole),
    },
    // Admin
    {
      path: '/admin/mics',
      component: <MicsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id',
      component: <MicDetailPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/nuevo',
      component: <AddMicPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id/editar',
      component: <AddMicPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics',
      component: <CrtRelatedMicsPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/mics',
      component: <MicsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/nuevo',
      component: <AddMicPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/crts/:crt_id/mics/:mic_id',
      component: <MicDetailPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id/editar',
      component: <AddMicPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/crts/:crt_id/mics',
      component: <CrtRelatedMicsPage />,
      isAvailable: isTransporter(useRole),
    },
    // Clients
    {
      path: '/cliente/mics',
      component: <MicsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id',
      component: <MicDetailPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/nuevo',
      component: <AddMicPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id/editar',
      component: <AddMicPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics',
      component: <CrtRelatedMicsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    // Despachante
    {
      path: '/despachante/mics',
      component: <MicsPage />,
      isAvailable: isDispatcher(useRole),
    },
    {
      path: '/despachante/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id',
      component: <MicDetailPage />,
      isAvailable: isDispatcher(useRole),
    },
    {
      path: '/despachante/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/nuevo',
      component: <AddMicPage />,
      isAvailable: isDispatcher(useRole),
    },
    {
      path: '/despachante/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics/:mic_id/editar',
      component: <AddMicPage />,
      isAvailable: isDispatcher(useRole),
    },
    {
      path: '/despachante/facturas/:invoice_id/sims/:sim_id/crts/:crt_id/mics',
      component: <CrtRelatedMicsPage />,
      isAvailable: isDispatcher(useRole),
    },
  ];
};
