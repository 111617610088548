import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useState } from 'react';

import {
  GenerateReportActions,
  InputDatePicker,
  InputSelect,
  InputSelectList,
  Loader,
} from '../../../../components';
import {
  StyledFiltersContainer,
  StyledTableContainer,
} from '../../../../utils/styles';
import { InputSelectListOption } from '../../../../utils/types/index';
import { CrtReportsResponse, TableData } from '../../types';
import { orderByOptions, sortByOptions, transformData } from './utils';

type Props = {
  data?: CrtReportsResponse | null;
  onGenerateReportPDF: () => void;
  onGenerateReportXLXS: () => void;
  onFilter: {
    sort: (value: string) => void;
    order: (value: string) => void;
    dateFrom: (value: string) => void;
    dateUntil: (value: string) => void;
    importer: (value: string) => void;
    exporter: (value: string) => void;
    transporter: (value: string) => void;
    companyType: (value: string) => void;
    companyQuery: (value: string) => void;
  };
  onPaginationChange: (page: number) => void;
  currentPage: number;
  isLoading: boolean;
  onCrtReportsFilterOptions: {
    transport: InputSelectListOption[];
    importer: InputSelectListOption[];
    exporter: InputSelectListOption[];
  };
};

const CrtReportsTable: FC<Props> = ({
  data,
  onFilter,
  onPaginationChange,
  onGenerateReportPDF,
  onGenerateReportXLXS,
  onCrtReportsFilterOptions,
  currentPage,
  isLoading,
}) => {
  const newData = transformData(data);

  const paginationData = {
    pageSize: 10,
    current: currentPage,
    defaultCurrent: Number(true),
    total: data?.operatives_count,
  };

  const [dateFromValue, setdateFromValue] = useState<string | undefined>();
  const [dateUntilValue, setdateUntilValue] = useState<string | undefined>();

  const { transport, importer, exporter } = onCrtReportsFilterOptions;

  const columns: ColumnsType<TableData> = [
    { title: 'CRT', key: 'crt', dataIndex: 'crt' },
    { title: 'Factura', key: 'invoice', dataIndex: 'invoice' },
    { title: 'Tipo', key: 'condition', dataIndex: 'condition' },
    { title: 'Exportador', key: 'exporter', dataIndex: 'exporter' },
    { title: 'Importador', key: 'importer', dataIndex: 'importer' },
    { title: 'Transporte', key: 'transport', dataIndex: 'transport' },
    { title: 'Destino', key: 'destination', dataIndex: 'destination' },
    { title: 'Producto', key: 'product', dataIndex: 'product' },
    { title: 'SIM', key: 'sim', dataIndex: 'sim' },
    { title: 'Saldo', key: 'balance', dataIndex: 'balance' },
  ];

  // Date From
  const handleOnFilterDateFrom = React.useCallback(
    (_date: unknown, dateString: string) => {
      setdateFromValue(dateString);
      onFilter.dateFrom(dateString);
    },
    [],
  );

  // Date Until
  const handleOnFilterDateUntil = React.useCallback(
    (_date: unknown, dateString: string) => {
      setdateUntilValue(dateString);
      onFilter.dateUntil(dateString);
    },
    [],
  );

  const handleSortQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.sort(event.target.value);
    },
    [],
  );

  const handleOrderQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onFilter.order(event.target.value);
    },
    [],
  );

  const handleOnSearchTransporterQuery = React.useCallback(
    (event: InputSelectListOption | null) => {
      onFilter.transporter(event?.value as string);
    },
    [],
  );
  const handleOnSearchImporterQuery = React.useCallback(
    (event: InputSelectListOption | null) => {
      onFilter.importer(event?.value as string);
    },
    [],
  );
  const handleOnSearchExporterQuery = React.useCallback(
    (event: InputSelectListOption | null) => {
      onFilter.exporter(event?.value as string);
    },
    [],
  );

  const handleOnInputCompanyChange = function (
    this: { companyType: string },
    value: string,
  ) {
    const { companyType } = this;
    onFilter.companyType(companyType);
    onFilter.companyQuery(value);
  };

  return (
    <StyledTableContainer>
      {isLoading && <Loader />}
      <StyledFiltersContainer>
        <InputDatePicker
          placeholder="Fecha Desde"
          handleOnChange={handleOnFilterDateFrom}
          value={dateFromValue}
        />
        <InputDatePicker
          placeholder="Fecha Hasta"
          handleOnChange={handleOnFilterDateUntil}
          value={dateUntilValue}
        />
        <InputSelectList
          placeholder="Transporte"
          options={transport}
          handleOnChange={handleOnSearchTransporterQuery}
          handleOnInputChange={handleOnInputCompanyChange.bind({
            companyType: 'Transportista',
          })}
        />
        <InputSelectList
          placeholder="Importador"
          options={importer}
          handleOnChange={handleOnSearchImporterQuery}
          handleOnInputChange={handleOnInputCompanyChange.bind({
            companyType: 'Importadora/Exportadora',
          })}
        />
        <InputSelectList
          placeholder="Exportador"
          options={exporter}
          handleOnChange={handleOnSearchExporterQuery}
          handleOnInputChange={handleOnInputCompanyChange.bind({
            companyType: 'Importadora/Exportadora',
          })}
        />
      </StyledFiltersContainer>
      <StyledFiltersContainer>
        <InputSelect
          options={sortByOptions}
          placeholder="Ordenar por"
          onChange={handleSortQuery}
        />
        <InputSelect
          options={orderByOptions}
          placeholder="Órden"
          onChange={handleOrderQuery}
        />
      </StyledFiltersContainer>
      <GenerateReportActions
        onGenerateReportPDF={onGenerateReportPDF}
        onGenerateReportXLXS={onGenerateReportXLXS}
      />
      <Table
        columns={columns}
        dataSource={newData}
        pagination={{
          ...paginationData,
          showSizeChanger: false,
          position: ['bottomCenter'],
          onChange: onPaginationChange,
        }}
      />
    </StyledTableContainer>
  );
};

export default CrtReportsTable;
