import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  fromUserRoleToUrl,
  NotificationMessages,
  URLS,
  WAIT_TIMES,
} from '../../../utils';
import ErrorHandler from '../../../utils/ErrorHandler';
import { useAxios, useShowNotification } from '../../../utils/hooks';
import { Invoice } from '../../../utils/types/invoice';
import useStore from '../../../zustand';
import { Inputs } from '../types';

type Props = {
  invoiceID?: string;
};

const useAddInvoice = ({ invoiceID }: Props) => {
  const API_URL_CREATE_INVOICE = '/custom-invoice/create';
  const API_URL_GET_INVOICE = '/custom-invoice/{id}/find';
  const API_URL_UPDATE_INVOICE = '/custom-invoice/{id}/update';

  const axios = useAxios();
  const navigate = useNavigate();
  const { showNotification, showErrorNotification } = useShowNotification();

  const [useRole] = useStore((state) => [state.userRole]);
  const transformedUserRole = fromUserRoleToUrl(useRole);

  const errorHandler = new ErrorHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [singleInvoiceData, setSingleInvoiceData] = useState<Inputs | null>(
    null,
  );
  const [customError, setCustomError] = useState<string[]>([]);

  // Create / Update
  const handleAddInvoice = async (data: Inputs) => {
    setIsLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      const invoiceData = {
        data: {
          date: data.date,
          po: data.opNumber,
          sale_condition: data.saleCondition,
          invoice_number: data.invoiceNumber,
          exporter_id: data.exporterID.value,
          importer_id: data.importerID.value,
          invoice_items: {
            data: data.items.map((item) => {
              return {
                merchandise_id: item.description.value,
                amount: item.amount,
                currency: item.currency,
                quantity: item.quantity,
                unit: item.UM,
              };
            }),
          },
        },
      };

      if (!invoiceID) {
        // Create Invoice
        await axios.post(
          API_URL_CREATE_INVOICE,
          { ...invoiceData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido creada satisfactoriamente',
        });

        setTimeout(() => {
          navigate(`/${transformedUserRole}${URLS.listInvoices}`);
        }, WAIT_TIMES.md);
      } else {
        // Update Invoice
        await axios.put(
          API_URL_UPDATE_INVOICE.replace('{id}', invoiceID),
          { ...invoiceData },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        showNotification({
          message: 'Ha sido modificado satisfactoriamente',
        });

        setTimeout(() => {
          navigate(
            `/${transformedUserRole}${URLS.invoiceDetail.replace(
              '{invoiceID}',
              invoiceID,
            )}`,
          );
        }, WAIT_TIMES.md);
      }
    } catch (error) {
      showErrorNotification({
        message: NotificationMessages.GENERIC_ERROR,
      });
      log.error(error);
      const mappedError = errorHandler.mapErrorMessage(error);
      setCustomError(mappedError);
      setIsLoading(false);
    }
  };

  // Get Invoice Data
  const handleGetInvoiceData = async (invoiceID: string) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    if (!token) {
      log.error('No token provided');
    }

    axios
      .get<Invoice>(
        `${API_URL_GET_INVOICE.replace('{id}', invoiceID)}?populate=*`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => {
        const result = response.data;

        const transformedData = {
          invoiceNumber: result?.invoice_number,
          date: result?.date,
          opNumber: result?.po,
          exporterID: {
            label: result?.exporter_id?.business_name,
            value: result?.exporter_id?.id,
          },
          importerID: {
            label: result?.importer_id?.business_name,
            value: result?.importer_id?.id,
          },
          saleCondition: result?.sale_condition,
          related_docs: {
            docs: result?.related_docs.docs,
            docs_count: result?.related_docs.docs_count
          },
          items: result?.invoice_items?.map((item) => {
            return {
              description: {
                label: item?.merchandise_id?.description,
                value: item?.merchandise_id?.id,
              },
              quantity: item?.quantity,
              UM: item?.unit,
              unitValue: item?.unit_price?.toString(),
              amount: item?.amount?.toString(),
              currency: item?.currency,
            };
          }),
        };
        setSingleInvoiceData(transformedData);
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (invoiceID) handleGetInvoiceData(invoiceID);
  }, []);

  return {
    isLoading,
    handleAddInvoice,
    singleInvoiceData,
    customError,
  };
};

export default useAddInvoice;
