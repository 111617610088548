import {
  FaRegFileAlt,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePdf,
} from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

const extensionIconMap: {
  [key: string]: IconType;
} = {
  pdf: FaRegFilePdf,
  xlsx: FaRegFileExcel,
  png: FaRegFileImage,
  jpg: FaRegFileImage,
  jpeg: FaRegFileImage,
};

export const getExtensionIcon = (ext: string) =>
  extensionIconMap[ext] ?? FaRegFileAlt;
