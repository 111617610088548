import React from 'react';

import {
  CtrReportsPage,
  OpReportsPage,
  SemitrailerReportsPage,
  TruckDriverReportsPage,
  TruckReportsPage,
} from '../../../pages';
import { isAdmin, isImpExpAdmin, isTransporter } from '../../roles';
import { Routes } from '../../types/route';

export const reportRoutes = (useRole: string): Routes[] => {
  return [
    // Admin
    {
      path: '/admin/reportes/op-operativas',
      component: <OpReportsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/reportes/crt-operativas',
      component: <CtrReportsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/reportes/camiones',
      component: <TruckReportsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/reportes/semiremolques',
      component: <SemitrailerReportsPage />,
      isAvailable: isAdmin(useRole),
    },
    {
      path: '/admin/reportes/choferes',
      component: <TruckDriverReportsPage />,
      isAvailable: isAdmin(useRole),
    },
    // Transporter
    {
      path: '/transportista/reportes/op-operativas',
      component: <OpReportsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/reportes/crt-operativas',
      component: <CtrReportsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/reportes/camiones',
      component: <TruckReportsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/reportes/semiremolques',
      component: <SemitrailerReportsPage />,
      isAvailable: isTransporter(useRole),
    },
    {
      path: '/transportista/reportes/choferes',
      component: <TruckDriverReportsPage />,
      isAvailable: isTransporter(useRole),
    },
    // Clients
    {
      path: '/cliente/reportes/op-operativas',
      component: <OpReportsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/reportes/crt-operativas',
      component: <CtrReportsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/reportes/camiones',
      component: <TruckReportsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/reportes/semiremolques',
      component: <SemitrailerReportsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
    {
      path: '/cliente/reportes/choferes',
      component: <TruckDriverReportsPage />,
      isAvailable: isImpExpAdmin(useRole),
    },
  ];
};
