import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';

import Typography from '../Typography';
import {
  StyledCheckbox,
  StyledCheckboxContainer,
  SubTextContainer,
} from './InputCheckbox.styled';

export type Props = {
  $hasValue?: boolean;
  $invalid?: boolean;
  customID: string;
  label?: string;
  subTextMessage?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const InputCheckbox: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { customID, label, name, subTextMessage, value, ...rest },
  ref,
) => {
  return (
    <StyledCheckboxContainer>
      <StyledCheckbox>
        <input
          id={`input-checkbox-${customID}`}
          name={name}
          ref={ref}
          type="checkbox"
          value={value}
          {...rest}
        />
        <label htmlFor={`input-checkbox-${customID}`}>
          <Typography theme="p">{label}</Typography>
        </label>
      </StyledCheckbox>
      {subTextMessage && (
        <SubTextContainer>
          <Typography theme="subtext">{subTextMessage}</Typography>
        </SubTextContainer>
      )}
    </StyledCheckboxContainer>
  );
};

export default React.forwardRef(InputCheckbox);
